import LogoLoader from '../LogoLoader';
import Modal from './Modal';

const LoadingModal = ({ 
    handleClose,
    message = 'Loading...' 
}) => {
    return (
        <Modal open dismissable={!!handleClose} blocking={false} handleClose={handleClose}>
            <Modal.Body>
                <div style={{
                    textAlign: 'center',
                    margin: '50px 0'
                }}>
                    <LogoLoader size={60} hideLogo />
                    <p
                        style={{
                            margin: '12px 0',
                            color: 'var(--light-text-color',
                            fontSize: '.9rem',
                            userSelect: 'none'
                        }}
                    >{message}</p>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default LoadingModal;
