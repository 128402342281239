import React from 'react';

import PropTypes from 'prop-types';

import styles from '../../../../styles/general/grid/overlays/NoRowsOverlay.module.scss';

const NoRowsOverlay = ({ content }) =>
    !content || typeof content === 'string' ? (
        <div className={styles.noItems}>{content ?? 'No Data To Display'}</div>
    ) : (
        content
    );

NoRowsOverlay.propTypes = {
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.object,
        PropTypes.func
    ])
};

export default NoRowsOverlay;
