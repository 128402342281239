import Checkbox from '../../input/Checkbox';
import PropTypes from 'prop-types';

import styles from '../../../../styles/general/grid/cell renderers/CheckboxCell.module.scss';

const CheckboxCell = ({ checked, disabled, label }) => (
    <div className={styles.checkboxCell}>
        <Checkbox checked={checked} disabled={disabled} label={label} />
    </div>
);

CheckboxCell.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string
};

export default CheckboxCell;
