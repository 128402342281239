import React, { useEffect, useState } from 'react';

import useApi from '../../../../hooks/useApi';

import DatePicker from '../../../general/input/DatePicker';
import FileUpload from '../../../general/input/FileUpload';
import InputLabel from '../../../general/input/InputLabel';
import Select from '../../../general/input/Select';
import LoadingOverlay from '../../../general/LoadingOverlay';
import Modal from '../../../general/modal/Modal';
import { isNumber } from 'lodash';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import styles from '../../../../styles/apps/certifications/ReviewCertificationModal.module.scss';

const ReviewCertificationModal = ({
    certificationId,
    handleClose = () => null,
    onSaved = () => null
}) => {
    const [{ data, loading: certificationLoading }] = useApi(
        `/employee-certifications/${certificationId}`,
        'GET'
    );
    const [
        { loading: loadingAttachments, error: attachmentError },
        getAttachments
    ] = useApi(
        `/employee-certifications/${certificationId}/attachments`,
        'GET',
        { manual: true }
    );
    const [{ loading: reviewLoading }, updateCertification] = useApi(
        `/employee-certifications/${certificationId}`,
        'PUT',
        { manual: true }
    );

    const { enqueueSnackbar } = useSnackbar();

    const [attachments, setAttachments] = useState(null);
    const [employee, setEmployee] = useState([]);
    const [employeeError, setEmployeeError] = useState(null);
    const [certification, setCertification] = useState(null);
    const [certificationError, setCertificationError] = useState(null);
    const [receivedDate, setReceivedDate] = useState(null);
    const [receivedError, setReceivedError] = useState(null);
    const [expiresDate, setExpiresDate] = useState(null);
    const [expiresError, setExpiresError] = useState(null);
    const [removedAttachmentIds, setRemovedAttachmentIds] = useState([]);
    const [formAttachmentError, setFormAttachmentError] = useState(null);

    const handleEmployeeChange = (employee) => setEmployee(employee);

    const handleCertificationChange = (certifications) => {
        setCertification(certifications);
    };

    const handleReceiveChange = (date) => setReceivedDate(date);
    const handleExpiresChange = (date) => setExpiresDate(date);

    let handleRemoveAttachment = (id) =>
        isNumber(id) && setRemovedAttachmentIds((removed) => [...removed, id]);

    let handleAttachmentChange = (attachments) => {
        setAttachments(attachments);
    };

    const resetErrors = () => {
        setReceivedError(null);
        setEmployeeError(null);
        setCertificationError(null);
        setFormAttachmentError(null);
    };

    const handleReview = () => {
        let formData = new FormData();
        resetErrors();

        if (!employee || employee?.length === 0) {
            return setEmployeeError('You must specify an employee.');
        }

        if (!receivedDate) {
            return setReceivedError('You must specify a received date.');
        }

        if (receivedDate <= new Date('1970-01-01T00:00:00')) {
            return setReceivedError(
                'Please provide a 4 digit year after 1970.'
            );
        }

        if (expiresDate && new Date(receivedDate) > new Date(expiresDate)) {
            return setExpiresError('Expires date must be after Received date.');
        }

        if (!certification || certification?.length === 0) {
            return setCertificationError(
                'You must specify a certification type.'
            );
        }

        if (attachments?.length <= 0) {
            return setFormAttachmentError(
                'You must include an attachment for this certification.'
            );
        }

        formData.append(
            'jsonData',
            JSON.stringify({
                employeeId: employee[0].id,
                expiresOn: expiresDate
                    ? DateTime.fromJSDate(new Date(expiresDate)).toFormat(
                          'yyyy-MM-dd'
                      )
                    : null,
                certifiedOn: receivedDate
                    ? DateTime.fromJSDate(new Date(receivedDate)).toFormat(
                          'yyyy-MM-dd'
                      )
                    : null,
                certificationTypeId: certification[0].id,
                removedAttachmentIds: removedAttachmentIds
            })
        );

        attachments
            .filter((a) => !isNumber(a.id) && a.file)
            .forEach((attachment) =>
                formData.append(attachment.filename, attachment.file)
            );

        updateCertification({
            data: formData
        })
            .then((row) => {
                handleClose();
                enqueueSnackbar('Certification review added.', {
                    variant: 'success',
                    autoHideDuration: 3000,
                    preventDuplicate: true
                });
                onSaved(row);
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar('Failed to add review.', {
                    variant: 'error',
                    autoHideDuration: 3000,
                    preventDuplicate: false
                });
            });
    };

    useEffect(() => {
        if (!data) return;

        setExpiresDate(
            data?.expiresOn && data?.expiresOn >= data?.certifiedOn
                ? DateTime.fromISO(data?.expiresOn, { zone: 'utc' })
                      .setZone('local', { keepLocalTime: true })
                      .toJSDate()
                : null
        );
        setReceivedDate(
            data?.certifiedOn
                ? DateTime.fromISO(data?.certifiedOn, { zone: 'utc' })
                      .setZone('local', { keepLocalTime: true })
                      .toJSDate()
                : null
        );
        setCertification([
            {
                id: data.certificationTypeId,
                code: data.code,
                description: data.description
            }
        ]);
        setEmployee([
            {
                id: data.employeeId,
                firstName: data.firstName,
                lastName: data.lastName,
                middleName: data.middleName
            }
        ]);
    }, [data]);

    useEffect(() => {
        getAttachments()
            .then((attachments) => {
                setAttachments(
                    attachments.map((a) => ({
                        ...a,
                        download: `/employee-certifications/${certificationId}/attachments/${a?.id}/download`,
                        preview: `/employee-certifications/${certificationId}/attachments/${a?.id}/preview`
                    }))
                );
            })
            .catch((err) => {
                console.error('err', err);
                enqueueSnackbar(
                    'Error encountered while retrieving attachments.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000
                    }
                );
            });
    }, []); //eslint-disable-line

    return (
        <Modal
            blocking={false}
            open={!!certificationId}
            handleClose={handleClose}
            style={{
                content: {
                    minHeight: '512px'
                }
            }}
        >
            <Modal.Title>Review Certification</Modal.Title>
            <Modal.Body>
                <div className={styles.container}>
                    {certificationLoading ? (
                        <LoadingOverlay
                            size="md"
                            spinColor="rgba(0,0,0,0.4)"
                            backgroundColor="rgba(0,0,0,0.1)"
                            label="Loading Certification..."
                            fontSize="14px"
                            labelPosition="right"
                        />
                    ) : (
                        <div className={styles.section}>
                            <div className={styles.detailGroup}>
                                <div
                                    className={[
                                        styles.detailProperty,
                                        styles.flexGrow
                                    ].join(' ')}
                                    style={{ maxWidth: '309px' }}
                                >
                                    <InputLabel
                                        label="Certified Employee"
                                        required={true}
                                        error={employeeError}
                                    >
                                        <Select
                                            handleRowSelection={
                                                handleEmployeeChange
                                            }
                                            selected={employee}
                                            getRowValue={(row) =>
                                                row?.firstName || row?.lastName
                                                    ? `${row?.lastName}, ${
                                                          row?.firstName
                                                      } ${
                                                          row?.middleName ?? ''
                                                      }`
                                                    : undefined
                                            }
                                            getRowId={(row) => row.id}
                                            pagination={{
                                                url: '/employees'
                                            }}
                                            placeholder="Employee Name"
                                            sort={[
                                                'LastName',
                                                'FirstName',
                                                'MiddleName',
                                                'Suffix',
                                                'EmployeeNumber'
                                            ]}
                                        />
                                    </InputLabel>
                                </div>
                                <div className={styles.detailProperty}>
                                    <InputLabel
                                        label="Received"
                                        required={true}
                                        error={receivedError}
                                    >
                                        <DatePicker
                                            value={receivedDate}
                                            onChange={handleReceiveChange}
                                        />
                                    </InputLabel>
                                </div>
                            </div>
                            <div className={styles.detailGroup}>
                                <div
                                    className={[
                                        styles.detailProperty,
                                        styles.flexGrow
                                    ].join(' ')}
                                    style={{ maxWidth: '309px' }}
                                >
                                    <InputLabel
                                        label="Certification"
                                        required={true}
                                        error={certificationError}
                                    >
                                        <Select
                                            handleRowSelection={
                                                handleCertificationChange
                                            }
                                            selected={certification}
                                            getRowValue={(row) =>
                                                row?.code || row?.description
                                                    ? `${row?.code} - ${row?.description}`
                                                    : undefined
                                            }
                                            getRowNodeId={(row) => row.id}
                                            pagination={{
                                                url: '/certification-types'
                                            }}
                                            sort={['Code', 'Description']}
                                            placeholder="Certification Name"
                                        />
                                    </InputLabel>
                                </div>
                                <div className={styles.detailProperty}>
                                    <InputLabel
                                        label="Expires"
                                        error={expiresError}
                                    >
                                        <DatePicker
                                            value={expiresDate}
                                            onChange={handleExpiresChange}
                                        />
                                    </InputLabel>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={styles.section}>
                        <div className={styles.detailGroup}>
                            <div className={styles.detailProperty}>
                                <InputLabel
                                    label="Attachments"
                                    required={true}
                                    error={formAttachmentError}
                                    labelClick={false}
                                >
                                    {loadingAttachments || !attachments ? (
                                        <LoadingOverlay
                                            size="xs"
                                            spinColor="rgba(0,0,0,0.4)"
                                            backgroundColor="rgba(0,0,0,0.1)"
                                            label="Loading..."
                                            fontSize="12px"
                                            labelPosition="right"
                                        />
                                    ) : (
                                        <FileUpload
                                            files={attachments}
                                            downloadSingle={true}
                                            emptyMessage={
                                                attachmentError &&
                                                attachmentError?.response
                                                    ?.status === 403
                                                    ? "You don't have permissions to view the attachments."
                                                    : attachmentError
                                                    ? 'Error retrieving attachments'
                                                    : 'No attachments added.'
                                            }
                                            downloadAll={`/certifications/${certificationId}/attachments/download`}
                                            handleRemove={
                                                handleRemoveAttachment
                                            }
                                            handleChange={
                                                handleAttachmentChange
                                            }
                                        />
                                    )}
                                </InputLabel>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Actions
                buttons={[
                    {
                        type: 'secondary',
                        variant: 'border',
                        label: 'Cancel',
                        onClick: handleClose
                    },
                    {
                        type: 'primary',
                        label: 'Review',
                        onClick: handleReview,
                        loading: reviewLoading
                    }
                ]}
            />
        </Modal>
    );
};

ReviewCertificationModal.propTypes = {
    certificationId: PropTypes.number,
    handleClose: PropTypes.func
};

export default ReviewCertificationModal;
