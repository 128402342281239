import useApi from '../../../../hooks/useApi';

import ErrorMessage from '../../../general/ErrorMessage';
import Button from '../../../general/input/Button';
import Modal from '../../../general/modal/Modal';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';

import styles from '../../../../styles/apps/contact/modal/DeleteCompanyModal.module.scss';

const DeleteCompanyModal = ({ handleClose, handleDelete, record }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [{ loading, error }, deleteContact] = useApi(
        '/contact/company',
        'DELETE',
        {
            manual: true
        }
    );

    const handleSubmitClicked = () =>
        deleteContact({
            url: `/contact/company/${record.id}`
        })
            .then(() => {
                handleDelete?.(record.id);
                enqueueSnackbar('Company deleted successfully.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                handleClose?.();
            })
            .catch((err) => {
                console.error(err);
                //TODO: show error title/message here as well as display it in the modal
                enqueueSnackbar(
                    err.response?.data?.title ?? 'Failed to delete Company.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000
                    }
                );
            });

    return (
        <Modal open handleClose={handleClose}>
            <Modal.Title icon={faTrashAlt}>Delete Company</Modal.Title>
            <Modal.Body>
                <div className={styles.confirmationMessage}>
                    Are you sure you want to delete this company?
                </div>
                <div className={styles.resourceContainer}>
                    <div className={styles.resourceMessage}>
                        The following resources will be deleted:
                    </div>
                    <div className={styles.resourceList}>
                        <ul>
                            <li>1 Company</li>
                            {record?.addresses?.filter?.((a) => !a.isDeleted)
                                ?.length > 0 && (
                                <li>{`${
                                    record?.addresses?.filter?.(
                                        (a) => !a.isDeleted
                                    )?.length
                                } Addresses`}</li>
                            )}
                            {record?.contacts?.filter?.((c) => !c.isDeleted)
                                ?.length > 0 && (
                                <li>{`${
                                    record?.contacts?.filter?.(
                                        (c) => !c.isDeleted
                                    )?.length
                                } Contacts`}</li>
                            )}
                        </ul>
                    </div>
                </div>
                {error && <ErrorMessage error={error?.response?.data?.detail} />}
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Confirm"
                    onClick={handleSubmitClicked}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteCompanyModal;
