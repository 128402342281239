import InputLabel from '../input/InputLabel';

import styles from '../../../styles/general/grid/FilterItem.module.scss';

const FilterItem = ({ filter }) => (
    <div className={styles.filterContent}>
        <InputLabel label={filter.label}>
            <filter.component {...filter.props} />
        </InputLabel>
    </div>
);

export default FilterItem;
