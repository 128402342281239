import useApi from '../../../../hooks/useApi';

import Button from '../../../general/input/Button';
import Modal from '../../../general/modal/Modal';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';

import styles from '../../../../styles/apps/quality/modal/DeleteEquipmentFormModal.module.scss';

const DeleteEquipmentFormModal = ({
    handleClose,
    handleDelete,
    equipmentForm
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [{ loading }, deleteForm] = useApi('/quality/form', 'DELETE', {
        manual: true
    });

    const handleSubmitClicked = () =>
        deleteForm({
            url: `/quality/form/${equipmentForm.id}`
        })
            .then(() => {
                handleDelete?.(equipmentForm.id);
                enqueueSnackbar('Form deleted successfully.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                handleClose?.();
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Failed to delete Form.', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });

    return (
        <Modal open handleClose={handleClose}>
            <Modal.Title icon={faTrashAlt}>Delete Form</Modal.Title>
            <Modal.Body>
                <div className={styles.confirmationMessage}>
                    Are you sure you want to delete this form?
                </div>
                <div className={styles.resourceContainer}>
                    <div className={styles.resourceMessage}>
                        The following resources will be deleted:
                    </div>
                    <div className={styles.resourceList}>
                        <ul>
                            <li>1 Form</li>
                        </ul>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Confirm"
                    onClick={handleSubmitClicked}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteEquipmentFormModal;
