import React, { useRef } from 'react';

import useOutsideClick from '../../hooks/useOutsideClick';
import useToggle from '../../hooks/useToggle';

import { isFunction } from 'lodash';
import PropTypes from 'prop-types';

import styles from '../../styles/DropdownMenu.module.scss';

const DropdownMenu = ({
    button,
    content,
    classes = {},
    orientation = 'right',
    disabled = false,
    overlay,
    style,
    open,
    hidden = false,
    handleToggle = () => null,
    width,
    uncontrolled = false
}) => {
    let [toggled, handleToggled] = useToggle();

    let menuRef = useRef();

    const menuToggle = (state) => {
        if (toggled !== state) handleToggled(state, handleToggle);
    };

    useOutsideClick(menuRef, () => menuToggle(false));

    return (
        <div
            className={[styles.dropdownContainer, classes.container].join(' ')}
            ref={menuRef}
            style={style?.container}
        >
            <div
                className={styles.buttonContainer}
                style={style?.buttonContainer}
                onClick={() =>
                    !uncontrolled && !disabled && menuToggle(!toggled)
                }
            >
                {isFunction(button) ? button(toggled, menuToggle) : button}
            </div>
            {(toggled || open || hidden) && (
                <div
                    className={styles.menuContainer}
                    style={{
                        right: orientation === 'left' ? 0 : null,
                        left: orientation === 'right' ? 0 : null,
                        top: overlay ? 0 : '100%',
                        display: open || toggled ? 'block' : 'none',
                        maxWidth: width ? null : '100%',
                        width: width,
                        ...style?.menuContainer
                    }}
                >
                    {isFunction(content)
                        ? content(toggled, menuToggle)
                        : content}
                </div>
            )}
        </div>
    );
};

DropdownMenu.propTypes = {
    button: PropTypes.func.isRequired,
    content: PropTypes.func.isRequired,
    orientation: PropTypes.oneOf(['right', 'left', 'center']),
    disabled: PropTypes.bool,
    overlay: PropTypes.bool,
    style: PropTypes.object,
    open: PropTypes.bool,
    hidden: PropTypes.bool,
    width: PropTypes.string,
    handleToggle: PropTypes.func
};

export default DropdownMenu;
