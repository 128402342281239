import React, { useEffect, useState } from 'react';

import useApi from '../../../hooks/useApi';
import useFetch from '../../../hooks/useFetch';

import LoadingOverlay from '../../general/LoadingOverlay';
import DocumentCard from './DocumentCard';
import { useSnackbar } from 'notistack';

import styles from '../../../styles/apps/documents/Documents.module.scss';

const Documents = () => {
    const { enqueueSnackbar } = useSnackbar();

    const [{ data: docs, loading: docsLoading, error: docsError }] = useApi(
        '/documents?platform=web',
        'GET'
    );
    const [, previewDocument] = useApi('', 'GET', { manual: true });
    const [{ loading }, downloadDocument] = useFetch('', 'GET', {
        manual: true
    });

    const [downloadLoading, setDownloadLoading] = useState(loading);

    useEffect(() => {
        document.title = 'CVE Apps - Documents';
    }, []);

    const handlePreviewDocument = (id) => {
        previewDocument({
            url: `/documents/${id}/preview`
        })
            .then((attachmentUrl) => {
                window.open(attachmentUrl, '_blank');
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar(
                    'Could not preview this file. Please try again.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000
                    }
                );
            });
    };

    const handleDownloadDocument = (id, fileName) => {
        setDownloadLoading(id);
        downloadDocument({
            url: `/documents/${id}/download`
        })
            .then(
                (res) =>
                    new Promise((resolve, reject) => {
                        if (!res.ok) reject();
                        else resolve(res.blob());
                    })
            )
            .then((data) => {
                var objectURL = URL.createObjectURL(data);
                let element = document.createElement('a');
                element.href = objectURL;
                element.target = '_blank';
                element.download = fileName;
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                URL.revokeObjectURL(objectURL);
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar(
                    'Something went wrong when downloading the file.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000,
                        preventDuplicate: true
                    }
                );
            })
            .finally(() => {
                setDownloadLoading(false);
            });
    };

    useEffect(() => {
        if (!docsError) return;
        else
            enqueueSnackbar('Error retrieving documents.', {
                variant: 'error',
                autoHideDuration: 3000,
                preventDuplicate: true
            });
    }, [docsError, enqueueSnackbar]);

    return (
        <div className={styles.documentContainer}>
            {docsLoading || !docs ? (
                <LoadingOverlay
                    spinColor="rgba(0,0,0,0.7)"
                    backgroundColor="rgba(0,0,0,0.1)"
                    label="Loading Documents..."
                    classes={{ container: styles.loading }}
                />
            ) : (
                <React.Fragment>
                    <h3 className={styles.header}>Documents</h3>
                    {docs?.map((document) => (
                        <DocumentCard
                            {...document}
                            key={document.id}
                            handleDownload={handleDownloadDocument.bind(
                                this,
                                document.id,
                                document.fileName
                            )}
                            handlePreview={handlePreviewDocument.bind(
                                this,
                                document.id
                            )}
                            downloading={downloadLoading === document.id}
                        />
                    ))}
                </React.Fragment>
            )}
        </div>
    );
};

export default Documents;
