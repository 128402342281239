import React, { useRef, useState } from 'react';

import useUser from '../../../../hooks/useUser';

import {
    CertificationTypeCreatePermissions,
    CertificationTypeUpdatePermissions
} from '../../../../js/services/permissions';
import ActionCell from '../../../general/grid/cell renderers/ActionCell';
import TextCell from '../../../general/grid/cell renderers/TextCell';
import Filters from '../../../general/grid/Filters';
import Grid from '../../../general/grid/Grid';
import Checkbox from '../../../general/input/Checkbox';
import Select from '../../../general/input/Select';
import EditCertificationTypeModal from '../modal/EditCertificationTypeModal';
import NewCertificationTypeModal from '../modal/NewCertificationTypeModal';
import {
    faFilter,
    faPencilAlt,
    faPlus
} from '@fortawesome/free-solid-svg-icons';

import styles from '../../../../styles/apps/certifications/Grid.module.scss';

const defaultFilterModel = {
    certificationTypes: {
        value: [],
        getFilter: (certificationTypes) =>
            certificationTypes.length === 0
                ? null
                : {
                      CertificationTypeIds: certificationTypes.map?.(
                          (ct) => ct.id
                      )
                  }
    },
    showInactive: {
        value: false,
        getFilter: (showInactive) => ({ ShowInactive: showInactive })
    }
};

const CertificationTypeGrid = () => {
    const gridRef = useRef();

    const { permissions } = useUser();

    //Filter state
    const [filter, setFilter] = useState(defaultFilterModel);

    //Modal state
    const [newModal, setNewModal] = useState(false);
    const [editModal, setEditModal] = useState(null);

    /**
     * Filter state handlers
     */

    /**
     * Handle the certification type select change event
     * @param {*} certificationTypes - list of certification types selected
     */
    const handleCertificationTypeChange = (certificationTypes) => {
        setFilter((filter) => ({
            ...filter,
            certificationTypes: {
                ...filter['certificationTypes'],
                value: certificationTypes
            }
        }));
    };

    /**
     * Handle the active change event
     * @param {*} active - state of the active toggle
     */
    const handleActiveChange = () => {
        setFilter((filter) => ({
            ...filter,
            showInactive: {
                ...filter['showInactive'],
                value: !filter['showInactive']?.value
            }
        }));
    };

    /**
     * Reset the filter state values to their defaults
     */
    const clearFilters = () => {
        setFilter(defaultFilterModel);
    };

    /**
     * Open the new or edit modals
     * @returns null
     */
    const handleNew = () => setNewModal(true);
    const handleEdit = (row) => {
        setEditModal(row);
    };

    const handleCreated = (row) => {
        gridRef?.current?.addRow(row);
    };

    const handleEdited = (row) => {
        gridRef?.current?.modifyRow(row.id, row);
    };

    return (
        <div className={styles.container}>
            <Grid
                ref={gridRef}
                classes={{ header: styles.gridHeader }}
                actions={[
                    {
                        type: 'primary',
                        label: 'New',
                        icon: faPlus,
                        onClick: handleNew,
                        permission: CertificationTypeCreatePermissions
                    }
                ].filter(
                    (action) =>
                        !action.permission ||
                        action.permission.every((p) => permissions?.includes(p))
                )}
                fixed
                filters={filter}
                getRowId={(r) => r.id}
                pagination={{
                    url: '/certification-types',
                    pageSize: 100
                }}
                sidepanel={{
                    filters: {
                        label: 'Filters',
                        icon: faFilter,
                        component: Filters,
                        props: {
                            clearFilters: clearFilters,
                            filters: [
                                {
                                    label: 'Certification Type',
                                    component: Select,
                                    props: {
                                        placeholder:
                                            'Select Certification Type(s)',
                                        multiselect: true,
                                        handleRowSelection:
                                            handleCertificationTypeChange,
                                        selected:
                                            filter.certificationTypes?.value,
                                        getRowId: (type) => type.id,
                                        getRowValue: (type) =>
                                            `${type?.code} - ${type?.description}`,
                                        pagination: {
                                            url: '/certification-types'
                                        },
                                        filter: {
                                            ShowInactive: {
                                                value: filter?.showInactive
                                                    ?.value,
                                                getFilter: (showInactive) => ({
                                                    ShowInactive: showInactive
                                                })
                                            }
                                        },
                                        sort: ['Code']
                                    }
                                },
                                {
                                    component: Checkbox,
                                    props: {
                                        handleChange: handleActiveChange,
                                        checked: filter['showInactive'].value,
                                        label: 'Show Inactive'
                                    }
                                }
                            ]
                        }
                    }
                }}
                columns={[
                    {
                        title: 'Code',
                        key: 'Code',
                        dataKey: 'code',
                        sortKey: 'Code',
                        width: 140,
                        maxWidth: 140,
                        cellRenderer: ({ cellData }) =>
                            cellData && <TextCell>{cellData}</TextCell>
                    },
                    {
                        title: 'Description',
                        key: 'Description',
                        dataKey: 'description',
                        sortKey: 'Description',
                        fixedGrow: 1,
                        minWidth: 200,
                        cellRenderer: ({ cellData }) =>
                            cellData && <TextCell>{cellData}</TextCell>
                    },
                    {
                        title: 'Active',
                        key: 'IsActive',
                        dataKey: 'isActive',
                        sortKey: 'IsActive',
                        cellRenderer: ({ cellData }) => (
                            <TextCell>{cellData ? 'Yes' : 'No'}</TextCell>
                        ),
                        width: 120,
                        maxWidth: 120
                    },
                    ...(CertificationTypeUpdatePermissions.every((p) =>
                        permissions.includes?.(p)
                    )
                        ? [
                              {
                                  title: ' ',
                                  key: 'Actions',
                                  width: 60,
                                  minWidth: 60,
                                  sortable: false,
                                  frozen: 'right',
                                  cellRenderer: ({ rowData }) => (
                                      <ActionCell
                                          actions={[
                                              {
                                                  icon: faPencilAlt,
                                                  type: 'grayscale',
                                                  onClick: handleEdit.bind(
                                                      this,
                                                      rowData?.id
                                                  ),
                                                  tooltip: {
                                                      tooltip: 'Edit',
                                                      hoverDelay: 650,
                                                      hoverTrigger: 'always'
                                                  }
                                              }
                                          ]}
                                      />
                                  )
                              }
                          ]
                        : [])
                ]}
            />
            {newModal && (
                <NewCertificationTypeModal
                    open={newModal}
                    blocking={false}
                    handleClose={() => setNewModal(false)}
                    onCreated={handleCreated}
                />
            )}
            {!!editModal && (
                <EditCertificationTypeModal
                    open={!!editModal}
                    blocking={false}
                    handleClose={() => setEditModal(false)}
                    onEdited={handleEdited}
                    certificationId={editModal}
                />
            )}
        </div>
    );
};

export default CertificationTypeGrid;
