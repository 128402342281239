import Checkbox from '../input/Checkbox';

const FormYesNo = ({ error, id, isEditable, value, onChange, styles, ...props }) => {
    value = value === 'yes';

    const handleChange = () => onChange(value ? 'no' : 'yes');

    return isEditable ? (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: error ? 'rgba(255, 144, 144, 0.5)' : 'rgba(195, 237, 251, 0.5)',
                minHeight: '100%',
                width: '100%',
                ...styles
            }}
        >
            <Checkbox checked={value} handleChange={handleChange} />
        </div>
    ) : (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
                marginTop: '2px'
            }}
        >
            <Checkbox checked={value} disabled />
        </div>
    );
};

export default FormYesNo;
