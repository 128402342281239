import { useState } from 'react';
import useApi from './useApi';
import { useSnackbar } from 'notistack';

const useS3PresignedUrl = (baseUrl) => {
    const { enqueueSnackbar } = useSnackbar();
    const [{ loading }, getPresignedUrl] = useApi(baseUrl, 'GET', {
        manual: true
    });
    const [id, setId] = useState(null);

    const retrievePresignedUrl = (id, url) => {
        setId(id);
        getPresignedUrl({
            url: url ?? baseUrl
        })
            .then((presignedUrl) => {
                window.open(presignedUrl, '_blank');
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar(
                    'Could not preview this file. Please try again.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000
                    }
                );
            })
            .finally(() => setId(null));
    };

    return {
        id,
        loading,
        getUrl: retrievePresignedUrl
    };
};

export default useS3PresignedUrl;
