import React, { useRef, useState } from 'react';

import { isNullOrEmpty } from '../../../js/services/validation';
import DropdownMenu from '../DropdownMenu';
import Loader from '../Loader';
import {
    faCheck,
    faChevronDown,
    faChevronUp
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import styles from '../../../styles/general/input/Dropdown.module.scss';

const Dropdown = ({
    placeholder,
    options,
    loading = false,
    multiselect = false,
    selected,
    readonly = false,
    handleSelect = () => null,
    persistent,
    required = false,
    style = {},
    hideArrow = false,
    dropdownWidth,
    buttonPadding
}) => {
    const inputRef = useRef();

    const [query, setQuery] = useState('');

    const _handleSelect = (selection) => {
        let selectedValues = Array.isArray(selected)
            ? selected
            : selected
            ? [selected]
            : [];

        if (multiselect) {
            if (selectedValues.some((s) => s?.key === selection?.key)) {
                let filteredValues = selectedValues.filter(
                    (s) => s?.key !== selection?.key
                );
                if (required && filteredValues.length === 0)
                    return handleSelect(selectedValues);
                return handleSelect(
                    selectedValues.filter((s) => s?.key !== selection?.key)
                );
            } else {
                return handleSelect([...selectedValues, selection]);
            }
        } else {
            if (selectedValues[0]?.key === selection?.key && !required) {
                return handleSelect(null);
            } else {
                handleSelect(selection);
            }
        }
    };

    const handleChange = (e) => {
        if (document.activeElement !== inputRef.current && !multiselect) {
            let closestMatch = null;
            closestMatch = options?.find?.((option) =>
                option?.key
                    ?.toLowerCase?.()
                    .includes(e.target.value?.toLowerCase?.())
            );
            if (closestMatch) handleSelect(closestMatch);
        }
        setQuery(e.target.value);
    };

    return (
        <DropdownMenu
            uncontrolled
            button={(active, handleToggle) => (
                <DropdownButton
                    active={active}
                    selected={selected}
                    placeholder={placeholder}
                    readonly={readonly}
                    loading={loading}
                    style={style}
                    hideArrow={hideArrow}
                    buttonPadding={buttonPadding}
                    query={query}
                    handleChange={handleChange}
                    handleToggle={handleToggle}
                    inputRef={inputRef}
                />
            )}
            content={(active, handleClose) =>
                !readonly && (
                    <DropdownContent
                        active={active}
                        multiselect={multiselect}
                        handleClose={handleClose}
                        persistent={persistent}
                        options={options}
                        handleSelect={_handleSelect}
                        selected={selected}
                        loading={loading}
                        query={query}
                    />
                )
            }
            style={{
                menuContainer: {
                    boxShadow: '2px 5px 12px 2px rgba(0,0,0,0.05)',
                    borderRadius: '4px',
                    left: '-1px',
                    top: 'calc(100% + 2px)'
                },
                container: { ...style.container },
                buttonContainer: { ...style.buttonContainer }
            }}
            width={dropdownWidth}
            disabled={readonly}
        />
    );
};

const DropdownButton = ({
    active,
    selected,
    placeholder,
    readonly,
    loading,
    style,
    hideArrow,
    buttonPadding,
    query,
    handleChange,
    handleToggle,
    inputRef
}) => (
    <div
        className={[
            styles.buttonContainer,
            active && !readonly ? styles['toggled'] : null,
            readonly ? styles['readonly'] : null
        ].join(' ')}
        style={{ ...style.buttonContainer, padding: buttonPadding }}
    >
        {loading && !active ? (
            <div className={styles.loader}>
                <Loader
                    spinColor="var(--xlight-text-color)"
                    background="rgba(0,0,0,0.1)"
                />
            </div>
        ) : null}
        <input
            type="text"
            value={
                !active && !isNullOrEmpty(selected)
                    ? selected && selected.length > 1
                        ? `${selected.length} Selected`
                        : selected.length === 1
                        ? selected[0].value
                        : selected.value
                    : active
                    ? query
                    : ''
            }
            placeholder={placeholder}
            onChange={handleChange}
            onFocus={handleToggle.bind(this, true)}
            className={styles.selected}
            ref={inputRef}
        />
        {!hideArrow && (
            <div
                className={[
                    styles.activeIcon,
                    active && !readonly ? styles['toggled'] : null,
                    readonly ? styles['readonly'] : null
                ].join(' ')}
                onClick={(e) => {
                    handleToggle();
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <FontAwesomeIcon icon={active ? faChevronUp : faChevronDown} />
            </div>
        )}
    </div>
);

const DropdownContent = ({
    selected,
    multiselect,
    persistent,
    handleClose,
    options,
    handleSelect,
    loading,
    query
}) => {
    let filteredOptions = options?.filter?.(
        (option) =>
            isNullOrEmpty(query) ||
            option.key?.toLowerCase?.().includes?.(query?.toLowerCase?.()) ||
            option.value?.toLowerCase?.()?.includes?.(query?.toLowerCase?.())
    );

    return (
        <div className={styles.contentContainer}>
            {!loading ? (
                filteredOptions?.length === 0 ? (
                    <div
                        className={[styles.option, styles.noRecords].join(' ')}
                    >
                        <p>No records found.</p>
                    </div>
                ) : (
                    filteredOptions?.map((option) => (
                        <div
                            key={option?.key}
                            tabIndex={0}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSelect(option);
                                    if (!multiselect && !persistent)
                                        handleClose();
                                }
                            }}
                            className={[
                                styles.option,
                                (Array.isArray(selected) &&
                                    selected.some(
                                        (s) => s?.key === option?.key
                                    )) ||
                                selected?.key === option?.key
                                    ? styles.active2
                                    : null
                            ].join(' ')}
                            onClick={(e) => {
                                handleSelect(option);
                                if (!multiselect && !persistent) handleClose();
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <div className={styles.activeIndicator}>
                                {((Array.isArray(selected) &&
                                    selected.some(
                                        (s) => s?.key === option?.key
                                    )) ||
                                    selected?.key === option?.key) && (
                                    <FontAwesomeIcon icon={faCheck} />
                                )}
                            </div>
                            <p>{option.value}</p>
                        </div>
                    ))
                )
            ) : (
                <div className={[styles.option, styles.loader].join(' ')}>
                    <div className={styles.loader}>
                        <Loader
                            spinColor="var(--xlight-text-color)"
                            background="rgba(0,0,0,0.1)"
                        />
                    </div>
                    <p className={styles.loading}>Loading...</p>
                </div>
            )}
        </div>
    );
};

Dropdown.propTypes = {
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            value: PropTypes.string
        })
    ),
    multiselect: PropTypes.bool,
    selected: PropTypes.oneOfType([
        PropTypes.shape({
            key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            value: PropTypes.string
        }),
        PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                value: PropTypes.string
            })
        )
    ]),
    readonly: PropTypes.bool,
    required: PropTypes.bool,
    handleSelect: PropTypes.func
};

export default Dropdown;
