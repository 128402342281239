import React, { useState } from 'react';

import useApi from '../../../../hooks/useApi';

import InputLabel from '../../../general/input/InputLabel';
import TextInput from '../../../general/input/TextInput';
import Modal from '../../../general/modal/Modal';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const NewCertificationTypeModal = ({
    open,
    blocking,
    handleClose = () => null,
    onCreated = () => null
}) => {
    const [code, setCode] = useState('');
    const [description, setDescription] = useState('');
    const [{ loading }, createCertificationType] = useApi(
        '/certification-types',
        'POST',
        { manual: true }
    );

    const { enqueueSnackbar } = useSnackbar();

    const handleCodeChange = (e) => {
        setCode(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleCreate = () => {
        createCertificationType({
            data: [
                {
                    Code: code,
                    Description: description
                }
            ]
        })
            .then((certType) => {
                handleClose();
                enqueueSnackbar(
                    `Certification type ${code} has been created.`,
                    {
                        variant: 'success',
                        autoHideDuration: 3000
                    }
                );
                onCreated(certType);
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar(
                    'Error encountered while adding a new certification type.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000
                    }
                );
            });
    };

    return (
        <Modal open={open} blocking={blocking} handleClose={handleClose}>
            <Modal.Title>New Certification Type</Modal.Title>
            <Modal.Body>
                <div
                    style={{
                        display: 'flex',
                        padding: '12px 0',
                        marginTop: '24px'
                    }}
                >
                    <div>
                        <InputLabel label="Certification Code" required={true}>
                            <TextInput
                                placeholder="Code"
                                value={code}
                                onChange={handleCodeChange}
                            />
                        </InputLabel>
                    </div>
                </div>
                <div style={{ display: 'flex', padding: '12px 0' }}>
                    <div style={{ width: '80%' }}>
                        <InputLabel label="Description" required={true}>
                            <TextInput
                                placeholder="Certification Type Description"
                                value={description}
                                onChange={handleDescriptionChange}
                                lines={4}
                            />
                        </InputLabel>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Actions
                buttons={[
                    {
                        label: 'Cancel',
                        onClick: handleClose,
                        type: 'secondary',
                        variant: 'border'
                    },
                    {
                        label: 'Create',
                        loading: loading,
                        onClick: handleCreate,
                        type: 'primary'
                    }
                ]}
            />
        </Modal>
    );
};

NewCertificationTypeModal.propTypes = {
    open: PropTypes.bool,
    blocking: PropTypes.bool,
    handleClose: PropTypes.func,
    onCreated: PropTypes.func
};

export default NewCertificationTypeModal;
