import React, { useEffect, useRef, useState } from 'react';

import useApi from '../../../hooks/useApi';
import useUser from '../../../hooks/useUser';

import { UserPermissionsUpdatePermissions } from '../../../js/services/permissions';
import Button from '../../general/input/Button';
import Select from '../../general/input/Select';
import LoadingOverlay from '../../general/LoadingOverlay';
import PermissionGroup from './PermissionGroup';
import { useSnackbar } from 'notistack';

import styles from '../../../styles/apps/permissions/Permissions.module.scss';

const Permissions = () => {
    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [checkedPermissions, setCheckedPermissions] = useState([]);
    const [permissionsChanged, setPermissionsChanged] = useState(null);

    const { userHasPermissions } = useUser();

    const [{ data: users }] = useApi('/user/azure', 'GET');
    const [{ data: user, loading: userLoading }, getUser] = useApi(
        '/user/azure/{id}',
        'GET',
        { manual: true }
    );
    const [{ loading: userPermissionsLoading }, getUserPermissions] = useApi(
        '',
        'GET',
        { manual: true }
    );
    const [{ data: permissions, loading: permissionsLoading }] = useApi(
        '/permissions',
        'GET'
    );
    const [{ loading: updatingPermissions }, updatePermissions] = useApi(
        '/permissions/user/{id}',
        'PUT',
        { manual: true }
    );

    const { enqueueSnackbar } = useSnackbar();

    const usersPermissions = useRef(null);
    const stableGetUserPermissions = useRef(getUserPermissions);
    const stableGetUser = useRef(getUser);

    useEffect(() => {
        if (!selectedEmployee || selectedEmployee.length === 0) return;

        setPermissionsChanged(false);

        stableGetUserPermissions
            ?.current({
                url: `/permissions/user/${selectedEmployee?.[0]?.id}`
            })
            .then((permissions) => {
                usersPermissions.current = permissions;
                setCheckedPermissions(permissions.permissions);
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar("Failed to get user's permissions.", {
                    variant: 'error',
                    preventDuplicate: true,
                    autoHideDuration: 3000
                });
            });

        stableGetUser
            ?.current({
                url: `/user/azure/${selectedEmployee?.[0]?.id}`
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar("Failed to get user's information.", {
                    variant: 'error',
                    preventDuplicate: true,
                    autoHideDuration: 3000
                });
            });
    }, [selectedEmployee, enqueueSnackbar]);

    const handleReset = () => {
        setPermissionsChanged(false);
        setCheckedPermissions(usersPermissions?.current?.permissions ?? []);
    };

    const handlePermissionUpdate = () => {
        updatePermissions({
            url: `/permissions/user/${selectedEmployee?.[0]?.id}`,
            data: {
                addedPermissionIds: usersPermissions?.current?.existingUser
                    ? checkedPermissions.filter(
                          (permission) =>
                              !usersPermissions?.current?.permissions?.includes(
                                  permission
                              )
                      )
                    : checkedPermissions,
                removedPermissionIds:
                    usersPermissions?.current?.permissions?.filter(
                        (permission) => !checkedPermissions.includes(permission)
                    )
            }
        })
            .then(() => {
                usersPermissions.current = {
                    ...usersPermissions.current,
                    existingUser: true,
                    permissions: checkedPermissions
                };
                enqueueSnackbar('Permissions updated successfully.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                setPermissionsChanged(false);
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar('Failed to update permissions.', {
                    variant: 'error',
                    autoHideDuration: 3000,
                    preventDuplicate: false
                });
            });
    };

    const permissionChecked = (id, state) => {
        setPermissionsChanged(true);
        setCheckedPermissions((permissions) =>
            !state ? permissions.filter((p) => p !== id) : [...permissions, id]
        );
    };

    return selectedEmployee?.length > 0 ? (
        <div className={styles.container}>
            <div className={[styles.section, styles.userInfo].join(' ')}>
                <div className={[styles.userCard, styles.card].join(' ')}>
                    <div>
                        <Select
                            options={users}
                            multiselect={false}
                            selected={selectedEmployee}
                            getRowValue={(data) => data?.displayName}
                            handleRowSelection={(selected) =>
                                setSelectedEmployee(selected)
                            }
                            handleFilterRows={(search, options) =>
                                options.filter((o) =>
                                    o.displayName
                                        ?.toLowerCase?.()
                                        .includes(search?.toLowerCase?.())
                                )
                            }
                            classes={{
                                button: {
                                    container: styles.userDropdownContainer,
                                    label: styles.userDropdownLabel,
                                    toggle: styles.userDropdownToggle,
                                    placeholder: styles.userDropdownPlaceholder
                                }
                            }}
                            placeholder="Select Employee"
                            sort={['displayName']}
                        />
                    </div>
                    {userLoading || user === null ? (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <LoadingOverlay
                                spinColor="rgba(0,0,0,0.5)"
                                backgroundColor="rgba(0,0,0,0.05)"
                                size="lg"
                                label="Loading User..."
                            />
                        </div>
                    ) : (
                        <React.Fragment>
                            <div>
                                <div className={styles.header}>
                                    <div className={styles.imageContainer}>
                                        {user?.image ? (
                                            <img
                                                src={`data:image/png;base64,${user?.image}`}
                                                className={styles.image}
                                                alt=""
                                            />
                                        ) : null}
                                    </div>
                                    <div className={styles.userContainer}>
                                        <p className={styles.name}>
                                            {user?.user?.displayName}
                                        </p>
                                        <p className={styles.title}>
                                            {user?.user?.jobTitle}
                                        </p>
                                        <p className={styles.division}>
                                            {user?.user?.division}
                                        </p>
                                        <p className={styles.id}>
                                            {user?.user?.azureId}
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.userInfo}>
                                    <div className={styles.infoSection}>
                                        <div className={styles.info}>
                                            <p className={styles.infoLabel}>
                                                Email
                                            </p>
                                            <p className={styles.infoValue}>
                                                {user?.user?.mail}
                                            </p>
                                        </div>
                                        <div className={styles.info}>
                                            <p className={styles.infoLabel}>
                                                Phone #
                                            </p>
                                            <p className={styles.infoValue}>
                                                {
                                                    user?.user
                                                        ?.businessPhones?.[0]
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
                <div className={[styles.userJobs, styles.card].join(' ')}></div>
            </div>
            <div className={styles.permissions}>
                <div className={styles.hierarchyContainer}>
                    {userPermissionsLoading ||
                    permissionsLoading ||
                    usersPermissions?.current === null ||
                    permissions === null ? (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                paddingTop: '80px'
                            }}
                        >
                            <LoadingOverlay
                                spinColor="rgba(0,0,0,0.5)"
                                backgroundColor="rgba(0,0,0,0.05)"
                                size="xl"
                                label="Loading Permissions"
                            />
                        </div>
                    ) : (
                        <PermissionGroup
                            group={permissions}
                            checkedPermissions={checkedPermissions}
                            permissionChecked={permissionChecked}
                            disabled={
                                !userHasPermissions(
                                    UserPermissionsUpdatePermissions,
                                    'or'
                                )
                            }
                        />
                    )}
                </div>
                {((!userPermissionsLoading &&
                    usersPermissions?.current?.permissions &&
                    !usersPermissions?.current?.existingUser) ||
                    (checkedPermissions && permissionsChanged)) &&
                    userHasPermissions(UserPermissionsUpdatePermissions) && (
                        <div className={styles.permissionActions}>
                            <Button
                                label="Reset"
                                type="secondary"
                                variant="border"
                                onClick={handleReset}
                            />
                            <Button
                                label={
                                    usersPermissions?.current?.existingUser
                                        ? 'Save'
                                        : 'Create User'
                                }
                                onClick={handlePermissionUpdate}
                                loading={updatingPermissions}
                            />
                        </div>
                    )}
            </div>
        </div>
    ) : (
        <div className={styles.userSearchContainer}>
            <div className={styles.selectUserGroup}>
                <Select
                    options={users}
                    multiselect={false}
                    selected={selectedEmployee}
                    getRowValue={(data) => data?.displayName}
                    handleRowSelection={(selected) =>
                        setSelectedEmployee(selected)
                    }
                    handleFilterRows={(search, options) =>
                        options.filter((o) =>
                            o.displayName
                                ?.toLowerCase?.()
                                .includes(search?.toLowerCase?.())
                        )
                    }
                    classes={{
                        button: {
                            container: styles.userDropdownContainer,
                            label: styles.userDropdownLabel,
                            toggle: styles.userDropdownToggle,
                            placeholder: styles.userDropdownPlaceholder
                        }
                    }}
                    placeholder="Select Employee"
                    sort={['displayName']}
                />
                <p className={styles.selectUserMessage}>
                    Please select a user to alter their permissions.
                </p>
            </div>
        </div>
    );
};

export default Permissions;
