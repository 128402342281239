import React from 'react';

import Word from '../../../assets/file_types/docx.svg';
import Outlook from '../../../assets/file_types/outlook.svg';
import PDF from '../../../assets/file_types/pdf.png';
import Powerpoint from '../../../assets/file_types/ptt.svg';
import Excel from '../../../assets/file_types/xlsx.svg';
import ZIP from '../../../assets/file_types/zip.svg';
import LoadingOverlay from '../../general/LoadingOverlay';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import styles from '../../../styles/apps/documents/Documents.module.scss';

const imageFormats = [
    'apng',
    'avif',
    'gif',
    'jpg',
    'jpeg',
    'jfif',
    'pjpeg',
    'pjp',
    'png',
    'svg',
    'webp'
];

const getFileIcon = (file) => {
    let splitName = file ? file.split('.') : '';
    let extension = splitName[splitName.length - 1];

    switch (extension) {
        case imageFormats.includes(extension):
            try {
                return (
                    <img
                        src={file.url ? file.url : URL.createObjectURL(file)}
                        alt={file.name ? file.name : file.filename}
                        className={styles.image}
                    />
                );
            } catch {
                return (
                    <FontAwesomeIcon icon={faFileAlt} className={styles.icon} />
                );
            }
        case 'xlsx':
        case 'xls':
        case 'csv':
            return (
                <img
                    src={Excel}
                    className={styles.image}
                    style={{ width: '22px' }}
                    alt="Excel"
                />
            );
        case 'docx':
        case 'docm':
        case 'doc':
            return (
                <img
                    src={Word}
                    className={styles.image}
                    style={{ width: '22px' }}
                    alt="Word"
                />
            );
        case 'pst':
        case 'msg':
        case 'eml':
            return (
                <img
                    src={Outlook}
                    className={styles.image}
                    style={{ width: '22px' }}
                    alt="Outlook"
                />
            );
        case 'pttx':
        case 'ptt':
        case 'pttm':
            return (
                <img
                    src={Powerpoint}
                    className={styles.image}
                    style={{ width: '22px' }}
                    alt="Powerpoint"
                />
            );
        case 'pdf':
            return (
                <img
                    src={PDF}
                    className={styles.image}
                    style={{ width: '22px' }}
                    alt="PDF"
                />
            );
        case 'zip':
            return (
                <img
                    src={ZIP}
                    className={styles.image}
                    style={{ width: '22px' }}
                    alt="zip"
                />
            );
        default:
            return <FontAwesomeIcon icon={faFileAlt} className={styles.icon} />;
    }
};

const DocumentCard = ({
    downloading,
    fileName,
    lastModified,
    name,
    handlePreview,
    handleDownload
}) => (
    <div className={styles.document}>
        <div className={styles.body}>
            <p className={styles.thumbnail}>{getFileIcon(fileName)}</p>
            <div className={styles.upper}>
                <p className={styles.name}>{name}</p>
                {lastModified && (
                    <div className={styles.modified}>
                        Last Modified:{' '}
                        {new Date(lastModified).toLocaleString('en-us', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        })}
                    </div>
                )}
            </div>
        </div>
        {fileName ? (
            <div className={styles.options}>
                <p onClick={handlePreview}>View Online</p>
                <span>|</span>
                <p onClick={handleDownload} className={styles.downloading}>
                    {downloading && (
                        <p className={styles.loader}>
                            <LoadingOverlay
                                size="sm"
                                label={null}
                                spinColor={'var(--secondary-theme-color)'}
                            />
                        </p>
                    )}{' '}
                    Download
                </p>
            </div>
        ) : (
            <div className={styles.options}>
                <p className={styles.notAvailable}>Not currently available</p>
            </div>
        )}
    </div>
);

DocumentCard.propTypes = {
    fileName: PropTypes.string,
    lastModified: PropTypes.string,
    name: PropTypes.string.isRequired,
    downloading: PropTypes.bool,
    handlePreview: PropTypes.func,
    handleDownload: PropTypes.func
};

export default DocumentCard;
