import { tryParseJSON } from "../../../js/services/manipulation";

const FormText = ({ id, props, styles }) => (
    <p style={{
        color: 'var(--medium-text-color)',
        fontSize: '0.85rem',
        padding: '2px 0',
        ...tryParseJSON(styles)
    }}>
        {tryParseJSON(props)?.label}
    </p>
);

export default FormText;
