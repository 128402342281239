import { useRef } from 'react';

import useApi from '../../../../hooks/useApi';

import Button from '../../../general/input/Button';
import InputLabel from '../../../general/input/InputLabel';
import Select from '../../../general/input/Select';
import TextInput from '../../../general/input/TextInput';
import Modal from '../../../general/modal/Modal';
import QualityTagInput from '../QualityTagInput';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { isNumber } from 'lodash';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import styles from '../../../../styles/apps/quality/modal/EditEquipmentFormModal.module.scss';
import ErrorMessage from '../../../general/ErrorMessage';

const EditEquipmentFormModal = ({ handleClose, handleEdit, equipmentForm, jobId }) => {
    const submitButtonRef = useRef();

    const [{ loading }, updateEquipmentForm] = useApi('/quality/form', 'PUT', {
        manual: true
    });
    const { enqueueSnackbar } = useSnackbar();
    const {
        handleSubmit,
        control,
        setError,
        formState: { errors }
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            name: equipmentForm.name,
            qualityTags: equipmentForm.qualityTags,
            equipment: equipmentForm?.equipmentId
                ? [
                      {
                          id: equipmentForm.equipmentId,
                          name: equipmentForm.equipmentName
                      }
                  ]
                : []
        }
    });

    const handleCreate = (data) => {
        updateEquipmentForm({
            url: `/quality/form/${equipmentForm.id}`,
            data: {
                equipmentId: data.equipment?.[0]?.id,
                name: data?.name,
                qualityTags: data?.qualityTags.map((qt) => ({
                    ...qt,
                    id: isNumber(qt.id) ? qt.id : 0
                }))
            }
        })
            .then((equipmentForm) => {
                handleEdit?.(equipmentForm.id, equipmentForm);
                enqueueSnackbar('Form updated successfully.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                handleClose();
            })
            .catch((err) => {
                setError('root.serverError', {
                    type: 'custom',
                    message: err.response?.data?.detail
                });
                enqueueSnackbar(
                    err.response?.data?.title ??
                        'Error encountered while updating form.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000
                    }
                );
            });
    };

    const handleSubmitClicked = () => submitButtonRef?.current?.click?.();

    return (
        <Modal open handleClose={handleClose} blocking>
            <Modal.Title icon={faPencilAlt}>Edit Form</Modal.Title>
            <Modal.Body>
                <form
                    autoComplete="true"
                    onSubmit={handleSubmit(handleCreate)}
                    noValidate={true}
                    className={styles.form}
                >
                    <div className={styles.row}>
                        <Controller
                            name="equipment"
                            control={control}
                            rules={{
                                required: 'You must select an equipment.'
                            }}
                            render={({
                                field: { value, onChange, ref },
                                fieldState: { error }
                            }) => (
                                <InputLabel
                                    label="Equipment"
                                    required
                                    error={error?.message}
                                >
                                    <Select
                                        placeholder="Select Equipment"
                                        selected={value}
                                        handleRowSelection={onChange}
                                        pagination={{
                                            url: `/quality/${jobId}/equipment`
                                        }}
                                        getRowValue={(r) => r.name}
                                        sort={['equipment']}
                                    />
                                </InputLabel>
                            )}
                        />
                    </div>
                    <div className={styles.row}>
                        <Controller
                            name="name"
                            control={control}
                            render={({
                                field: { value, onChange, ref },
                                fieldState: { error }
                            }) => (
                                <InputLabel
                                    label="Form Name"
                                    error={error?.message}
                                >
                                    <TextInput
                                        placeholder="Form Name"
                                        value={value}
                                        onChange={onChange}
                                        inputRef={ref}
                                    />
                                </InputLabel>
                            )}
                        />
                    </div>
                    <div className={styles.row}>
                        <Controller
                            name="qualityTags"
                            control={control}
                            render={({
                                field: { value, onChange },
                                fieldState: { error }
                            }) => (
                                <InputLabel label="Tags" error={error}>
                                    <QualityTagInput
                                        onChange={onChange}
                                        value={value}
                                        jobId={jobId}
                                    />
                                </InputLabel>
                            )}
                        />
                    </div>
                    {errors.root?.serverError && (
                        <div>
                            <ErrorMessage
                                error={errors.root.serverError.message}
                            />
                        </div>
                    )}
                    <div
                        style={{
                            visibility: 'hidden',
                            opacity: 0
                        }}
                    >
                        <button formAction="submit" ref={submitButtonRef} />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Update"
                    onClick={handleSubmitClicked}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default EditEquipmentFormModal;
