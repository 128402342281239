import React, { lazy, Suspense } from 'react';

import {
    CertificationReviewGridPermissions,
    CertificationTypeGridPermissions,
    ContactsFlagCreatePermissions,
    ContactsFlagDeletePermissions,
    ContactsFlagUpdatePermissions,
    ContactsReadPermissions,
    PretaskCreatePermissions,
    PretaskGridPermissions,
    PretaskSupervisorGridPermissions,
    PretaskTemplateCreatePermissions,
    PretaskUpdatePermissions,
    QualityPermissionsUpdatePermissions,
    QualityReadPermissions,
    SuggestionBoxReadPermissions,
    UserPermissionsReadPermissions
} from '../../js/services/permissions';
import Certifications from '../apps/certifications/Certifications';
import CertificationReviewGrid from '../apps/certifications/grid/CertificationReviewGrid';
import CertificationTypeGrid from '../apps/certifications/grid/CertificationTypeGrid';
import EmployeeCertificationsGrid from '../apps/certifications/grid/EmployeeCertificationsGrid';
import Contact from '../apps/contact/Contact';
import CompanyGrid from '../apps/contact/grid/CompanyGrid';
import ContactGrid from '../apps/contact/grid/ContactGrid';
import FlagGrid from '../apps/contact/grid/FlagGrid';
import Documents from '../apps/documents/Documents';
import Permissions from '../apps/permissions/Permissions';
import CustomizePretask from '../apps/pretask/CustomizePretask';
import CustomizePretaskDialog from '../apps/pretask/CustomizePretaskDialog';
import EditPretask from '../apps/pretask/EditPretask';
import PretaskGrid from '../apps/pretask/grid/PretaskGrid';
import PretaskSupervisorGrid from '../apps/pretask/grid/PretaskSupervisorGrid';
import NewPretask from '../apps/pretask/NewPretask';
import Pretask from '../apps/pretask/Pretask';
import ViewPretask from '../apps/pretask/ViewPretask';
import EditQualityForm from '../apps/quality/EditQualityForm';
import AttachmentGrid from '../apps/quality/grid/AttachmentGrid';
import EquipmentGrid from '../apps/quality/grid/EquipmentGrid';
import FormGrid from '../apps/quality/grid/FormGrid';
import PermissionGrid from '../apps/quality/grid/PermissionGrid';
import TagGrid from '../apps/quality/grid/TagGrid';
import JobOverview from '../apps/quality/JobOverview';
import Quality from '../apps/quality/Quality';
import ViewQualityForm from '../apps/quality/ViewQualityForm';
import SuggestionAdmin from '../apps/suggestion_box/SuggestionAdmin';
import SuggestionBox from '../apps/suggestion_box/SuggestionBox';
import SuggestionForm from '../apps/suggestion_box/SuggestionForm';
import NewWorkOrder from '../apps/workorder/NewWorkOrder';
import WorkOrder from '../apps/workorder/WorkOrder';
import LogoLoader from '../general/LogoLoader';
import Navigation from '../navigation/Navigation';
import ConditionalRoute from './ConditionalRoute';
import RootErrorBoundary from './ErrorBoundaries/RootErrorBoundary';
import Login from './Login';
import UserProvider from './Providers/UserProvider';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate
} from '@azure/msal-react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faCheck,
    faPencilRuler,
    faRuler
} from '@fortawesome/free-solid-svg-icons';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

library.add(faRuler, faCheck, faPencilRuler);

const Dashboard = lazy(() => import('../apps/dashboard/Dashboard'));
const PageNotFound = lazy(() => import('../config/PageNotFound'));

function App() {
    return (
        <div className="app" id="main">
            <BrowserRouter>
                <AuthenticatedTemplate>
                    <RootErrorBoundary>
                        <UserProvider>
                            <Navigation>
                                <Suspense fallback={<LogoLoader />}>
                                    <Routes>
                                        <Route
                                            path="/"
                                            element={<Dashboard />}
                                        />
                                        <Route
                                            path="certifications"
                                            element={<Certifications />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Navigate to="./list" />
                                                }
                                            />
                                            <Route
                                                path="list"
                                                element={
                                                    <ConditionalRoute
                                                        elements={{
                                                            CertificationReview:
                                                                {
                                                                    component: (
                                                                        <CertificationReviewGrid />
                                                                    ),
                                                                    condition:
                                                                        'or',
                                                                    permissions:
                                                                        CertificationReviewGridPermissions
                                                                },
                                                            default: {
                                                                component: (
                                                                    <EmployeeCertificationsGrid />
                                                                )
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="types"
                                                element={
                                                    <ConditionalRoute
                                                        elements={{
                                                            CertificationType: {
                                                                component: (
                                                                    <CertificationTypeGrid />
                                                                ),
                                                                permissions:
                                                                    CertificationTypeGridPermissions
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path="contacts"
                                            element={
                                                <ConditionalRoute
                                                    elements={{
                                                        Contact: {
                                                            component: (
                                                                <Contact />
                                                            ),
                                                            permissions:
                                                                ContactsReadPermissions
                                                        }
                                                    }}
                                                />
                                            }
                                        >
                                            <Route
                                                index
                                                element={<ContactGrid />}
                                            />
                                            <Route
                                                path="companies"
                                                element={<CompanyGrid />}
                                            />
                                            <Route
                                                path="flags"
                                                element={
                                                    <ConditionalRoute
                                                        elements={{
                                                            ContactFlagGrid: {
                                                                component: (
                                                                    <FlagGrid />
                                                                ),
                                                                condition: 'or',
                                                                permissions: [
                                                                    ContactsFlagCreatePermissions,
                                                                    ContactsFlagDeletePermissions,
                                                                    ContactsFlagUpdatePermissions
                                                                ]
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path="pretask"
                                            element={<Pretask />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Navigate to="./my-pretasks" />
                                                }
                                            />
                                            <Route
                                                path="my-pretasks"
                                                element={
                                                    <ConditionalRoute
                                                        elements={{
                                                            PretaskGrid: {
                                                                component: (
                                                                    <PretaskGrid />
                                                                ),
                                                                permissions:
                                                                    PretaskGridPermissions
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="supervisor"
                                                element={
                                                    <ConditionalRoute
                                                        elements={{
                                                            PretaskSupervisorGrid:
                                                                {
                                                                    component: (
                                                                        <PretaskSupervisorGrid />
                                                                    ),
                                                                    condition:
                                                                        'or',
                                                                    permissions:
                                                                        PretaskSupervisorGridPermissions
                                                                }
                                                        }}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="customize-forms"
                                                element={
                                                    <ConditionalRoute
                                                        elements={{
                                                            CustomizePretask: {
                                                                component: (
                                                                    <CustomizePretaskDialog />
                                                                ),
                                                                permissions:
                                                                    PretaskTemplateCreatePermissions
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        </Route>
                                        <Route path="pretask">
                                            <Route
                                                path="customize"
                                                element={
                                                    <ConditionalRoute
                                                        elements={{
                                                            CustomizePretask: {
                                                                component: (
                                                                    <CustomizePretask />
                                                                ),
                                                                permissions:
                                                                    PretaskTemplateCreatePermissions
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={
                                                    <ConditionalRoute
                                                        elements={{
                                                            EditPretask: {
                                                                component: (
                                                                    <EditPretask />
                                                                ),
                                                                permissions:
                                                                    PretaskUpdatePermissions
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="new"
                                                element={
                                                    <ConditionalRoute
                                                        elements={{
                                                            NewPretask: {
                                                                component: (
                                                                    <NewPretask />
                                                                ),
                                                                permissions:
                                                                    PretaskCreatePermissions
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="view/:id"
                                                element={
                                                    <ConditionalRoute
                                                        elements={{
                                                            ViewPretask: {
                                                                component: (
                                                                    <ViewPretask />
                                                                ),
                                                                permissions:
                                                                    PretaskCreatePermissions
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path="suggestionbox"
                                            element={<SuggestionBox />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Navigate to="./submit" />
                                                }
                                            />
                                            <Route
                                                path="submit"
                                                element={<SuggestionForm />}
                                            />
                                            <Route
                                                path="review"
                                                element={
                                                    <ConditionalRoute
                                                        elements={{
                                                            ReviewSuggestions: {
                                                                component: (
                                                                    <SuggestionAdmin />
                                                                ),
                                                                condition: 'or',
                                                                permissions:
                                                                    SuggestionBoxReadPermissions
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path="documents"
                                            element={<Documents />}
                                        />
                                        <Route
                                            path="permissions"
                                            element={
                                                <ConditionalRoute
                                                    elements={{
                                                        Permissions: {
                                                            component: (
                                                                <Permissions />
                                                            ),
                                                            permissions:
                                                                UserPermissionsReadPermissions
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                        <Route
                                            path="quality"
                                            element={
                                                <ConditionalRoute
                                                    elements={{
                                                        Permissions: {
                                                            component: (
                                                                <Quality />
                                                            ),
                                                            permissions:
                                                                QualityReadPermissions
                                                        }
                                                    }}
                                                />
                                            }
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Navigate to="overview" />
                                                }
                                            />
                                            <Route
                                                path="overview"
                                                element={<JobOverview />}
                                            />
                                            <Route
                                                path="equipment"
                                                element={<EquipmentGrid />}
                                            />
                                            <Route
                                                path="forms"
                                                element={<FormGrid />}
                                            />
                                            <Route
                                                path="attachments"
                                                element={<AttachmentGrid />}
                                            />
                                            <Route
                                                path="tags"
                                                element={<TagGrid />}
                                            />
                                            <Route
                                                path="permissions"
                                                element={
                                                    <ConditionalRoute
                                                        elements={{
                                                            Permissions: {
                                                                component: (
                                                                    <PermissionGrid />
                                                                ),
                                                                permissions:
                                                                    QualityPermissionsUpdatePermissions
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path="workorder"
                                            element={
                                                <ConditionalRoute
                                                    elements={{
                                                        Permissions: {
                                                            component: (
                                                                <WorkOrder />
                                                            ),
                                                            permissions: []
                                                        }
                                                    }}
                                                />
                                            }
                                        >
                                            <Route
                                                index
                                                element={<Navigate to="new" />}
                                            />
                                            <Route
                                                path="new"
                                                element={<NewWorkOrder />}
                                            />
                                        </Route>
                                        <Route path="quality">
                                            <Route
                                                path="forms/:formId/view"
                                                element={<ViewQualityForm />}
                                            />
                                            <Route
                                                path="forms/:formId/:step"
                                                element={<EditQualityForm />}
                                            />
                                        </Route>
                                        <Route
                                            path="*"
                                            element={<PageNotFound />}
                                        />
                                    </Routes>
                                </Suspense>
                            </Navigation>
                        </UserProvider>
                    </RootErrorBoundary>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <RootErrorBoundary>
                        <UserProvider auth={false}>
                            <Navigation>
                                <Suspense fallback={<LogoLoader />}>
                                    <Routes>
                                        <Route
                                            path="suggestionbox"
                                            element={<SuggestionBox />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Navigate to="./submit" />
                                                }
                                            />
                                            <Route
                                                path="submit"
                                                element={<SuggestionForm />}
                                            />
                                        </Route>
                                        <Route
                                            path="documents"
                                            element={<Documents />}
                                        />
                                        <Route path="*" element={<Login />} />
                                    </Routes>
                                </Suspense>
                            </Navigation>
                        </UserProvider>
                    </RootErrorBoundary>
                </UnauthenticatedTemplate>
            </BrowserRouter>
        </div>
    );
}

export default App;
