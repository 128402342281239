import React, { useEffect, useState } from 'react';

import { CertificationTypeGridPermissions } from '../../../js/services/permissions';
import Tabs from '../../navigation/Tabs';
import { Outlet, useMatch } from 'react-router-dom';

import styles from '../../../styles/apps/certifications/Certifications.module.scss';

const tabs = [
    {
        key: 'list',
        label: 'Employee Certifications',
        link: '/certifications/list'
    },
    {
        key: 'types',
        label: 'Certification Types',
        permission: CertificationTypeGridPermissions,
        link: '/certifications/types'
    }
];

const Certifications = () => {
    const match = useMatch({
        path: '/certifications/:page',
        end: false
    });

    const [tab, setTab] = useState(match?.params?.page ?? 'list');

    const handleTabChange = (tab) => {
        setTab(tab);
    };

    useEffect(() => {
        document.title = 'CVE Apps - Certifications';
    }, []);

    return (
        <div className={styles.container} style={{ position: 'relative' }}>
            <div className={styles.tabs}>
                <Tabs tabs={tabs} active={tab} onChange={handleTabChange} />
            </div>
            <Outlet />
        </div>
    );
};

export default Certifications;
