import InputLabel from '../../general/input/InputLabel';
import TextInput from '../../general/input/TextInput';
import { TextTooltip } from '../../general/Tooltip';
import QualityTagInput from './QualityTagInput';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useFormContext } from 'react-hook-form';

import styles from '../../../styles/apps/quality/NewEquipmentFormCard.module.scss';

const NewEquipmentFormCard = ({ index, id, jobId, removeFormType }) => {
    const { control } = useFormContext();

    return (
        <div className={styles.container} key={id}>
            <div className={styles.row}>
                <Controller
                    name={`equipmentForms.${index}.formTemplate`}
                    control={control}
                    rules={{
                        required: 'You must select a form type.'
                    }}
                    render={({
                        field: { value, onChange },
                        fieldState: { error }
                    }) => <p className={styles.formType}>{value?.name}</p>}
                />
                <div className={styles.deleteContainer}>
                    <TextTooltip
                        tooltip="Remove"
                        hoverTrigger="always"
                        hoverDelay={600}
                    >
                        <div
                            className={styles.delete}
                            onClick={() => removeFormType(index)}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </div>
                    </TextTooltip>
                </div>
            </div>
            <div className={styles.row}>
                <Controller
                    name={`equipmentForms.${index}.name`}
                    control={control}
                    render={({
                        field: { value, onChange }
                    }) => (
                        <InputLabel>
                            <TextInput
                                placeholder="Form Name"
                                value={value}
                                onChange={onChange}
                            />
                        </InputLabel>
                    )}
                />
            </div>
            <div className={styles.row}>
                <Controller
                    name={`equipmentForms.${index}.qualityTags`}
                    control={control}
                    render={({
                        field: { value, onChange },
                        fieldState: { error }
                    }) => (
                        <InputLabel error={error?.message}>
                            <QualityTagInput
                                onChange={onChange}
                                value={value}
                                jobId={jobId}
                            />
                        </InputLabel>
                    )}
                />
            </div>
        </div>
    );
};

export default NewEquipmentFormCard;
