import Button from '../../input/Button';
import { TextTooltip } from '../../Tooltip';
import PropTypes from 'prop-types';
import { removeNulls } from '../../../../js/services/manipulation';

import styles from '../../../../styles/general/grid/cell renderers/ActionCell.module.scss';

const ActionCell = ({ actions }) => (
    <div className={styles.actions}>
        {removeNulls(actions)?.map?.((action, i) => (
            <Action action={action} key={i} />
        ))}
    </div>
);

const Action = ({ action }) => {
    const handleClick = (e) => {
        e.stopPropagation();
        e.preventDefault();

        action.onClick(e);
    };

    return action.tooltip && !action.disabled ? (
        <TextTooltip {...(action.tooltip ?? {})}>
            <Button
                {...action}
                onClick={handleClick}
                className={styles.action}
            />
        </TextTooltip>
    ) : (
        <Button {...action} className={styles.action} />
    );
};

ActionCell.propTypes = {
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.object,
            handleClick: PropTypes.func,
            tooltip: PropTypes.shape({
                tooltip: PropTypes.string,
                hoverTrigger: PropTypes.oneOf(['always', 'never', 'overflow']),
                hoverDelay: PropTypes.number
            })
        })
    )
};

export default ActionCell;
