import { TextTooltip } from '../../Tooltip';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';

import styles from '../../../../styles/general/grid/cell renderers/HeaderCell.module.scss';

const HeaderCell = ({
    className,
    children,
    sortable,
    onClick,
    sortState,
    column,
    ...props
}) => (
    <div
        {...props}
        onClick={(e) =>
            onClick?.({
                key: column?.sortKey ?? column?.dataKey ?? column?.key,
                direction:
                    sortState?.[
                        column?.sortKey ?? column?.dataKey ?? column?.key
                    ]?.direction,
                order: sortState?.[
                    column?.sortKey ?? column?.dataKey ?? column?.key
                ]?.order,
                event: e,
                columns: isFunction(column?.sortColumns)
                    ? column?.sortColumns?.(
                          column?.sortKey ?? column?.dataKey ?? column?.key,
                          sortState?.[
                              column?.sortKey ?? column?.dataKey ?? column?.key
                          ]?.direction
                      )
                    : column?.sortColumns?.map?.((c) => ({
                          Field: c,
                          Direction:
                              sortState?.[
                                  column?.sortKey ??
                                      column?.dataKey ??
                                      column?.key
                              ]?.direction === 'asc'
                                  ? 'desc'
                                  : 'asc',
                          CaseInsensitive: true
                      }))
            })
        }
        className={[
            className,
            sortable ? styles.headerCellSortable : null
        ].join(' ')}
    >
        <div className={styles.headerCellLabel}>
            <TextTooltip
                tooltip={`${column?.title}${
                    sortState?.[
                        column?.sortKey ?? column?.dataKey ?? column?.key
                    ]?.direction === 'desc'
                        ? ' (descending)'
                        : sortState?.[
                              column?.sortKey ?? column?.dataKey ?? column?.key
                          ]?.direction === 'asc'
                        ? ' (ascending)'
                        : ''
                }`}
                hoverTrigger="overflow"
                hoverDelay={2000}
                clickTrigger='never'
                clickToOpen={false}
            >
                {column?.title}
            </TextTooltip>
        </div>
        {sortable &&
            sortState?.[column?.sortKey ?? column?.dataKey ?? column?.key]
                ?.direction && (
                <div className={styles.headerCellSortIcon}>
                    {sortState?.[
                        column?.sortKey ?? column?.dataKey ?? column?.key
                    ]?.direction === 'desc' ? (
                        <FontAwesomeIcon icon={faArrowDown} />
                    ) : sortState?.[
                          column?.sortKey ?? column?.dataKey ?? column?.key
                      ]?.direction === 'asc' ? (
                        <FontAwesomeIcon icon={faArrowUp} />
                    ) : null}
                    {sortable &&
                        sortState?.[
                            column?.sortKey ?? column?.dataKey ?? column?.key
                        ]?.order &&
                        Object.keys(sortState).length > 1 && (
                            <div className={styles.sortOrder}>
                                {
                                    sortState?.[
                                        column?.sortKey ??
                                            column?.dataKey ??
                                            column?.key
                                    ]?.order
                                }
                            </div>
                        )}
                </div>
            )}
    </div>
);

HeaderCell.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    sortable: PropTypes.bool,
    onClick: PropTypes.func,
    sortState: PropTypes.object,
    column: PropTypes.object
};

export default HeaderCell;
