import React from 'react';

import useDimensions from '../../../hooks/useDimensions';

import Button from '../../general/input/Button';
import { SnackbarProvider as NotISnackbarProvider } from 'notistack';

//Overwritten styling for the Snackbar components
import styles from '../../../styles/general/Snackbar.module.scss';

const SnackbarProvider = ({ children }) => {
    const snackbarRef = React.createRef();
    const dismiss = (key) => {
        snackbarRef?.current?.closeSnackbar(key);
    };

    const [width] = useDimensions();

    return (
        <NotISnackbarProvider
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            classes={{
                containerAnchorOriginBottomRight:
                    styles.snackbarAnchorOriginBottomRight,
                containerRoot: styles.containerRoot
            }}
            dense={width < 600}
            ref={snackbarRef}
            action={(key) => (
                <Button
                    onClick={() => dismiss(key)}
                    label="DISMISS"
                    className={styles.dismiss}
                />
            )}
        >
            {children}
        </NotISnackbarProvider>
    );
};

export default SnackbarProvider;
