import { useRef } from 'react';

import useApi from '../../../../hooks/useApi';

import ErrorMessage from '../../../general/ErrorMessage';
import Button from '../../../general/input/Button';
import Checkbox from '../../../general/input/Checkbox';
import CheckboxGroup from '../../../general/input/CheckboxGroup';
import Chip from '../../../general/input/Chip';
import InputLabel from '../../../general/input/InputLabel';
import Select from '../../../general/input/Select';
import TextInput from '../../../general/input/TextInput';
import Modal from '../../../general/modal/Modal';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import styles from '../../../../styles/apps/contact/modal/NewFlagModal.module.scss';

const NewFlagModal = ({ handleClose, handleNew }) => {
    const submitButtonRef = useRef();

    const [{ loading }, createFlag] = useApi('/contact/flag', 'POST', {
        manual: true
    });
    const { enqueueSnackbar } = useSnackbar();

    const {
        handleSubmit,
        control,
        getValues,
        setError,
        setValue,
        formState: { errors },
        ...methods
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            name: '',
            defaultSelected: false,
            contactSelection: 'specific',
            contactIds: []
        }
    });

    const handleCreate = (data) => {
        createFlag({
            data: {
                name: data.name,
                defaultSelected: data.defaultSelected,
                allContactsSelected: data.contactSelection === 'all',
                contactIds:
                    data.contactSelection === 'all'
                        ? null
                        : data.contactIds?.map?.((c) => c.id)
            }
        })
            .then((flag) => {
                handleNew(flag);
                enqueueSnackbar('Flag created successfully.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                handleClose();
            })
            .catch((err) => {
                console.error(err);
                err.response?.data?.detail && setError('root.serverError', {
                    type: 'custom',
                    message: err.response?.data?.detail
                });
                enqueueSnackbar(
                    err.response?.data?.title ??
                        'Error encountered while creating flag.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000
                    }
                );
            });
    };

    const handleSubmitClicked = () => submitButtonRef?.current?.click?.();

    return (
        <Modal open handleClose={handleClose} blocking>
            <Modal.Title icon={faPlus}>New Flag</Modal.Title>
            <Modal.Body>
                <FormProvider
                    handleSubmit={handleSubmit}
                    control={control}
                    {...methods}
                >
                    <form
                        autoComplete="true"
                        onSubmit={handleSubmit(handleCreate)}
                        noValidate={true}
                        className={styles.form}
                    >
                        <div className={styles.row}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: 'You must provide a flag name.'
                                }}
                                render={({
                                    field: { value, onChange, ref },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Flag Name"
                                        required
                                        error={error?.message}
                                    >
                                        <TextInput
                                            autoFocus
                                            placeholder="Flag Name"
                                            value={value}
                                            onChange={onChange}
                                            inputRef={ref}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div className={styles.row}>
                            <Controller
                                name="defaultSelected"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <Checkbox
                                        handleChange={() => onChange(!value)}
                                        checked={value}
                                        label="Selected by default on new contacts"
                                    />
                                )}
                            />
                        </div>
                        <div className={styles.row}>
                            <InputLabel
                                label="Add To Existing Contacts"
                                style={{
                                    label: {
                                        fontSize: '.95rem',
                                        marginBottom: '8px'
                                    },
                                    container: {
                                        paddingTop: '16px',
                                        borderTop: '1px solid #d8d8d8'
                                    }
                                }}
                            >
                                <Controller
                                    name="contactSelection"
                                    control={control}
                                    render={({
                                        field: { value, onChange }
                                    }) => (
                                        <>
                                            <CheckboxGroup
                                                handleChange={onChange}
                                                selected={value}
                                                required
                                                options={[
                                                    {
                                                        key: 'specific',
                                                        value: 'Specific Contacts'
                                                    },
                                                    {
                                                        key: 'all',
                                                        value: 'All Contacts'
                                                    }
                                                ]}
                                                label="All Contacts"
                                            />
                                            {value === 'specific' && (
                                                <Controller
                                                    name="contactIds"
                                                    control={control}
                                                    render={({
                                                        field: {
                                                            value,
                                                            onChange
                                                        }
                                                    }) => (
                                                        <>
                                                            <InputLabel
                                                                label="Contacts"
                                                                style={{
                                                                    container: {
                                                                        marginTop:
                                                                            '8px',
                                                                        marginBottom:
                                                                            '100px'
                                                                    }
                                                                }}
                                                            >
                                                                <Select
                                                                    pagination={{
                                                                        url: '/contact'
                                                                    }}
                                                                    placeholder="Select Contact(s)"
                                                                    getRowId={(
                                                                        row
                                                                    ) => row.id}
                                                                    getRowValue={(
                                                                        row
                                                                    ) =>
                                                                        `${row.name} - ${row.company?.name}`
                                                                    }
                                                                    selected={
                                                                        value
                                                                    }
                                                                    handleRowSelection={
                                                                        onChange
                                                                    }
                                                                    multiselect
                                                                />
                                                                <div
                                                                    className={
                                                                        styles.chipContainer
                                                                    }
                                                                >
                                                                    {value?.map(
                                                                        (v) => (
                                                                            <Chip
                                                                                key={
                                                                                    v.id
                                                                                }
                                                                                label={`${v.name} - ${v.company?.name}`}
                                                                                id={
                                                                                    v.id
                                                                                }
                                                                                removeable
                                                                                handleRemove={(
                                                                                    id
                                                                                ) =>
                                                                                    onChange(
                                                                                        value.filter(
                                                                                            (
                                                                                                v
                                                                                            ) =>
                                                                                                v.id !==
                                                                                                id
                                                                                        )
                                                                                    )
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                                </div>
                                                            </InputLabel>
                                                        </>
                                                    )}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                            </InputLabel>
                        </div>
                        {errors.root?.serverError && (
                            <div>
                                <ErrorMessage
                                    error={errors.root.serverError.message}
                                />
                            </div>
                        )}
                        <div
                            style={{
                                visibility: 'hidden',
                                opacity: 0,
                                height: 0
                            }}
                        >
                            <button formAction="submit" ref={submitButtonRef} />
                        </div>
                    </form>
                </FormProvider>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Create"
                    onClick={handleSubmitClicked}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default NewFlagModal;
