import { useCallback, useEffect, useRef, useState } from 'react';

import api, { apiScopes } from '../js/services/api';
import msalInstance from '../js/services/auth';
import { apiBaseUrl } from '../js/services/url';
import PropTypes from 'prop-types';

const useFetch = (url, method, options = {}) => {
    //Keep track of component mounted state to prevent state change on a DOM element
    const isMounted = useRef(true);
    const controllerRef = useRef();

    const [loading, setLoading] = useState(!options.manual);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        controllerRef.current = new AbortController();
        isMounted.current = true;

        return () => {
            controllerRef?.current?.abort?.('unmount');
            isMounted.current = false;
        };
    }, []);

    /**
     * Acquire an access token, insert it into the Authorization header, and call the API
     * @returns null
     */
    const call = useCallback(
        async (config = {}) => {
            if (!isMounted.current) return;
            setLoading(true);
            setError(null);

            let callUrl = config?.url ?? url;

            const account = msalInstance.getAllAccounts()?.[0];
            let accessToken;
            if (account) {
                const msalResponse = await msalInstance.acquireTokenSilent({
                    scopes: apiScopes,
                    account: account
                });
                accessToken = `Bearer ${msalResponse.accessToken}`;
            }

            if (callUrl && Array.isArray(callUrl)) {
                const callPromises = callUrl.map((cu) =>
                    api(`${apiBaseUrl}${cu}`, {
                        method: method,
                        signal: controllerRef?.current?.signal,
                        body: config.data,
                        headers: {
                            Authorization: accessToken
                        },
                        ...options,
                        ...config
                    })
                );
                const response = await Promise.allSettled(callPromises);
                if (isMounted.current) {
                    if (!options.promiseOnly) {
                        setData(
                            response.filter(
                                (datum) => datum.status === 'fulfilled'
                            )
                        );
                        setError(
                            response.filter(
                                (datum) => datum.status === 'rejected'
                            )
                        );
                    }
                    return {
                        data: response.filter(
                            (datum) => datum.status === 'fulfilled'
                        ),
                        error: response.filter(
                            (datum) => datum.status === 'rejected'
                        )
                    };
                }
                setLoading(false);
            } else {
                const response = await fetch(`${apiBaseUrl}${callUrl}`, {
                    method: method,
                    signal: controllerRef?.current?.signal,
                    body: config.data,
                    headers: {
                        Authorization: accessToken
                    },
                    ...options,
                    ...config
                });

                if (!options.promiseOnly) {
                    setData(response?.data);
                    setError(null);
                }

                setLoading(false);
                return response;
            }
        },
        [method, options, url]
    );

    useEffect(() => {
        if (options.manual) return;
        call();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return [{ data, loading, error }, call];
};

useFetch.propTypes = {
    url: PropTypes.string.isRequired,
    method: PropTypes.oneOf(['GET', 'PUT', 'POST', 'DELETE', 'PATCH']),
    options: PropTypes.shape({
        promiseOnly: PropTypes.bool,
        manual: PropTypes.bool
    })
};

export default useFetch;
