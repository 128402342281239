import { useEffect, useRef } from 'react';

import useApi from '../../../hooks/useApi';

import { tryParseJSON } from '../../../js/services/manipulation';
import { isDate, isValidJSON } from '../../../js/services/validation';
import ErrorMessage from '../../general/ErrorMessage';
import Button from '../../general/input/Button';
import LoadingOverlay from '../../general/LoadingOverlay';
import FormBlock from './FormBlock';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useMatch, useNavigate } from 'react-router-dom';

import styles from '../../../styles/apps/quality/EditQualityForm.module.scss';

const EditQualityForm = () => {
    const submitButtonRef = useRef();

    const navigate = useNavigate();

    const match = useMatch({
        path: '/quality/forms/:formId/:step',
        end: false
    });

    const { enqueueSnackbar } = useSnackbar();

    const [{ data: template, loading, error }] = useApi(
        `/quality/form/${match?.params?.formId}/${match?.params?.step}`,
        'GET',
        { manual: false }
    );

    const [{ loading: postingFields }, updateFields] = useApi(
        `/quality/form/${match?.params?.formId}/${match?.params?.step}/fields`,
        'PUT',
        { manual: true }
    );

    const {
        control,
        setError,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all'
    });

    useEffect(() => {
        reset(
            template?.fields?.reduce?.(
                (acc, field) => ({
                    ...acc,
                    [`${field.formTemplateFieldId}`]: tryParseJSON(field.value)
                }),
                {}
            )
        );
    }, [template, reset]);

    const handleSave = (data) => {
        let finalData = [];
        //let errorFound = false;
        for (const record of Object.keys(data)) {
            const fieldValues = template?.fields?.find?.(
                (field) => `${field.formTemplateFieldId}` === record
            );
            if (!fieldValues || fieldValues.readOnly) continue;
            finalData = [
                ...finalData,
                {
                    ...fieldValues,
                    value: data[record]
                        ? isValidJSON(data[record]) && (typeof(data[record]) == 'string' || typeof(data[record]) == 'number')
                            ? `${data[record]}`
                            : isDate(data[record])
                            ? data[record]?.toISOString()
                            : JSON.stringify(data[record])
                        : null
                }
            ];
        }
        updateFields({
            data: finalData
        })
            .then(() => {
                enqueueSnackbar('Form Saved', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                navigate(-1);
            })
            .catch((err) => {
                let errors = tryParseJSON(err?.response?.data?.detail);

                enqueueSnackbar(
                    errors && errors.length > 0
                        ? 'Missing required fields.'
                        : 'Could not save form.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000
                    }
                );
                errors?.forEach?.((error) => {
                    setError(error.Key, {
                        type: 'required',
                        message: error.VerboseError
                    });
                });
            });
    };

    const handleSubmitPress = () => {
        submitButtonRef.current?.click();
    };

    return (
        <div className={styles.container}>
            {loading ? (
                <div>
                    <LoadingOverlay label="Loading Form..." />
                </div>
            ) : error ? (
                <div className={styles.error}>
                    <h3>{error?.response?.data?.title}</h3>
                    <p>{error?.response?.data?.detail}</p>
                    <Link to='/quality/forms'>Back To Forms</Link>
                </div>
            ) : template.currentStep && template.currentStep.action == null ? (
                <div className={styles.error}>
                    <h3>Form Completed</h3>
                    <p>This form cannot be edited since it has already been completed. If you wish to edit it, the form must be reset.</p>
                    <Link to='/quality/forms'>Back To Forms</Link>
                </div>
            ) : (
                <FormProvider handleSubmit={handleSubmit} control={control}>
                    <form
                        autoComplete="true"
                        onSubmit={handleSubmit(handleSave)}
                        noValidate={true}
                        className={styles.form}
                    >
                        {template?.formTemplate?.layout?.map?.((b) => (
                            <FormBlock
                                key={b.id}
                                {...b}
                                control={control}
                                //debug
                            />
                        ))}
                        <div
                            style={{
                                visibility: 'hidden',
                                opacity: 0
                            }}
                        >
                            <button formAction="submit" ref={submitButtonRef} />
                        </div>
                    </form>
                    <div className={styles.errors}>
                        {Object.keys(errors)?.map?.((error) => (
                            <ErrorMessage error={errors[error]?.message} />
                        ))}
                    </div>
                    <div className={styles.actions}>
                        <Button
                            label="Submit"
                            onClick={handleSubmitPress}
                            loading={postingFields}
                        />
                    </div>
                </FormProvider>
            )}
        </div>
    );
};

export default EditQualityForm;
