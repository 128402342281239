import { createReducer } from '@reduxjs/toolkit';

const setUser = (userState, action) => ({
    ...userState,
    image: action?.image,
    user: action?.user
});

const setPermissions = (userState, action) => ({
    ...userState,
    permissions: action?.permissions
});

const jobReducer = createReducer(
    { permissions: null },
    {
        SET_USER: setUser,
        SET_PERMISSIONS: setPermissions
    }
);

export default jobReducer;
