import { useEffect, useRef } from 'react';

import useApi from '../../../../hooks/useApi';

import ErrorMessage from '../../../general/ErrorMessage';
import Button from '../../../general/input/Button';
import InputLabel from '../../../general/input/InputLabel';
import TextInput from '../../../general/input/TextInput';
import LoadingModal from '../../../general/modal/LoadingModal';
import Modal from '../../../general/modal/Modal';
import ContactTextInput from '../ContactTextInput';
import { faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { difference, isNumber, uniqueId } from 'lodash';
import { useSnackbar } from 'notistack';
import {
    Controller,
    FormProvider,
    useFieldArray,
    useForm
} from 'react-hook-form';

import styles from '../../../../styles/apps/contact/modal/EditCompanyModal.module.scss';

const EditCompanyModal = ({ handleClose, handleEdit, record }) => {
    const submitButtonRef = useRef();

    const [{ loading: loadingCompany, data: company }, getCompany] = useApi(
        '/contact/company',
        'GET',
        {
            manual: true
        }
    );
    const [{ loading }, updateCompany] = useApi('/contact/company', 'PUT', {
        manual: true
    });
    const { enqueueSnackbar } = useSnackbar();

    const {
        handleSubmit,
        control,
        getValues,
        setError,
        setValue,
        formState: { errors },
        ...methods
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            name: '',
            primary: ''
        }
    });

    const {
        fields: addressFields,
        append: appendAddress,
        remove: removeAddress,
        replace: replaceAddress
    } = useFieldArray({
        name: 'addressFields',
        control
    });

    useEffect(() => {
        getCompany({
            url: `/contact/company/${record?.id}`
        })
            .then((company) => {
                setValue('name', company.name);
                setValue(
                    'primary',
                    company.addresses.find((a) => a.isPrimary)?.id
                );
                replaceAddress(company.addresses?.filter((a) => !a.isDeleted));
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Could not retrieve company.', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });
    }, []); //eslint-disable-line

    const handleSave = (data) => {
        const oldAddressIds = company?.addresses
            ?.filter?.((a) => !a.isDeleted)
            ?.map((a) => a.id);

        const deletedCompanyAddressIds = difference(
            oldAddressIds,
            data.addressFields?.map((af) => af.id)
        );

        const addressFields = data.addressFields?.map((af) => ({
            ...af,
            isPrimary: data.primary === af.id
        }));

        const newCompanyAddresses = addressFields
            ?.filter((af) => !isNumber(af.id))
            .map((af) => ({
                ...af,
                id: null
            }));

        const updatedCompanyAddresses = addressFields?.filter((af) => {
            if (!isNumber(af.id)) return false;
            const oldAddress = company?.addresses?.find?.(
                (a) => a.id === af.id
            );

            return (
                oldAddress.address !== af.address ||
                oldAddress.isPrimary !== af.isPrimary
            );
        });

        updateCompany({
            url: `/contact/company/${record?.id}`,
            data: {
                name: data.name,
                deletedCompanyAddressIds,
                newCompanyAddresses,
                updatedCompanyAddresses
            }
        })
            .then((company) => {
                handleEdit(record?.id, company);
                enqueueSnackbar('Company updated successfully.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                handleClose();
            })
            .catch((err) => {
                console.error(err);
                err.response?.data?.detail && setError('root.serverError', {
                    type: 'custom',
                    message: err.response?.data?.detail
                });
                enqueueSnackbar(
                    err.response?.data?.title ??
                        'Error encountered while updatings company.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000
                    }
                );
            });
    };

    const handleAddAddress = () => {
        let id = uniqueId('address');
        if (!addressFields || addressFields.length === 0)
            setValue('primary', id);
        appendAddress({
            id: id,
            address: ''
        });
    };

    const handleSubmitClicked = () => submitButtonRef?.current?.click?.();

    return loadingCompany ? (
        <LoadingModal message="Loading Company..." />
    ) : (
        <Modal open handleClose={handleClose} blocking>
            <Modal.Title icon={faPencil}>Edit Company</Modal.Title>
            <Modal.Body>
                <FormProvider
                    handleSubmit={handleSubmit}
                    control={control}
                    {...methods}
                >
                    <form
                        autoComplete="true"
                        onSubmit={handleSubmit(handleSave)}
                        noValidate={true}
                        className={styles.form}
                    >
                        <div className={styles.row}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: 'You must provide a company name.'
                                }}
                                render={({
                                    field: { value, onChange, ref },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Company Name"
                                        required
                                        error={error?.message}
                                    >
                                        <TextInput
                                            autoFocus
                                            placeholder="Company Name"
                                            value={value}
                                            onChange={onChange}
                                            inputRef={ref}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div className={styles.row}>
                            <Controller
                                name="addressFields"
                                control={control}
                                render={({
                                    field: { value: addressFields }
                                }) => (
                                    <div className={styles.textInputContainer}>
                                        {addressFields &&
                                        addressFields.length !== 0 ? (
                                            addressFields.map(
                                                (field, index) => (
                                                    <ContactTextInput
                                                        {...field}
                                                        index={index}
                                                        id={field?.id}
                                                        key={field?.id}
                                                        placeholder="Enter an address"
                                                        category="addressFields"
                                                        property="address"
                                                        removeable
                                                        handleRemove={() => {
                                                            if (
                                                                getValues(
                                                                    'primary'
                                                                ) ===
                                                                    addressFields[
                                                                        index
                                                                    ].id &&
                                                                addressFields.length >
                                                                    1
                                                            )
                                                                setValue(
                                                                    'primary',
                                                                    addressFields.find(
                                                                        (af) =>
                                                                            af.id !==
                                                                            addressFields[
                                                                                index
                                                                            ].id
                                                                    ).id
                                                                );
                                                            removeAddress(
                                                                index
                                                            );
                                                        }}
                                                    />
                                                )
                                            )
                                        ) : (
                                            <div
                                                className={
                                                    styles.emptyMessageContainer
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles.emptyMessage
                                                    }
                                                >
                                                    No addresses currently
                                                    associated with this
                                                    company.
                                                </p>
                                            </div>
                                        )}
                                        <div className={styles.actionContainer}>
                                            <span
                                                tabIndex={0}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleAddAddress();
                                                    }
                                                }}
                                                onClick={handleAddAddress}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                />
                                                <p>Add Address</p>
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        {errors.root?.serverError && (
                            <div>
                                <ErrorMessage
                                    error={errors.root.serverError.message}
                                />
                            </div>
                        )}
                        <div
                            style={{
                                visibility: 'hidden',
                                opacity: 0,
                                height: 0
                            }}
                        >
                            <button formAction="submit" ref={submitButtonRef} />
                        </div>
                    </form>
                </FormProvider>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Save"
                    onClick={handleSubmitClicked}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default EditCompanyModal;
