import InputLabel from '../../general/input/InputLabel';
import TextInput from '../../general/input/TextInput';
import { TextTooltip } from '../../general/Tooltip';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useFormContext } from 'react-hook-form';

import styles from '../../../styles/apps/contact/ContactTextInput.module.scss';

const ContactTextInput = ({
    id,
    index,
    category,
    property,
    primaryField = 'primary',
    handleRemove,
    ...props
}) => {
    const { control } = useFormContext();

    return (
        <Controller
            name={`${category}.${index}.${property}`}
            control={control}
            rules={{
                required: 'You must provide an address.'
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
                <InputLabel error={error?.message}>
                    <div className={styles.container}>
                        <Controller
                            name={primaryField}
                            control={control}
                            rules={{
                                required: 'You must select a primary option'
                            }}
                            render={({
                                field: {
                                    value: primary,
                                    onChange: handlePrimary
                                },
                                fieldState: { error: primaryError }
                            }) => (
                                <div
                                    onClick={handlePrimary.bind(this, id)}
                                    className={[
                                        styles.primaryCheckboxContainer,
                                        primary === id ? styles.active : null
                                    ].join(' ')}
                                >
                                    <TextTooltip
                                        tooltip={'Primary'}
                                        hoverTrigger="always"
                                        hoverDelay={1000}
                                    >
                                        <FontAwesomeIcon
                                            icon={
                                                primary === id
                                                    ? faCheckSquare
                                                    : faSquare
                                            }
                                        />
                                    </TextTooltip>
                                </div>
                            )}
                        />
                        <TextInput
                            value={value}
                            onChange={onChange}
                            classes={{
                                input: styles.textInput,
                                container: styles.textInputContainer
                            }}
                            autoFocus
                            {...props}
                        />
                        <TextTooltip
                            hoverTrigger="always"
                            hoverDelay={1000}
                            tooltip="Remove"
                            position={{
                                x: 'center',
                                y: 'bottom'
                            }}
                        >
                            <p className={styles.clearIcon} onClick={handleRemove}>
                                <FontAwesomeIcon icon={faTimes} />
                            </p>
                        </TextTooltip>
                    </div>
                </InputLabel>
            )}
        />
    );
};

export default ContactTextInput;
