import { useRef, useState } from 'react';

import useApi from '../../../../hooks/useApi';

import ErrorMessage from '../../../general/ErrorMessage';
import Button from '../../../general/input/Button';
import Checkbox from '../../../general/input/Checkbox';
import Dropdown from '../../../general/input/Dropdown';
import InputLabel from '../../../general/input/InputLabel';
import TextInput from '../../../general/input/TextInput';
import Modal from '../../../general/modal/Modal';
import Tabs from '../../../navigation/Tabs';
import NewEquipmentFormCard from '../NewEquipmentFormCard';
import QualityTagInput from '../QualityTagInput';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { isNumber, uniqueId } from 'lodash';
import { useSnackbar } from 'notistack';
import {
    Controller,
    FormProvider,
    useFieldArray,
    useForm
} from 'react-hook-form';

import styles from '../../../../styles/apps/quality/modal/NewEquipmentModal.module.scss';

const NewEquipmentModal = ({ handleClose, handleNew, jobId }) => {
    const submitButtonRef = useRef();

    const [{ loading }, createEquipment] = useApi(
        '/quality/equipment',
        'POST',
        { manual: true }
    );
    const [{ loading: loadingFormTypes, data: formTypes }] = useApi(
        `/quality/${jobId}/form/templates?jobSpecific=true`
    );

    const { enqueueSnackbar } = useSnackbar();
    const {
        clearErrors,
        handleSubmit,
        control,
        setError,
        formState: { errors },
        ...methods
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            name: '',
            equipmentForms: [],
            qualityTags: [],
            inheritTags: true
        }
    });

    const {
        fields: formTypeFields,
        append: appendFormType,
        remove: removeFormType
    } = useFieldArray({
        name: 'equipmentForms',
        control
    });

    const [tab, setTab] = useState('forms');

    const handleCreate = (data) => {
        createEquipment({
            data: {
                jobId: jobId,
                ...data,
                equipmentForms: data.equipmentForms.map((ef) => ({
                    ...ef,
                    formTemplate: ef.formTemplate,
                    qualityTags: ef.qualityTags.map((qt) => ({
                        ...qt,
                        id: isNumber(qt.id) ? qt.id : 0
                    }))
                })),
                qualityTags: data.qualityTags.map((qt) => ({
                    ...qt,
                    id: isNumber(qt.id) ? qt.id : 0
                }))
            }
        })
            .then((equipment) => {
                handleNew(equipment);
                enqueueSnackbar('Equipment created successfully.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                handleClose();
            })
            .catch((err) => {
                console.error(err);
                setError('root.serverError', {
                    type: 'custom',
                    message: err.response?.data?.detail
                });
                enqueueSnackbar(
                    err.response?.data?.title ??
                        'Error encountered while creating equipment.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000
                    }
                );
            });
    };

    const handleAddForm = (selected) => {
        clearErrors('equipmentForms');
        appendFormType({
            id: uniqueId('equipmentForms'),
            name: '',
            formTemplate: selected,
            qualityTags: []
        });
    };

    const handleSubmitClicked = () => submitButtonRef?.current?.click?.();

    return (
        <Modal open handleClose={handleClose} blocking>
            <Modal.Title icon={faPlus}>New Equipment</Modal.Title>
            <Modal.Body>
                <FormProvider
                    handleSubmit={handleSubmit}
                    control={control}
                    {...methods}
                >
                    <form
                        autoComplete="true"
                        onSubmit={handleSubmit(handleCreate)}
                        noValidate={true}
                        className={styles.form}
                    >
                        <div className={styles.row}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required:
                                        'You must provide an equipment name.'
                                }}
                                render={({
                                    field: { value, onChange, ref },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Equipment Name"
                                        required
                                        error={error?.message}
                                    >
                                        <TextInput
                                            placeholder="Equipment Name"
                                            value={value}
                                            onChange={onChange}
                                            inputRef={ref}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div>
                            <Tabs
                                tabs={[
                                    { key: 'forms', label: 'Forms' },
                                    { key: 'tags', label: 'Tags' }
                                ]}
                                active={tab}
                                onChange={(tab) => setTab(tab)}
                            />
                        </div>
                        <div
                            className={styles.row}
                            style={{
                                visibility:
                                    tab === 'forms' ? 'visible' : 'hidden',
                                display: tab === 'forms' ? 'flex' : 'none',
                                flexDirection: 'column',
                                gap: '4px'
                            }}
                        >
                            <Controller
                                name="inheritTags"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <Checkbox
                                        label="Inherit Equipment Tags"
                                        checked={value}
                                        handleChange={onChange.bind(
                                            this,
                                            !value
                                        )}
                                    />
                                )}
                            />
                            <Dropdown
                                placeholder="Select Form Type(s)"
                                selected={[]}
                                required={true}
                                options={formTypes?.data?.map((ft) => ({
                                    key: ft.name,
                                    value: ft.name,
                                    ...ft
                                }))}
                                persistent
                                loading={loadingFormTypes}
                                handleSelect={handleAddForm}
                            />
                            {formTypeFields && formTypeFields.length !== 0 ? (
                                formTypeFields.map((field, index) => (
                                    <NewEquipmentFormCard
                                        {...field}
                                        key={field.id}
                                        index={index}
                                        removeFormType={removeFormType}
                                        jobId={jobId}
                                    />
                                ))
                            ) : (
                                <div className={styles.emptyMessageContainer}>
                                    <p className={styles.emptyMessage}>
                                        No forms currently attached to this
                                        peice of equipment.
                                    </p>
                                </div>
                            )}
                            {/*  <div className={styles.actionContainer}>
                                    <span
                                        onClick={() => {
                                            appendFormType({
                                                id: uniqueId('equipmentForms'),
                                                name: '',
                                                formTemplate: [],
                                                qualityTags: []
                                            });
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                        <p>Add Form</p>
                                    </span>
                                </div> */}
                        </div>
                        <div
                            className={styles.row}
                            style={{
                                visibility:
                                    tab === 'tags' ? 'visible' : 'hidden',
                                display: tab === 'tags' ? 'block' : 'none'
                            }}
                        >
                            <Controller
                                name="qualityTags"
                                control={control}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel error={error}>
                                        <QualityTagInput
                                            onChange={onChange}
                                            value={value}
                                            jobId={jobId}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        {errors.root?.serverError && (
                            <div>
                                <ErrorMessage
                                    error={errors.root.serverError.message}
                                />
                            </div>
                        )}
                        <div
                            style={{
                                visibility: 'hidden',
                                opacity: 0
                            }}
                        >
                            <button formAction="submit" ref={submitButtonRef} />
                        </div>
                    </form>
                </FormProvider>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Create"
                    onClick={handleSubmitClicked}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default NewEquipmentModal;
