import { useEffect, useRef, useState } from 'react';

import useApi from '../../../../hooks/useApi';
import useUser from '../../../../hooks/useUser';

import { STATES } from '../../../../js/services/constants';
import { ContactsCustodianUpdatePermissions } from '../../../../js/services/permissions';
import ErrorMessage from '../../../general/ErrorMessage';
import Button from '../../../general/input/Button';
import CheckboxGroup from '../../../general/input/CheckboxGroup';
import Chip from '../../../general/input/Chip';
import Dropdown from '../../../general/input/Dropdown';
import InputLabel from '../../../general/input/InputLabel';
import Select from '../../../general/input/Select';
import TextInput from '../../../general/input/TextInput';
import LoadingOverlay from '../../../general/LoadingOverlay';
import Modal from '../../../general/modal/Modal';
import { TextTooltip } from '../../../general/Tooltip';
import Tabs from '../../../navigation/Tabs';
import ContactTextInput from '../ContactTextInput';
import NewCompanyModal from './NewCompanyModal';
import { faPlus, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniqueId } from 'lodash';
import { useSnackbar } from 'notistack';
import {
    Controller,
    FormProvider,
    useFieldArray,
    useForm
} from 'react-hook-form';

import styles from '../../../../styles/apps/contact/modal/NewContactModal.module.scss';

const NewContactModal = ({ handleClose, handleNew, ...props }) => {
    const submitButtonRef = useRef();

    const { displayName, userHasPermissions } = useUser();

    const [newCompany, setNewCompany] = useState(false);
    const [tab, setTab] = useState('phones');

    const [{ loading }, createContact] = useApi('/contact', 'POST', {
        manual: true
    });
    const [{ loading: loadingFlags, data: flags }, getFlags] = useApi(
        `/contact/flag?filter=${JSON.stringify({ ShowDeleted: false })}`,
        'GET',
        { manual: true }
    );

    const [{ loading: loadingCategories, data: categories }] = useApi(
        '/contact/categories',
        'GET',
        { manual: false }
    );

    const { enqueueSnackbar } = useSnackbar();
    const {
        handleSubmit,
        control,
        getValues,
        setError,
        setValue,
        formState: { errors },
        ...methods
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            streetAddress: '',
            city: '',
            state: null,
            zip: '',
            name: '',
            company: [],
            categories: [],
            categoryPrimary: null,
            email: '',
            flags: [],
            phonePrimary: null,
            custodians: []
        }
    });

    const {
        fields: phoneFields,
        append: appendPhone,
        remove: removePhone
    } = useFieldArray({
        name: 'phoneFields',
        control
    });

    useEffect(() => {
        getFlags()
            .then((flags) => {
                setValue(
                    'flags',
                    flags?.data
                        ?.filter((f) => f.defaultSelected)
                        ?.map((f) => f.id)
                );
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Could not retrieve flags.', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });
    }, []); //eslint-disable-line

    const handleCreate = (data) => {
        createContact({
            data: {
                contactCompanyId: data.company?.[0]?.id,
                contactPhones: data.phoneFields?.map?.((pf) => ({
                    ...pf,
                    isPrimary: data.phonePrimary === pf.id
                })),
                contactCategories: data.categories.map((c) => ({
                    ...c,
                    categoryId: c.key ?? c.id,
                    isPrimary: data.categoryPrimary === c.key ?? c.id
                })),
                selectedFlagIds: data.flags,
                name: data.name,
                email: data.email,
                streetAddress: data.streetAddress,
                city: data.city,
                state: data.state?.key,
                zip: data.zip,
                userIds: data.custodians.map((m) => m.id ?? m.key)
            }
        })
            .then((contact) => {
                handleNew(contact);
                enqueueSnackbar('Contact created successfully.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                handleClose();
            })
            .catch((err) => {
                console.error(err);
                err.response?.data?.detail &&
                    setError('root.serverError', {
                        type: 'custom',
                        message: err.response?.data?.detail
                    });
                enqueueSnackbar(
                    err.response?.data?.title ??
                        'Error encountered while creating contact.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000
                    }
                );
            });
    };

    const handleNewCompany = () => setNewCompany(true);
    const handleCancelNewCompany = () => setNewCompany(false);
    const handleNewCompanyCreation = (company) =>
        setValue('company', [company]);

    const handleAddPhone = () => {
        let id = uniqueId('phone');
        if (!phoneFields || phoneFields.length === 0)
            setValue('phonePrimary', id);
        appendPhone({
            id: id,
            phone: ''
        });
    };

    const handleSubmitClicked = () => submitButtonRef?.current?.click?.();

    return !newCompany ? (
        <Modal open handleClose={handleClose} blocking {...props}>
            <Modal.Title icon={faPlus}>New Contact</Modal.Title>
            <Modal.Body>
                <FormProvider
                    handleSubmit={handleSubmit}
                    control={control}
                    {...methods}
                >
                    <form
                        autoComplete="true"
                        onSubmit={handleSubmit(handleCreate)}
                        noValidate={true}
                        className={styles.form}
                    >
                        <div className={styles.row}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: 'You must provide a contact name.'
                                }}
                                render={({
                                    field: { value, onChange, ref },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Contact"
                                        required
                                        error={error?.message}
                                    >
                                        <TextInput
                                            autoFocus
                                            placeholder="Contact Name"
                                            value={value}
                                            onChange={onChange}
                                            inputRef={ref}
                                            autoComplete="off"
                                            name="contact"
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div className={styles.row}>
                            <Controller
                                name="company"
                                control={control}
                                rules={{
                                    required: 'You must select a company.'
                                }}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <>
                                        <InputLabel
                                            label="Company"
                                            required
                                            error={error?.message}
                                        >
                                            <Select
                                                placeholder="Select Company"
                                                selected={value}
                                                pagination={{
                                                    url: `/contact/company`
                                                }}
                                                getRowValue={(row) => row?.name}
                                                handleRowSelection={onChange}
                                                sort={['name']}
                                            />
                                        </InputLabel>
                                        <div className={styles.actionContainer}>
                                            <span
                                                tabIndex={0}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleNewCompany();
                                                    }
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                                onClick={handleNewCompany}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                />
                                                <p>New Company</p>
                                            </span>
                                        </div>
                                    </>
                                )}
                            />
                        </div>
                        <div className={styles.row}>
                            <Controller
                                name="custodians"
                                control={control}
                                rules={{
                                    validate: (value) =>
                                        !userHasPermissions(
                                            ContactsCustodianUpdatePermissions
                                        ) ||
                                        (value.length && value.length !== 0) ||
                                        'You must specify a custodian.'
                                }}
                                render={({
                                    field: { value: users, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Custodians"
                                        error={error?.message}
                                        required
                                    >
                                        {userHasPermissions(
                                            ContactsCustodianUpdatePermissions
                                        ) && (
                                            <Select
                                                pagination={{
                                                    url: '/user/app'
                                                }}
                                                placeholder="Select Users"
                                                selected={users}
                                                getRowValue={(row) =>
                                                    row.displayName
                                                }
                                                multiselect
                                                handleRowSelection={onChange}
                                                sort={['name']}
                                            />
                                        )}
                                        {userHasPermissions(
                                            ContactsCustodianUpdatePermissions
                                        ) ? (
                                            users &&
                                            users.length > 0 && (
                                                <div
                                                    className={
                                                        styles.chipContainer
                                                    }
                                                >
                                                    {users.map?.((u) => (
                                                        <Chip
                                                            label={
                                                                u.displayName
                                                            }
                                                            id={u.id}
                                                            removeable
                                                            handleRemove={(
                                                                id
                                                            ) =>
                                                                onChange(
                                                                    users.filter(
                                                                        (u) =>
                                                                            u.id !==
                                                                            id
                                                                    )
                                                                )
                                                            }
                                                        />
                                                    ))}
                                                </div>
                                            )
                                        ) : (
                                            <Chip label={displayName} />
                                        )}
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div className={styles.row}>
                            <Controller
                                name="categories"
                                control={control}
                                rules={{
                                    required: 'You must select a division.'
                                }}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <>
                                        <InputLabel
                                            label="Division(s)"
                                            required
                                            error={error?.message}
                                        >
                                            <Dropdown
                                                loading={loadingCategories}
                                                placeholder="Select Divisions"
                                                options={categories?.map(
                                                    (c) => ({
                                                        ...c,
                                                        key: c.id,
                                                        value: c.name
                                                    })
                                                )}
                                                selected={value}
                                                multiselect
                                                handleSelect={(selection) => {
                                                    if (
                                                        !selection.some?.(
                                                            (s) =>
                                                                s.id ===
                                                                getValues(
                                                                    'categoryPrimary'
                                                                )
                                                        )
                                                    ) {
                                                        setValue(
                                                            'categoryPrimary',
                                                            selection?.[0]?.id
                                                        );
                                                    }
                                                    onChange(selection);
                                                }}
                                            />
                                        </InputLabel>
                                        <Controller
                                            name="categoryPrimary"
                                            control={control}
                                            rules={{
                                                required:
                                                    'You must select a primary division.'
                                            }}
                                            render={({
                                                field: {
                                                    value: primaryCategory,
                                                    onChange:
                                                        primaryCategoryChange
                                                },
                                                fieldState: { error }
                                            }) => (
                                                <div
                                                    className={
                                                        styles.chipContainer
                                                    }
                                                >
                                                    {value.map((v) => (
                                                        <Chip
                                                            left={(props) => (
                                                                <div
                                                                    {...props}
                                                                    className={[
                                                                        props.className,
                                                                        styles.primaryCategoryIcon,
                                                                        primaryCategory ===
                                                                        v.id
                                                                            ? styles.primaryCategory
                                                                            : null
                                                                    ].join(' ')}
                                                                >
                                                                    <TextTooltip
                                                                        hoverTrigger="always"
                                                                        hoverDelay={
                                                                            1000
                                                                        }
                                                                        tooltip={
                                                                            primaryCategory ===
                                                                            v.id
                                                                                ? 'Primary Division'
                                                                                : 'Set As Primary'
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faStar
                                                                            }
                                                                            onClick={primaryCategoryChange.bind(
                                                                                this,
                                                                                v.id
                                                                            )}
                                                                        />
                                                                    </TextTooltip>
                                                                </div>
                                                            )}
                                                            label={v.value}
                                                            key={v.key}
                                                            id={v.key}
                                                            removeable
                                                            handleRemove={(
                                                                id
                                                            ) => {
                                                                let newCategories =
                                                                    value.filter(
                                                                        (v) =>
                                                                            v.id !==
                                                                            id
                                                                    );

                                                                if (
                                                                    primaryCategory ===
                                                                    id
                                                                ) {
                                                                    primaryCategoryChange(
                                                                        newCategories?.[0]
                                                                            ?.id
                                                                    );
                                                                }
                                                                onChange(
                                                                    newCategories
                                                                );
                                                            }}
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        />
                                    </>
                                )}
                            />
                        </div>
                        <div className={styles.row}>
                            <Controller
                                name="email"
                                control={control}
                                render={({
                                    field: { value, onChange, ref },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Email"
                                        error={error?.message}
                                    >
                                        <TextInput
                                            placeholder="Email Address"
                                            value={value}
                                            onChange={onChange}
                                            inputRef={ref}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div
                            className={styles.row}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px'
                            }}
                        >
                            <div style={{ flex: 1 }}>
                                <Controller
                                    name="streetAddress"
                                    control={control}
                                    render={({
                                        field: { value, onChange, ref },
                                        fieldState: { error }
                                    }) => (
                                        <InputLabel
                                            label="Street Address"
                                            error={error?.message}
                                        >
                                            <TextInput
                                                placeholder="Street Address"
                                                value={value}
                                                onChange={onChange}
                                                inputRef={ref}
                                                autoComplete="street-address"
                                            />
                                        </InputLabel>
                                    )}
                                />
                            </div>
                            <div style={{ flex: 1 }}>
                                <Controller
                                    name="city"
                                    control={control}
                                    render={({
                                        field: { value, onChange, ref },
                                        fieldState: { error }
                                    }) => (
                                        <InputLabel
                                            label="City"
                                            error={error?.message}
                                        >
                                            <TextInput
                                                placeholder="City"
                                                value={value}
                                                onChange={onChange}
                                                inputRef={ref}
                                            />
                                        </InputLabel>
                                    )}
                                />
                            </div>
                            <div style={{ width: '80px' }}>
                                <Controller
                                    name="state"
                                    control={control}
                                    render={({
                                        field: { value, onChange },
                                        fieldState: { error }
                                    }) => (
                                        <InputLabel
                                            label="State"
                                            error={error?.message}
                                            style={{
                                                container: {
                                                    width: '80px'
                                                }
                                            }}
                                        >
                                            <Dropdown
                                                selected={value}
                                                placeholder="State"
                                                options={STATES}
                                                handleSelect={onChange}
                                            />
                                        </InputLabel>
                                    )}
                                />
                            </div>
                        </div>
                        <div
                            className={styles.row}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px'
                            }}
                        >
                            <Controller
                                name="zip"
                                control={control}
                                render={({
                                    field: { value, onChange, ref },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Zip code"
                                        error={error?.message}
                                    >
                                        <TextInput
                                            placeholder="Zip code"
                                            value={value}
                                            onChange={onChange}
                                            inputRef={ref}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div
                            style={{
                                borderBottom: '1px solid rgba(0,0,0,0.1)'
                            }}
                        >
                            <Tabs
                                tabs={[
                                    { key: 'phones', label: 'Phone Numbers' },
                                    { key: 'flags', label: 'Flags' }
                                ]}
                                active={tab}
                                onChange={(tab) => setTab(tab)}
                            />
                        </div>
                        <div
                            className={styles.row}
                            style={{
                                visibility:
                                    tab === 'phones' ? 'visible' : 'hidden',
                                display: tab === 'phones' ? 'block' : 'none'
                            }}
                        >
                            <Controller
                                name="phoneFields"
                                control={control}
                                render={({ field: { value: phoneFields } }) => (
                                    <div className={styles.textInputContainer}>
                                        {phoneFields &&
                                        phoneFields.length !== 0 ? (
                                            phoneFields.map((field, index) => (
                                                <ContactTextInput
                                                    {...field}
                                                    index={index}
                                                    key={field?.id}
                                                    placeholder="Enter a phone number"
                                                    category="phoneFields"
                                                    property="phone"
                                                    primaryField="phonePrimary"
                                                    removeable
                                                    handleRemove={() => {
                                                        if (
                                                            getValues(
                                                                'phonePrimary'
                                                            ) ===
                                                                phoneFields[
                                                                    index
                                                                ].id &&
                                                            phoneFields.length >
                                                                1
                                                        )
                                                            setValue(
                                                                'phonePrimary',
                                                                phoneFields.find(
                                                                    (pf) =>
                                                                        pf.id !==
                                                                        phoneFields[
                                                                            index
                                                                        ].id
                                                                ).id
                                                            );
                                                        removePhone(index);
                                                    }}
                                                />
                                            ))
                                        ) : (
                                            <div
                                                className={
                                                    styles.emptyMessageContainer
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles.emptyMessage
                                                    }
                                                >
                                                    No phone numbers currently
                                                    associated with this
                                                    contact.
                                                </p>
                                            </div>
                                        )}
                                        <div className={styles.actionContainer}>
                                            <span
                                                tabIndex={0}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleAddPhone();
                                                    }
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                                onClick={handleAddPhone}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                />
                                                <p>Add Phone Number</p>
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div
                            className={styles.row}
                            style={{
                                visibility:
                                    tab === 'flags' ? 'visible' : 'hidden',
                                display: tab === 'flags' ? 'block' : 'none'
                            }}
                        >
                            <Controller
                                name="flags"
                                control={control}
                                render={({ field: { value, onChange } }) =>
                                    loadingFlags ? (
                                        <LoadingOverlay
                                            size="sm"
                                            labelPosition="right"
                                            label="Loading Flags..."
                                        />
                                    ) : (
                                        <CheckboxGroup
                                            orientation="vertical"
                                            options={flags?.data?.map?.(
                                                (f) => ({
                                                    ...f,
                                                    value: f.name,
                                                    key: f.id
                                                })
                                            )}
                                            handleChange={onChange}
                                            selected={value}
                                            multiselect
                                        />
                                    )
                                }
                            />
                        </div>
                        {errors.root?.serverError && (
                            <div>
                                <ErrorMessage
                                    error={errors.root.serverError.message}
                                />
                            </div>
                        )}
                        <div
                            style={{
                                visibility: 'hidden',
                                opacity: 0
                            }}
                        >
                            <button formAction="submit" ref={submitButtonRef} />
                        </div>
                    </form>
                </FormProvider>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Create"
                    onClick={handleSubmitClicked}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    ) : (
        <NewCompanyModal
            handleNew={handleNewCompanyCreation}
            handleClose={handleCancelNewCompany}
        />
    );
};

export default NewContactModal;
