import { createContext, useEffect, useState } from 'react';

import useApi from '../../../hooks/useApi';

import LogoLoader from '../../general/LogoLoader';
import ErrorCode from '../ErrorBoundaries/ErrorCode';
import { useIsAuthenticated } from '@azure/msal-react';
import { isFunction } from 'lodash';

const UserContext = createContext();

const UserProvider = ({ auth = true, children }) => {
    const [user, setUser] = useState(null);
    const [loadingUser, setLoadingUser] = useState(true);
    const [, setError] = useState();

    const [, getUser] = useApi('/user', 'GET', { manual: true });

    const authenticated = useIsAuthenticated();

    useEffect(() => {
        getUser?.()
            ?.then((userData) => {
                setUser(userData);
            })
            .catch((err) => {
                if (auth) {
                    setError(() => {
                        throw new ErrorCode(
                            "Couldn't retrieve the current user.",
                            'E_UserProvider_0001',
                            err?.message
                        );
                    });
                }
            })
            .finally(() => setLoadingUser(false));
    }, []); //eslint-disable-line

    const isAuthenticated = () => authenticated;

    const userHasPermissions = (permissions, condition = 'and') => {
        if (Array.isArray(permissions)) {
            if (condition === 'and') {
                return !!permissions?.every?.((p) =>
                    user?.permissions?.includes?.(p)
                );
            } else {
                return !!permissions?.some?.((p) =>
                    user?.permissions?.includes?.(p)
                );
            }
        } else {
            if (condition === 'and') {
                return !!user?.permissions?.includes?.(permissions);
            } else {
                return !!user?.permissions?.some?.((p) =>
                    p?.includes?.(permissions)
                );
            }
        }
    };

    const userHasJobPermissions = (permissions, condition = 'and') => {
        if (Array.isArray(permissions)) {
            if (condition === 'and') {
                return !!permissions?.every?.((p) =>
                    user?.jobPermissions?.[user?.defaultJob?.id]?.includes?.(p)
                );
            } else {
                return !!permissions?.some?.((p) =>
                    user?.jobPermissions?.[user?.defaultJob?.id]?.includes?.(p)
                );
            }
        } else {
            if (condition === 'and') {
                return !!user?.jobPermissions?.[user?.defaultJob?.id]?.includes?.(permissions);
            } else {
                return !!user?.jobPermissions?.[user?.defaultJob?.id]?.some?.((p) =>
                    p?.includes?.(permissions)
                );
            }
        }
    }

    const setUserJob = (job) => setUser({
        ...user,
        defaultJob: job
    });

    return (
        <UserContext.Provider
            value={{
                azureId: user?.user?.azureId,
                defaultJob: user?.defaultJob,
                displayName: user?.user?.displayName,
                division: user?.user?.division,
                id: user?.user?.id,
                jobTitle: user?.user?.jobTitle,
                mail: user?.user?.mail,
                mobilePhone: user?.user?.mobilePhone,
                businessPhones: user?.user?.businessPhones,
                image: user?.image,
                permissions: user?.permissions,
                isAuthenticated,
                setUserJob,
                userHasPermissions,
                userHasJobPermissions
            }}
        >
            {loadingUser ? (
                <LogoLoader />
            ) : isFunction(children) ? (
                children()
            ) : (
                children
            )}
        </UserContext.Provider>
    );
};

export { UserContext };
export default UserProvider;
