import React, { useState } from 'react';

import useApi from '../../../../hooks/useApi';

import FileUpload from '../../../general/input/FileUpload';
import InputLabel from '../../../general/input/InputLabel';
import TextInput from '../../../general/input/TextInput';
import LoadingOverlay from '../../../general/LoadingOverlay';
import Modal from '../../../general/modal/Modal';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import styles from '../../../../styles/apps/suggestion_box/modal/ReviewSuggestionModal.module.scss';

const ReviewSuggestionModal = ({ suggestion, handleClose, handleReviewed }) => {
    //const [{ data }, getSuggestion] = useApi(`/suggestions/${suggestionId}`, 'GET', { manual: true });
    const [
        {
            data: attachments,
            error: attachmentError,
            loading: loadingAttachments
        }
    ] = useApi(`/suggestions/${suggestion?.id}/attachments`, 'GET');
    const [{ loading: reviewLoading }, addReview] = useApi('', 'PUT', {
        manual: true
    });

    /* const stableGetSuggestion = useRef(getSuggestion);

    useEffect(() => {
        if (!suggestionId) return;

        stableGetSuggestion.current({
            url: `/suggestions/${suggestionId}`
        });
    }, [suggestionId]); */

    const { enqueueSnackbar } = useSnackbar();

    const [comment, setComment] = useState('');

    const handleComment = (e) => setComment(e.target.value);

    const handleReview = () => {
        addReview({
            url: `/suggestions/${suggestion?.id}`,
            data: {
                reviewerRemarks: comment,
                isResolved: true
            }
        })
            .then(() => {
                handleReviewed({
                    ...suggestion,
                    reviewerRemarks: comment,
                    isResolved: true
                });
                enqueueSnackbar('Suggestion has been updated.', {
                    variant: 'success',
                    autoHideDuration: 3000,
                    preventDuplicate: true
                });
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar('Failed to update suggestion.', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });
    };

    return (
        <Modal blocking={false} open={true} handleClose={handleClose}>
            <Modal.Title>Review Suggestion</Modal.Title>
            <div style={{ overflow: 'auto', maxHeight: '650px' }}>
                <Modal.Body>
                    <div className={styles.container}>
                        <div className={styles.section}>
                            <div className={styles.detailGroup}>
                                <div className={styles.detailProperty}>
                                    <div className={styles.detailLabel}>
                                        Suggestion
                                    </div>
                                    <div className={styles.detailValue}>
                                        {suggestion?.feedback}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.detailGroup}>
                                <div className={styles.detailProperty}>
                                    <div className={styles.detailLabel}>
                                        Attachments
                                    </div>
                                    <div className={styles.detailValue}>
                                        {loadingAttachments ? (
                                            <LoadingOverlay
                                                size="xs"
                                                spinColor="rgba(0,0,0,0.4)"
                                                backgroundColor="rgba(0,0,0,0.1)"
                                                label="Loading..."
                                                fontSize="12px"
                                                labelPosition="right"
                                            />
                                        ) : (
                                            <FileUpload
                                                readOnly={true}
                                                files={
                                                    attachments
                                                        ? attachments.map(
                                                              (attachment) => ({
                                                                  ...attachment,
                                                                  download: `/suggestions/${suggestion?.id}/attachments/${attachment.id}/download`,
                                                                  preview: `/suggestions/${suggestion?.id}/attachments/${attachment.id}/preview`
                                                              })
                                                          )
                                                        : []
                                                }
                                                downloadSingle={true}
                                                emptyMessage={
                                                    attachmentError &&
                                                    attachmentError?.response
                                                        ?.status === 403
                                                        ? "You don't have permissions to view the attachments."
                                                        : attachmentError
                                                        ? 'Error retrieving attachments'
                                                        : 'No attachments added.'
                                                }
                                                downloadAll={`/suggestions/${suggestion?.id}/attachments/download`}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.detailGroup}>
                                <InputLabel label="Comments">
                                    <TextInput
                                        lines={8}
                                        placeholder="Add any comments you may have."
                                        value={comment}
                                        onChange={handleComment}
                                    />
                                </InputLabel>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Actions
                    buttons={[
                        {
                            type: 'secondary',
                            variant: 'border',
                            label: 'Cancel',
                            onClick: handleClose
                        },
                        {
                            type: 'primary',
                            label: 'Resolve',
                            onClick: handleReview,
                            loading: reviewLoading
                        }
                    ]}
                />
            </div>
        </Modal>
    );
};

ReviewSuggestionModal.propTypes = {
    suggestion: PropTypes.object,
    handleClose: PropTypes.func,
    handleReviewed: PropTypes.func
};

export default ReviewSuggestionModal;
