import React, { useEffect, useMemo, useRef, useState } from 'react';

import useApi from '../../../hooks/useApi';
import useUser from '../../../hooks/useUser';

import {
    SuggestionBoxReportPermissions,
    SuggestionBoxReviewActionPermissions,
    SuggestionBoxUpdatePermissions
} from '../../../js/services/permissions';
import ActionCell from '../../general/grid/cell renderers/ActionCell';
import TextCell from '../../general/grid/cell renderers/TextCell';
import Filters from '../../general/grid/Filters';
import Grid from '../../general/grid/Grid';
import Checkbox from '../../general/input/Checkbox';
import DateRange from '../../general/input/DateRange';
import Dropdown from '../../general/input/Dropdown';
import TextInput from '../../general/input/TextInput';
import ReviewSuggestionModal from './modal/ReviewSuggestionModal';
import SuggestionReportModal from './modal/SuggestionReportModal';
import SuggestionDetail from './SuggestionDetail';
import {
    faCheck,
    faFileExport,
    faFilter,
    faMagnifyingGlass
} from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
import { DateTime } from 'luxon';

import styles from '../../../styles/apps/suggestion_box/SuggestionAdmin.module.scss';

const defaultFilterState = {
    areaOfSuggestion: {
        value: [],
        getFilter: (areaOfSuggestion) =>
            (Array.isArray(areaOfSuggestion) &&
                areaOfSuggestion.length === 0) ||
            !areaOfSuggestion
                ? null
                : {
                      Categories: Array.isArray(areaOfSuggestion)
                          ? areaOfSuggestion.map((area) => area?.key)
                          : [areaOfSuggestion.key]
                  }
    },
    suggestion: {
        value: '',
        getFilter: (value) =>
            !value || value.replace(/\s/g, '') === ''
                ? null
                : { Feedback: value }
    },
    date: {
        value: {
            from: null,
            to: null
        },
        getFilter: (value) => {
            if (!value.to && !value.from) return null;
            let filterObj = {};
            if (value.to)
                filterObj.SubmittedTo = DateTime.fromJSDate(
                    new Date(value.to)
                ).toFormat('yyyy-MM-dd');
            if (value.from)
                filterObj.SubmittedFrom = DateTime.fromJSDate(
                    new Date(value.from)
                ).toFormat('yyyy-MM-dd');
            return filterObj;
        }
    },
    showResolved: {
        value: true,
        getFilter: (value) => ({ ShowResolved: value })
    }
};

const SuggestionAdmin = () => {
    //Grid refs
    const gridRef = useRef(null);

    const { userHasPermissions } = useUser();

    //Filters
    const [filter, setFilter] = useState(defaultFilterState);
    const [description, setDescription] = useState(
        defaultFilterState.suggestion?.value
    );

    //Modal state
    const [review, setReview] = useState(false);
    const [exportModal, setExportModal] = useState(false);
    const [detail, setDetail] = useState(null);

    const [selected, setSelected] = useState([]);

    //Retrieve suggestions
    const [{ data: categories }] = useApi('/suggestions/categories', 'GET');

    /**
     * Filter state handlers
     */

    /**
     * Handle the date picker change event
     * @param {*} type - 'from' or 'to' depending on the filter changed
     * @param {*} date - date selected in the date picker
     */
    const handleDateChanged = (type, date) => {
        setFilter((filter) => ({
            ...filter,
            date: {
                ...filter['date'],
                value: {
                    ...filter.date.value,
                    [type]: date
                }
            }
        }));
    };

    const handleAreaOfSuggestion = (area) => {
        setFilter((filter) => ({
            ...filter,
            areaOfSuggestion: {
                ...filter['areaOfSuggestion'],
                value: area
            }
        }));
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleDescription = useMemo(
        () =>
            debounce((desc) => {
                if (desc === filter.suggestion.value) return;
                setFilter((filter) => ({
                    ...filter,
                    suggestion: {
                        ...filter['suggestion'],
                        value: desc
                    }
                }));
            }, 400),
        [filter.suggestion.value]
    );

    useEffect(() => {
        handleDescription(description);
    }, [description, handleDescription]);

    const handleShowResolved = () => {
        setFilter((filter) => ({
            ...filter,
            showResolved: {
                ...filter['showResolved'],
                value: !filter['showResolved'].value
            }
        }));
    };

    /**
     * Reset the filter state values to their defaults
     */
    const clearFilters = () => {
        setFilter(defaultFilterState);
        setDescription(defaultFilterState.suggestion?.value);
    };

    /**
     * Handle review modal open & close
     */
    const handleReview = (review) => setReview(review);
    const handleReviewClose = () => setReview(null);

    /**
     * Handle export modal open & close
     */
    const handleExport = () => setExportModal(true);
    const handleExportClose = () => setExportModal(false);

    /**
     * Handle a suggestion being reviewed
     * @param {*} id - id of suggestion that was reviewed
     */
    const handleReviewed = (row) => {
        handleReviewClose();
        //If the detail panel is showing the suggestion, refetch the panel data
        if (detail?.id === row.id) {
            gridRef?.current?.sidepanel?.refetch?.();
        }
        gridRef?.current?.modifyRow(row.id, row);
    };

    /**
     * Open the detail sidepanel and fetch the record when row clicked
     * @param {*} row - row id that was selected
     */
    const handleRowSelected = (id, row) => {
        gridRef.current.sidepanel.show('detail');
        setDetail(row);
        setSelected([row]);
    };

    /**
     * Close the detail sidepanel and clear detail
     */
    const handleRowDeselected = () => {
        gridRef.current.sidepanel.show(null);
        setDetail(null);
        setSelected([]);
    };

    const handlePanelClose = () => {
        setSelected([]);
    };

    return (
        <div className={styles.container}>
            <Grid
                ref={gridRef}
                pagination={{
                    url: '/suggestions',
                    pageSize: 100
                }}
                fixed
                selected={selected}
                checkboxSelection
                filters={filter}
                actions={[
                    {
                        label: 'Export',
                        icon: faFileExport,
                        type: 'secondary',
                        variant: 'border',
                        permission: SuggestionBoxReportPermissions,
                        onClick: handleExport
                    }
                ].filter(
                    (action) =>
                        !action.permission ||
                        userHasPermissions(action.permission)
                )}
                rowSelect
                handleRowSelected={handleRowSelected}
                handleRowDeselected={handleRowDeselected}
                handlePanelClose={handlePanelClose}
                sidepanel={{
                    detail: {
                        label: 'Detail',
                        icon: faMagnifyingGlass,
                        component: SuggestionDetail,
                        props: {
                            suggestion: detail,
                            handleReview: handleReview
                        }
                    },
                    filters: {
                        label: 'Filters',
                        icon: faFilter,
                        component: Filters,
                        props: {
                            clearFilters: clearFilters,
                            filters: [
                                {
                                    label: 'Area of Suggestion',
                                    component: Dropdown,
                                    width: '100%',
                                    props: {
                                        placeholder: 'Select Area',
                                        selected: filter.areaOfSuggestion.value,
                                        multiselect: true,
                                        handleSelect: handleAreaOfSuggestion,
                                        styles: {
                                            container: { width: '100%' }
                                        },
                                        options: categories
                                            ? categories.map((category) => ({
                                                  key: category.id,
                                                  value: category.name
                                              }))
                                            : []
                                    }
                                },
                                {
                                    component: TextInput,
                                    label: 'Description',
                                    width: '100%',
                                    props: {
                                        placeholder: 'Description',
                                        value: description,
                                        onChange: handleDescriptionChange
                                    }
                                },
                                {
                                    label: 'Date',
                                    component: DateRange,
                                    props: {
                                        to: filter.date.value.to,
                                        from: filter.date.value.from,
                                        handleChange: handleDateChanged
                                    }
                                },
                                {
                                    component: Checkbox,
                                    labelPlacement: 'right',
                                    props: {
                                        label: 'Show Resolved Suggestions',
                                        checked: filter.showResolved.value,
                                        handleChange: handleShowResolved
                                    }
                                }
                            ]
                        }
                    }
                }}
                columns={[
                    {
                        title: 'Date',
                        key: 'createdOn',
                        dataKey: 'createdOn',
                        sortKey: 'CreatedOn',
                        width: 120,
                        minWidth: 120,
                        cellRenderer: ({ cellData }) => (
                            <TextCell>
                                {cellData &&
                                    new Intl.DateTimeFormat('en-US', {
                                        timeZone: 'UTC',
                                        month: '2-digit',
                                        day: '2-digit',
                                        year: 'numeric'
                                    }).format(new Date(cellData))}
                            </TextCell>
                        )
                    },
                    {
                        title: 'Area Of Suggestion',
                        key: 'categoryName',
                        dataKey: 'categoryName',
                        sortKey: 'CategoryName',
                        width: 200,
                        minWidth: 200,
                        cellRenderer: ({ cellData }) => (
                            <TextCell>{cellData}</TextCell>
                        )
                    },
                    {
                        title: 'Job Name',
                        key: 'jobName',
                        dataKey: 'jobName',
                        sortKey: 'JobName',
                        width: 160,
                        minWidth: 160,
                        cellRenderer: ({ cellData }) => (
                            <TextCell>{cellData}</TextCell>
                        )
                    },
                    {
                        title: 'Suggestion',
                        key: 'feedback',
                        dataKey: 'feedback',
                        sortKey: 'Feedback',
                        fixedGrow: true,
                        width: 200,
                        minWidth: 200,
                        cellRenderer: ({ cellData }) => (
                            <TextCell>{cellData}</TextCell>
                        )
                    },
                    ...(filter.showResolved.value ? [{
                        title: 'Reviewed By',
                        key: 'reviewerName',
                        dataKey: 'reviewerName',
                        sortable: false,
                        width: 160,
                        minWidth: 160,
                        cellRenderer: ({ cellData }) => (
                            <TextCell>{cellData}</TextCell>
                        )
                    }] : []),
                    ...(userHasPermissions(SuggestionBoxUpdatePermissions, 'or')
                        ? [
                              {
                                  title: ' ',
                                  key: 'view',
                                  width: 60,
                                  minWidth: 60,
                                  sortable: false,
                                  frozen: 'right',
                                  cellRenderer: ({ rowData }) => (
                                      <ActionCell
                                          actions={
                                              rowData?.isResolved || !userHasPermissions(SuggestionBoxReviewActionPermissions[rowData?.['categoryName']])
                                                  ? []
                                                  : [
                                                        {
                                                            type: 'grayscale',
                                                            icon: faCheck,
                                                            onClick:
                                                                handleReview.bind(
                                                                    this,
                                                                    rowData
                                                                ),
                                                            tooltip: {
                                                                tooltip:
                                                                    'Review',
                                                                hoverDelay: 650,
                                                                hoverTrigger:
                                                                    'always'
                                                            }
                                                        }
                                                    ]
                                          }
                                      />
                                  )
                              }
                          ]
                        : [])
                ]}
            />
            {review && (
                <ReviewSuggestionModal
                    suggestion={review}
                    handleClose={handleReviewClose}
                    handleReviewed={handleReviewed}
                />
            )}
            {exportModal && (
                <SuggestionReportModal
                    handleClose={handleExportClose}
                    filters={filter}
                />
            )}
        </div>
    );
};

export default SuggestionAdmin;
