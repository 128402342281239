import { createReducer } from '@reduxjs/toolkit';

const toggleExpanded = (navigationState) => ({
    ...navigationState,
    expanded: !navigationState.expanded
});

const setExpanded = (navigationState, action) => ({
    ...navigationState,
    expanded: action.expanded
});

const navigationReducer = createReducer(
    {
        expanded: false
    },
    {
        TOGGLE_EXPANDED: toggleExpanded,
        SET_EXPANDED: setExpanded
    }
);

export default navigationReducer;
