import { useRef } from 'react';

import useApi from '../../../../hooks/useApi';

import Button from '../../../general/input/Button';
import InputLabel from '../../../general/input/InputLabel';
import Select from '../../../general/input/Select';
import TextInput from '../../../general/input/TextInput';
import Modal from '../../../general/modal/Modal';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import styles from '../../../../styles/apps/quality/modal/EditEquipmentAttachmentModal.module.scss';
import { isNumber } from 'lodash';
import QualityTagInput from '../QualityTagInput';

const EditEquipmentAttachmentModal = ({
    handleClose,
    attachment,
    handleEdit,
    jobId
}) => {
    const submitButtonRef = useRef();

    const [{ loading }, updateAttachment] = useApi(
        '/quality/attachment',
        'PUT',
        {
            manual: true
        }
    );
    const { enqueueSnackbar } = useSnackbar();
    const { handleSubmit, control, getValues } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            equipment: attachment.equipment ? [attachment.equipment] : [],
            name: attachment.attachment.filename.split?.('.')?.[0],
            extension: attachment.attachment.filename.split?.('.')?.[1],
            description: attachment.attachment.description,
            qualityTags: attachment.qualityTags
        }
    });

    const handleUpdate = (data) => {
        updateAttachment({
            url: `/quality/attachment/${attachment?.attachment?.id}`,
            data: {
                filename: `${data?.name}.${data?.extension}`,
                description: data?.description,
                equipmentId: data?.equipment?.[0]?.id,
                jobId: jobId,
                qualityTags: data?.qualityTags.map((qt) => ({
                    ...qt,
                    id: isNumber(qt.id) ? qt.id : 0
                }))
            }
        })
            .then((attachment) => {
                handleEdit(attachment.id, attachment);
                enqueueSnackbar('Attachment updated successfully.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                handleClose();
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar(
                    'Error encountered while updating attachment.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000
                    }
                );
            });
    };

    const handleSubmitClicked = () => submitButtonRef?.current?.click?.();

    return (
        <Modal open handleClose={handleClose} blocking>
            <Modal.Title icon={faPencilAlt}>Edit Attachment</Modal.Title>
            <Modal.Body>
                <form
                    autoComplete="true"
                    onSubmit={handleSubmit(handleUpdate)}
                    noValidate={true}
                    className={styles.form}
                >
                    <div className={styles.row}>
                        <Controller
                            name="equipment"
                            control={control}
                            render={({
                                field: { value, onChange },
                                fieldState: { error }
                            }) => (
                                <InputLabel label="Equipment" error={error}>
                                    <Select
                                        placeholder="Select Equipment"
                                        selected={value}
                                        handleRowSelection={onChange}
                                        pagination={{
                                            url: `/quality/${jobId}/equipment`
                                        }}
                                        getRowValue={(r) => r.name}
                                        sort={['equipment']}
                                    />
                                </InputLabel>
                            )}
                        />
                    </div>
                    <div className={styles.row}>
                        <Controller
                            name="name"
                            control={control}
                            rules={{
                                required: 'You must provide an attachment name.'
                            }}
                            render={({
                                field: { value, onChange, ref },
                                fieldState: { error }
                            }) => (
                                <InputLabel
                                    label="Attachment Name"
                                    required
                                    error={error?.message}
                                >
                                    <div className={styles.nameContainer}>
                                        <div className={styles.inputContainer}>
                                        <TextInput
                                            placeholder="Attachment Name"
                                            value={value}
                                            onChange={onChange}
                                            inputRef={ref}
                                        />
                                        </div>
                                        <span className={styles.extension}>
                                            <p>{`.${getValues('extension')}`}</p>
                                        </span>
                                    </div>
                                </InputLabel>
                            )}
                        />
                    </div>
                    <div className={styles.row}>
                        <Controller
                            name="description"
                            control={control}
                            render={({
                                field: { value, onChange, ref },
                                fieldState: { error }
                            }) => (
                                <InputLabel
                                    label="Attachment Description"
                                    error={error?.message}
                                >
                                    <TextInput
                                        placeholder="Attachment Description"
                                        value={value}
                                        onChange={onChange}
                                        inputRef={ref}
                                        lines={4}
                                    />
                                </InputLabel>
                            )}
                        />
                    </div>
                    <div className={styles.row}>
                        <Controller
                            name="qualityTags"
                            control={control}
                            render={({
                                field: { value, onChange },
                                fieldState: { error }
                            }) => (
                                <InputLabel label="Tags" error={error}>
                                    <QualityTagInput
                                        value={value}
                                        onChange={onChange}
                                        jobId={jobId}
                                    />
                                </InputLabel>
                            )}
                        />
                    </div>
                    <div
                        style={{
                            visibility: 'hidden',
                            opacity: 0
                        }}
                    >
                        <button formAction="submit" ref={submitButtonRef} />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Update"
                    onClick={handleSubmitClicked}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default EditEquipmentAttachmentModal;
