import React, { useRef, useState } from 'react';

import useApi from '../../../../hooks/useApi';
import useUser from '../../../../hooks/useUser';

import {
    CertificationCreatePermissions,
    CertificationDeletePermissions,
    CertificationReportPermissions,
    CertificationUpdatePermissions
} from '../../../../js/services/permissions';
import ActionCell from '../../../general/grid/cell renderers/ActionCell';
import TextCell from '../../../general/grid/cell renderers/TextCell';
import Filters from '../../../general/grid/Filters';
import Grid from '../../../general/grid/Grid';
import DateRange from '../../../general/input/DateRange';
import Dropdown from '../../../general/input/Dropdown';
import Select from '../../../general/input/Select';
import DeleteEmployeeCertificationModal from '../modal/DeleteEmployeeCertificationModal';
import EditEmployeeCertificationModal from '../modal/EditEmployeeCertificationModal';
import EmployeeCertificationReportModal from '../modal/EmployeeCertificationReportModal';
import NewEmployeeCertificationModal from '../modal/NewEmployeeCertificationModal';
import ReviewCertificationDetail from '../ReviewCertificationDetail';
import {
    faFileExport,
    faFilter,
    faMagnifyingGlass,
    faPencilAlt,
    faPlus,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { DateTime } from 'luxon';

import styles from '../../../../styles/apps/certifications/Grid.module.scss';

const defaultFilterModel = {
    certifiedOn: {
        value: {
            from: null,
            to: null
        },
        getFilter: (value) => {
            if (!value.from && !value.to) return null;
            let filter = {};
            if (value.from)
                filter['ReceivedFrom'] = DateTime.fromJSDate(
                    new Date(value.from)
                ).toFormat('yyyy-MM-dd');
            if (value.to)
                filter['ReceivedTo'] = DateTime.fromJSDate(
                    new Date(value.to)
                ).toFormat('yyyy-MM-dd');
            return filter;
        }
    },
    expiresOn: {
        value: {
            from: null,
            to: null
        },
        getFilter: (value) => {
            if (!value.from && !value.to) return null;
            let filter = {};
            if (value.from)
                filter['ExpiresFrom'] = DateTime.fromJSDate(
                    new Date(value.from)
                ).toFormat('yyyy-MM-dd');
            if (value.to)
                filter['ExpiresTo'] = DateTime.fromJSDate(
                    new Date(value.to)
                ).toFormat('yyyy-MM-dd');
            return filter;
        }
    },
    certificationTypes: {
        value: [],
        getFilter: (certifications) =>
            certifications.length === 0
                ? null
                : { CertificationTypeIds: certifications.map?.((c) => c.id) }
    },
    employees: {
        value: [],
        getFilter: (employees) =>
            employees.length === 0
                ? null
                : { EmployeeIds: employees.map?.((e) => e.id) }
    },
    divisions: {
        value: [],
        getFilter: (divisions) =>
            divisions.length === 0
                ? null
                : { DivisionIds: divisions.map?.((d) => d.key) }
    },
    hideInactive: {
        value: true,
        getFilter: () => ({ IsEmployeeActive: true })
    },
    hideReviewed: {
        value: true,
        getFilter: () => ({ IsCertification: true })
    }
};

const EmployeeCertificationsGrid = () => {
    //Retrieve user permissions
    const { userHasPermissions } = useUser();
    const [{ data: divisionsList, loading: divisionsLoading }] = useApi(
        '/divisions',
        'GET'
    );

    const gridRef = useRef(null);

    const [selected, setSelected] = useState([]);

    //Filters
    const [filter, setFilter] = useState(defaultFilterModel);

    //Modal state
    const [newModal, setNewModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [exportModal, setExportModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const [detail, setDetail] = useState(null);

    /**
     * Filter state handlers
     */

    /**
     * Handle the received date picker change event
     * @param {*} type - 'from' or 'to' depending on the filter changed
     * @param {*} date - date selected in the date picker
     */
    const handleReceiveChanged = (type, date) => {
        setFilter((filter) => ({
            ...filter,
            certifiedOn: {
                ...filter['certifiedOn'],
                value: {
                    ...filter.certifiedOn.value,
                    [type]: date
                }
            }
        }));
    };

    /**
     * Handle the expires date picker change event
     * @param {*} type - 'from' or 'to' depending on the filter changed
     * @param {*} date - date selected in the date picker
     */
    const handleExpiresChanged = (type, date) => {
        setFilter((filter) => ({
            ...filter,
            expiresOn: {
                ...filter['expiresOn'],
                value: {
                    ...filter.expiresOn.value,
                    [type]: date
                }
            }
        }));
    };

    /**
     * Handle the employees select change event
     * @param {*} employees - list of employees selected
     */
    const handleEmployeeChange = (employees) => {
        setFilter((filter) => ({
            ...filter,
            employees: {
                ...filter['employees'],
                value: employees
            }
        }));
    };

    /**
     * Handle the divisions select change event
     * @param {*} divisions - list of divisions selected
     */
    const handleDivisionChange = (divisions) => {
        setFilter((filter) => ({
            ...filter,
            divisions: {
                ...filter['divisions'],
                value: divisions
            }
        }));
    };

    /**
     * Handle the certifications select change event
     * @param {*} certifications - list of certifications selected
     */
    const handleCertificationChange = (certificationTypes) => {
        setFilter((filter) => ({
            ...filter,
            certificationTypes: {
                ...filter['certificationTypes'],
                value: certificationTypes
            }
        }));
    };

    /**
     * Reset the filter state values to their defaults
     */
    const clearFilters = () => {
        setFilter(defaultFilterModel);
    };

    /**
     * Handle new modal open & close
     */
    const handleNew = () => setNewModal(true);
    const handleNewClose = () => setNewModal(false);

    /**
     * Handle export modal open & close
     */
    const handleExport = () => setExportModal(true);
    const handleExportClose = () => setExportModal(false);

    /**
     * Handle edit modal open & close
     */
    const handleEdit = (id) => {
        setEditModal(id);
    };
    const handleEditClose = () => setEditModal(null);

    /**
     * Handle delete modal open & close
     */
    const handleDelete = (id) => {
        setDeleteModal(id);
    };
    const handleDeleteClose = () => setDeleteModal(false);

    /**
     * Handle an employee certification being edited
     * @param {*} id - id of employee certification that was edited
     */
    const handleSaved = (row) => {
        if (detail === row.employeeCertificationId) {
            setDetail(null);
            gridRef.current.sidepanel.show(null);
        }
        gridRef?.current?.removeRow(
            row.employeeCertificationId,
            'employeeCertificationId'
        );
    };

    /**
     * Handle a new employee certifications being created
     */
    const handleCreated = (row) => {
        gridRef.current?.addRow(row);
    };

    /**
     * Handle an employee certification being deleted
     * @param {*} id
     */
    const handleDeleted = (id) => {
        if (detail === id) {
            setDetail(null);
            gridRef.current.sidepanel.show(null);
        }
        gridRef?.current?.removeRow(id, 'employeeCertificationId');
    };

    /**
     * Open the detail sidepanel and fetch the record when row clicked
     * @param {*} row - row id that was selected
     */
    const handleRowSelected = (row) => {
        gridRef.current.sidepanel.show('detail');
        setDetail(row);
        setSelected([{ id: row }]);
    };

    /**
     * Close the detail sidepanel and clear detail
     */
    const handleRowDeselected = () => {
        gridRef.current.sidepanel.show(null);
        setDetail(null);
        setSelected([]);
    };

    const handlePanelClose = () => {
        setSelected([]);
    };

    return (
        <div className={styles.container}>
            <Grid
                ref={gridRef}
                pagination={{
                    url: '/employee-certifications',
                    pageSize: 100
                }}
                fixed
                selected={selected}
                filters={filter}
                rowKey="employeeCertificationId"
                getRowId={(r) => r.employeeCertificationId}
                getRowClass={(row) => {
                    let curDate = new Date();
                    let expiringDate = new Date();
                    expiringDate.setDate(expiringDate.getDate() + 60);
                    let value = new Date(row?.expiresOn);
                    return expiringDate <= value || !row?.expiresOn
                        ? styles.currentCertification
                        : curDate > value
                        ? styles.expiredCertification
                        : styles.expiringCertification;
                }}
                rowSelect
                handleRowDeselected={handleRowDeselected}
                handleRowSelected={handleRowSelected}
                handlePanelClose={handlePanelClose}
                actions={[
                    {
                        type: 'primary',
                        label: 'New',
                        icon: faPlus,
                        onClick: handleNew,
                        permission: CertificationCreatePermissions
                    },
                    {
                        type: 'secondary',
                        variant: 'border',
                        label: 'Export',
                        icon: faFileExport,
                        onClick: handleExport,
                        permission: CertificationReportPermissions
                    }
                ].filter(
                    (action) =>
                        !action.permission ||
                        userHasPermissions(action.permission)
                )}
                sidepanel={{
                    detail: {
                        label: 'Detail',
                        icon: faMagnifyingGlass,
                        component: ReviewCertificationDetail,
                        props: {
                            employeeCertificationId: detail,
                            handleEdit: (id) => setEditModal([id]),
                            handleDelete: (id) => setDeleteModal([id])
                        }
                    },
                    filters: {
                        label: 'Filters',
                        icon: faFilter,
                        component: Filters,
                        props: {
                            clearFilters: clearFilters,
                            filters: [
                                {
                                    label: 'Employee',
                                    component: Select,
                                    props: {
                                        handleRowSelection:
                                            handleEmployeeChange,
                                        selected: filter.employees?.value,
                                        getRowValue: (row) =>
                                            row?.firstName || row?.lastName
                                                ? `${row?.lastName}, ${
                                                      row?.firstName
                                                  } ${row?.middleName ?? ''}`
                                                : undefined,
                                        getRowId: (row) => row.id,
                                        placeholder: 'Select Employee(s)',
                                        filter: {
                                            IsActive: {
                                                value: true,
                                                getFilter: () => ({
                                                    IsActive: true
                                                })
                                            }
                                        },
                                        multiselect: true,
                                        pagination: {
                                            url: '/employees'
                                        },
                                        sort: [
                                            'LastName',
                                            'FirstName',
                                            'MiddleName',
                                            'Suffix',
                                            'EmployeeNumber'
                                        ]
                                    }
                                },
                                {
                                    label: 'Division',
                                    component: Dropdown,
                                    width: '100%',
                                    props: {
                                        placeholder: 'Select Division(s)',
                                        loading: divisionsLoading,
                                        options: divisionsList?.map((d) => ({
                                            key: d.id,
                                            value: d.name
                                        })),
                                        handleSelect: handleDivisionChange,
                                        multiselect: true,
                                        selected: filter.divisions?.value
                                    }
                                },
                                {
                                    component: Select,
                                    label: 'Certification',
                                    props: {
                                        placeholder: 'Select Certification(s)',
                                        handleRowSelection:
                                            handleCertificationChange,
                                        selected:
                                            filter.certificationTypes?.value,
                                        getRowValue: (row) =>
                                            row?.description
                                                ? row?.description
                                                : undefined,
                                        getRowId: (row) => row.id,
                                        multiselect: true,
                                        filter: {
                                            ShowInactive: {
                                                value: true,
                                                getFilter: (show) => ({
                                                    ShowInactive: show
                                                })
                                            }
                                        },
                                        pagination: {
                                            url: '/certification-types'
                                        },
                                        sort: ['Description']
                                    }
                                },
                                {
                                    label: 'Received',
                                    component: DateRange,
                                    props: {
                                        to: filter.certifiedOn?.value.to,
                                        from: filter.certifiedOn?.value.from,
                                        handleChange: handleReceiveChanged
                                    }
                                },
                                {
                                    label: 'Expires',
                                    component: DateRange,
                                    props: {
                                        to: filter.expiresOn?.value.to,
                                        from: filter.expiresOn?.value.from,
                                        handleChange: handleExpiresChanged
                                    }
                                }
                            ]
                        }
                    }
                }}
                columns={[
                    {
                        title: 'Employee',
                        key: 'Employees',
                        dataKey: 'Employees',
                        sortColumns: [
                            'LastName',
                            'FirstName',
                            'MiddleName',
                            'Suffix',
                            'EmployeeNumber'
                        ],
                        width: 215,
                        minWidth: 215,
                        cellRenderer: ({ rowData }) => (
                            <TextCell>
                                {`${rowData?.lastName}, ${rowData?.firstName} ${
                                    rowData?.middleName ?? ''
                                }`}
                            </TextCell>
                        )
                    },
                    {
                        title: 'Division',
                        key: 'Division',
                        dataKey: 'division',
                        sortKey: 'Division',
                        width: 180,
                        maxWidth: 180,
                        cellRenderer: ({ cellData }) =>
                            cellData && <TextCell>{cellData}</TextCell>
                    },
                    {
                        title: 'Certification',
                        key: 'Description',
                        dataKey: 'description',
                        sortKey: 'Description',
                        fixedGrow: 1,
                        minWidth: 300,
                        cellRenderer: ({ cellData }) =>
                            cellData && <TextCell>{cellData}</TextCell>
                    },
                    {
                        title: 'Received',
                        key: 'CertifiedOn',
                        dataKey: 'certifiedOn',
                        sortKey: 'CertifiedOn',
                        width: 110,
                        maxWidth: 110,
                        cellRenderer: ({ cellData }) =>
                            cellData && (
                                <TextCell>
                                    {cellData &&
                                        new Intl.DateTimeFormat('en-US', {
                                            timeZone: 'UTC',
                                            month: '2-digit',
                                            day: '2-digit',
                                            year: 'numeric'
                                        }).format(new Date(cellData))}
                                </TextCell>
                            )
                    },
                    {
                        title: 'Expires',
                        key: 'ExpiresOn',
                        dataKey: 'expiresOn',
                        sortKey: 'ExpiresOn',
                        width: 110,
                        maxWidth: 110,
                        cellRenderer: ({ cellData }) =>
                            cellData && (
                                <TextCell>
                                    {cellData &&
                                        new Intl.DateTimeFormat('en-US', {
                                            timeZone: 'UTC',
                                            month: '2-digit',
                                            day: '2-digit',
                                            year: 'numeric'
                                        }).format(new Date(cellData))}
                                </TextCell>
                            )
                    },
                    ...(userHasPermissions(CertificationUpdatePermissions) ||
                    userHasPermissions(CertificationDeletePermissions)
                        ? [
                              {
                                  title: '',
                                  key: 'Actions',
                                  sortKey: 'CanReview',
                                  width: 84,
                                  minWidth: 84,
                                  frozen: 'right',
                                  maxWidth: 100,
                                  cellRenderer: ({ rowData }) => (
                                      <ActionCell
                                          actions={[
                                              ...(userHasPermissions(
                                                  CertificationUpdatePermissions
                                              )
                                                  ? [
                                                        {
                                                            icon: faPencilAlt,
                                                            type: 'grayscale',
                                                            onClick:
                                                                handleEdit.bind(
                                                                    this,
                                                                    rowData?.employeeCertificationId
                                                                ),
                                                            tooltip: {
                                                                tooltip: 'Edit',
                                                                hoverDelay: 650,
                                                                hoverTrigger:
                                                                    'always'
                                                            }
                                                        }
                                                    ]
                                                  : []),
                                              ...(userHasPermissions(
                                                  CertificationDeletePermissions
                                              )
                                                  ? [
                                                        {
                                                            icon: faTrashAlt,
                                                            type: 'grayscale',
                                                            onClick:
                                                                handleDelete.bind(
                                                                    this,
                                                                    rowData?.employeeCertificationId
                                                                ),
                                                            tooltip: {
                                                                tooltip:
                                                                    'Delete',
                                                                hoverDelay: 650,
                                                                hoverTrigger:
                                                                    'always'
                                                            }
                                                        }
                                                    ]
                                                  : [])
                                          ]}
                                      />
                                  )
                              }
                          ]
                        : [])
                ]}
            />
            {!!editModal && (
                <EditEmployeeCertificationModal
                    open={true}
                    handleClose={handleEditClose}
                    onSaved={handleSaved}
                    certificationId={editModal}
                />
            )}
            {newModal && (
                <NewEmployeeCertificationModal
                    open={newModal}
                    handleClose={handleNewClose}
                    onCreated={handleCreated}
                />
            )}
            <DeleteEmployeeCertificationModal
                open={!!deleteModal}
                handleClose={handleDeleteClose}
                onDeleted={handleDeleted}
                certificationId={deleteModal}
            />
            {exportModal && (
                <EmployeeCertificationReportModal
                    handleClose={handleExportClose}
                    filters={filter}
                />
            )}
        </div>
    );
};

export default EmployeeCertificationsGrid;
