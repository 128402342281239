import React from 'react';

import App from './components/config/App';
import SnackbarProvider from './components/config/Providers/SnackbarProvider';
import msalInstance from './js/services/auth';
import createStore from './js/store/configureStore';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from '@azure/msal-react';
import 'core-js/actual/array';
import 'core-js/actual/error';
import 'core-js/actual/function/bind';
import 'core-js/actual/function/has-instance';
import 'core-js/actual/function/name';
import 'core-js/actual/json/stringify';
import 'core-js/actual/number/is-nan';
import 'core-js/actual/object/has-own';
import 'core-js/actual/object/is';
import 'core-js/actual/object/keys';
import 'core-js/actual/object/to-string';
import 'core-js/actual/object/values';
import 'core-js/actual/string/match';
import 'core-js/actual/string/replace';
import 'core-js/actual/string/search';
import 'core-js/actual/string/split';

/** Polyfills */
import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';

import './styles/config/index.scss';

//Redux store creation
const store = createStore();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <MsalProvider instance={msalInstance}>
        <ReduxProvider store={store}>
            <SnackbarProvider>
                <App />
                <div id="tooltip-root"></div>
                {/* <div id="modal-root"></div> */}
            </SnackbarProvider>
        </ReduxProvider>
    </MsalProvider>
);

/* if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./components/config/App', renderApp);
} */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
//renderApp();
