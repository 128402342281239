import { useState } from 'react';

import Button from '../../general/input/Button';
import DeleteWorkOrderTimeModal from './modal/DeleteWorkOrderTimeModal';
import EditWorkOrderTimeModal from './modal/EditWorkOrderTimeModal';
import NewWorkOrderTimeModal from './modal/NewWorkOrderTimeModal';
import {
    faClock,
    faPencilAlt,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addHours } from 'date-fns';

import styles from '../../../styles/apps/workorder/WorkOrderTime.module.scss';

const WorkOrderTime = ({ fields, handleAdd, handleRemove, handleEdit }) => {
    const [modal, setModal] = useState({
        new: false,
        edit: false,
        delete: false
    });

    const handleCloseModal = (type) =>
        setModal((modal) => ({
            ...modal,
            [type]: false
        }));

    const handleOpenModal = (type, value) =>
        setModal((modal) => ({
            ...modal,
            [type]: value ?? true
        }));

    return (
        <div className={styles.container}>
            <div>
                {fields?.map?.((field, index) => (
                    <div className={styles.card} key={index}>
                        <div className={styles.cardBody}>
                            <div className={styles.header}>
                                <p
                                    className={styles.action}
                                    onClick={handleOpenModal.bind(
                                        this,
                                        'edit',
                                        {
                                            ...field,
                                            index: index
                                        }
                                    )}
                                >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </p>
                                <div className={styles.workType}>
                                    <p>
                                        {field?.workType && field.workType.name}
                                    </p>
                                </div>
                            </div>
                            <div className={styles.dates}>
                                <p>
                                    {field?.startDate &&
                                        new Date(
                                            field?.startDate
                                        ).toLocaleString('en-us', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}
                                </p>
                                <p>-</p>
                                <p>
                                    {field?.startDate &&
                                        field?.hoursWorked &&
                                        new Date(
                                            addHours(
                                                field?.startDate,
                                                field?.hoursWorked
                                            )
                                        ).toLocaleString('en-us', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}
                                </p>
                                <p className={styles.hours}>
                                    ({field?.hoursWorked} hours)
                                </p>
                            </div>
                            {field?.notes && (
                                <div className={styles.notes}>
                                    <p>{field.notes}</p>
                                </div>
                            )}
                        </div>
                        <div className={styles.cardActions}>
                            <div
                                className={styles.action}
                                onClick={handleOpenModal.bind(this, 'delete', {
                                    ...field,
                                    index: index
                                })}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Button
                label="New Time Entry"
                icon={faClock}
                className={styles.timeButton}
                type="secondary"
                onClick={handleOpenModal.bind(this, 'new')}
                form
            />
            {modal.new && (
                <NewWorkOrderTimeModal
                    handleClose={handleCloseModal.bind(this, 'new')}
                    handleNew={handleAdd}
                />
            )}
            {modal.edit && (
                <EditWorkOrderTimeModal
                    handleClose={handleCloseModal.bind(this, 'edit')}
                    handleEdit={handleEdit}
                    timeRecord={modal.edit}
                />
            )}
            {modal.delete && (
                <DeleteWorkOrderTimeModal
                    handleClose={handleCloseModal.bind(this, 'delete')}
                    handleDelete={handleRemove}
                    timeRecord={modal.delete}
                />
            )}
        </div>
    );
};

export default WorkOrderTime;
