import React, { useState } from 'react';

import TextInput from '../input/TextInput';
import FilterItem from './FilterItem';
import { faSearch, faTimes, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../../../styles/general/grid/Filters.module.scss';

const Filters = ({
    filters = [],
    clearFilters = () => null,
    classes = {},
    close = false,
    handleClose = () => null
}) => {
    const [search, setSearch] = useState('');

    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    return (
        <div className={styles.container}>
            <div className={styles.filterHeader}>
                <div className={styles.heading}>
                    <div
                        className={[
                            styles.label,
                            close ? styles.close : null
                        ].join(' ')}
                        onClick={() => close && handleClose()}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                        <p>Filters</p>
                    </div>
                    <div className={styles.undo} onClick={clearFilters}>
                        <FontAwesomeIcon icon={faUndo} />
                        <p>Reset</p>
                    </div>
                </div>
                <div className={styles.search}>
                    <TextInput
                        placeholder="Search Filters"
                        value={search}
                        onChange={handleSearch}
                        icon={faSearch}
                    />
                </div>
            </div>
            <div
                className={[
                    styles.filterContainer,
                    classes.filterContainer
                ].join(' ')}
            >
                {filters
                    ?.filter?.(
                        (filter) =>
                            !search ||
                            filter?.label
                                ?.toLowerCase?.()
                                ?.includes?.(search?.toLowerCase?.())
                    )
                    ?.map?.((filter, i) => (
                        <FilterItem filter={filter} key={i} />
                    ))}
            </div>
        </div>
    );
};

Filters.propTypes = {};

export default Filters;
