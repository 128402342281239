import React from 'react';

import Loader from './Loader';
import PropTypes from 'prop-types';

import styles from '../../styles/general/LoadingOverlay.module.scss';

const LoadingOverlay = ({
    labelPosition = 'bottom',
    size = 'lg',
    spinColor = 'rgba(0,0,0,0.4)',
    backgroundColor = 'rgba(0,0,0,0.1)',
    label = 'Loading...',
    position = 'relative',
    top,
    left,
    right,
    bottom,
    contrast = false,
    fontSize,
    classes = {}
}) => {
    const getHeight = () => {
        switch (size) {
            case 'xl':
                return '42px';
            case 'lg':
                return '26px';
            case 'md':
                return '18px';
            case 'sm':
                return '14px';
            case 'xs':
                return '12px';
            default:
                return '26px';
        }
    };

    return (
        <div
            className={[styles.overlayContainer, classes?.container].join(' ')}
            styles={{
                position: position,
                top: top,
                left: left,
                right: right,
                bottom: bottom
            }}
        >
            <div
                className={[
                    styles.overlayLabel,
                    styles[`${labelPosition.toLowerCase()}Label`],
                    styles[size],
                    styles[contrast ? 'contrast' : 'normal']
                ].join(' ')}
            >
                <Loader
                    spinColor={spinColor}
                    background={backgroundColor}
                    height={getHeight()}
                />
                {label && (
                    <div
                        className={styles.label}
                        style={{ fontSize: fontSize }}
                    >
                        {label}
                    </div>
                )}
            </div>
        </div>
    );
};

LoadingOverlay.propTypes = {
    labelPosition: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    spinColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    label: PropTypes.string,
    position: PropTypes.oneOf(['relative', 'absolute', 'fixed', 'sticky']),
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    contrast: PropTypes.bool,
    fontSize: PropTypes.string
};

export default LoadingOverlay;
