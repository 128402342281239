import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from 'react';

import useApi from '../../../hooks/useApi';
import useDimensions from '../../../hooks/useDimensions';

import { removeNulls } from '../../../js/services/manipulation';
import Button, { DropdownButton } from '../input/Button';
import { TextTooltip } from '../Tooltip';
import Cell from './cell renderers/Cell';
import CheckboxCell from './cell renderers/CheckboxCell';
import HeaderCell from './cell renderers/HeaderCell';
import NoRowsOverlay from './overlays/NoRowsOverlay';
import ErrorRow from './row renderers/ErrorRow';
import LoadingRow from './row renderers/LoadingRow';
import Row from './row renderers/Row';
import SidePanel from './SidePanel';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { isFunction } from 'lodash';
import { debounce } from 'lodash';
import BaseTable, { AutoResizer, Column } from 'react-base-table';
import 'react-base-table/styles.css';

import styles from '../../../styles/general/grid/Grid.module.scss';
import '../../../styles/general/grid/GridOverrides.css';

const buildUrl = ({ url: _url, sort, filters, params = {} }) => {
    let url = _url;

    let optString = Object.keys(params)
        ?.filter?.((opt) => !!params[opt])
        ?.map?.((opt) => `${opt}=${params[opt]}`)
        ?.join?.('&');

    let sortObj = Object.keys(sort)
        ?.sort((a, b) => sort?.[a]?.order - sort?.[b]?.order)
        .reduce(
            (acc, s) => [
                ...acc,
                ...(sort[s].columns
                    ? sort[s].columns
                    : [
                          {
                              Field: s,
                              Direction: sort[s].direction,
                              CaseInsentitive: true
                          }
                      ])
            ],
            []
        );

    let sortString =
        sortObj && sortObj.length > 0 ? `sort=${JSON.stringify(sortObj)}` : '';

    let filterObj = filters
        ? Object.keys(filters)
              ?.filter(
                  (filter) =>
                      !!filters?.[filter]?.getFilter?.(filters?.[filter]?.value)
              )
              .reduce(
                  (acc, cur) => ({
                      ...acc,
                      ...filters[cur].getFilter?.(filters?.[cur]?.value)
                  }),
                  {}
              )
        : {};

    let filterString = `filter=${encodeURIComponent(
        JSON.stringify(filterObj)
    )}`;

    url = `${url}?${[optString, filterString, sortString].join('&')}`;

    return url;
};

const ClientGrid = forwardRef(
    (
        {
            actions = [],
            checkboxSelection = false,
            classes = {},
            columns = [],
            customRenderers,
            defaultExpandedRowKeys = [],
            disabled = false,
            disabledIds,
            error,
            expandColumnKey,
            expandedIds,
            filters,
            fixed = false,
            getRowClass,
            getRowId = (row) => row?.id,
            handlePanelClose,
            handleRowDeselected,
            handleRowSelected,
            handleRowSelection,
            headerHeight,
            hideHeader = false,
            loading,
            multiselect = false,
            noRowsOverlay,
            options,
            overscanRowCount = 8,
            required = false,
            renderOverride,
            rowEventHandlers,
            rowHeight = 38,
            rowKey,
            rowSelect,
            selected,
            sidepanel,
            sort: externalSort,
            style = {}
        },
        ref
    ) => {
        const gridContainer = useRef();
        const panelRef = useRef();

        const [gridWidth] = useDimensions({
            ref: gridContainer,
            debounce: true
        });

        const [sort, setSort] = useState(externalSort ?? {});
        const [data, setData] = useState(options ?? null);

        const [expanded, setExpanded] = useState(defaultExpandedRowKeys ?? []);
        const [manuallyExpanded, setManuallyExpanded] = useState(
            expandedIds ?? []
        );
        const [unexpanded, setUnexpanded] = useState([]);

        const [scrollbarPresence, setScrollbarPresence] = useState({
            vertical: null,
            horizontal: null
        });
        const [showPanel, setShowPanel] = useState('filters');

        const selectedIds = useMemo(
            () => selected?.map?.((s) => getRowId?.(s) ?? s?.id),
            [selected, getRowId]
        );

        useImperativeHandle(ref, () => ({
            addRow: (row) => {
                setData((data) => [
                    ...(Array.isArray(row) ? row : [row]),
                    ...(data ?? [])
                ]);
            },
            modifyRow: (id, row, key) => {
                setData((data) =>
                    data.map((d) =>
                        d[key ?? 'id'] === id
                            ? {
                                  ...d,
                                  ...row
                              }
                            : d
                    )
                );
            },
            removeRow: (id, key) => {
                setData((data) => data.filter((d) => d[key ?? 'id'] !== id));
            },
            sidepanel: {
                refetch: () => {
                    panelRef?.current?.refetch?.();
                },
                show: (panel) => {
                    setShowPanel(panel);
                }
            }
        }));

        const handleRowExpand = (ev) => {
            if (!ev.expanded) {
                setExpanded((expanded) =>
                    expanded.filter?.((ex) => ex !== ev.rowKey)
                );
                setUnexpanded((unexpanded) => [...unexpanded, ev.rowKey]);
            } else {
                setUnexpanded((unexpanded) =>
                    unexpanded.filter?.((ex) => ex !== ev.rowKey)
                );
                setExpanded((expanded) => [...expanded, ev.rowKey]);
            }
        };

        const rowClicked = ({ rowData, rowKey }) => {
            if (disabledIds?.includes?.(getRowId?.(rowData) ?? rowData?.id))
                return;

            if (multiselect) {
                if (selectedIds?.includes?.(rowKey)) {
                    if (!(required && selectedIds.length === 1)) {
                        handleRowDeselected?.(rowKey, rowData);
                        handleRowSelection?.(
                            selected?.filter?.((s) => getRowId?.(s) !== rowKey)
                        );
                    }
                } else {
                    handleRowSelected?.(rowKey, rowData);
                    handleRowSelection?.([...(selected ?? []), rowData]);
                }
            } else {
                if (selectedIds?.includes?.(rowKey)) {
                    if (!required) {
                        handleRowDeselected?.(rowKey, rowData);
                        handleRowSelection?.([]);
                    }
                } else {
                    handleRowSelected?.(rowKey, rowData);
                    handleRowSelection?.(
                        Array.isArray(selected) ? [rowData] : rowData
                    );
                }
            }
        };

        const handleSort = ({ key, direction, order, columns, event }) => {
            let sortObj = sort;

            if (event.shiftKey) {
                if (Object.keys(sort).find((s) => s === key)) {
                    sortObj = Object.keys(sort).reduce((acc, cur) => {
                        if (cur === key && direction === 'desc') return acc;
                        return {
                            ...acc,
                            [cur]: {
                                direction:
                                    key !== cur
                                        ? sort?.[cur]?.direction
                                        : direction === 'asc'
                                        ? 'desc'
                                        : 'asc',
                                order:
                                    key === cur
                                        ? Object.keys(sort).length
                                        : sort[cur].order > order
                                        ? sort[cur].order - 1
                                        : sort[cur].order,
                                columns: sort?.[cur]?.columns
                            }
                        };
                    }, {});
                } else {
                    sortObj = {
                        ...sortObj,
                        [key]: {
                            direction: 'asc',
                            order: Object.keys(sort).length + 1,
                            columns: columns
                        }
                    };
                }
            } else {
                if (direction === 'desc') sortObj = {};
                else
                    sortObj = {
                        [key]: {
                            direction: direction === 'asc' ? 'desc' : 'asc',
                            order: 1,
                            columns: columns
                        }
                    };
            }

            setSort(sortObj);
        };

        const renderData = useMemo(() => {
            if (renderOverride) return renderOverride;
            if (loading)
                return [
                    { 'row-variant': 'loading', [rowKey ?? 'id']: 'loading' }
                ];
            if (error)
                return [
                    {
                        'row-variant': 'error',
                        [rowKey ?? 'id']: 'error',
                        value: error
                    }
                ];

            let finalData = data ?? [];

            let sortObj =
                Object.keys(sort ?? {})?.length === 0
                    ? externalSort ?? {}
                    : sort;

            var sortScheme = Object.keys(sortObj).map((s) => ({
                key: s,
                direction: sortObj[s].direction === 'asc' ? 1 : -1
            }));

            finalData = finalData?.sort?.((a, b) => {
                let i = 0,
                    result = 0;
                while (i < sortScheme?.length && result === 0) {
                    result =
                        sortScheme[i].direction *
                        sortScheme[i].key
                            ?.toString?.()
                            .split('.')
                            .reduce((x, y) => x[y], a)
                            ?.toString?.()
                            ?.localeCompare?.(
                                sortScheme[i].key
                                    .toString()
                                    .split('.')
                                    .reduce((x, y) => x[y], b)
                                    ?.toString?.(),
                                'en',
                                { numeric: true }
                            );
                    i++;
                }
                return result;
            });

            return finalData;
        }, [data, loading, error, externalSort, sort, renderOverride, rowKey]);

        const renderEmpty = () => <NoRowsOverlay content={noRowsOverlay} />;

        useEffect(() => {
            setData(options);
        }, [options]);

        const rowRenderer = ({ cells, rowData, columns, ...rest }) => {
            let width = cells.reduce(
                (agg, cell) => agg + cell?.props?.style?.width,
                0
            );
            let minWidth = cells.reduce(
                (agg, cell) => agg + cell?.props?.style?.minWidth,
                0
            );

            let frozenColumns = !columns?.some((c) => !c.frozen);

            return rowData['row-variant'] === 'loading' && !frozenColumns ? (
                <LoadingRow />
            ) : rowData['row-variant'] === 'error' && !frozenColumns ? (
                <ErrorRow />
            ) : customRenderers?.[rowData['row-variant']] ? (
                customRenderers[rowData['row-variant']]?.({
                    cells,
                    rowData,
                    ...rest,
                    handleRowExpand: handleRowExpand,
                    expanded: [
                        ...manuallyExpanded?.filter?.(
                            (me) => !unexpanded?.includes?.(me)
                        ),
                        ...expanded
                    ]?.includes?.(rowData?.id),
                    width: width,
                    minWidth: minWidth
                })
            ) : (rowData['row-variant'] === 'loading' ||
                  rowData['row-variant'] === 'error') &&
              frozenColumns ? null : (
                cells
            );
        };

        useEffect(() => {
            if (!expandedIds) return;
            setManuallyExpanded(expandedIds ?? []);
        }, [expandedIds]);

        const togglePanel = (tab) => {
            setShowPanel((panel) => (panel === tab ? null : tab));
        };

        const hidePanel = () => {
            setShowPanel(null);
        };

        const handleScrollbarPresenceChange = ({
            size,
            vertical,
            horizontal
        }) => {
            setScrollbarPresence({
                vertical: vertical ? size : null,
                horizontal: horizontal ? size : null
            });
        };

        useEffect(() => {
            if (!showPanel) handlePanelClose?.();
        }, [showPanel]); //eslint-disable-line

        useEffect(() => {
            setSort(externalSort ?? {});
        }, [externalSort]);

        return (
            <div
                className={[styles.gridContainer, classes.gridContainer].join(
                    ' '
                )}
            >
                <div className={styles.grid}>
                    {!hideHeader && (
                        <header className={styles.header} style={style?.header}>
                            <div className={styles.actionsContainer}>
                                <div className={styles.actions}>
                                    {removeNulls(actions)?.map?.((action) =>
                                        isFunction(action) ? (
                                            action()
                                        ) : action?.options ? (
                                            <DropdownButton
                                                {...action}
                                                key={`action_${action.label}`}
                                                disabled={
                                                    isFunction(action?.disabled)
                                                        ? action.disabled(
                                                              selected
                                                          )
                                                        : action.disabled
                                                }
                                                className={styles.button}
                                            />
                                        ) : (
                                            <Button
                                                key={action?.label}
                                                {...action}
                                                disabled={
                                                    isFunction(action?.disabled)
                                                        ? action.disabled(
                                                              selected
                                                          )
                                                        : action.disabled
                                                }
                                                className={styles.button}
                                            />
                                        )
                                    )}
                                </div>
                                <div className={styles.actions}>
                                    {filters && (
                                        <TextTooltip
                                            hoverTrigger="always"
                                            tooltip={
                                                showPanel === 'filters'
                                                    ? 'Collapse Filters'
                                                    : 'Expand Filters'
                                            }
                                            position={{
                                                x: 'left',
                                                y: 'center'
                                            }}
                                            offset={{
                                                x: 8,
                                                y: 0
                                            }}
                                        >
                                            <Button
                                                type="primary"
                                                icon={faFilter}
                                                onClick={togglePanel.bind(
                                                    this,
                                                    'filters'
                                                )}
                                                className={[
                                                    styles.button,
                                                    styles.filter,
                                                    showPanel === 'filters'
                                                        ? styles.active
                                                        : null
                                                ].join(' ')}
                                            />
                                        </TextTooltip>
                                    )}
                                </div>
                            </div>
                        </header>
                    )}
                    <div
                        className={styles.body}
                        style={{ height: '100%' }}
                        ref={gridContainer}
                    >
                        <AutoResizer>
                            {({ width, height }) => (
                                <BaseTable
                                    fixed={fixed ?? false}
                                    disabled={disabled}
                                    emptyRenderer={renderEmpty}
                                    rowKey={rowKey}
                                    rowHeight={rowHeight ?? 50}
                                    headerHeight={headerHeight ?? 42}
                                    data={renderData}
                                    width={width}
                                    height={height}
                                    rowEventHandlers={{
                                        onClick: rowSelect ? rowClicked : null,
                                        ...(rowEventHandlers ?? {})
                                    }}
                                    ignoreFunctionInColumnCompare={false}
                                    rowProps={({ rowData, rowIndex }) => ({
                                        tagName: Row,
                                        disabled: disabledIds?.includes?.(
                                            getRowId(rowData)
                                        ),
                                        selected: selectedIds?.includes?.(
                                            getRowId(rowData)
                                        ),
                                        rowClass: getRowClass?.(rowData),
                                        index: rowIndex
                                    })}
                                    expandColumnKey={expandColumnKey}
                                    defaultExpandedRowKeys={
                                        defaultExpandedRowKeys
                                    }
                                    expandedRowKeys={[
                                        ...manuallyExpanded.filter(
                                            (me) => !unexpanded?.includes?.(me)
                                        ),
                                        ...expanded
                                    ]}
                                    onRowExpand={handleRowExpand}
                                    onScrollbarPresenceChange={
                                        handleScrollbarPresenceChange
                                    }
                                    sortState={sort}
                                    rowRenderer={rowRenderer}
                                    cellProps={() => ({
                                        tagName: Cell
                                    })}
                                    overscanRowCount={overscanRowCount}
                                    headerCellProps={({ column }) => ({
                                        tagName: HeaderCell,
                                        sortable: column.isSortable,
                                        onClick:
                                            column.onClick ??
                                            (column.isSortable
                                                ? handleSort
                                                : null),
                                        sortState: sort,
                                        column: column
                                    })}
                                >
                                    {checkboxSelection && (
                                        <Column
                                            title=""
                                            key="Check"
                                            dataKey="check"
                                            minWidth={50}
                                            width={50}
                                            align="center"
                                            cellRenderer={({ rowData }) => (
                                                <CheckboxCell
                                                    checked={selectedIds?.includes?.(
                                                        getRowId?.(rowData) ??
                                                            rowData?.id
                                                    )}
                                                    disabled={disabledIds?.includes?.(
                                                        getRowId?.(rowData) ??
                                                            rowData?.id
                                                    )}
                                                />
                                            )}
                                        />
                                    )}
                                    {removeNulls(columns).map((column) => (
                                        <Column
                                            {...column}
                                            isSortable={column.sortable ?? true}
                                            sortable={false}
                                            key={column.key}
                                            width={
                                                fixed && column.fixedGrow
                                                    ? column.minWidth
                                                        ? gridWidth -
                                                              (scrollbarPresence.vertical
                                                                  ? scrollbarPresence.vertical
                                                                  : 0) -
                                                              columns
                                                                  .filter(
                                                                      (c) =>
                                                                          !c.fixedGrow
                                                                  )
                                                                  .reduce(
                                                                      (
                                                                          acc,
                                                                          cur
                                                                      ) =>
                                                                          acc +
                                                                          cur.width,
                                                                      0
                                                                  ) -
                                                              (checkboxSelection
                                                                  ? 50
                                                                  : 0) >=
                                                          column.minWidth
                                                            ? gridWidth -
                                                              (scrollbarPresence.vertical
                                                                  ? scrollbarPresence.vertical
                                                                  : 0) -
                                                              columns
                                                                  .filter(
                                                                      (c) =>
                                                                          !c.fixedGrow
                                                                  )
                                                                  .reduce(
                                                                      (
                                                                          acc,
                                                                          cur
                                                                      ) =>
                                                                          acc +
                                                                          cur.width,
                                                                      0
                                                                  ) -
                                                              (checkboxSelection
                                                                  ? 50
                                                                  : 0)
                                                            : column.minWidth
                                                        : gridWidth -
                                                          (scrollbarPresence.vertical
                                                              ? scrollbarPresence.vertical
                                                              : 0) -
                                                          columns
                                                              .filter(
                                                                  (c) =>
                                                                      !c.fixedGrow
                                                              )
                                                              .reduce(
                                                                  (acc, cur) =>
                                                                      acc +
                                                                      cur.width,
                                                                  0
                                                              ) -
                                                          (checkboxSelection
                                                              ? 50
                                                              : 0)
                                                    : column.width
                                            }
                                        />
                                    ))}
                                </BaseTable>
                            )}
                        </AutoResizer>
                    </div>
                </div>
                <SidePanel
                    sidepanel={sidepanel}
                    panel={showPanel}
                    togglePanel={togglePanel}
                    hidePanel={hidePanel}
                    panelRef={panelRef}
                />
            </div>
        );
    }
);

const InfiniteGrid = forwardRef(
    (
        {
            actions = [],
            checkboxSelection = false,
            classes = {},
            columns = [],
            customRenderers,
            defaultExpandedRowKeys = [],
            disabled = false,
            disabledIds,
            expandColumnKey,
            expandedIds,
            filters,
            fixed = false,
            getRowClass,
            getRowId = (row) => row?.id,
            handlePanelClose,
            handleRowDeselected,
            handleRowSelected,
            handleRowSelection,
            headerHeight,
            hideHeader = false,
            multiselect = false,
            noRowsOverlay,
            overscanRowCount = 8,
            pagination,
            required = false,
            renderOverride,
            rowEventHandlers,
            rowHeight = 38,
            rowKey,
            rowSelect,
            selected,
            sidepanel,
            sort: externalSort,
            style = {}
        },
        ref
    ) => {
        const gridContainer = useRef();
        const panelRef = useRef();
        const pageRef = useRef(0);
        const loadedAllRef = useRef(false);
        const initialLoadingRef = useRef(true);
        const loadingMoreRef = useRef(false);
        const filterRef = useRef(filters ?? {});
        const sortRef = useRef(externalSort ?? {});

        const [width] = useDimensions();

        const [gridWidth] = useDimensions({
            ref: gridContainer,
            debounce: true
        });

        const [{ loading, error }, getData] = useApi('', 'GET', {
            manual: true
        });

        const [sort, setSort] = useState(externalSort ?? {});
        const [data, setData] = useState([]);
        const [externalLoading, setExternalLoading] = useState(false);

        const [expanded, setExpanded] = useState(defaultExpandedRowKeys ?? []);
        const [manuallyExpanded, setManuallyExpanded] = useState(
            expandedIds ?? []
        );
        const [unexpanded, setUnexpanded] = useState([]);

        const [scrollbarPresence, setScrollbarPresence] = useState({
            vertical: null,
            horizontal: null
        });
        const [showPanel, setShowPanel] = useState(width >= 1500 && 'filters');

        const selectedIds = useMemo(
            () => selected?.map?.((s) => getRowId?.(s) ?? s?.id),
            [selected, getRowId]
        );

        useImperativeHandle(ref, () => ({
            addRow: (row) => {
                setData((data) => [
                    ...(Array.isArray(row) ? row : [row]),
                    ...(data ?? [])
                ]);
            },
            getRow: (id, key) => {
                return data.find((d) => d[key ?? 'id'] === id);
            },
            modifyRow: (id, row, key) => {
                setData((data) =>
                    data.map((d) =>
                        d[key ?? 'id'] === id
                            ? {
                                  ...d,
                                  ...row
                              }
                            : d
                    )
                );
            },
            removeRow: (id, key) => {
                setData((data) => data.filter((d) => d[key ?? 'id'] !== id));
            },
            sidepanel: {
                refetch: () => {
                    panelRef?.current?.refetch?.();
                },
                show: (panel) => {
                    setShowPanel(panel);
                }
            }
        }));

        const handleRowExpand = (ev) => {
            if (!ev.expanded) {
                setExpanded((expanded) =>
                    expanded.filter?.((ex) => ex !== ev.rowKey)
                );
                setUnexpanded((unexpanded) => [...unexpanded, ev.rowKey]);
            } else {
                setUnexpanded((unexpanded) =>
                    unexpanded.filter?.((ex) => ex !== ev.rowKey)
                );
                setExpanded((expanded) => [...expanded, ev.rowKey]);
            }
        };

        const rowClicked = ({ rowData, rowKey }) => {
            if (disabledIds?.includes?.(getRowId?.(rowData) ?? rowData?.id))
                return;

            if (multiselect) {
                if (selectedIds?.includes?.(rowKey)) {
                    if (!(required && selectedIds.length === 1)) {
                        handleRowDeselected?.(rowKey, rowData);
                        handleRowSelection?.(
                            selected?.filter?.((s) => getRowId?.(s) !== rowKey)
                        );
                    }
                } else {
                    handleRowSelected?.(rowKey, rowData);
                    handleRowSelection?.([...(selected ?? []), rowData]);
                }
            } else {
                if (selectedIds?.includes?.(rowKey)) {
                    if (!required) {
                        handleRowDeselected?.(rowKey, rowData);
                        handleRowSelection?.([]);
                    }
                } else {
                    handleRowSelected?.(rowKey, rowData);
                    handleRowSelection?.(
                        Array.isArray(selected) ? [rowData] : rowData
                    );
                }
            }
        };

        const handleSort = ({ key, direction, order, columns, event }) => {
            let sortObj = sort;

            if (event.shiftKey) {
                if (Object.keys(sort).find((s) => s === key)) {
                    sortObj = Object.keys(sort).reduce((acc, cur) => {
                        if (cur === key && direction === 'desc') return acc;
                        return {
                            ...acc,
                            [cur]: {
                                direction:
                                    key !== cur
                                        ? sort?.[cur]?.direction
                                        : direction === 'asc'
                                        ? 'desc'
                                        : 'asc',
                                order:
                                    key === cur
                                        ? Object.keys(sort).length
                                        : sort[cur].order > order
                                        ? sort[cur].order - 1
                                        : sort[cur].order,
                                columns: sort?.[cur]?.columns
                            }
                        };
                    }, {});
                } else {
                    sortObj = {
                        ...sortObj,
                        [key]: {
                            direction: 'asc',
                            order: Object.keys(sort).length + 1,
                            columns: columns
                        }
                    };
                }
            } else {
                if (direction === 'desc') sortObj = {};
                else
                    sortObj = {
                        [key]: {
                            direction: direction === 'asc' ? 'desc' : 'asc',
                            order: 1,
                            columns: columns
                        }
                    };
            }

            sortRef.current = sortObj;
            setSort(sortObj);
        };

        const renderData = useMemo(() => {
            if (renderOverride) return renderOverride;
            if (error)
                return [{ 'row-variant': 'error', [rowKey ?? 'id']: 'error' }];
            if (initialLoadingRef.current)
                return [
                    { 'row-variant': 'loading', [rowKey ?? 'id']: 'loading' }
                ];

            let finalData = data ?? [];

            if (!loadedAllRef.current) {
                return [
                    ...finalData,
                    { 'row-variant': 'loading', [rowKey ?? 'id']: 'loading' }
                ];
            } else return finalData;
        }, [data, error, renderOverride, rowKey]);

        const renderEmpty = () => <NoRowsOverlay content={noRowsOverlay} />;

        useEffect(() => {
            let initialLoad = initialLoadingRef.current;

            filterRef.current = filters;

            if (initialLoad) {
                resetGrid();
            } else {
                handleFilterChange();
            }
        }, [sort, pagination?.record, filters]); //eslint-disable-line
        
        const resetGrid = () => {
            pageRef.current = 0;
            loadedAllRef.current = false;
            initialLoadingRef.current = true;
            setData([]);

            handlePageRetrieve();
        };

        const _getData = () => {
            if (!pagination?.url) return Promise.resolve();
            let config = {
                url: buildUrl({
                    url: pagination?.url,
                    sort: sortRef.current,
                    filters: filterRef.current,
                    params: {
                        ...pagination?.params,
                        page: pageRef?.current,
                        pageSize: pagination?.pageSize
                    }
                })
            };

            if (pagination?.callback) {
                setExternalLoading(true);
                return pagination
                    .callback(config)
                    .then((res) => {
                        if (res?.total === 1 || res?.page === res?.total)
                            loadedAllRef.current = true;
                        return Promise.resolve();
                    })
                    .finally(() => setExternalLoading(false));
            }
            return (pagination?.promiseCallback ?? getData)(config);
        };

        const handlePageRetrieve = () => {
            if (loadedAllRef.current || loading || externalLoading) return;

            loadingMoreRef.current = true;

            _getData().then((res) => {
                pageRef.current = pageRef.current + 1;
                if (!res) return;
                if (res?.total === 0 || res?.page === res?.total)
                    loadedAllRef.current = true;
                if (pagination?.dataTransform)
                    data.data = pagination.dataTransform(data.data);
                setData((d) => [...(d ?? []), ...(res?.data ?? [])]);
                loadingMoreRef.current = false;
                if (initialLoadingRef.current)
                    initialLoadingRef.current = false;
            });
        };

        const handleFilterChange = useCallback( //eslint-disable-line
            debounce(resetGrid, 300), //eslint-disable-line
            [] //eslint-disable-line
        );

        const handleEndReached = () => {
            if (
                initialLoadingRef.current ||
                loadingMoreRef.current ||
                loadedAllRef.current
            )
                return;
            handlePageRetrieve();
        };

        const rowRenderer = ({ cells, rowData, columns, ...rest }) => {
            let width = cells.reduce(
                (agg, cell) => agg + cell?.props?.style?.width,
                0
            );
            let minWidth = cells.reduce(
                (agg, cell) => agg + cell?.props?.style?.minWidth,
                0
            );

            let frozenColumns = !columns?.some((c) => !c.frozen);

            return rowData['row-variant'] === 'loading' && !frozenColumns ? (
                <LoadingRow />
            ) : rowData['row-variant'] === 'error' && !frozenColumns ? (
                <ErrorRow />
            ) : customRenderers?.[rowData['row-variant']] ? (
                customRenderers[rowData['row-variant']]?.({
                    cells,
                    rowData,
                    ...rest,
                    handleRowExpand: handleRowExpand,
                    expanded: [
                        ...manuallyExpanded?.filter?.(
                            (me) => !unexpanded?.includes?.(me)
                        ),
                        ...expanded
                    ]?.includes?.(rowData?.id),
                    width: width,
                    minWidth: minWidth
                })
            ) : (rowData['row-variant'] === 'loading' ||
                  rowData['row-variant'] === 'error') &&
              frozenColumns ? null : (
                cells
            );
        };

        useEffect(() => {
            if (!expandedIds) return;
            setManuallyExpanded(expandedIds ?? []);
        }, [expandedIds]);

        const togglePanel = (tab) => {
            setShowPanel((panel) => (panel === tab ? null : tab));
        };

        const hidePanel = () => {
            setShowPanel(null);
        };

        const handleScrollbarPresenceChange = ({
            size,
            vertical,
            horizontal
        }) => {
            setScrollbarPresence({
                vertical: vertical ? size : null,
                horizontal: horizontal ? size : null
            });
        };

        useEffect(() => {
            if (!showPanel) handlePanelClose?.();
        }, [showPanel]); //eslint-disable-line

        useEffect(() => {
            setSort(externalSort ?? {});
            if(externalSort) sortRef.current = externalSort;
            //sortRef.current = (externalSort ?? {});
        }, [externalSort]);

        return (
            <div
                className={[styles.gridContainer, classes.gridContainer].join(
                    ' '
                )}
            >
                <div className={styles.grid}>
                    {!hideHeader && (
                        <header className={styles.header} style={style?.header}>
                            <div className={styles.actionsContainer}>
                                <div className={styles.actions}>
                                    {removeNulls(actions)?.map?.((action) =>
                                        isFunction(action) ? (
                                            action()
                                        ) : action?.options ? (
                                            <DropdownButton
                                                {...action}
                                                key={`action_${action.label}`}
                                                disabled={
                                                    isFunction(action?.disabled)
                                                        ? action.disabled(
                                                              selected
                                                          )
                                                        : action.disabled
                                                }
                                                className={styles.button}
                                            />
                                        ) : (
                                            <Button
                                                key={action?.label}
                                                {...action}
                                                disabled={
                                                    isFunction(action?.disabled)
                                                        ? action.disabled(
                                                              selected
                                                          )
                                                        : action.disabled
                                                }
                                                className={styles.button}
                                            />
                                        )
                                    )}
                                </div>
                                <div className={styles.actions}>
                                    {filters && (
                                        <TextTooltip
                                            hoverTrigger="always"
                                            tooltip={
                                                showPanel === 'filters'
                                                    ? 'Collapse Filters'
                                                    : 'Expand Filters'
                                            }
                                            position={{
                                                x: 'left',
                                                y: 'center'
                                            }}
                                            offset={{
                                                x: 8,
                                                y: 0
                                            }}
                                        >
                                            <Button
                                                type="primary"
                                                icon={faFilter}
                                                onClick={togglePanel.bind(
                                                    this,
                                                    'filters'
                                                )}
                                                className={[
                                                    styles.button,
                                                    styles.filter,
                                                    showPanel === 'filters'
                                                        ? styles.active
                                                        : null
                                                ].join(' ')}
                                            />
                                        </TextTooltip>
                                    )}
                                </div>
                            </div>
                        </header>
                    )}
                    <div
                        className={styles.body}
                        style={{ height: '100%' }}
                        ref={gridContainer}
                    >
                        <AutoResizer>
                            {({ width, height }) => (
                                <BaseTable
                                    fixed={fixed ?? false}
                                    disabled={disabled}
                                    emptyRenderer={renderEmpty}
                                    rowKey={rowKey}
                                    rowHeight={rowHeight ?? 50}
                                    headerHeight={headerHeight ?? 42}
                                    data={renderData}
                                    width={width}
                                    height={height}
                                    rowEventHandlers={{
                                        onClick: rowSelect ? rowClicked : null,
                                        ...(rowEventHandlers ?? {})
                                    }}
                                    ignoreFunctionInColumnCompare={false}
                                    rowProps={({ rowData, rowIndex }) => ({
                                        tagName: Row,
                                        disabled: disabledIds?.includes?.(
                                            getRowId(rowData)
                                        ),
                                        selected: selectedIds?.includes?.(
                                            getRowId(rowData)
                                        ),
                                        rowClass: getRowClass?.(rowData),
                                        index: rowIndex
                                    })}
                                    expandColumnKey={expandColumnKey}
                                    defaultExpandedRowKeys={
                                        defaultExpandedRowKeys
                                    }
                                    expandedRowKeys={[
                                        ...manuallyExpanded.filter(
                                            (me) => !unexpanded?.includes?.(me)
                                        ),
                                        ...expanded
                                    ]}
                                    onRowExpand={handleRowExpand}
                                    onScrollbarPresenceChange={
                                        handleScrollbarPresenceChange
                                    }
                                    sortState={sort}
                                    rowRenderer={rowRenderer}
                                    cellProps={({ column }) => ({
                                        tagName: Cell,
                                        customClasses: column.classes
                                    })}
                                    overscanRowCount={overscanRowCount}
                                    onEndReached={handleEndReached}
                                    headerCellProps={({ column }) => ({
                                        tagName: HeaderCell,
                                        sortable: column.isSortable,
                                        onClick:
                                            column.onClick ??
                                            (column.isSortable
                                                ? handleSort
                                                : null),
                                        sortState: sort,
                                        column: column
                                    })}
                                >
                                    {checkboxSelection && (
                                        <Column
                                            title=""
                                            key="Check"
                                            dataKey="check"
                                            minWidth={50}
                                            width={50}
                                            align="center"
                                            cellRenderer={({ rowData }) => (
                                                <CheckboxCell
                                                    checked={selectedIds?.includes?.(
                                                        getRowId?.(rowData) ??
                                                            rowData?.id
                                                    )}
                                                    disabled={disabledIds?.includes?.(
                                                        getRowId?.(rowData) ??
                                                            rowData?.id
                                                    )}
                                                />
                                            )}
                                        />
                                    )}
                                    {removeNulls(columns).map((column) => (
                                        <Column
                                            {...column}
                                            isSortable={column.sortable ?? true}
                                            sortable={false}
                                            key={column.key}
                                            width={
                                                fixed && column.fixedGrow
                                                    ? column.minWidth
                                                        ? gridWidth -
                                                              (scrollbarPresence.vertical
                                                                  ? scrollbarPresence.vertical
                                                                  : 0) -
                                                              columns
                                                                  .filter(
                                                                      (c) =>
                                                                          !c.fixedGrow
                                                                  )
                                                                  .reduce(
                                                                      (
                                                                          acc,
                                                                          cur
                                                                      ) =>
                                                                          acc +
                                                                          cur.width,
                                                                      0
                                                                  ) -
                                                              (checkboxSelection
                                                                  ? 50
                                                                  : 0) >=
                                                          column.minWidth
                                                            ? gridWidth -
                                                              (scrollbarPresence.vertical
                                                                  ? scrollbarPresence.vertical
                                                                  : 0) -
                                                              columns
                                                                  .filter(
                                                                      (c) =>
                                                                          !c.fixedGrow
                                                                  )
                                                                  .reduce(
                                                                      (
                                                                          acc,
                                                                          cur
                                                                      ) =>
                                                                          acc +
                                                                          cur.width,
                                                                      0
                                                                  ) -
                                                              (checkboxSelection
                                                                  ? 50
                                                                  : 0)
                                                            : column.minWidth
                                                        : gridWidth -
                                                          (scrollbarPresence.vertical
                                                              ? scrollbarPresence.vertical
                                                              : 0) -
                                                          columns
                                                              .filter(
                                                                  (c) =>
                                                                      !c.fixedGrow
                                                              )
                                                              .reduce(
                                                                  (acc, cur) =>
                                                                      acc +
                                                                      cur.width,
                                                                  0
                                                              ) -
                                                          (checkboxSelection
                                                              ? 50
                                                              : 0)
                                                    : column.width
                                            }
                                        />
                                    ))}
                                </BaseTable>
                            )}
                        </AutoResizer>
                    </div>
                </div>
                <SidePanel
                    sidepanel={sidepanel}
                    panel={showPanel}
                    togglePanel={togglePanel}
                    hidePanel={hidePanel}
                    panelRef={panelRef}
                />
            </div>
        );
    }
);

export { ClientGrid };
export default InfiniteGrid;
