import React, { useEffect, useRef } from 'react';

import useApi from '../../../hooks/useApi';

import ItemCard from '../../general/cards/ItemCard';
import {
    faCheck,
    faExclamationTriangle,
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

const PretaskEmployee = ({
    employee = {},
    status,
    handleRemove = () => null,
    handleCertifications = () => null,
    isRemovable = true
}) => {
    const stableHandleCertifications = useRef(handleCertifications);

    const [{ data: employeeCertification }] = useApi(
        `/employee-certifications?pageSize=100&filter=${JSON.stringify({
            EmployeeIds: [employee.employeeId],
            ExpiresFrom: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd')
        })}`,
        'GET'
    );

    useEffect(() => {
        //Pass the employees certifications back to the parent to display error messages
        if (employeeCertification)
            stableHandleCertifications?.current?.(
                employee,
                employeeCertification?.data?.map?.(
                    (ec) => ec.certificationTypeId
                )
            );
    }, [employeeCertification, employee]);

    return (
        <ItemCard
            label={`${employee?.lastName}, ${employee?.firstName} ${
                employee?.middleName ?? ''
            }`}
            style={{
                container: {
                    background:
                        status === 'uncertified'
                            ? '#600011'
                            : status === 'loading'
                            ? 'transparent'
                            : null,
                    border:
                        status === 'uncertified'
                            ? '1px solid #48010e'
                            : status === 'loading'
                            ? '1px solid rgba(0,0,0,0.2)'
                            : null,
                    color:
                        status === 'loading' ? 'var(--light-text-color)' : null
                }
            }}
            loading={status === 'loading'}
            icon={
                status === 'loading'
                    ? null
                    : status === 'uncertified'
                    ? faExclamationTriangle
                    : faCheck
            }
            actions={
                isRemovable
                    ? [
                          {
                              icon: faTimes,
                              handleClick: handleRemove.bind(
                                  this,
                                  employee?.employeeId
                              )
                          }
                      ]
                    : []
            }
        />
    );
};

PretaskEmployee.propTypes = {
    employee: PropTypes.object,
    status: PropTypes.oneOf(['loading', 'uncertified', 'certified']),
    isRemovable: PropTypes.bool,
    handleRemove: PropTypes.func,
    handleCertifications: PropTypes.func
};

export default PretaskEmployee;
