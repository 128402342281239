import React, { useEffect } from 'react';

import useApi from '../../../hooks/useApi';

import ItemCard from '../../general/cards/ItemCard';
import SignatureCard from '../../general/cards/SignatureCard';
import FileUpload from '../../general/input/FileUpload';
import LoadingOverlay from '../../general/LoadingOverlay';
import PretaskChecklist from './PretaskChecklist';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { Link, useParams } from 'react-router-dom';

import styles from '../../../styles/apps/pretask/ViewPretask.module.scss';

const ViewPretask = () => {
    const { id } = useParams();

    const [{ data: pretask, error: pretaskError, loading: pretaskLoading }] =
        useApi(`/pretasks/${id}`, 'GET');

    useEffect(() => {
        document.title = 'CVE Apps - View Pretask';
    }, []);

    return pretaskLoading ? (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <LoadingOverlay label={'Loading Pretask...'} size="xl" />
        </div>
    ) : pretaskError?.response?.status === 404 ? (
        <div className={styles.errorContainer}>
            <div className={styles.error}>404</div>
            <div className={styles.header}>Pretask Not Found</div>
            <div className={styles.message}>
                The pretask you requested could not be found.
            </div>
            <div className={styles.link}>
                <Link to="/pretask">
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                    Back To Pretasks
                </Link>
            </div>
        </div>
    ) : pretaskError?.response?.status === 401 ? (
        <div className={styles.errorContainer}>
            <div className={styles.error}>401</div>
            <div className={styles.header}>You Are Not Authorized</div>
            <div className={styles.message}>
                You do not have permissions to view the pretask you selected.
            </div>
            <div className={styles.link}>
                <Link to="/pretask">
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                    Back To Pretasks
                </Link>
            </div>
        </div>
    ) : pretaskError?.response?.status ? (
        <div className={styles.errorContainer}>
            <div className={styles.error}>{pretaskError?.response?.status}</div>
            <div className={styles.header}>Error Encountered</div>
            <div className={styles.message}>
                We ran into an error when retrieving the requested Pretask.
            </div>
            <div className={styles.link}>
                <Link to="/pretask">
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                    Back To Pretasks
                </Link>
            </div>
        </div>
    ) : (
        <div className={styles.container}>
            <div className={styles.link}>
                <Link to="/pretask">
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                    Back To Pretasks
                </Link>
            </div>
            <div className={styles.pretaskContainer}>
                <div className={styles.pretaskHeader}>
                    <section className={styles.section}>
                        <div className={styles.header}>
                            <h1>Pretask - Daily Work Permit</h1>
                            <h3>MUST BE COMPLETED BEFORE STARTING ANY WORK</h3>
                        </div>
                        <div className={styles.body}>
                            {pretask?.jobNumber && (
                                <div
                                    className={[
                                        styles.input,
                                        styles.jobNumber
                                    ].join(' ')}
                                >
                                    <p className={styles.label}>Job Number</p>
                                    <p className={styles.value}>
                                        {pretask.jobNumber}
                                    </p>
                                </div>
                            )}
                            <div
                                className={[
                                    styles.input,
                                    styles.jobNumber
                                ].join(' ')}
                            >
                                <p className={styles.label}>Job Description</p>
                                <p className={styles.value}>
                                    {pretask.jobDescription}
                                </p>
                            </div>
                            <div className={[styles.input].join(' ')}>
                                <p className={styles.label}>Date</p>
                                <p className={styles.value}>
                                    {DateTime.fromISO(
                                        pretask?.dateSpecified
                                    )?.toFormat('MM/dd/yyyy hh:mm:ss a')}
                                </p>
                            </div>
                            {pretask?.useWorkArea && (
                                <div
                                    className={[
                                        styles.input,
                                        styles.workArea
                                    ].join(' ')}
                                >
                                    <p className={styles.label}>Work Area</p>
                                    {pretask?.workArea ? (
                                        <p className={styles.value}>
                                            {pretask?.workArea}
                                        </p>
                                    ) : (
                                        <p className={styles.notProvided}>
                                            No work area provided
                                        </p>
                                    )}
                                </div>
                            )}
                            {pretask?.headers?.map((header) => (
                                <div className={styles.input}>
                                    <p className={styles.label}>
                                        {header.name}
                                    </p>
                                    {header.value ? (
                                        <p className={styles.value}>
                                            {header.value}
                                        </p>
                                    ) : (
                                        <p className={styles.notProvided}>
                                            No answer provided
                                        </p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </section>
                </div>
                {pretask?.useCustomForm ? (
                    <section className={styles.section}>
                        <div className={styles.body}>
                            <div className={styles.input}>
                                <p className={styles.label}>Attachments</p>
                                <p className={styles.value}>
                                    <FileUpload
                                        readOnly
                                        downloadSingle
                                        files={[pretask?.attachment].map(
                                            (att) => ({
                                                ...att,
                                                preview: `/pretasks/${pretask?.id}/attachment/preview`,
                                                download: `/pretasks/${pretask?.id}/attachment/download`
                                            })
                                        )}
                                    />
                                </p>
                            </div>
                        </div>
                        <div className={styles.body}>
                            {pretask?.useComments && (
                                <div className={styles.input}>
                                    <p className={styles.label}>Comments</p>
                                    {pretask?.comments ? (
                                        <p className={styles.value}>
                                            {pretask?.comments}
                                        </p>
                                    ) : (
                                        <p className={styles.notProvided}>
                                            No comments
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className={styles.body}>
                            <div className={styles.input}>
                                <p className={styles.label}>
                                    Supervisor Signature
                                </p>
                                <p className={styles.value}>
                                    <SignatureCard
                                        signature={`data:image/png;base64,${pretask.supervisorSignatureBase64}`}
                                        label={`${pretask.supervisor}`}
                                    />
                                </p>
                            </div>
                        </div>
                    </section>
                ) : (
                    <div className={styles.pretaskBody}>
                        <section className={styles.section}>
                            <div className={styles.header}>
                                <h3>Work Activities</h3>
                            </div>
                            {pretask?.activities?.map((activity, i) => (
                                <div
                                    className={styles.activity}
                                    key={activity.id}
                                >
                                    <div className={styles.input}>
                                        <p className={styles.label}>
                                            Description of Work Activity
                                        </p>
                                        <p className={styles.value}>
                                            {activity.description}
                                        </p>
                                    </div>
                                    <div className={styles.input}>
                                        <p className={styles.label}>
                                            Location of Work Activity
                                        </p>
                                        {activity.locationOfWork ? (
                                            <p className={styles.value}>
                                                {activity.locationOfWork}
                                            </p>
                                        ) : (
                                            <p className={styles.notProvided}>
                                                No location specified
                                            </p>
                                        )}
                                    </div>
                                    <div className={styles.hazardList}>
                                        <p className={styles.label}>Hazards</p>
                                        <div className={styles.hazards}>
                                            {activity?.hazards?.length > 0 ? (
                                                Object.values(
                                                    activity.hazards
                                                        ?.filter(
                                                            (hazard) =>
                                                                hazard.value
                                                        )
                                                        ?.reduce(
                                                            (acc, cur) => ({
                                                                ...acc,
                                                                [cur.id]: cur
                                                            }),
                                                            {}
                                                        )
                                                )
                                                    ?.sort((a, b) =>
                                                        a.description >
                                                        b.description
                                                            ? 1
                                                            : a.description ===
                                                              b.description
                                                            ? 0
                                                            : -1
                                                    )
                                                    .map((hazard) => (
                                                        <ItemCard
                                                            label={
                                                                hazard.description
                                                            }
                                                            classes={{
                                                                container:
                                                                    styles.card
                                                            }}
                                                        />
                                                    ))
                                            ) : (
                                                <p
                                                    className={
                                                        styles.notProvided
                                                    }
                                                >
                                                    No hazards selected
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.input}>
                                        <p className={styles.label}>
                                            Explain each potential hazard
                                        </p>
                                        {activity.explainHazard ? (
                                            <p className={styles.value}>
                                                {activity.explainHazard}
                                            </p>
                                        ) : (
                                            <p className={styles.notProvided}>
                                                No explanation provided
                                            </p>
                                        )}
                                    </div>
                                    <div className={styles.input}>
                                        <p className={styles.label}>
                                            How will each potential hazard be
                                            mitigated?
                                        </p>
                                        {activity.mitigateHazard ? (
                                            <p className={styles.value}>
                                                {activity.mitigateHazard}
                                            </p>
                                        ) : (
                                            <p className={styles.notProvided}>
                                                No explanation provided
                                            </p>
                                        )}
                                    </div>
                                    <div className={styles.employees}>
                                        <p className={styles.label}>
                                            Assigned Employees
                                        </p>
                                        {activity?.employees?.length > 0 ? (
                                            <div
                                                className={styles.employeeList}
                                            >
                                                {activity.employees
                                                    .sort((a, b) =>
                                                        `${a.lastName}, ${a.middleName} ${a.firstName}`.localeCompare(
                                                            `${b.lastName}, ${b.middleName} ${b.firstName}`
                                                        )
                                                    )
                                                    .map((e) => (
                                                        <ItemCard
                                                            label={`${
                                                                e.lastName
                                                            }, ${e.firstName} ${
                                                                e.middleName ??
                                                                ''
                                                            }`}
                                                            classes={{
                                                                container:
                                                                    styles.card
                                                            }}
                                                        />
                                                    ))}
                                            </div>
                                        ) : (
                                            <p className={styles.notProvided}>
                                                No employees assigned
                                            </p>
                                        )}
                                    </div>
                                    <div className={styles.timestamp}>
                                        {activity?.createdOn
                                            ? DateTime.fromJSDate(
                                                  new Date(activity?.modifiedOn)
                                              ).toFormat(
                                                  'yyyy-MM-dd hh:mm:ss a'
                                              )
                                            : null}
                                    </div>
                                </div>
                            ))}
                        </section>
                        <section className={styles.section}>
                            <div className={styles.header}>
                                <h3>Checklists</h3>
                            </div>
                            <div className={styles.body}>
                                <div className={styles.checklists}>
                                    <PretaskChecklist
                                        checklists={pretask?.checklists}
                                        readOnly={true}
                                    />
                                </div>
                                {pretask?.useComments && (
                                    <div className={[styles.input].join(' ')}>
                                        <p className={styles.label}>Comments</p>
                                        {pretask?.comments ? (
                                            <p className={styles.value}>
                                                {pretask?.comments}
                                            </p>
                                        ) : (
                                            <p className={styles.notProvided}>
                                                No comments provided
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </section>
                        <section className={styles.section}>
                            <div className={styles.header}>
                                <h3>Signatures</h3>
                            </div>
                            <div
                                className={[
                                    styles.body,
                                    styles.signatures
                                ].join(' ')}
                            >
                                <div className={styles.requiredSignatures}>
                                    <div className={styles.employeeSignatures}>
                                        <h3>Employee Signatures</h3>
                                        <div
                                            className={
                                                styles.signatureAddContainer
                                            }
                                        >
                                            <div>
                                                {pretask?.signatures?.filter?.(
                                                    (e) => e.employeeId
                                                )?.length === 0 ? (
                                                    <div
                                                        className={
                                                            styles.noEmployeeMessage
                                                        }
                                                    >
                                                        No employees assigned to
                                                        an activity
                                                    </div>
                                                ) : (
                                                    pretask?.signatures
                                                        ?.filter?.(
                                                            (e) => e.employeeId
                                                        )
                                                        ?.map((emp) => (
                                                            <SignatureCard
                                                                signature={`data:image/png;base64,${emp.signatureBase64}`}
                                                                label={`${emp.employeeFirstName} ${emp.employeeLastName}`}
                                                                key={emp.id}
                                                                timestamp={
                                                                    emp.modifiedOn
                                                                }
                                                            />
                                                        ))
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={styles.supervisorSignatures}
                                    >
                                        <div
                                            className={
                                                styles.signatureAddContainer
                                            }
                                        >
                                            <h3
                                                className={
                                                    styles.supervisorHeader
                                                }
                                            >
                                                Supervisor Signature
                                            </h3>
                                            <div
                                                className={
                                                    styles.signatureAddBody
                                                }
                                            >
                                                {pretask?.supervisorSignatureBase64 ? (
                                                    <SignatureCard
                                                        signature={`data:image/png;base64,${pretask?.supervisorSignatureBase64}`}
                                                        label={
                                                            pretask?.supervisor
                                                        }
                                                    />
                                                ) : (
                                                    <div
                                                        className={
                                                            styles.noEmployeeMessage
                                                        }
                                                    >
                                                        The supervisor has not
                                                        signed this pretask.
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.extraSignatures}>
                                    <h3>Extra Signatures/Audited By</h3>
                                    {pretask?.signatures?.filter?.(
                                        (e) => !e.employeeId
                                    )?.length > 0 ? (
                                        <div className={styles.signatureList}>
                                            {pretask?.signatures
                                                ?.filter?.((e) => !e.employeeId)
                                                ?.map((item) => (
                                                    <SignatureCard
                                                        signature={`data:image/png;base64,${item.signatureBase64}`}
                                                        label={item.name}
                                                        key={item.id}
                                                        timestamp={
                                                            item.createdOn
                                                        }
                                                    />
                                                ))}
                                        </div>
                                    ) : (
                                        <div className={styles.noExtraMessage}>
                                            No extra signatures provided
                                        </div>
                                    )}
                                </div>
                            </div>
                        </section>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ViewPretask;
