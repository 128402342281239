import React from 'react';

import Button from '../../../general/input/Button';
import Modal from '../../../general/modal/Modal';
import PropTypes from 'prop-types';

import styles from '../../../../styles/apps/pretask/modal/DeleteActivityModal.module.scss';

const DeleteFieldModal = ({
    handleDelete = () => null,
    handleClose = () => null,
    message
}) => (
    <Modal open={true} handleClose={handleClose} dismissable={false}>
        <Modal.Body>
            <div className={styles.body}>
                <section>
                    <h1 className={styles.header}>Delete Confirmation</h1>
                </section>
                <section>
                    <p className={styles.message}>{message}</p>
                </section>
            </div>
        </Modal.Body>
        <Modal.Actions>
            <Button
                label="Cancel"
                type="secondary"
                variant="border"
                onClick={handleClose}
            />
            <Button label="Confirm" onClick={handleDelete} />
        </Modal.Actions>
    </Modal>
);

DeleteFieldModal.propTypes = {
    handleDelete: PropTypes.func
};

export default DeleteFieldModal;
