const isDate = (obj) => obj && obj.constructor === Date;

const isEmptyObject = (obj) =>
    obj && Object.keys(obj)?.length === 0 && obj.constructor === Object;

const isNullOrEmpty = (value) => {
    if (!value) return true;

    switch (Object.prototype.toString.call(value)) {
        case '[object String]':
            return value.replace(/\s/gi, '') === '';
        case '[object Array]':
            return !value.length || value.length === 0;
        case '[object Object]':
            return Object.keys(value)?.length === 0;
        default:
            return false;
    }
};

const isValidJSON = (obj) =>
    obj && (obj.constructor === String || obj.constructor === Number);

export { isDate, isEmptyObject, isNullOrEmpty, isValidJSON };
