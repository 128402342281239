import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
    useState
} from 'react';

import { TextTooltip } from '../Tooltip';
import {
    faEraser,
    faPencilAlt,
    faTimes,
    faTimesCircle,
    faTrash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _SignaturePad from 'signature_pad';

import styles from '../../../styles/general/input/SignaturePad.module.scss';

const SignaturePad = forwardRef(({ required, error }, ref) => {
    const padRef = useRef();
    const canvasRef = useRef();
    const canvasContainerRef = useRef();

    const [action, setAction] = useState('draw');
    const [canvasWidth, setCanvasWidth] = useState(0);

    useImperativeHandle(ref, () => ({
        draw: handleDraw,
        erase: handleErase,
        clear: handleClear,
        toDataURL: () => {
            return padRef?.current?.toDataURL();
        },
        isEmpty: () => padRef?.current?.isEmpty()
    }));

    useLayoutEffect(() => {
        setCanvasWidth(
            canvasContainerRef?.current?.getBoundingClientRect().width
        );
    }, []);

    useEffect(() => {
        padRef.current = new _SignaturePad(canvasRef.current, {
            throttle: 0
        });

        const ro = new ResizeObserver((entries) => {
            if (!canvasRef.current) return;
            const canvas = canvasRef.current;
            const dataUrl = padRef.current.toDataURL();
            setCanvasWidth(entries[0].target.getBoundingClientRect().width);
            let img = new Image();
            img.onload = () => {
                canvas.getContext('2d').drawImage(img, 0, 0);
            };
            img.src = dataUrl;
        });

        ro.observe(canvasContainerRef.current);

        return () => {
            ro.disconnect();
        };
    }, []);

    const handleClear = () => {
        padRef.current?.clear?.();
    };

    const handleErase = () => {
        setAction('erase');
        const ctx = canvasRef.current.getContext('2d');
        padRef.current.maxWidth = 4;
        padRef.current.minWidth = 7.5;
        ctx.globalCompositeOperation = 'destination-out';
    };

    const handleDraw = () => {
        setAction('draw');
        const ctx = canvasRef.current.getContext('2d');
        padRef.current.maxWidth = 2.5;
        padRef.current.minWidth = 0.5;
        ctx.globalCompositeOperation = 'source-over';
    };

    return (
        <div className={styles.container}>
            <div className={styles.headerControls}>
                <div className={styles.left}>
                    <TextTooltip
                        tooltip="Erase"
                        position={{
                            x: 'center',
                            y: 'bottom'
                        }}
                        offset={{
                            x: 8,
                            y: 8
                        }}
                        hoverTrigger="always"
                        hoverDelay={500}
                    >
                        <div
                            className={[
                                styles.action,
                                action === 'erase' ? styles.active : null
                            ].join(' ')}
                            onClick={handleErase}
                        >
                            <FontAwesomeIcon icon={faEraser} />
                        </div>
                    </TextTooltip>
                    <TextTooltip
                        tooltip="Sign"
                        position={{
                            x: 'center',
                            y: 'bottom'
                        }}
                        hoverTrigger="always"
                        hoverDelay={500}
                    >
                        <div
                            className={[
                                styles.action,
                                action === 'draw' ? styles.active : null
                            ].join(' ')}
                            onClick={handleDraw}
                        >
                            <FontAwesomeIcon icon={faPencilAlt} />
                        </div>
                    </TextTooltip>
                </div>
                <div className={styles.right}>
                    <TextTooltip
                        tooltip="Clear"
                        position={{
                            x: 'center',
                            y: 'bottom'
                        }}
                        offset={{
                            x: -8,
                            y: 8
                        }}
                        hoverTrigger="always"
                        hoverDelay={500}
                    >
                        <div className={styles.action} onClick={handleClear}>
                            <FontAwesomeIcon icon={faTrash} />
                        </div>
                    </TextTooltip>
                </div>
            </div>
            <div className={styles.drawableArea} ref={canvasContainerRef}>
                <div>
                    <canvas
                        className={[
                            styles.pad,
                            error ? styles.error : null
                        ].join(' ')}
                        ref={canvasRef}
                        width={canvasWidth}
                        height={150}
                    ></canvas>
                </div>
                <div
                    className={styles.drawPrompt}
                    style={{ width: `${canvasWidth - 40}px` }}
                >
                    <FontAwesomeIcon icon={faTimes} />
                    <p>Sign Here</p>
                </div>
            </div>
            {error && (
                <div className={styles.messageContainer}>
                    <div className={[styles.message, styles.error].join(' ')}>
                        <FontAwesomeIcon
                            icon={faTimesCircle}
                            className={styles.icon}
                        />
                        <p className={styles.label}>{error}</p>
                    </div>
                </div>
            )}
        </div>
    );
});

SignaturePad.propTypes = {};

export default SignaturePad;
