import useApi from '../../../../hooks/useApi';

import Button from '../../../general/input/Button';
import Modal from '../../../general/modal/Modal';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';

import styles from '../../../../styles/apps/quality/modal/DeleteQualityTagModal.module.scss';

const DeleteQualityTagModal = ({ handleClose, handleDelete, tag }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [{ loading }, deleteTag] = useApi('/quality/tag', 'DELETE', {
        manual: true
    });

    const handleSubmitClicked = () =>
        deleteTag({
            url: `/quality/tag/${tag.id}`
        })
            .then(() => {
                handleDelete?.(tag.id);
                enqueueSnackbar('Tag deleted successfully.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                handleClose?.();
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Failed to delete Tag.', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });

    return (
        <Modal open handleClose={handleClose}>
            <Modal.Title icon={faTrashAlt}>Delete Tag</Modal.Title>
            <Modal.Body>
                <div className={styles.confirmationMessage}>
                    Are you sure you want to delete this tag?
                </div>
                <div className={styles.resourceContainer}>
                    <div className={styles.resourceMessage}>
                        The following resources will be deleted:
                    </div>
                    <div className={styles.resourceList}>
                        <ul>
                            <li>1 Tag</li>
                        </ul>
                    </div>
                    <div className={styles.resourceContainer}>
                        <div className={styles.resourceMessage}>
                            The following resources are using this tag:
                        </div>
                        <div className={styles.resourceList}>
                            <ul>
                                <li>{tag.equipmentIds.length} Equipment</li>
                                <li>{tag.equipmentFormIds.length} Forms</li>
                                <li>{tag.attachmentIds.length} Attachments</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Confirm"
                    onClick={handleSubmitClicked}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteQualityTagModal;
