import { useReducer, useRef } from 'react';

import InputLabel from '../../general/input/InputLabel';
import Select from '../../general/input/Select';
import TextInput from '../../general/input/TextInput';
import { TextTooltip } from '../../general/Tooltip';
import { faCheck, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniqueId } from 'lodash';

import styles from '../../../styles/apps/quality/QualityTagInput.module.scss';

const qualityTagReducer = (state, action) => {
    switch (action.type) {
        case 'tag':
            return {
                ...state,
                tag: action.payload
            };
        case 'visibility':
            return {
                ...state,
                visible: action.payload
            };
        case 'error':
            return {
                ...state,
                error: action.payload
            };
        case 'reset':
            return action.payload;
        default:
            return state;
    }
};

const QualityTagInput = ({ value, onChange, hideSelect = false, jobId }) => {
    const [qualityTag, dispatch] = useReducer(qualityTagReducer, {
        tag: '',
        visible: false,
        error: null
    });

    const createRef = useRef();

    const handleCustomTagChange = (e) =>
        dispatch({
            type: 'tag',
            payload: e?.target?.value
        });

    const handleCreation = () =>
        dispatch({
            type: 'visibility',
            payload: true
        });

    const handleKeyDown = e => {
        if(e.key === 'Enter'){
            handleCreation();
        }
        e.preventDefault?.();
        e.stopPropogation?.();
    }

    const cancelCreation = () =>
        dispatch({
            type: 'reset',
            payload: {
                tag: '',
                visible: false,
                error: null
            }
        });

    const handleRemove = (id) => onChange(value.filter((v) => v.id !== id));
    const handleReset = () => onChange([]);

    const handleSave = () => {
        if (qualityTag.tag.trim() === '')
            return dispatch({
                type: 'error',
                payload: 'Tag cannot be empty.'
            });

        if (
            value.some?.(
                (v) =>
                    v?.name?.trim?.().toLowerCase?.() ===
                    qualityTag.tag.trim().toLowerCase?.()
            )
        )
            return dispatch({
                type: 'error',
                payload: 'Tag already exists.'
            });

        onChange([
            ...value,
            {
                id: uniqueId('tag'),
                name: qualityTag.tag
            }
        ]);
        createRef?.current?.focus?.();
        cancelCreation();
    };

    const handleAdd = (id, tag) =>
        onChange([
            ...value,
            {
                id: id,
                name: tag.name
            }
        ]);

    return (
        <div>
            {!hideSelect && (
                <Select
                    placeholder="Select Tag(s)"
                    selected={value}
                    handleReset={handleReset}
                    handleRowSelected={handleAdd}
                    handleRowDeselected={handleRemove}
                    pagination={{
                        url: `/quality/${jobId}/tags`
                    }}
                    getRowValue={(r) => r.name}
                    multiselect
                    sort={['name']}
                />
            )}
            <div className={styles.bubbleContainer}>
                {value
                    ?.sort?.((a, b) => a.name?.localeCompare?.(b.name))
                    ?.map((tag) => (
                        <p
                            className={styles.bubble}
                            key={tag.id}
                            onClick={handleRemove.bind(this, tag.id)}
                        >
                            <span>{tag?.name}</span>
                            <span className={styles.bubbleRemove}>
                                <FontAwesomeIcon icon={faTimes} />
                            </span>
                        </p>
                    ))}
            </div>
            {qualityTag.visible && (
                <div className={styles.customTagContainer}>
                    <InputLabel error={qualityTag.error}>
                        <TextInput
                            value={qualityTag.tag}
                            onChange={handleCustomTagChange}
                            onEnter={handleSave}
                            autoFocus
                            placeholder={'New Tag'}
                        />
                    </InputLabel>
                    <div className={styles.customTagIcons}>
                        <span
                            className={styles.customTagIcon}
                            onClick={handleSave}
                        >
                            <TextTooltip
                                tooltip="Add Tag"
                                hoverTrigger="always"
                                hoverDelay={400}
                            >
                                <FontAwesomeIcon icon={faCheck} />
                            </TextTooltip>
                        </span>
                        <span
                            className={styles.customTagIcon}
                            onClick={cancelCreation}
                        >
                            <TextTooltip
                                tooltip="Cancel"
                                hoverTrigger="always"
                                hoverDelay={400}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </TextTooltip>
                        </span>
                    </div>
                </div>
            )}
            <div className={styles.actionContainer}>
                <span onClick={handleCreation} onKeyDown={handleKeyDown} ref={createRef} tabIndex={-1} className={styles.action}>
                    <FontAwesomeIcon icon={faPlus} />
                    <p>Create Tag</p>
                </span>
            </div>
        </div>
    );
};

export default QualityTagInput;
