import React, { useEffect, useState } from 'react';

import useApi from '../../../hooks/useApi';
import useUser from '../../../hooks/useUser';

import SignatureCard from '../../general/cards/SignatureCard';
import ErrorMessage from '../../general/ErrorMessage';
import Button from '../../general/input/Button';
import DatePicker from '../../general/input/DatePicker';
import InputLabel from '../../general/input/InputLabel';
import TextInput from '../../general/input/TextInput';
import LoadingOverlay from '../../general/LoadingOverlay';
import SignatureModal from '../../general/modal/SignatureModal';
import DeleteActivityModal from './modal/DeleteActivityModal';
import DeleteSignatureModal from './modal/DeleteSignatureModal';
import IncompleteActivityModal from './modal/IncompleteActivityModal';
import PretaskActivity from './PretaskActivity';
import PretaskChecklist from './PretaskChecklist';
import {
    faArrowAltCircleLeft,
    faExclamationTriangle,
    faPlus,
    faPlusCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniqueId } from 'lodash';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import styles from '../../../styles/apps/pretask/EditPretask.module.scss';

const EditPretask = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const { displayName: username, id: userId } = useUser();
    const navigate = useNavigate();

    //usePrompt('Changes you have made may not be saved. Are you sure you want to navigate away?', true);

    const [{ data: pretask, error: pretaskError, loading: pretaskLoading }] =
        useApi(`/pretasks/${id}`, 'GET');
    const [{ loading: savingPretask }, submitPretask] = useApi(
        '/pretasks',
        'PUT',
        { manual: true }
    );

    //Set the default values when pretask is retrieved
    useEffect(() => {
        if (!pretask) return;
        if (pretask?.supervisorId !== userId) navigate('/pretask');
        setDate(
            pretask?.dateSpecified
                ? DateTime.fromISO(pretask.dateSpecified).toJSDate()
                : null
        );
        setWorkArea(pretask?.workArea ?? '');
        setComments(pretask?.comments ?? '');
        setJobDescription(pretask?.jobDescription);
        setActivities(
            pretask?.activities.map((activity) => ({
                ...activity,
                hazards: Object.values(
                    activity.hazards
                        ?.filter((hazard) => hazard.value)
                        ?.reduce(
                            (acc, cur) => ({
                                ...acc,
                                [cur.id]: cur
                            }),
                            {}
                        )
                )?.map((hazard) => ({
                    key: hazard.hazardId,
                    value: hazard.description,
                    object: hazard
                })),
                employees: activity.employees.map((employee) => ({
                    ...employee,
                    pretaskEmployeeId: employee.id,
                    id: employee.employeeId
                })),
                availableHazards: activity.hazards.map((hazard) => ({
                    id: hazard.id,
                    hazardId: hazard.hazardId
                })),
                defaultEmployees: activity.employees.map(
                    (employee) => employee.employeeId
                )
            }))
        );
        setCustomHeaders(pretask?.headers);
        setChecklists(
            Object.values(
                pretask?.checklists?.reduce(
                    (acc, cur) => ({
                        ...acc,
                        [cur.id]: cur
                    }),
                    {}
                )
            )?.map((checklist) => ({
                ...checklist,
                responseValue: checklist.responseValue ?? ''
            }))
        );
        setEmployeeSignatures(() => {
            let empCounts = pretask?.activities?.reduce((acc, cur) => {
                let curObj = { ...acc };
                cur.employees.forEach((emp) => {
                    curObj[emp.employeeId] = curObj[emp.employeeId]
                        ? {
                              count: (curObj[emp.employeeId].count ?? 0) + 1,
                              name: `${emp.firstName} ${emp.lastName}`
                          }
                        : {
                              count: 1,
                              name: `${emp.firstName} ${emp.lastName}`
                          };
                });
                return curObj;
            }, {});

            return Object.keys(empCounts)?.map((emp) => {
                let signature = pretask?.signatures?.find(
                    (signature) => signature.employeeId === parseInt(emp)
                );
                return {
                    id: signature?.id,
                    employeeId: signature
                        ? signature.employeeId
                        : parseInt(emp),
                    label: signature
                        ? `${signature.employeeFirstName} ${signature.employeeLastName}`
                        : empCounts[emp]?.name,
                    modifiedOn: signature ? signature.modifiedOn : null,
                    signature: signature?.signatureBase64
                        ? `data:image/png;base64,${signature.signatureBase64}`
                        : null,
                    count: empCounts[emp]?.count
                };
            });
        });
        setExtraSignatures(
            pretask?.signatures
                ?.filter((s) => !s.employeeId)
                .map((s) => ({
                    id: s.id,
                    label: s.name,
                    signature: `data:image/png;base64,${s.signatureBase64}`,
                    modifiedOn: s.modifiedOn
                }))
        );
        setSupervisorSignature(
            pretask?.supervisorSignatureBase64
                ? {
                      label: username,
                      signature: `data:image/png;base64,${pretask?.supervisorSignatureBase64}`
                  }
                : null
        );
    }, [pretask, username]); //eslint-disable-line

    const [activities, setActivities] = useState([]);

    /**
     * Form Fields
     */
    const [comments, setComments] = useState('');
    const [date, setDate] = useState(null);
    const [error, setError] = useState({});
    const [loadingError, setLoadingError] = useState(false);
    const [jobDescription, setJobDescription] = useState('');
    const [customHeaders, setCustomHeaders] = useState([]);
    const [checklists, setChecklists] = useState([]);
    const [employeeSignatures, setEmployeeSignatures] = useState([]);
    const [extraSignatures, setExtraSignatures] = useState([]);
    const [supervisorSignature, setSupervisorSignature] = useState(null);
    const [workArea, setWorkArea] = useState('');

    /**
     * Modal State
     */
    const [showActivityDelete, setShowActivityDelete] = useState(null);
    const [showCustomSignature, setShowCustomSignature] = useState(false);
    const [showSupervisorSignature, setShowSupervisorSignature] =
        useState(false);
    const [showEmployeeSignature, setShowEmployeeSignature] = useState(false);
    const [showDeleteSignature, setShowDeleteSignature] = useState(false);
    const [showIncompleteActivity, setShowIncompleteActivity] = useState(false);

    useEffect(() => {
        document.title = 'CVE Apps - Edit Pretask';
    }, []);

    /**
     * State Functions
     */
    const handleJobDescriptionChange = (e) => {
        setJobDescription(e.target.value);
    };

    const handleWorkAreaChange = (e) => {
        setWorkArea(e.target.value);
    };

    const handleCommentsChange = (e) => {
        setComments(e.target.value);
    };

    const handleCustomHeaderChange = (input, e) => {
        setCustomHeaders((customHeaders) =>
            customHeaders.map((header) => {
                return header.name === input
                    ? { ...header, value: e.target.value }
                    : header;
            })
        );
    };

    const addActivity = () => {
        setActivities((activities) => [
            ...activities,
            {
                id: uniqueId('activity'),
                description: '',
                locationOfWork: '',
                hazards: [],
                explainHazard: '',
                mitigateHazard: '',
                employees: [],
                isRemovable: true
            }
        ]);
    };

    const handleActivityChange = (sequence, activity) => {
        setActivities((activities) => [
            ...activities.slice(0, sequence),
            activity,
            ...activities.slice(sequence + 1)
        ]);
    };

    const handleActivityDelete = (sequence) => {
        setShowActivityDelete(sequence);
    };

    const handleActivityDeleteClose = () => {
        setShowActivityDelete(null);
    };

    const handleRemoveActivity = (sequence) => {
        handleEmployeeRemove(
            activities[sequence].employees.map((e) => e.employeeId)
        );
        setActivities((activities) => [
            ...activities.slice(0, sequence),
            ...activities.slice(sequence + 1)
        ]);
        setShowActivityDelete(null);
        enqueueSnackbar(`Activity was removed.`, {
            variant: 'success',
            autoHideDuration: 2000,
            preventDuplicate: false
        });
    };

    const handleCustomSignatureClose = () => setShowCustomSignature(false);

    const handleCustomSignatureChange = (signature, label) => {
        setExtraSignatures((signatures) => [
            ...signatures,
            { signature, label, id: uniqueId('signature') }
        ]);
        handleCustomSignatureClose();
    };

    const handleSupervisorSignatureClose = () =>
        setShowSupervisorSignature(false);

    const handleSupervisorSignatureChange = (signature, label) => {
        setSupervisorSignature({ signature, label });
        handleSupervisorSignatureClose();
    };

    const handleShowEmployeeSignature = (emp) => {
        let incompleteActivities = [];

        activities.forEach((activity) => {
            if (
                activity.employees
                    ?.map((employee) => employee.employeeId)
                    ?.includes(emp.employeeId)
            ) {
                if (
                    activity.description.replace(/\s/g, '') === '' ||
                    activity.explainHazard.replace(/\s/g, '') === '' ||
                    activity.locationOfWork.replace(/\s/g, '') === '' ||
                    activity.mitigateHazard.replace(/\s/g, '') === ''
                ) {
                    incompleteActivities = [
                        ...incompleteActivities,
                        activity.id
                    ];
                }
            }
        });

        if (incompleteActivities.length > 0) {
            setShowIncompleteActivity(true);
        } else {
            setShowEmployeeSignature(emp);
        }
    };

    const handleEmployeeSignatureClose = () => setShowEmployeeSignature(false);

    const handleEmployeeSignatureChange = (emp, signature) => {
        setEmployeeSignatures((signatures) =>
            signatures.some((s) => s.employeeId === emp.employeeId)
                ? signatures.map((s) =>
                      s.employeeId === emp.employeeId
                          ? { ...s, signature: signature }
                          : s
                  )
                : [
                      ...signatures,
                      {
                          label: emp.employeeFirstName,
                          signature: signature,
                          employeeId: emp.employeeId
                      }
                  ]
        );
        setActivities((activities) =>
            activities.map((activity) => {
                let assignedEmpIds = activity.employees.map(
                    (e) => e.employeeId
                );

                return {
                    ...activity,
                    isLocked: assignedEmpIds.includes(emp.employeeId)
                        ? true
                        : activity.isLocked ?? false,
                    employees: activity.employees.map((e) => ({
                        ...e,
                        isRemovable:
                            e.employeeId === emp.employeeId
                                ? false
                                : e.isRemovable
                    }))
                };
            })
        );
        handleEmployeeSignatureClose();
    };

    const handleEmployeeAdd = (selected) => {
        setEmployeeSignatures((signatures) => {
            return signatures.some((s) => s.employeeId === selected.id)
                ? signatures.map((s) => {
                      return s.employeeId === selected.id
                          ? {
                                ...s,
                                count: s.count + 1,
                                signature: null,
                                modifiedOn: null
                            }
                          : s;
                  })
                : [
                      ...signatures,
                      {
                          employeeId: selected.employeeId,
                          label: `${selected.firstName} ${selected.lastName}`,
                          count: 1,
                          signature: null,
                          modifiedOn: null
                      }
                  ];
        });
    };

    const handleEmployeeRemove = (ids, sequence) => {
        ids = Array.isArray(ids) ? ids : [ids];
        setActivities((activities) =>
            activities.map((activity, i) => ({
                ...activity,
                employees:
                    i === sequence
                        ? activity.employees.filter((e) => !ids.includes(e.id))
                        : activity.employees
            }))
        );
        setEmployeeSignatures((signatures) => {
            return signatures
                .map((s) => {
                    return ids?.includes(s.employeeId)
                        ? s.count === 1
                            ? null
                            : {
                                  ...s,
                                  count: s.count - 1
                              }
                        : s;
                })
                .filter((s) => s);
        });
    };

    const handleDeleteSignature = (type, id) =>
        setShowDeleteSignature({ type, id });
    const handleDeleteSignatureConfirm = (type, id) => {
        if (type === 'supervisor') {
            setSupervisorSignature(null);
        } else if (type === 'employee') {
            setEmployeeSignatures((signatures) =>
                signatures.map((s) =>
                    s.employeeId === id ? { ...s, signature: null } : s
                )
            );
        } else {
            setExtraSignatures((signatures) =>
                signatures.filter((sig) => sig.id !== id)
            );
        }
        handleDeleteSignatureClose();
    };
    const handleDeleteSignatureClose = () => setShowDeleteSignature(false);

    const handleChecklist = (checklists) => setChecklists(checklists);

    const handleCancel = () => navigate('/pretask');

    const handleDateChange = (date) => {
        setDate(date);
    };

    const handleSave = () => {
        setError({});
        setLoadingError(true);

        let errObj = {};

        if (!pretask.jobNumber && pretask.useJobDescription && !jobDescription)
            errObj['jobDescription'] =
                'You must provide a job description or job number.';
        if (!date) errObj['date'] = 'You must provide the date of the Pretask.';

        if (pretask?.useCustomForm) {
            if (!supervisorSignature || !supervisorSignature.signature)
                errObj['supervisorSignature'] =
                    'You must sign the Pretask form.';
        } else {
            if (supervisorSignature) {
                if (
                    date &&
                    DateTime.local().toISODate() !==
                        DateTime.fromJSDate(new Date(date)).toISODate()
                )
                    errObj['date'] =
                        'The date must be the current day to submit a pretask with signatures.';
                if (pretask?.useWorkArea && !workArea)
                    errObj['workArea'] = 'You must provide a work area.';
                if (activities.length === 0)
                    errObj['activities'] =
                        'You must have at least one work activity.';
                if (
                    activities?.some?.(
                        (activity) => activity.employees.length <= 0
                    )
                )
                    errObj['activityEmployee'] =
                        'All work activities must have at least 1 employee.';
                if (
                    checklists?.some?.(
                        (checklist) =>
                            !checklist.value ||
                            (checklist.responseFlag
                                ?.split?.('.')
                                ?.includes?.(checklist.value) &&
                                (!checklist.responseValue ||
                                    checklist?.responseValue?.replace?.(
                                        /\s/g,
                                        ''
                                    ) === ''))
                    )
                )
                    errObj['checklist'] =
                        'You must select an option for every checklist and explain your selections.';
                if (employeeSignatures?.some?.((emp) => !emp.signature))
                    errObj['employeeSignatures'] =
                        'All employees must sign the pretask.';
            } else {
                if (
                    [...employeeSignatures, ...extraSignatures].some(
                        (sig) => sig.signature
                    )
                )
                    errObj['signatures'] =
                        'The supervisor must sign the pretask if anyone else has signed.';
            }
        }

        setLoadingError(false);
        if (Object.keys(errObj).length !== 0) {
            return setError(errObj);
        } else {
            let modificationObj = {
                activities: activities.map((activity, index) => ({
                    description: activity?.description,
                    employeeIds: activity?.employees?.map(
                        (employee) => employee.employeeId
                    ),
                    explainHazard: activity?.explainHazard,
                    hazards: pretask?.hazards?.map((globalHazard) => {
                        return {
                            hazardId: globalHazard.id,
                            id:
                                activity?.availableHazards?.find(
                                    (hazard) =>
                                        hazard.hazardId === globalHazard.id
                                )?.id ?? 0,
                            value:
                                activity?.hazards?.some(
                                    (hazard) =>
                                        parseInt(hazard.key) === globalHazard.id
                                ) ?? false
                        };
                    }),
                    id: `${activity?.id}`?.includes('activity')
                        ? 0
                        : activity.id,
                    locationOfWork: activity?.locationOfWork,
                    mitigateHazard: activity?.mitigateHazard,
                    sequence: index
                })),
                checklists: checklists.map((checklist) => ({
                    checklistId: checklist?.checklistId,
                    id: checklist?.id,
                    value: checklist?.value,
                    responseValue: checklist?.responseFlag
                        ?.split?.('.')
                        ?.includes?.(checklist?.value)
                        ? checklist.responseValue
                        : null
                })),
                comments: comments,
                dateSpecified:
                    !pretask?.supervisorSignatureBase64 && supervisorSignature
                        ? DateTime.now().toISO()
                        : date,
                headers: customHeaders.map((header) => ({
                    id: header.id,
                    headerId: header.headerId,
                    value: header.value
                })),
                jobDescription: jobDescription,
                signatures: [...employeeSignatures, ...extraSignatures]
                    .filter((signature) => signature.signature)
                    ?.map((signature, index) => ({
                        id:
                            signature.id &&
                            `${signature.id}`.includes('signature')
                                ? 0
                                : signature.id,
                        employeeId: signature.employeeId,
                        name: signature.employeeId ? null : signature.label,
                        //sequence: index,
                        signatureBase64: signature?.signature?.replace?.(
                            'data:image/png;base64,',
                            ''
                        )
                    })),
                supervisorSignatureBase64:
                    supervisorSignature?.signature?.replace?.(
                        'data:image/png;base64,',
                        ''
                    ),
                workArea: workArea
            };

            submitPretask({
                url: `/pretasks/${pretask.id}`,
                data: modificationObj
            })
                .then(() => {
                    enqueueSnackbar('Your pretask was saved.', {
                        variant: 'success',
                        autoHideDuration: 4000
                    });

                    navigate('/pretask');
                })
                .catch((err) => {
                    console.log('err', err);
                    enqueueSnackbar(
                        'An error was encountered when submitting the Pretask.',
                        {
                            variant: 'error',
                            autoHide: 3000
                        }
                    );
                });
        }
    };

    return pretaskLoading ? (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <LoadingOverlay label={'Loading Pretask...'} size="xl" />
        </div>
    ) : pretaskError?.response?.status === 404 ? (
        <div className={styles.errorContainer}>
            <div className={styles.error}>404</div>
            <div className={styles.header}>Pretask Not Found</div>
            <div className={styles.message}>
                The pretask you requested could not be found.
            </div>
            <div className={styles.link}>
                <Link to="/pretask">
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                    Back To Pretasks
                </Link>
            </div>
        </div>
    ) : pretaskError?.response?.status === 401 ? (
        <div className={styles.errorContainer}>
            <div className={styles.error}>401</div>
            <div className={styles.header}>You Are Not Authorized</div>
            <div className={styles.message}>
                You do not have permissions to view the pretask you selected.
            </div>
            <div className={styles.link}>
                <Link to="/pretask">
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                    Back To Pretasks
                </Link>
            </div>
        </div>
    ) : pretaskError?.response?.status ? (
        <div className={styles.errorContainer}>
            <div className={styles.error}>{pretaskError?.response?.status}</div>
            <div className={styles.header}>Error Encountered</div>
            <div className={styles.message}>
                We ran into an error when retrieving the requested Pretask.
            </div>
            <div className={styles.link}>
                <Link to="/pretask">
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                    Back To Pretasks
                </Link>
            </div>
        </div>
    ) : (
        <div className={styles.container}>
            <div className={styles.link}>
                <Link to="/pretask">
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                    Back To Pretask List
                </Link>
            </div>
            <div className={styles.pretaskContainer}>
                <div className={styles.pretaskHeader}>
                    <section className={styles.section}>
                        <div className={styles.header}>
                            <h1>Pretask - Daily Work Permit</h1>
                            <h3>MUST BE COMPLETED BEFORE STARTING ANY WORK</h3>
                        </div>
                        <div className={styles.body}>
                            <div
                                className={[
                                    styles.input,
                                    styles.jobNumber
                                ].join(' ')}
                            >
                                {pretask.jobNumber && (
                                    <InputLabel
                                        label={'Job Number'}
                                        required={true}
                                    >
                                        <div className={styles.value}>
                                            {pretask.jobNumber}
                                        </div>
                                    </InputLabel>
                                )}
                            </div>
                            <div
                                className={[
                                    styles.input,
                                    styles.jobNumber
                                ].join(' ')}
                            >
                                <InputLabel
                                    label={'Job Description'}
                                    required={true}
                                >
                                    {pretask.jobNumber ? (
                                        <div className={styles.value}>
                                            {pretask.jobDescription}
                                        </div>
                                    ) : (
                                        <TextInput
                                            value={jobDescription}
                                            onChange={
                                                handleJobDescriptionChange
                                            }
                                        />
                                    )}
                                </InputLabel>
                            </div>
                            <div className={[styles.input].join(' ')}>
                                <InputLabel
                                    label="Date/Time"
                                    labelClick={false}
                                    required={true}
                                >
                                    {pretask.supervisorSignatureBase64 ? (
                                        <div className={styles.value}>
                                            {DateTime.fromJSDate(date).toFormat(
                                                'MM/dd/yyyy hh:mm:ss a'
                                            )}
                                        </div>
                                    ) : (
                                        <DatePicker
                                            value={date}
                                            onChange={handleDateChange}
                                        />
                                    )}
                                </InputLabel>
                            </div>
                            {pretask?.useWorkArea && (
                                <div
                                    className={[
                                        styles.input,
                                        styles.workArea
                                    ].join(' ')}
                                >
                                    <InputLabel
                                        label="Work Area"
                                        required={
                                            !!(
                                                pretask?.supervisorSignatureBase64 ||
                                                supervisorSignature
                                            )
                                        }
                                    >
                                        <TextInput
                                            value={workArea}
                                            onChange={handleWorkAreaChange}
                                        />
                                    </InputLabel>
                                </div>
                            )}
                            {customHeaders
                                ?.sort((a, b) =>
                                    a.sequence > b.sequence
                                        ? 1
                                        : a.sequence < b.sequence
                                        ? -1
                                        : 0
                                )
                                ?.map((header) => (
                                    <div
                                        className={styles.input}
                                        key={header.id}
                                    >
                                        <InputLabel label={header.name}>
                                            <TextInput
                                                value={header.value}
                                                onChange={handleCustomHeaderChange.bind(
                                                    this,
                                                    header.name
                                                )}
                                            />
                                        </InputLabel>
                                    </div>
                                ))}
                        </div>
                    </section>
                </div>
                <div className={styles.pretaskBody}>
                    <section className={styles.section}>
                        <div className={styles.header}>
                            <h3>Work Activities</h3>
                        </div>
                        <div className={styles.body}>
                            {activities.map((activity, i) => (
                                <div
                                    className={styles.activity}
                                    key={activity.id}
                                >
                                    <PretaskActivity
                                        activity={{ ...activity, sequence: i }}
                                        availableHazards={pretask?.hazards}
                                        handleUpdate={handleActivityChange}
                                        handleDelete={handleActivityDelete.bind(
                                            this,
                                            i
                                        )}
                                        handleAdd={handleEmployeeAdd}
                                        handleRemove={handleEmployeeRemove}
                                    />
                                </div>
                            ))}
                            <div className={styles.newActivity}>
                                <Button
                                    type="primary"
                                    label="New Activity"
                                    icon={faPlus}
                                    className={styles.newActivityButton}
                                    onClick={addActivity}
                                />
                            </div>
                        </div>
                    </section>
                    <section className={styles.section}>
                        <div className={styles.header}>
                            <h3>Checklists</h3>
                        </div>
                        <div className={styles.body}>
                            <div className={styles.checklists}>
                                <PretaskChecklist
                                    checklists={checklists}
                                    handleChange={handleChecklist}
                                />
                            </div>
                            <div className={[styles.input].join(' ')}>
                                <InputLabel label="Comments">
                                    <TextInput
                                        lines={4}
                                        value={comments}
                                        onChange={handleCommentsChange}
                                    />
                                </InputLabel>
                            </div>
                        </div>
                    </section>
                    <section className={styles.section}>
                        <div className={styles.header}>
                            <h3>Signatures</h3>
                        </div>
                        <div
                            className={[styles.body, styles.signatures].join(
                                ' '
                            )}
                        >
                            <div className={styles.requiredSignatures}>
                                <div className={styles.employeeSignatures}>
                                    <h3>Employee Signatures</h3>
                                    {employeeSignatures?.length > 0 && (
                                        <div
                                            className={styles.signatureWarning}
                                        >
                                            <FontAwesomeIcon
                                                icon={faExclamationTriangle}
                                            />
                                            Once an employee signs, all
                                            activities they are a part of will
                                            be locked for editing.
                                        </div>
                                    )}
                                    <div
                                        className={styles.signatureAddContainer}
                                    >
                                        <div>
                                            {employeeSignatures.length === 0 ? (
                                                <div
                                                    className={
                                                        styles.noEmployeeMessage
                                                    }
                                                >
                                                    No Employees assigned to an
                                                    activity.
                                                </div>
                                            ) : (
                                                employeeSignatures.map(
                                                    (emp) => (
                                                        <SignatureCard
                                                            signature={
                                                                emp.signature
                                                            }
                                                            key={emp.employeeId}
                                                            label={emp.label}
                                                            timestamp={
                                                                emp.modifiedOn
                                                            }
                                                            actions={
                                                                !emp?.signature
                                                                    ? [
                                                                          {
                                                                              icon: faPlusCircle,
                                                                              handleClick:
                                                                                  handleShowEmployeeSignature.bind(
                                                                                      this,
                                                                                      emp
                                                                                  )
                                                                          }
                                                                      ]
                                                                    : []
                                                            }
                                                        />
                                                    )
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.supervisorSignatures}>
                                    <div
                                        className={styles.signatureAddContainer}
                                    >
                                        <h3 className={styles.supervisorHeader}>
                                            <p className={styles.required}>*</p>
                                            Supervisor Signature
                                        </h3>
                                        <div
                                            className={styles.signatureAddBody}
                                        >
                                            {supervisorSignature && (
                                                <SignatureCard
                                                    signature={
                                                        supervisorSignature?.signature
                                                    }
                                                    label={
                                                        supervisorSignature?.label
                                                    }
                                                    actions={
                                                        pretask?.supervisorSignatureBase64
                                                            ? []
                                                            : [
                                                                  {
                                                                      icon: 'times',
                                                                      handleClick:
                                                                          handleDeleteSignature.bind(
                                                                              this,
                                                                              'supervisor'
                                                                          )
                                                                  }
                                                              ]
                                                    }
                                                />
                                            )}
                                        </div>
                                        {!supervisorSignature && (
                                            <Button
                                                label="Add Signature"
                                                icon={faPlus}
                                                onClick={() =>
                                                    setShowSupervisorSignature(
                                                        true
                                                    )
                                                }
                                                className={styles.addButton}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.extraSignatures}>
                                <h3>Extra Signatures/Audited By</h3>
                                <div className={styles.signatureList}>
                                    {extraSignatures.map((item) => (
                                        <SignatureCard
                                            signature={item.signature}
                                            key={item.id}
                                            label={item.label}
                                            timestamp={item.modifiedOn}
                                        />
                                    ))}
                                </div>
                                <div className={styles.signatureAddContainer}>
                                    <Button
                                        label="Add Signature"
                                        icon={faPlus}
                                        onClick={() =>
                                            setShowCustomSignature(true)
                                        }
                                        className={styles.addButton}
                                    />
                                </div>
                            </div>
                            {showEmployeeSignature && (
                                <SignatureModal
                                    editableHeader={{
                                        label: 'Name',
                                        value: `${showEmployeeSignature.label}`,
                                        required: true,
                                        readonly: true
                                    }}
                                    handleClose={handleEmployeeSignatureClose}
                                    handleSave={handleEmployeeSignatureChange.bind(
                                        this,
                                        showEmployeeSignature
                                    )}
                                />
                            )}
                            {showCustomSignature && (
                                <SignatureModal
                                    editableHeader={{
                                        label: 'Name',
                                        required: true
                                    }}
                                    handleClose={handleCustomSignatureClose}
                                    handleSave={handleCustomSignatureChange}
                                />
                            )}
                            {showSupervisorSignature && (
                                <SignatureModal
                                    editableHeader={{
                                        label: 'Supervisor',
                                        value: username,
                                        readonly: true,
                                        required: true
                                    }}
                                    handleClose={handleSupervisorSignatureClose}
                                    handleSave={handleSupervisorSignatureChange}
                                />
                            )}
                            {showIncompleteActivity && (
                                <IncompleteActivityModal
                                    handleClose={() =>
                                        setShowIncompleteActivity(false)
                                    }
                                />
                            )}
                            {showDeleteSignature && (
                                <DeleteSignatureModal
                                    handleClose={handleDeleteSignatureClose}
                                    handleDelete={handleDeleteSignatureConfirm.bind(
                                        this,
                                        showDeleteSignature.type,
                                        showDeleteSignature.id
                                    )}
                                />
                            )}
                        </div>
                    </section>
                    {error && Object.keys(error)?.length > 0 && (
                        <div className={styles.errors}>
                            {Object.keys(error)?.map((err, i) => (
                                <ErrorMessage error={error[err]} key={i} />
                            ))}
                        </div>
                    )}
                    <div className={[styles.actions].join(' ')}>
                        <Button
                            label="Cancel"
                            type="secondary"
                            variant="border"
                            onClick={handleCancel}
                        />
                        <Button
                            label="Save"
                            onClick={handleSave}
                            loading={savingPretask || loadingError}
                        />
                    </div>
                    {showActivityDelete !== null && (
                        <DeleteActivityModal
                            handleDelete={handleRemoveActivity.bind(
                                this,
                                showActivityDelete
                            )}
                            handleClose={handleActivityDeleteClose}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default EditPretask;
