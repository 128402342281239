import React, { useEffect, useState } from 'react';

import useApi from '../../../hooks/useApi';
import useLocalStorage from '../../../hooks/useLocalStorage';

import ErrorMessage from '../../general/ErrorMessage';
import Button from '../../general/input/Button';
import Dropdown from '../../general/input/Dropdown';
import Select from '../../general/input/Select';
import { useNavigate } from 'react-router-dom';

import styles from '../../../styles/apps/pretask/CustomizePretaskDialog.module.scss';

const CustomizePretaskDialog = () => {
    const navigate = useNavigate();
    const [defaults, setDefaults] = useLocalStorage(
        'customize-pretask-defaults'
    );

    const [{ data: divisions, loading: loadingDivisions }] = useApi(
        '/divisions?includeDepartments=true'
    );
    const [departments, setDepartments] = useState([]);

    const [error, setError] = useState(null);
    const [division, setDivision] = useState(defaults?.divisionId ?? null);
    const [department, setDepartment] = useState(
        defaults?.departmentId ?? null
    );
    const [jobNumber, setJobNumber] = useState(
        defaults?.job &&
            typeof defaults.job === 'object' &&
            Object.keys(defaults.job).length > 0
            ? [defaults.job]
            : []
    );

    const handleCustomize = () => {
        let query = [];
        setError(null);
        if (!division) setError('You must provide a division.');
        if (division) query = [...query, `divisionId=${division.key}`];
        if (department) query = [...query, `departmentId=${department.key}`];
        if (jobNumber && jobNumber.length > 0)
            query = [...query, `jobId=${jobNumber?.[0]?.id}`];
        setDefaults({
            divisionId: division,
            departmentId: department,
            job: {
                id: jobNumber[0]?.id,
                vistaJobNumber: jobNumber[0]?.vistaJobNumber,
                vistaJobDescription: jobNumber[0]?.vistaJobDescription
            }
        });
        navigate({
            pathname: '/pretask/customize',
            search: query.join('&')
        });
    };

    useEffect(() => {
        setDepartments(
            divisions
                ?.find((d) => d.id === division?.key)
                ?.departments?.filter?.(
                    (department) => department.name !== division?.value
                )
                ?.map?.((department) => ({
                    key: department.id,
                    value: department.name
                }))
        );
    }, [division, divisions]);

    const handleDivisionChange = (selected) => {
        setDivision((division) => {
            if (division?.key !== selected?.key) setDepartment(null);
            return selected;
        });
    };

    return (
        <div className={styles.container}>
            <div className={styles.customizePromptContainer}>
                <div className={styles.header}>Customize Pretask</div>
                <div className={styles.inputs}>
                    <Dropdown
                        placeholder="Division"
                        options={divisions?.map((division) => ({
                            key: division.id,
                            value: division.name
                        }))}
                        handleSelect={handleDivisionChange}
                        selected={division}
                        loading={loadingDivisions}
                    />
                    {departments && departments?.length > 0 && (
                        <Dropdown
                            placeholder="Department"
                            options={departments}
                            selected={department}
                            handleSelect={setDepartment}
                        />
                    )}
                    <Select
                        placeholder="Job Number"
                        filters={{
                            isActive: {
                                value: true,
                                getFilter: (isActive) => ({
                                    IsActive: isActive
                                })
                            }
                        }}
                        handleRowSelection={(jobNumber) =>
                            setJobNumber(jobNumber)
                        }
                        selected={jobNumber}
                        getRowValue={(row) =>
                            row?.vistaJobNumber || row?.vistaJobDescription
                                ? `${row?.vistaJobNumber ?? ''} - ${
                                      row?.vistaJobDescription ?? ''
                                  }`
                                : undefined
                        }
                        getRowId={(row) => row.id}
                        pagination={{
                            url: '/jobs'
                        }}
                        sort={['VistaJobNumber', 'VistaJobDescription']}
                    />
                </div>
                {error && (
                    <div>
                        <ErrorMessage error={error} />
                    </div>
                )}
                <div className={styles.actions}>
                    <Button
                        label="Customize"
                        type="secondary"
                        variant="border"
                        disabled={!division && !jobNumber}
                        onClick={handleCustomize}
                    />
                </div>
            </div>
        </div>
    );
};

export default CustomizePretaskDialog;
