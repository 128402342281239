import { useRef } from 'react';

import useApi from '../../../../hooks/useApi';

import ErrorMessage from '../../../general/ErrorMessage';
import Button from '../../../general/input/Button';
import CheckboxGroup from '../../../general/input/CheckboxGroup';
import InputLabel from '../../../general/input/InputLabel';
import LoadingModal from '../../../general/modal/LoadingModal';
import Modal from '../../../general/modal/Modal';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import styles from '../../../../styles/apps/quality/modal/EditPermissionModal.module.scss';
import ErrorModal from '../../../general/modal/ErrorModal';

const EditPermissionModal = ({ handleClose, record, job, handleEdit }) => {
    const submitButtonRef = useRef();
    const { enqueueSnackbar } = useSnackbar();

    const [{ data, error, loading }] = useApi(`/quality/permissions`, 'GET');

    const [{ loading: updating }, updatePermissions] = useApi(``, 'PUT', {
        manual: true
    });

    const {
        handleSubmit,
        control,
        setError,
        formState: { errors },
        ...methods
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            permissionIds: record?.jobPermissions?.map?.((p) => p.id)
        }
    });

    const handleCreate = (data) => {
        updatePermissions({
            url: `/quality/${job}/${record.id}/permissions`,
            data: {
                permissionIds: data.permissionIds
            }
        })
            .then((result) => {
                handleEdit(result.id, result);
                handleClose();
                enqueueSnackbar('Successfully edited permissions.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Failed to edit permissions.', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });
    };

    const handleSubmitClicked = () => submitButtonRef.current?.click?.();

    return error ? <ErrorModal
        message="Error retrieving permissions for this job, please try again."
        handleClose={handleClose}
    /> : loading ? (
        <LoadingModal message="Loading permissions..." />
    ) : (
        <Modal open handleClose={handleClose}>
            <Modal.Title icon={faPencilAlt}>Edit Permissions</Modal.Title>
            <Modal.Body>
                <FormProvider
                    handleSubmit={handleSubmit}
                    control={control}
                    {...methods}
                >
                    <form
                        autoComplete="true"
                        onSubmit={handleSubmit(handleCreate)}
                        noValidate={true}
                        className={styles.form}
                    >
                        <div className={styles.row}>
                            <Controller
                                name={`permissionIds`}
                                control={control}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        error={error?.message}
                                        label="Form Permissions"
                                    >
                                        <CheckboxGroup
                                            orientation="vertical"
                                            options={data?.map?.((p) => ({
                                                key: p.id,
                                                value: p.name
                                            }))}
                                            selected={value}
                                            handleChange={onChange}
                                            multiselect
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        {errors.root?.serverError && (
                            <div>
                                <ErrorMessage
                                    error={errors.root.serverError.message}
                                />
                            </div>
                        )}
                        <div
                            style={{
                                visibility: 'hidden',
                                opacity: 0
                            }}
                        >
                            <button formAction="submit" ref={submitButtonRef} />
                        </div>
                    </form>
                </FormProvider>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Update"
                    onClick={handleSubmitClicked}
                    loading={updating}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default EditPermissionModal;
