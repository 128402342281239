import React from 'react';

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import styles from '../../styles/general/ErrorMessage.module.scss';

const ErrorMessage = ({ error, classes = {}, icon = faTimesCircle }) => (
    <div className={[styles.container, classes?.container].join(' ')}>
        <div
            className={[styles.message, styles.error, classes?.message].join(
                ' '
            )}
        >
            <FontAwesomeIcon
                icon={icon}
                className={[styles.icon, classes?.icon].join(' ')}
            />
            <p className={[styles.label, classes?.label].join(' ')}>{error}</p>
        </div>
    </div>
);

ErrorMessage.propTypes = {
    error: PropTypes.string,
    classes: PropTypes.shape({
        container: PropTypes.object,
        message: PropTypes.object,
        icon: PropTypes.object,
        label: PropTypes.object
    }),
    icon: PropTypes.string
};

export default ErrorMessage;
