import React from 'react';

import useOutsideClick from '../../../hooks/useOutsideClick';

import Button from '../input/Button';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import styles from '../../../styles/general/Modal.module.scss';

const Modal = ({
    children,
    open = false,
    blocking = false,
    dismissable = true,
    handleClose = () => null,
    style = {},
    classes = {}
}) => {
    let modalRef = React.useRef();

    useOutsideClick(modalRef, handleClose, !blocking);

    return (
        open &&
        createPortal(
            <div className={[styles.container, classes?.container].join(' ')}>
                <div
                    className={[styles.content, classes?.content].join(' ')}
                    style={{ ...style?.content }}
                    ref={modalRef}
                >
                    {dismissable && (
                        <div
                            className={[
                                styles.modalClose,
                                classes?.dismiss
                            ].join(' ')}
                            style={style?.dismiss}
                            onClick={handleClose}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    )}
                    {children}
                </div>
            </div>,
            document.body
        )
    );
};

const Title = ({ children, icon, classes, style }) => (
    <div className={styles.title}>
        {icon && (
            <div
                className={[styles.iconContainer, classes?.iconContainer].join(
                    ' '
                )}
                style={style?.iconContainer}
            >
                <FontAwesomeIcon icon={icon} style={style?.icon} />
            </div>
        )}
        {children}
    </div>
);
Modal.Title = Title;

const Body = ({ children, style = {} }) => (
    <div className={styles.body} style={style?.container}>
        <div className={styles.bodyContent} style={style?.content}>
            {children}
        </div>
    </div>
);
Modal.Body = Body;

const Actions = ({ children, buttons }) => (
    <div className={styles.actions}>
        {buttons
            ? buttons.map((button, i) => <Button key={i} {...button} />)
            : children}
    </div>
);
Modal.Actions = Actions;

Modal.propTypes = {
    children: PropTypes.any,
    open: PropTypes.bool,
    blocking: PropTypes.bool,
    dismissable: PropTypes.bool,
    handleClose: PropTypes.func,
    style: PropTypes.object
};

export default Modal;
