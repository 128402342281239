import FormCheckbox from '../../components/general/forminputs/FormCheckbox';
import FormDate from '../../components/general/forminputs/FormDate';
import FormDropdown from '../../components/general/forminputs/FormDropdown';
import FormDynamic from '../../components/general/forminputs/FormDynamic';
import FormEmployeePicker from '../../components/general/forminputs/FormEmployeePicker';
import FormImage from '../../components/general/forminputs/FormImage';
import FormSignature from '../../components/general/forminputs/FormSignature';
import FormText from '../../components/general/forminputs/FormText';
import FormTextInput from '../../components/general/forminputs/FormTextInput';
import FormYesNo from '../../components/general/forminputs/FormYesNo';

const determineComponent = (props) => {
    let { type } = props;
    props.isEditable = props.readOnly ? false : props.isEditable;
    switch (type) {
        case 'dynamic':
            return <FormDynamic {...props} />;
        case 'image':
            return <FormImage {...props} />;
        case 'text':
            return <FormText {...props} />;
        case 'textinput':
            return <FormTextInput {...props} />;
        case 'dropdown':
            return <FormDropdown {...props} />;
        case 'checkbox':
            return <FormCheckbox {...props} />;
        case 'date':
            return <FormDate {...props} />;
        case 'signature':
            return <FormSignature {...props} />;
        case 'employee':
            return <FormEmployeePicker {...props}/>;
        case 'yes/no':
            return <FormYesNo {...props}/>;
        default: 
            return null;
    }
};

export default determineComponent;
