import { useState } from 'react';

import useApi from '../../../../hooks/useApi';
import useLocalStorage from '../../../../hooks/useLocalStorage';

import Button from '../../../general/input/Button';
import InputLabel from '../../../general/input/InputLabel';
import Select from '../../../general/input/Select';
import Modal from '../../../general/modal/Modal';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';

import styles from '../../../../styles/apps/quality/modal/ChangeJobModal.module.scss';

const ChangeJobModal = ({
    handleClose,
    handleConfirm,
    job,
    message,
    title = 'Change Job'
}) => {
    const [recentJobs, updateRecentJobs] = useLocalStorage('recent-jobs', []);
    const [{ loading }, updateJob] = useApi('/user/job', 'PUT', {
        manual: true
    });
    const { enqueueSnackbar } = useSnackbar();

    const [selectedJob, setSelectedJob] = useState(job ?? []);

    const handleChange = (job) => setSelectedJob(job);

    const handleSubmit = () => {
        if (!selectedJob || selectedJob?.length <= 0) return;

        updateJob({
            data: {
                jobId: selectedJob[0].id
            }
        })
            .then((job) => {
                if (
                    !recentJobs.some((recent) => recent.id === job.id) &&
                    recentJobs.length >= 5
                ) {
                    updateRecentJobs([
                        job,
                        ...recentJobs.slice(
                            recentJobs.length - 4,
                            recentJobs.length
                        )
                    ]);
                } else {
                    updateRecentJobs([
                        job,
                        ...recentJobs.filter((rj) => rj.id !== job.id)
                    ]);
                }
                enqueueSnackbar('Current job updated.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                handleConfirm?.(job);
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Failed to update current job.', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });
    };

    return (
        <Modal open handleClose={handleClose} blocking>
            <Modal.Title icon={faBuilding}>{title}</Modal.Title>
            <Modal.Body>
                <div className={styles.container}>
                    {message && <p className={styles.message}>{message}</p>}
                    <InputLabel label="Job" required>
                        <Select
                            placeholder="Select Job"
                            selected={selectedJob}
                            handleRowSelection={handleChange}
                            pagination={{
                                url: '/quality/jobs'
                            }}
                            getRowValue={(j) =>
                                `${j.vistaJobNumber} - ${j.vistaJobDescription}`
                            }
                        />
                    </InputLabel>
                    <div className={styles.recentJobContainer}>
                        <div className={styles.recentJobHeader}>
                            <p>Recently Used Jobs</p>
                        </div>
                        <div className={styles.jobList}>
                            {recentJobs?.length > 0 ? (
                                recentJobs?.map?.((job) => (
                                    <div
                                        className={[
                                            styles.job,
                                            selectedJob?.[0]?.id === job.id
                                                ? styles.selected
                                                : null
                                        ].join(' ')}
                                        onClick={handleChange.bind(this, [job])}
                                    >
                                        <p>
                                            {job.vistaJobNumber} -{' '}
                                            {job.vistaJobDescription}
                                        </p>
                                    </div>
                                ))
                            ) : (
                                <p className={styles.noRecentJobs}>
                                    No recent jobs found on this device.
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    variant="border"
                    type="secondary"
                    onClick={handleClose}
                />
                <Button
                    label="Confirm"
                    disabled={!selectedJob || selectedJob.length <= 0}
                    onClick={handleSubmit}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default ChangeJobModal;
