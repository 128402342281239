import styles from '../../../styles/general/skeleton/GridSkeleton.module.scss';

const GridSkeleton = () => (
    <div className={styles.gridContainer}>
        <div className={styles.grid}>
            <header className={styles.header}>
                <div className={styles.action} />
                <div className={styles.action} />
            </header>
            <div className={styles.body}>
                <div className={styles.gridHeader}>
                    <div className={styles.cell}>
                        <div className={styles.label} />
                    </div>
                    <div className={styles.cell}>
                        <div
                            className={styles.label}
                            style={{ width: '20%' }}
                        />
                    </div>
                    <div className={styles.cell}>
                        <div
                            className={styles.label}
                            style={{ width: '75%' }}
                        />
                    </div>
                    <div className={styles.cell}>
                        <div
                            className={styles.label}
                            style={{ width: '45%' }}
                        />
                    </div>
                    <div className={styles.cell}>
                        <div
                            className={styles.label}
                            style={{ width: '30%' }}
                        />
                    </div>
                    <div className={styles.cell}>
                        <div
                            className={styles.label}
                            style={{ width: '55%' }}
                        />
                    </div>
                </div>
                {Array.from({ length: 20 }, (_, index) => {
                    return (
                        <div className={styles.gridItem} key={index}>
                            <div className={styles.cell}>
                                <div className={styles.label} />
                            </div>
                            <div className={styles.cell}>
                                <div
                                    className={styles.label}
                                    style={{
                                        width: `${Math.random() * 45 + 20}%`
                                    }}
                                />
                            </div>
                            <div className={styles.cell}>
                                <div
                                    className={styles.label}
                                    style={{
                                        width: `${Math.random() * 45 + 20}%`
                                    }}
                                />
                            </div>
                            <div className={styles.cell}>
                                <div
                                    className={styles.label}
                                    style={{
                                        width: `${Math.random() * 45 + 20}%`
                                    }}
                                />
                            </div>
                            <div className={styles.cell}>
                                <div
                                    className={styles.label}
                                    style={{
                                        width: `${Math.random() * 45 + 20}%`
                                    }}
                                />
                            </div>
                            <div className={styles.cell}>
                                <div
                                    className={styles.label}
                                    style={{
                                        width: `${Math.random() * 45 + 20}%`
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
        <div className={styles.sidePanel}>
            <div className={styles.sidePanelItem}>
                <div className={styles.icon} />
                <div className={styles.label} />
            </div>
        </div>
    </div>
);

export default GridSkeleton;
