import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import styles from '../../../styles/general/card/SignatureCard.module.scss';

const SignatureCard = ({ signature, label, actions = [], timestamp }) => (
    <div className={styles.container}>
        <div className={styles.card}>
            <div className={styles.upper}>
                <div className={styles.label}>
                    <p>{label}</p>
                </div>
                {signature && (
                    <div className={styles.signature}>
                        <img srcSet={`${signature} 2x`} alt="" />
                    </div>
                )}
                <div className={styles.actions}>
                    <div className={[styles.action].join(' ')}>
                        {actions?.map?.((action, i) => (
                            <button
                                onClick={action.handleClick}
                                key={i}
                                className={
                                    signature ? styles.remove : styles.add
                                }
                            >
                                <FontAwesomeIcon icon={action.icon} />
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            <div className={styles.lower}>
                <div className={styles.timestamp}>
                    {timestamp
                        ? DateTime.fromJSDate(new Date(timestamp)).toFormat(
                              'yyyy-MM-dd hh:mm:ss a'
                          )
                        : null}
                </div>
            </div>
        </div>
    </div>
);

SignatureCard.propTypes = {
    signature: PropTypes.string,
    label: PropTypes.string,
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            handleClick: PropTypes.func
        })
    )
};

export default SignatureCard;
