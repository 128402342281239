import React, { useEffect, useRef, useState } from 'react';

import useApi from '../../../hooks/useApi';
import useUser from '../../../hooks/useUser';

import SignatureCard from '../../general/cards/SignatureCard';
import ErrorMessage from '../../general/ErrorMessage';
import Button from '../../general/input/Button';
import DatePicker from '../../general/input/DatePicker';
import FileUpload from '../../general/input/FileUpload';
import InputLabel from '../../general/input/InputLabel';
import TextInput from '../../general/input/TextInput';
import LoadingOverlay from '../../general/LoadingOverlay';
import SignatureModal from '../../general/modal/SignatureModal';
import DeleteActivityModal from './modal/DeleteActivityModal';
import DeleteSignatureModal from './modal/DeleteSignatureModal';
import IncompleteActivityModal from './modal/IncompleteActivityModal';
import PretaskActivity from './PretaskActivity';
import PretaskChecklist from './PretaskChecklist';
import {
    faArrowAltCircleLeft,
    faExclamationTriangle,
    faPlus,
    faPlusCircle,
    faRedo,
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniqueId } from 'lodash';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import styles from '../../../styles/apps/pretask/EditPretask.module.scss';

const NewPretask = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [params] = useSearchParams();

    const { displayName: username } = useUser();

    const [
        { data: template, error: templateError, loading: templateLoading },
        getTemplate
    ] = useApi('', 'GET', { manual: true });
    const [{ loading: submittingPretask }, submitPretask] = useApi(
        '/pretasks',
        'POST',
        { manual: true }
    );

    const stableGetTemplate = useRef(getTemplate);

    const [activities, setActivities] = useState([]);

    /**
     * Form Fields
     */
    //const [checklist, setChecklist] = useState([])
    const [comments, setComments] = useState('');
    const [date, setDate] = useState(new Date());
    const [error, setError] = useState({});
    const [loadingError, setLoadingError] = useState(false);
    const [jobDescription, setJobDescription] = useState('');
    const [jobNumber, setJobNumber] = useState(null);
    const [employeeSignatures, setEmployeeSignatures] = useState([]);
    const [extraSignatures, setExtraSignatures] = useState([]);
    const [supervisorSignature, setSupervisorSignature] = useState(null);
    const [workArea, setWorkArea] = useState('');
    const [checklists, setChecklists] = useState([]);
    const [customHeaders, setCustomHeaders] = useState([]);
    const [attachments, setAttachments] = useState([]);

    /**
     * Modal State
     */
    const [showActivityDelete, setShowActivityDelete] = useState(null);
    const [showCustomSignature, setShowCustomSignature] = useState(false);
    const [showSupervisorSignature, setShowSupervisorSignature] =
        useState(false);
    const [showEmployeeSignature, setShowEmployeeSignature] = useState(false);
    const [showDeleteSignature, setShowDeleteSignature] = useState(false);
    const [showIncompleteActivity, setShowIncompleteActivity] = useState(false);

    useEffect(() => {
        document.title = 'CVE Apps - New Pretask';
        const templateUrl = ['divisionId', 'departmentId', 'jobId']
            .map((section) =>
                params?.get(section) ? `${section}=${params?.get(section)}` : ''
            )
            .join('&');
        stableGetTemplate
            ?.current({
                url: `/pretask-templates/current-template?${templateUrl}&context=create-pretask`
            })
            .then((template) => {
                setActivities(
                    template?.useCustomForm
                        ? []
                        : [
                              {
                                  id: uniqueId(),
                                  description: '',
                                  locationOfWork: '',
                                  hazards: [],
                                  explainHazard: '',
                                  mitigateHazard: '',
                                  employees: [],
                                  isRemovable: true
                              }
                          ]
                );
                setCustomHeaders(
                    template?.headers?.map((header) => ({
                        ...header,
                        value: header.value ?? ''
                    }))
                );
                setChecklists(
                    template?.checklists?.map((checklist) => ({
                        ...checklist,
                        checklistValue: ''
                    }))
                );
                setWorkArea(template?.workArea ?? '');
                setJobDescription(template?.jobDescription ?? '');
                setJobNumber(template?.jobNumber);
            });
    }, [stableGetTemplate, params]);

    /**
     * State Functions
     */
    const handleJobDescriptionChange = (e) => {
        setJobDescription(e.target.value);
    };

    const handleWorkAreaChange = (e) => {
        setWorkArea(e.target.value);
    };

    const handleCommentsChange = (e) => {
        setComments(e.target.value);
    };

    const handleCustomHeaderChange = (input, e) => {
        setCustomHeaders((customHeaders) =>
            customHeaders.map((header) => {
                return header.name === input
                    ? { ...header, value: e.target.value }
                    : header;
            })
        );
    };

    const handleFileUpload = (files) => {
        setAttachments(files);
    };

    const handleFileRemove = () => {
        setAttachments([]);
    };

    const addActivity = () => {
        setActivities((activities) => [
            ...activities,
            {
                id: uniqueId(),
                description: '',
                locationOfWork: '',
                hazards: [],
                explainHazard: '',
                mitigateHazard: '',
                employees: [],
                isRemovable: true
            }
        ]);
    };

    const handleActivityChange = (sequence, activity) => {
        setActivities((activities) => [
            ...activities.slice(0, sequence),
            activity,
            ...activities.slice(sequence + 1)
        ]);
    };

    const handleActivityDelete = (sequence) => {
        setShowActivityDelete(sequence);
    };

    const handleActivityDeleteClose = () => {
        setShowActivityDelete(null);
    };

    const handleRemoveActivity = (sequence) => {
        handleEmployeeRemove(
            activities[sequence].employees.map((emp) => emp.id)
        );
        setActivities((activities) => [
            ...activities.slice(0, sequence),
            ...activities.slice(sequence + 1)
        ]);
        setShowActivityDelete(null);
        enqueueSnackbar(`Activity was removed.`, {
            variant: 'success',
            autoHideDuration: 2000,
            preventDuplicate: false
        });
    };

    const handleChecklist = (checklists) => setChecklists(checklists);

    const handleCustomSignatureClose = () => setShowCustomSignature(false);

    const handleCustomSignatureChange = (signature, label) => {
        setExtraSignatures((signatures) => [
            ...signatures,
            { signature, label, name: label, id: uniqueId() }
        ]);
        handleCustomSignatureClose();
    };

    const handleSupervisorSignatureClose = () =>
        setShowSupervisorSignature(false);

    const handleSupervisorSignatureChange = (signature, label) => {
        setSupervisorSignature({ signature, label });
        handleSupervisorSignatureClose();
    };

    const handleDateChange = (date) => setDate(date);

    const handleShowEmployeeSignature = (emp) => {
        let incompleteActivities = [];

        activities.forEach((activity) => {
            if (
                activity.employees
                    ?.map((employee) => employee.id)
                    ?.includes(emp.id)
            ) {
                if (
                    activity.description.replace(/\s/g, '') === '' ||
                    activity.explainHazard.replace(/\s/g, '') === '' ||
                    activity.locationOfWork.replace(/\s/g, '') === '' ||
                    activity.mitigateHazard.replace(/\s/g, '') === ''
                ) {
                    incompleteActivities = [
                        ...incompleteActivities,
                        activity.id
                    ];
                }
            }
        });

        if (incompleteActivities.length > 0) {
            setShowIncompleteActivity(true);
        } else {
            setShowEmployeeSignature(emp);
        }
    };

    const handleEmployeeSignatureClose = () => setShowEmployeeSignature(false);

    const handleEmployeeSignatureChange = (emp, signature) => {
        setEmployeeSignatures((signatures) =>
            signatures.some((s) => s.employeeId === emp.employeeId)
                ? signatures.map((s) =>
                      s.employeeId === emp.employeeId
                          ? { ...s, signature: signature }
                          : s
                  )
                : [
                      ...signatures,
                      {
                          label: emp.employeeId,
                          signature: signature,
                          employeeId: emp.employeeId
                      }
                  ]
        );
        setActivities((activities) =>
            activities.map((activity) => {
                let assignedEmpIds = activity.employees.map(
                    (e) => e.employeeId
                );

                return {
                    ...activity,
                    isLocked: assignedEmpIds.includes(emp.employeeId)
                        ? true
                        : activity.isLocked ?? false,
                    employees: activity.employees.map((e) => ({
                        ...e,
                        isRemovable:
                            e.employeeId === emp.employeeId
                                ? false
                                : e.isRemovable
                    }))
                };
            })
        );
        handleEmployeeSignatureClose();
    };

    const handleEmployeeAdd = (selected) => {
        setEmployeeSignatures((signatures) => {
            return signatures.some((s) => s.employeeId === selected.id)
                ? signatures.map((s) => {
                      return s.employeeId === selected.id
                          ? { ...s, count: s.count + 1, signature: null }
                          : s;
                  })
                : [
                      ...signatures,
                      {
                          ...selected,
                          label: selected.employeeId,
                          count: 1,
                          signature: null
                      }
                  ];
        });
    };

    const handleEmployeeRemove = (ids, sequence) => {
        ids = Array.isArray(ids) ? ids : [ids];
        setActivities((activities) =>
            activities.map((activity, i) => ({
                ...activity,
                employees:
                    i === sequence
                        ? activity.employees.filter((e) => !ids.includes(e.id))
                        : activity.employees
            }))
        );
        setEmployeeSignatures((signatures) => {
            return signatures
                .map((s) => {
                    return ids?.includes(s.employeeId)
                        ? s.count === 1
                            ? null
                            : {
                                  ...s,
                                  count: s.count - 1
                              }
                        : s;
                })
                .filter((s) => s);
        });
    };

    const handleDeleteSignature = (type, id) =>
        setShowDeleteSignature({ type, id });
    const handleDeleteSignatureConfirm = (type, id) => {
        if (type === 'supervisor') {
            setSupervisorSignature(null);
        } else if (type === 'employee') {
            setEmployeeSignatures((signatures) =>
                signatures.map((s) =>
                    s.id === id ? { ...s, signature: null } : s
                )
            );
        } else {
            setExtraSignatures((signatures) =>
                signatures.filter((sig) => sig.id !== id)
            );
        }
        handleDeleteSignatureClose();
    };
    const handleDeleteSignatureClose = () => setShowDeleteSignature(false);

    const handleCancel = () => navigate('/pretask');

    const handleSave = async () => {
        setError({});
        setLoadingError(true);

        let errObj = {};

        if (!jobNumber && template?.useJobDescription && !jobDescription)
            errObj['jobDescription'] =
                'You must provide a job description or job number.';
        if (!date) errObj['date'] = 'You must provide a date for the pretask.';
        if (template?.useCustomForm) {
            if (!attachments || attachments.length < 1)
                errObj['attachments'] = 'You must upload a Pretask form.';
            if (!supervisorSignature || !supervisorSignature.signature)
                errObj['supervisorSignature'] =
                    'The supervisor must sign the Pretask form.';
        } else {
            if (supervisorSignature) {
                if (
                    date &&
                    DateTime.local().toISODate() !==
                        DateTime.fromJSDate(new Date(date)).toISODate()
                )
                    errObj['date'] =
                        'The date must be the current day to submit a pretask with signatures.';
                if (template?.useWorkArea && !workArea)
                    errObj['workArea'] = 'You must provide a work area.';
                if (activities.length === 0)
                    errObj['activities'] =
                        'You must have at least one work activity.';
                if (
                    activities?.some?.(
                        (activity) => activity.employees.length <= 0
                    )
                )
                    errObj['activityEmployee'] =
                        'All work activities must have at least 1 employee.';
                if (
                    checklists?.some?.(
                        (checklist) =>
                            !checklist.value ||
                            (checklist.responseFlag
                                ?.split?.('.')
                                ?.includes?.(checklist.value) &&
                                (!checklist.responseValue ||
                                    checklist?.responseValue?.replace?.(
                                        /\s/g,
                                        ''
                                    ) === ''))
                    )
                )
                    errObj['checklist'] =
                        'You must select an option for every checklist and explain your selections.';
                if (employeeSignatures?.some?.((emp) => !emp.signature))
                    errObj['employeeSignatures'] =
                        'All employees must sign the pretask.';
            } else {
                if (
                    [...employeeSignatures, ...extraSignatures].some(
                        (sig) => sig.signature
                    )
                )
                    errObj['signatures'] =
                        'The supervisor must sign the pretask if anyone else has signed.';
            }
        }

        setLoadingError(false);
        if (Object.keys(errObj).length !== 0) {
            return setError(errObj);
        } else {
            let attachment;
            if (attachments && attachments[0]) {
                try {
                    attachment = await new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(attachments[0].file);
                        reader.onload = () =>
                            resolve(reader.result?.split?.(',')?.[1]);
                        reader.onerror = (error) => reject(error);
                    });
                } catch (error) {
                    console.log('attachment error', error);
                }
            }

            let creationObj = {
                activities: activities.map((activity, index) => ({
                    description: activity?.description,
                    employeeIds: activity?.employees?.map(
                        (employee) => employee.id
                    ),
                    explainHazard: activity?.explainHazard,
                    hazards: template?.hazards?.map((globalHazard) => {
                        return {
                            hazardId: globalHazard.id,
                            value: activity.hazards.some(
                                (hazard) =>
                                    parseInt(hazard.key) === globalHazard.id
                            )
                        };
                    }),
                    locationOfWork: activity?.locationOfWork,
                    mitigateHazard: activity?.mitigateHazard,
                    sequence: index
                })),
                checklists: checklists.map((checklist) => ({
                    checklistId: checklist?.id,
                    value: checklist?.value,
                    responseValue: checklist?.responseFlag
                        ?.split?.('.')
                        ?.includes?.(checklist?.value)
                        ? checklist.responseValue
                        : null
                })),
                comments: comments,
                dateSpecified: supervisorSignature
                    ? DateTime.now().toISO()
                    : date,
                headers: customHeaders.map((header) => ({
                    value: header?.value,
                    headerId: header.id
                })),
                jobDescription: jobDescription,
                jobId: params.get('jobId')
                    ? parseInt(params.get('jobId'))
                    : null,
                departmentId: params.get('departmentId')
                    ? parseInt(params.get('departmentId'))
                    : null,
                divisionId: params.get('divisionId')
                    ? parseInt(params.get('divisionId'))
                    : null,
                paperFormBase64: attachment,
                paperFormFileType: attachments?.[0]?.file?.type,
                paperFormFileName: attachments?.[0]?.file?.name,
                signatures: [...employeeSignatures, ...extraSignatures]
                    .filter((signature) => signature.signature)
                    ?.map((signature) => ({
                        employeeId: signature.employeeId,
                        name: signature.name,
                        signatureBase64: signature.signature?.replace?.(
                            'data:image/png;base64,',
                            ''
                        )
                    })),
                supervisorSignatureBase64:
                    supervisorSignature?.signature?.replace?.(
                        'data:image/png;base64,',
                        ''
                    ),
                templateId: template.id,
                workArea: workArea
            };

            submitPretask({
                data: creationObj
            })
                .then(() => {
                    enqueueSnackbar('Your pretask was created.', {
                        variant: 'success',
                        autoHideDuration: 4000
                    });

                    navigate('/pretask');
                })
                .catch((err) => {
                    console.log(err);
                    enqueueSnackbar(
                        'Error encountered while creating pretask.',
                        {
                            variant: 'error',
                            autoHideDuration: 5000
                        }
                    );
                });
        }
    };

    return templateLoading || (!template && !templateError) ? (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <LoadingOverlay label="Loading Pretask Template..." size="xl" />
        </div>
    ) : templateError?.response?.status === 404 ? (
        <div className={styles.errorContainer}>
            <div className={styles.error}>404</div>
            <div className={styles.header}>Pretask Template Not Found</div>
            <div className={styles.message}>
                The pretask template for the division/department/job you
                selected could not be found.
            </div>
            <div className={styles.link}>
                <Link to="/pretask">
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                    Back To Pretasks
                </Link>
            </div>
        </div>
    ) : templateError?.response?.status === 401 ? (
        <div className={styles.errorContainer}>
            <div className={styles.error}>401</div>
            <div className={styles.header}>You Are Not Authorized</div>
            <div className={styles.message}>
                You do not have permissions to view the pretask template you
                selected.
            </div>
            <div className={styles.link}>
                <Link to="/pretask">
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                    Back To Pretasks
                </Link>
            </div>
        </div>
    ) : templateError?.response?.status ? (
        <div className={styles.errorContainer}>
            <div className={styles.error}>
                {templateError?.response?.status}
            </div>
            <div className={styles.header}>Error Encountered</div>
            <div className={styles.message}>
                We ran into an error when retrieving the requested Pretask
                template.
            </div>
            <div className={styles.link}>
                <Link to="/pretask">
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                    Back To Pretasks
                </Link>
            </div>
        </div>
    ) : (
        <div className={styles.container}>
            <div className={styles.link}>
                <Link to="/pretask">
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                    Back To Pretask List
                </Link>
            </div>
            <div className={styles.pretaskContainer}>
                <div className={styles.pretaskHeader}>
                    <section className={styles.section}>
                        <div className={styles.header}>
                            <h1>Pretask - Daily Work Permit</h1>
                            <h3>MUST BE COMPLETED BEFORE STARTING ANY WORK</h3>
                        </div>
                        <div className={styles.body}>
                            <div
                                className={[
                                    styles.input,
                                    styles.jobNumber
                                ].join(' ')}
                            >
                                {jobNumber && (
                                    <InputLabel
                                        label={'Job Number'}
                                        required={true}
                                    >
                                        <div className={styles.value}>
                                            {jobNumber}
                                        </div>
                                    </InputLabel>
                                )}
                            </div>
                            <div
                                className={[
                                    styles.input,
                                    styles.jobNumber
                                ].join(' ')}
                            >
                                <InputLabel
                                    label={'Job Description'}
                                    required={true}
                                >
                                    {jobNumber ? (
                                        <div className={styles.value}>
                                            {template?.jobDescription}
                                        </div>
                                    ) : (
                                        <TextInput
                                            value={jobDescription}
                                            onChange={
                                                handleJobDescriptionChange
                                            }
                                        />
                                    )}
                                </InputLabel>
                            </div>
                            <div className={[styles.input].join(' ')}>
                                <InputLabel
                                    label="Date/Time"
                                    labelClick={false}
                                    required={true}
                                >
                                    <DatePicker
                                        value={date}
                                        onChange={handleDateChange}
                                    />
                                </InputLabel>
                            </div>
                            <div
                                className={[styles.input, styles.workArea].join(
                                    ' '
                                )}
                            >
                                {template?.useWorkArea && (
                                    <InputLabel
                                        label="Work Area"
                                        required={!!supervisorSignature}
                                    >
                                        <TextInput
                                            value={workArea}
                                            onChange={handleWorkAreaChange}
                                        />
                                    </InputLabel>
                                )}
                            </div>
                            {customHeaders
                                ?.sort((a, b) =>
                                    a.sequence > b.sequence
                                        ? 1
                                        : a.sequence < b.sequence
                                        ? -1
                                        : 0
                                )
                                ?.map((header) => (
                                    <div
                                        className={styles.input}
                                        key={header.id}
                                    >
                                        <InputLabel label={header.name}>
                                            <TextInput
                                                value={header.value}
                                                onChange={handleCustomHeaderChange.bind(
                                                    this,
                                                    header.name
                                                )}
                                            />
                                        </InputLabel>
                                    </div>
                                ))}
                        </div>
                    </section>
                </div>
                {template?.useCustomForm ? (
                    <section className={styles.section}>
                        <div className={styles.body}>
                            <InputLabel
                                label="Pretask Form Attachment"
                                labelClick={false}
                                required={true}
                            >
                                <FileUpload
                                    handleUpload={handleFileUpload}
                                    handleRemove={handleFileRemove}
                                    uploadLimit={1}
                                    replaceOnUpload={true}
                                    files={attachments}
                                />
                            </InputLabel>
                        </div>
                        <div className={styles.body}>
                            {template?.useComments && (
                                <InputLabel label="Comments">
                                    <TextInput
                                        lines={3}
                                        value={comments}
                                        onChange={handleCommentsChange}
                                    />
                                </InputLabel>
                            )}
                        </div>
                        <div className={styles.body}>
                            <InputLabel
                                label="Supervisor Signature"
                                required={true}
                                labelClick={false}
                            >
                                {supervisorSignature && (
                                    <SignatureCard
                                        signature={
                                            supervisorSignature?.signature
                                        }
                                        label={supervisorSignature?.label}
                                        actions={[
                                            {
                                                icon: faTimes,
                                                handleClick:
                                                    handleDeleteSignature.bind(
                                                        this,
                                                        'supervisor'
                                                    )
                                            }
                                        ]}
                                    />
                                )}
                                {!supervisorSignature && (
                                    <Button
                                        label="Add Signature"
                                        icon={faPlus}
                                        onClick={() =>
                                            setShowSupervisorSignature(true)
                                        }
                                        className={styles.addButton}
                                    />
                                )}
                            </InputLabel>
                        </div>
                        {error && Object.keys(error)?.length > 0 && (
                            <div className={styles.errors}>
                                {Object.keys(error)?.map((err) => (
                                    <ErrorMessage error={error[err]} />
                                ))}
                            </div>
                        )}
                        <div className={[styles.actions].join(' ')}>
                            <Button
                                label="Cancel"
                                type="secondary"
                                variant="border"
                                onClick={handleCancel}
                            />
                            <Button
                                label="Save"
                                onClick={handleSave}
                                loading={submittingPretask || loadingError}
                            />
                        </div>
                    </section>
                ) : (
                    <div className={styles.pretaskBody}>
                        <section className={styles.section}>
                            <div className={styles.header}>
                                <h3>Work Activities</h3>
                            </div>
                            <div className={styles.body}>
                                {activities.map((activity, i) => (
                                    <div
                                        className={styles.activity}
                                        key={activity.id}
                                    >
                                        <PretaskActivity
                                            activity={{
                                                ...activity,
                                                sequence: i
                                            }}
                                            handleUpdate={handleActivityChange}
                                            handleDelete={handleActivityDelete.bind(
                                                this,
                                                i
                                            )}
                                            handleAdd={handleEmployeeAdd}
                                            handleRemove={handleEmployeeRemove}
                                            availableHazards={template?.hazards}
                                        />
                                    </div>
                                ))}
                                <div className={styles.newActivity}>
                                    <Button
                                        type="primary"
                                        label="New Activity"
                                        icon={faPlus}
                                        className={styles.newActivityButton}
                                        onClick={addActivity}
                                    />
                                </div>
                            </div>
                        </section>
                        <section className={styles.section}>
                            <div className={styles.header}>
                                <h3>Checklists</h3>
                            </div>
                            <div className={styles.body}>
                                <div className={styles.checklists}>
                                    <PretaskChecklist
                                        checklists={checklists}
                                        handleChange={handleChecklist}
                                    />
                                </div>
                                <div className={[styles.input].join(' ')}>
                                    {template?.useComments && (
                                        <InputLabel label="Comments">
                                            <TextInput
                                                lines={4}
                                                value={comments}
                                                onChange={handleCommentsChange}
                                            />
                                        </InputLabel>
                                    )}
                                </div>
                            </div>
                        </section>
                        <section className={styles.section}>
                            <div className={styles.header}>
                                <h3>Signatures</h3>
                            </div>
                            <div
                                className={[
                                    styles.body,
                                    styles.signatures
                                ].join(' ')}
                            >
                                <div className={styles.requiredSignatures}>
                                    <div className={styles.employeeSignatures}>
                                        <h3>Employee Signatures</h3>
                                        {employeeSignatures?.length > 0 && (
                                            <div
                                                className={
                                                    styles.signatureWarning
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faExclamationTriangle}
                                                />
                                                Once an employee signs, all
                                                activities they are a part of
                                                will be locked for editing.
                                            </div>
                                        )}
                                        <div
                                            className={
                                                styles.signatureAddContainer
                                            }
                                        >
                                            <div>
                                                {employeeSignatures.length ===
                                                0 ? (
                                                    <div
                                                        className={
                                                            styles.noEmployeeMessage
                                                        }
                                                    >
                                                        No Employees assigned to
                                                        an activity.
                                                    </div>
                                                ) : (
                                                    employeeSignatures.map(
                                                        (emp) => (
                                                            <SignatureCard
                                                                signature={
                                                                    emp.signature
                                                                }
                                                                label={`${
                                                                    emp.lastName
                                                                }, ${
                                                                    emp.firstName
                                                                } ${
                                                                    emp.middleName ??
                                                                    ''
                                                                }`}
                                                                actions={[
                                                                    {
                                                                        icon: emp.signature
                                                                            ? faRedo
                                                                            : faPlusCircle,
                                                                        handleClick:
                                                                            handleShowEmployeeSignature.bind(
                                                                                this,
                                                                                emp
                                                                            )
                                                                    }
                                                                ]}
                                                                key={emp.id}
                                                            />
                                                        )
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={styles.supervisorSignatures}
                                    >
                                        <div
                                            className={
                                                styles.signatureAddContainer
                                            }
                                        >
                                            <h3
                                                className={
                                                    styles.supervisorHeader
                                                }
                                            >
                                                <p className={styles.required}>
                                                    *
                                                </p>
                                                Supervisor Signature
                                            </h3>
                                            <div
                                                className={
                                                    styles.signatureAddBody
                                                }
                                            >
                                                {supervisorSignature && (
                                                    <SignatureCard
                                                        signature={
                                                            supervisorSignature?.signature
                                                        }
                                                        label={
                                                            supervisorSignature?.label
                                                        }
                                                        actions={[
                                                            {
                                                                icon: faRedo,
                                                                handleClick:
                                                                    () =>
                                                                        setShowSupervisorSignature(
                                                                            true
                                                                        )
                                                            },
                                                            {
                                                                icon: faTimes,
                                                                handleClick:
                                                                    handleDeleteSignature.bind(
                                                                        this,
                                                                        'supervisor'
                                                                    )
                                                            }
                                                        ]}
                                                    />
                                                )}
                                            </div>
                                            {!supervisorSignature && (
                                                <Button
                                                    label="Add Signature"
                                                    icon={faPlus}
                                                    onClick={() =>
                                                        setShowSupervisorSignature(
                                                            true
                                                        )
                                                    }
                                                    className={styles.addButton}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.extraSignatures}>
                                    <h3>Extra Signatures/Audited By</h3>
                                    <div className={styles.signatureList}>
                                        {extraSignatures.map((item) => (
                                            <SignatureCard
                                                signature={item.signature}
                                                label={item.label}
                                                key={item.id}
                                            />
                                        ))}
                                    </div>
                                    <div
                                        className={styles.signatureAddContainer}
                                    >
                                        <Button
                                            label="Add Signature"
                                            icon={faPlus}
                                            onClick={() =>
                                                setShowCustomSignature(true)
                                            }
                                            className={styles.addButton}
                                        />
                                    </div>
                                </div>
                                {showEmployeeSignature && (
                                    <SignatureModal
                                        editableHeader={{
                                            label: 'Name',
                                            value: `${showEmployeeSignature.firstName} ${showEmployeeSignature.lastName}`,
                                            required: true,
                                            readonly: true
                                        }}
                                        handleClose={
                                            handleEmployeeSignatureClose
                                        }
                                        handleSave={handleEmployeeSignatureChange.bind(
                                            this,
                                            showEmployeeSignature
                                        )}
                                    />
                                )}
                                {showCustomSignature && (
                                    <SignatureModal
                                        editableHeader={{
                                            label: 'Name',
                                            required: true
                                        }}
                                        handleClose={handleCustomSignatureClose}
                                        handleSave={handleCustomSignatureChange}
                                    />
                                )}
                            </div>
                        </section>
                        {error && Object.keys(error)?.length > 0 && (
                            <div className={styles.errors}>
                                {Object.keys(error)?.map((err, i) => (
                                    <ErrorMessage error={error[err]} key={i} />
                                ))}
                            </div>
                        )}
                        <div className={[styles.actions].join(' ')}>
                            <Button
                                label="Cancel"
                                type="secondary"
                                variant="border"
                                onClick={handleCancel}
                            />
                            <Button
                                label="Save"
                                onClick={handleSave}
                                loading={submittingPretask || loadingError}
                            />
                        </div>
                        {showActivityDelete !== null && (
                            <DeleteActivityModal
                                handleDelete={handleRemoveActivity.bind(
                                    this,
                                    showActivityDelete
                                )}
                                handleClose={handleActivityDeleteClose}
                            />
                        )}
                    </div>
                )}
                {showSupervisorSignature && (
                    <SignatureModal
                        editableHeader={{
                            label: 'Supervisor',
                            value: username,
                            readonly: true,
                            required: true
                        }}
                        handleClose={handleSupervisorSignatureClose}
                        handleSave={handleSupervisorSignatureChange}
                    />
                )}
                {showIncompleteActivity && (
                    <IncompleteActivityModal
                        handleClose={() => setShowIncompleteActivity(false)}
                    />
                )}
                {showDeleteSignature && (
                    <DeleteSignatureModal
                        handleClose={handleDeleteSignatureClose}
                        handleDelete={handleDeleteSignatureConfirm.bind(
                            this,
                            showDeleteSignature.type,
                            showDeleteSignature.id
                        )}
                    />
                )}
            </div>
        </div>
    );
};

export default NewPretask;
