import { useCallback, useState } from 'react';

const useLocalStorage = (key, defaultValue) => {
    const [state, setState] = useState(() => {
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch {
            return null;
        }
    });

    const updateValue = useCallback(
        (value) => {
            setState(value);
            if (!value) {
                localStorage.clear(key);
            } else {
                localStorage.setItem(
                    key,
                    typeof value === 'string' ? value : JSON.stringify(value)
                );
            }
        },
        [key]
    );

    return [state ?? defaultValue, updateValue];
};

export default useLocalStorage;
