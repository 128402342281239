import React, { useEffect, useRef, useState } from 'react';

import useApi from '../../../hooks/useApi';

import Button from '../../general/input/Button';
import CheckboxGroup from '../../general/input/CheckboxGroup';
import Dropdown from '../../general/input/Dropdown';
import InputLabel from '../../general/input/InputLabel';
import Select from '../../general/input/Select';
import LoadingOverlay from '../../general/LoadingOverlay';
import DeleteFieldModal from './modal/DeleteFieldModal';
import {
    faArrowAltCircleLeft,
    faGripVertical,
    faSave,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniqueId } from 'lodash';
import { useSnackbar } from 'notistack';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import styles from '../../../styles/apps/pretask/CustomizePretask.module.scss';

const CustomFieldContainer = ({
    fields,
    Component = CustomField,
    handleUpdate = () => null,
    handleReorder = () => null,
    handleRemove = () => null
}) => {
    const onDragEnd = (result) => {
        const { destination, source } = result;

        if (!destination) return;

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const fieldIds = Array.from(fields);
        const field = fieldIds.splice(source.index, 1);
        fieldIds.splice(destination.index, 0, ...field);

        handleReorder(fieldIds);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'droppable'}>
                {(provided) => (
                    <div
                        className={styles.customFields}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {fields.map((field, index) => (
                            <Component
                                {...field}
                                index={index}
                                key={field.key ?? field.id}
                                handleUpdate={handleUpdate}
                                handleRemove={handleRemove}
                            />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

const CustomField = ({ name, id, index, handleUpdate, handleRemove }) => (
    <Draggable draggableId={id} index={index}>
        {(provided) => (
            <div
                {...provided.draggableProps}
                ref={provided.innerRef}
                className={styles.customField}
                style={provided.draggableProps.style}
            >
                <div className={styles.card}>
                    <div
                        {...provided.dragHandleProps}
                        className={styles.handle}
                    >
                        <FontAwesomeIcon icon={faGripVertical} />
                    </div>
                    <div className={styles.leftGroup}>
                        <div className={styles.header}>
                            <input
                                type="text"
                                value={name}
                                className={styles.value}
                                onChange={handleUpdate.bind(this, id)}
                            />
                            <div
                                className={styles.delete}
                                onClick={handleRemove.bind(this, {
                                    id: id,
                                    message:
                                        'Are you sure you want to remove this field?',
                                    type: 'field'
                                })}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </Draggable>
);

const CustomHazard = ({
    name,
    id,
    index,
    isRemovable,
    handleUpdate,
    handleRemove
}) => (
    <Draggable draggableId={`${id}`} index={index}>
        {(provided) => (
            <div
                {...provided.draggableProps}
                ref={provided.innerRef}
                className={styles.customField}
                style={provided.draggableProps.style}
            >
                <div className={styles.card}>
                    <div
                        {...provided.dragHandleProps}
                        className={styles.handle}
                    >
                        <FontAwesomeIcon icon={faGripVertical} />
                    </div>
                    <div className={styles.leftGroup}>
                        {isRemovable ? (
                            <div className={styles.header}>
                                <input
                                    type="text"
                                    value={name}
                                    className={styles.value}
                                    onChange={handleUpdate.bind(this, id)}
                                />
                                <div
                                    className={styles.delete}
                                    onClick={handleRemove.bind(this, {
                                        id: id,
                                        message:
                                            'Are you sure you want to remove this hazard?',
                                        type: 'hazard'
                                    })}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </div>
                            </div>
                        ) : (
                            <div className={styles.header}>
                                <div className={styles.value}>{name}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )}
    </Draggable>
);

const CustomChecklist = ({
    response,
    name,
    id,
    index,
    handleUpdate,
    handleRemove
}) => {
    const handleNameChange = (e) => {
        handleUpdate(id, 'name', e.target.value);
    };

    const handleAdditionalChange = (additional) => {
        handleUpdate(id, 'response', additional);
    };

    return (
        <Draggable draggableId={id} index={index}>
            {(provided) => (
                <div
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    className={styles.customField}
                    style={provided.draggableProps.style}
                >
                    <div className={styles.card}>
                        <div
                            {...provided.dragHandleProps}
                            className={styles.handle}
                        >
                            <FontAwesomeIcon icon={faGripVertical} />
                        </div>
                        <div className={styles.leftGroup}>
                            <div className={styles.header}>
                                <input
                                    type="text"
                                    value={name}
                                    placeholder="Checklist Prompt"
                                    className={styles.value}
                                    onChange={handleNameChange}
                                />
                                <div
                                    className={styles.delete}
                                    onClick={handleRemove.bind(this, {
                                        id: id,
                                        message:
                                            'Are you sure you want to remove this checklist?',
                                        type: 'checklist'
                                    })}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div>
                                    <InputLabel label="Additional Response">
                                        <CheckboxGroup
                                            options={[
                                                { key: 'YES', value: 'Y' },
                                                { key: 'NO', value: 'N' },
                                                { key: 'NA', value: 'N/A' }
                                            ]}
                                            multiselect={true}
                                            selected={
                                                response === ''
                                                    ? []
                                                    : response?.split?.('.')
                                            }
                                            handleChange={
                                                handleAdditionalChange
                                            }
                                        />
                                    </InputLabel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    );
};

const CustomizePretask = () => {
    const [params] = useSearchParams();

    const hazardsRef = useRef();

    const templateUrl = ['divisionId', 'departmentId', 'jobId']
        .map((section) =>
            params?.get(section) ? `${section}=${params?.get(section)}` : null
        )
        .filter((s) => s)
        .join('&');
    const [{ data: template, error, loading }] = useApi(
        `/pretask-templates/current-template?${templateUrl}&context=edit-pretask-template`,
        'GET'
    );
    const [{ loading: submissionLoading }, saveTemplate] = useApi(
        '/pretask-templates',
        'POST',
        { manual: true }
    );

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!template) return;
        setFormType(
            template.useCustomForm
                ? { key: 'Form Upload', value: 'Form Upload' }
                : { key: 'Custom Form', value: 'Custom Form' }
        );
        setOptionalFields(() => {
            let optionalFields = ['jobDescription'];
            if (template?.useComments)
                optionalFields = [...optionalFields, 'comments'];
            if (template?.useWorkArea)
                optionalFields = [...optionalFields, 'workArea'];
            return optionalFields;
        });
        setCustomFields(
            template?.headers
                ?.sort((a, b) =>
                    a.sequence > b.sequence
                        ? 1
                        : a.sequence === b.sequence
                        ? 0
                        : -1
                )
                ?.map((header) => ({
                    ...header,
                    fieldId: header.id,
                    id: uniqueId('header')
                })) ?? []
        );
        setHazards(
            template?.hazards
                ?.sort((a, b) =>
                    a.sequence > b.sequence
                        ? 1
                        : a.sequence === b.sequence
                        ? 0
                        : -1
                )
                ?.map((hazard) => ({
                    ...hazard,
                    name: hazard.description,
                    id: uniqueId('hazard'),
                    hazardId: hazard.id,
                    preexisting: true
                })) ?? []
        );
        setChecklists(
            template?.checklists
                ?.sort((a, b) =>
                    a.sequence > b.sequence
                        ? 1
                        : a.sequence === b.sequence
                        ? 0
                        : -1
                )
                ?.map((checklist) => ({
                    ...checklist,
                    response: checklist.responseFlag,
                    id: checklist.id + ''
                })) ?? []
        );

        if (
            (params.get('jobId') &&
                parseInt(params.get('jobId')) !== template.jobId) ||
            (params.get('departmentId') &&
                parseInt(params.get('departmentId')) !==
                    template.departmentId) ||
            (params.get('divisionId') &&
                parseInt(params.get('divisionId')) !== template.divisionId)
        ) {
            setSave(true);
        }
    }, [template, params]);

    useEffect(() => {
        //TODO: handle error
    }, [error]);

    useEffect(() => {
        document.title = 'CVE Apps - Customize Pretask';
    }, []);

    const [formType, setFormType] = useState(null);
    const [hazards, setHazards] = useState([]);
    const [checklists, setChecklists] = useState([]);
    const [optionalFields, setOptionalFields] = useState(['comments']);
    const [customFields, setCustomFields] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [save, setSave] = useState(false);

    const handleChange = () => setSave(true);

    const handleFormType = (selected) => {
        handleChange();
        setFormType(selected);
    };

    const handleOptionalField = (selected) => {
        handleChange();
        setOptionalFields(selected);
    };
    const handleCustomField = (id, e) => {
        handleChange();
        setCustomFields((fields) =>
            fields.map((field) =>
                field.id === id ? { ...field, name: e.target.value } : field
            )
        );
    };
    const handleHazard = (id, e) => {
        handleChange();
        setHazards((hazards) =>
            hazards.map((hazard) => {
                if (hazard.id === id) {
                    if (hazard.preexisting) {
                        hazard = { ...hazard, preexisting: false };
                    }
                    return { ...hazard, name: e.target.value };
                } else {
                    return hazard;
                }
            })
        );
    };
    const handleChecklist = (id, key, value) => {
        handleChange();
        setChecklists((checklists) =>
            checklists.map((checklist) =>
                checklist.id === id
                    ? {
                          ...checklist,
                          [key]: key === 'name' ? value : value?.join?.('.')
                      }
                    : checklist
            )
        );
    };

    const handleAddField = () => {
        handleChange();
        setCustomFields((fields) => [
            ...fields,
            { id: uniqueId('newfield'), name: 'New Field' }
        ]);
    };
    const handleAddHazard = () => {
        handleChange();
        setHazards((hazards) => [
            ...hazards,
            {
                id: uniqueId('hazard'),
                name: 'New Hazard',
                isRemovable: true,
                preexisting: false
            }
        ]);
    };
    const handleAddChecklist = () => {
        handleChange();
        setChecklists((checklists) => [
            ...checklists,
            { id: uniqueId('newchecklist'), name: 'New Checklist' }
        ]);
    };

    const handleRemove = (entity) => setShowDelete(entity);
    const handleRemoveField = (field) => {
        handleChange();
        const { id, type } = field;
        if (type === 'field') {
            setCustomFields((fields) =>
                fields.filter((field) => field.id !== id)
            );
        } else if (type === 'hazard') {
            setHazards((hazards) =>
                hazards.filter((hazard) => {
                    if (
                        hazard.id === id &&
                        hazard.hazardId &&
                        hazards.reduce(
                            (acc, cur) =>
                                cur.hazardId === hazard.hazardId
                                    ? acc + 1
                                    : acc,
                            0
                        ) <= 1
                    ) {
                        hazardsRef?.current?.deselectRow(
                            parseInt(hazard.hazardId)
                        );
                    }
                    return hazard.id !== id;
                })
            );
        } else if (type === 'checklist') {
            setChecklists((checklists) =>
                checklists.filter((checklist) => checklist.id !== id)
            );
        }

        enqueueSnackbar(
            `${
                type[0]?.toUpperCase?.() + type?.substring(1)
            } has been deleted.`,
            {
                variant: 'success',
                autoHideDuration: 3000
            }
        );
        setShowDelete(false);
    };

    const handleHideShowDelete = () => setShowDelete(false);

    const handleFieldReorder = (fields) => {
        handleChange();
        setCustomFields(fields);
    };
    const handleHazardReorder = (hazards) => {
        handleChange();
        setHazards(hazards);
    };
    const handleChecklistReorder = (checklists) => {
        handleChange();
        setChecklists(checklists);
    };

    const handleHazardSelection = (id, selected) => {
        setHazards((hazards) => [
            ...hazards,
            {
                ...selected,
                name: selected.description,
                hazardId: selected.id,
                id: uniqueId('hazard'),
                isRemovable: true,
                preexisting: true
            }
        ]);
        handleChange();
    };

    const handleHazardDeselection = (deselected) => {
        if (
            hazards.find((hazard) => hazard.hazardId === deselected)
                ?.isRemovable === false
        ) {
            return enqueueSnackbar('You cannot remove a default hazard.', {
                variant: 'warning',
                preventDuplicate: true,
                autoHideDuration: 2500
            });
        }
        setHazards((hazards) =>
            hazards.filter((hazard) => hazard.hazardId !== deselected)
        );
        handleChange();
    };

    const handleReset = () => {
        setHazards((hazards) => hazards.filter((h) => !h.isRemovable));
    };

    const handleSubmit = () => {
        saveTemplate({
            data:
                formType?.key === 'Form Upload'
                    ? {
                          departmentId: parseInt(params?.get('departmentId')),
                          divisionId: parseInt(params?.get('divisionId')),
                          headers: customFields.map((header, sequence) => ({
                              ...header,
                              sequence
                          })),
                          jobId: parseInt(params?.get('jobId')),
                          useCustomForm: true,
                          useComments: optionalFields?.includes?.('comments'),
                          useWorkArea: optionalFields?.includes?.('workArea')
                      }
                    : {
                          checklists: checklists.map((checklist, sequence) => ({
                              ...checklist,
                              sequence
                          })),
                          departmentId: parseInt(params?.get('departmentId')),
                          divisionId: parseInt(params?.get('divisionId')),
                          hazards: hazards.map((hazard, sequence) => ({
                              ...hazard,
                              sequence
                          })),
                          headers: customFields.map((header, sequence) => ({
                              ...header,
                              sequence
                          })),
                          jobId: parseInt(params?.get('jobId')),
                          useComments: optionalFields?.includes?.('comments'),
                          useCustomForm: false,
                          useJobDescription:
                              optionalFields?.includes?.('jobDescription'),
                          useWorkArea: optionalFields?.includes?.('workArea')
                      }
        })
            .then(() => {
                enqueueSnackbar('The template has been updated.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                setSave(false);
            })
            .catch((err) => {
                enqueueSnackbar('Failed to update the template.', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });
    };

    return loading ? (
        <div className={styles.loading}>
            <LoadingOverlay label="Loading Pretask Template..." />
        </div>
    ) : error?.response?.status === 404 ? (
        <div className={styles.errorContainer}>
            <div className={styles.error}>404</div>
            <div className={styles.header}>Pretask Template Not Found</div>
            <div className={styles.message}>
                The pretask template you requested could not be found.
            </div>
            <div className={styles.link}>
                <Link to="/pretask/customize-forms">
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                    Back To Pretasks
                </Link>
            </div>
        </div>
    ) : (
        <div className={styles.container}>
            {save && (
                <div className={styles.save}>
                    <p className={styles.message}>You have unsaved changes</p>
                    <div>
                        <Button
                            label="Save"
                            icon={faSave}
                            onClick={handleSubmit}
                            className={styles.button}
                            loading={submissionLoading}
                        />
                    </div>
                </div>
            )}
            {
                <div className={styles.customize}>
                    <div className={styles.formType}>
                        <InputLabel label="Form Type">
                            <Dropdown
                                options={[
                                    {
                                        key: 'Custom Form',
                                        value: 'Custom Form'
                                    },
                                    { key: 'Form Upload', value: 'Form Upload' }
                                ]}
                                styles={{ container: { width: '200px' } }}
                                selected={formType}
                                handleSelect={handleFormType}
                                required={true}
                            />
                        </InputLabel>
                    </div>
                    {formType && (
                        <div className={styles.main}>
                            <div className={styles.leftColumn}>
                                <section>
                                    <div className={styles.header}>
                                        <h3>Header</h3>
                                    </div>
                                    <div className={styles.body}>
                                        <section>
                                            <div className={styles.header}>
                                                <h5>Optional Fields</h5>
                                            </div>
                                            <div className={styles.body}>
                                                <CheckboxGroup
                                                    orientation="column"
                                                    options={[
                                                        {
                                                            key: 'comments',
                                                            value: 'Show Comments'
                                                        },
                                                        {
                                                            key: 'workArea',
                                                            value: 'Show Work Area'
                                                        }
                                                    ]}
                                                    handleChange={
                                                        handleOptionalField
                                                    }
                                                    selected={optionalFields}
                                                    multiselect={true}
                                                />
                                            </div>
                                        </section>
                                        <section>
                                            <div className={styles.header}>
                                                <h5>Custom Fields</h5>
                                            </div>
                                            <div
                                                className={styles.body}
                                                style={{ margin: 0 }}
                                            >
                                                {customFields &&
                                                customFields.length > 0 ? (
                                                    <CustomFieldContainer
                                                        fields={customFields}
                                                        handleUpdate={
                                                            handleCustomField
                                                        }
                                                        handleReorder={
                                                            handleFieldReorder
                                                        }
                                                        handleRemove={
                                                            handleRemove
                                                        }
                                                    />
                                                ) : (
                                                    <div
                                                        className={
                                                            styles.noItemsMessage
                                                        }
                                                    >
                                                        This template does not
                                                        have any custom fields.
                                                    </div>
                                                )}
                                                <div
                                                    className={
                                                        styles.addCustomButton
                                                    }
                                                >
                                                    <Button
                                                        type="secondary"
                                                        variant="border"
                                                        label="Add Field"
                                                        onClick={handleAddField}
                                                    />
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </section>
                                {formType?.key === 'Custom Form' && (
                                    <section>
                                        <div className={styles.header}>
                                            <h3>Checklists</h3>
                                        </div>
                                        <div
                                            className={styles.body}
                                            style={{ margin: 0 }}
                                        >
                                            {checklists &&
                                            checklists.length > 0 ? (
                                                <CustomFieldContainer
                                                    fields={checklists}
                                                    Component={CustomChecklist}
                                                    handleUpdate={
                                                        handleChecklist
                                                    }
                                                    handleReorder={
                                                        handleChecklistReorder
                                                    }
                                                    handleRemove={handleRemove}
                                                />
                                            ) : (
                                                <div
                                                    className={
                                                        styles.noItemsMessage
                                                    }
                                                >
                                                    This template does not have
                                                    any checklists.
                                                </div>
                                            )}
                                            <div
                                                className={
                                                    styles.addCustomButton
                                                }
                                            >
                                                <Button
                                                    type="secondary"
                                                    variant="border"
                                                    label="Add Checklist"
                                                    onClick={handleAddChecklist}
                                                />
                                            </div>
                                        </div>
                                    </section>
                                )}
                            </div>
                            {formType?.key === 'Custom Form' && (
                                <div className={styles.rightColumn}>
                                    <section>
                                        <div className={styles.header}>
                                            <h3>Hazards</h3>
                                        </div>
                                        <div
                                            className={styles.body}
                                            style={{ margin: 0 }}
                                        >
                                            <div
                                                className={styles.headerSelect}
                                            >
                                                <Select
                                                    pagination={{
                                                        url: '/hazards'
                                                    }}
                                                    filter={{
                                                        IsStandard: {
                                                            value: false,
                                                            getFilter: (
                                                                isStandard
                                                            ) => ({
                                                                IsStandard:
                                                                    isStandard
                                                            })
                                                        }
                                                    }}
                                                    selected={hazards
                                                        .filter(
                                                            (h) =>
                                                                h.isRemovable &&
                                                                h.preexisting
                                                        )
                                                        .map((h) => ({
                                                            ...h,
                                                            id: h.hazardId
                                                        }))}
                                                    placeholder="Select Hazard(s)"
                                                    getRowValue={(row) =>
                                                        row?.description
                                                    }
                                                    getRowId={(row) => row.id}
                                                    multiselect
                                                    handleRowSelected={
                                                        handleHazardSelection
                                                    }
                                                    handleRowDeselected={
                                                        handleHazardDeselection
                                                    }
                                                    handleReset={handleReset}
                                                    ref={hazardsRef}
                                                />
                                            </div>
                                            {hazards && hazards.length > 0 ? (
                                                <CustomFieldContainer
                                                    fields={hazards}
                                                    Component={CustomHazard}
                                                    handleUpdate={handleHazard}
                                                    handleReorder={
                                                        handleHazardReorder
                                                    }
                                                    handleRemove={handleRemove}
                                                />
                                            ) : (
                                                <div
                                                    className={
                                                        styles.noItemsMessage
                                                    }
                                                >
                                                    This template does not have
                                                    any hazards.
                                                </div>
                                            )}
                                            <div
                                                className={
                                                    styles.addCustomButton
                                                }
                                            >
                                                <Button
                                                    type="secondary"
                                                    variant="border"
                                                    label="Add Hazard"
                                                    onClick={handleAddHazard}
                                                />
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            )}
                            {showDelete && (
                                <DeleteFieldModal
                                    message={showDelete?.message}
                                    handleClose={handleHideShowDelete}
                                    handleDelete={handleRemoveField.bind(
                                        this,
                                        showDelete
                                    )}
                                />
                            )}
                        </div>
                    )}
                </div>
            }
        </div>
    );
};

export default CustomizePretask;
