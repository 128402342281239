import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';

import useApi from '../../../hooks/useApi';
import useUser from '../../../hooks/useUser';

import {
    CertificationDeletePermissions,
    CertificationReviewPermissions,
    CertificationUpdatePermissions
} from '../../../js/services/permissions';
import ErrorCode from '../../config/ErrorBoundaries/ErrorCode';
import FileUpload from '../../general/input/FileUpload';
import LoadingOverlay from '../../general/LoadingOverlay';
import {
    faComments,
    faPencilAlt,
    faTimes,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import styles from '../../../styles/apps/certifications/ReviewCertificationDetail.module.scss';

const ReviewCertificationDetail = forwardRef(
    (
        {
            employeeCertificationId,
            handleClose,
            handleEdit,
            handleDelete,
            handleReview = () => null
        },
        ref
    ) => {
        //Retrieve user permissions
        const { userHasPermissions } = useUser();

        const [{ data, loading }, getEmployeeCertifications] = useApi(
            `/employee-certifications/${employeeCertificationId}`,
            'GET',
            { manual: true }
        );
        const [
            { data: attachments, loading: loadingAttachments },
            getAttachments
        ] = useApi(
            `/employee-certifications/${employeeCertificationId}/attachments`,
            'GET',
            { manual: true }
        );

        const [, setError] = useState(null);

        const stableGetEmployeeCertification = useRef(
            getEmployeeCertifications
        );
        const stableGetAttachments = useRef(getAttachments);

        useImperativeHandle(
            ref,
            () => ({
                refetch: () => {
                    if (!employeeCertificationId) return;

                    stableGetEmployeeCertification
                        .current({
                            url: `/employee-certifications/${employeeCertificationId}`
                        })
                        .catch((err) => {
                            setError(() => {
                                throw new ErrorCode(
                                    `Get Certification Attachments By ID (${employeeCertificationId})`,
                                    'E_ReviewCertification_Detail_0003',
                                    err?.message
                                );
                            });
                        });
                    stableGetAttachments
                        .current({
                            url: `/employee-certifications/${employeeCertificationId}/attachments`
                        })
                        .catch((err) => {
                            setError(() => {
                                throw new ErrorCode(
                                    `Get Certification Attachments By ID (${employeeCertificationId})`,
                                    'E_ReviewCertification_Detail_0004',
                                    err?.message
                                );
                            });
                        });
                }
            }),
            [employeeCertificationId]
        );

        useEffect(() => {
            if (employeeCertificationId == null) return;

            stableGetEmployeeCertification
                .current({
                    url: `/employee-certifications/${employeeCertificationId}`
                })
                .catch((err) => {
                    setError(() => {
                        throw new ErrorCode(
                            `Get Certification Attachments By ID (${employeeCertificationId})`,
                            'E_ReviewCertification_Detail_0001',
                            err?.message
                        );
                    });
                });
            stableGetAttachments
                .current({
                    url: `/employee-certifications/${employeeCertificationId}/attachments`
                })
                .catch((err) => {
                    setError(() => {
                        throw new ErrorCode(
                            `Get Certification Attachments By ID (${employeeCertificationId})`,
                            'E_ReviewCertification_Detail_0002',
                            err?.message
                        );
                    });
                });
        }, [employeeCertificationId]);

        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.close} onClick={handleClose}>
                        <FontAwesomeIcon icon={faTimes} />
                        <div className={styles.label}>Close</div>
                    </div>
                    {employeeCertificationId && !loading && (
                        <div className={styles.actions}>
                            {userHasPermissions(
                                CertificationReviewPermissions,
                                'or'
                            ) &&
                                data?.canReview && (
                                    <div
                                        className={styles.action}
                                        onClick={() =>
                                            handleReview(
                                                employeeCertificationId
                                            )
                                        }
                                    >
                                        <FontAwesomeIcon icon={faComments} />
                                    </div>
                                )}
                            {userHasPermissions(
                                CertificationUpdatePermissions
                            ) && (
                                <div
                                    className={styles.action}
                                    onClick={() =>
                                        handleEdit(employeeCertificationId)
                                    }
                                >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </div>
                            )}
                            {userHasPermissions(
                                CertificationDeletePermissions
                            ) && (
                                <div
                                    className={styles.action}
                                    onClick={() =>
                                        handleDelete(employeeCertificationId)
                                    }
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className={styles.body}>
                    <div className={styles.content}>
                        {employeeCertificationId ? (
                            <div className={styles.container}>
                                <div className={styles.section}>
                                    {loading ? (
                                        <div className={styles.loading}>
                                            <LoadingOverlay
                                                size="lg"
                                                spinColor="rgba(0,0,0,0.7)"
                                                backgroundColor="rgba(0,0,0,0.1)"
                                                label="Loading Certification..."
                                            />
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            <div className={styles.detailGroup}>
                                                <div
                                                    className={
                                                        styles.detailProperty
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.detailLabel
                                                        }
                                                    >
                                                        Certified Date
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.detailValue
                                                        }
                                                    >
                                                        {data?.certifiedOn &&
                                                            new Intl.DateTimeFormat(
                                                                'en-US',
                                                                {
                                                                    timeZone:
                                                                        'UTC',
                                                                    month: '2-digit',
                                                                    day: '2-digit',
                                                                    year: 'numeric'
                                                                }
                                                            ).format(
                                                                new Date(
                                                                    data?.certifiedOn
                                                                )
                                                            )}
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        styles.detailProperty
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.detailLabel
                                                        }
                                                    >
                                                        Expiration Date
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.detailValue
                                                        }
                                                    >
                                                        {data?.expiresOn &&
                                                            new Intl.DateTimeFormat(
                                                                'en-US',
                                                                {
                                                                    timeZone:
                                                                        'UTC',
                                                                    month: '2-digit',
                                                                    day: '2-digit',
                                                                    year: 'numeric'
                                                                }
                                                            ).format(
                                                                new Date(
                                                                    data?.expiresOn
                                                                )
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.detailGroup}>
                                                <div
                                                    className={
                                                        styles.detailProperty
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.detailLabel
                                                        }
                                                    >
                                                        Submitted By
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.detailValue
                                                        }
                                                    >
                                                        {data?.submitterName}
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        styles.detailProperty
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.detailLabel
                                                        }
                                                    >
                                                        Certification
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.detailValue
                                                        }
                                                    >
                                                        {data?.description}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.detailGroup}>
                                                <div
                                                    className={
                                                        styles.detailProperty
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.detailLabel
                                                        }
                                                    >
                                                        Employee
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.detailValue
                                                        }
                                                    >
                                                        {data?.firstName}{' '}
                                                        {data?.middleName}{' '}
                                                        {data?.lastName}
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        styles.detailProperty
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.detailLabel
                                                        }
                                                    >
                                                        Division
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.detailValue
                                                        }
                                                    >
                                                        {data?.division}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.detailGroup}>
                                                <div
                                                    className={
                                                        styles.detailProperty
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.detailLabel
                                                        }
                                                    >
                                                        Reviewer
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.detailValue
                                                        }
                                                    >
                                                        {data?.reviewerName}
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        styles.detailProperty
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.detailLabel
                                                        }
                                                    >
                                                        Verifier
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.detailValue
                                                        }
                                                    >
                                                        {data?.verifierName}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.detailGroup}>
                                                <div
                                                    className={
                                                        styles.detailProperty
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.detailLabel
                                                        }
                                                    >
                                                        Status
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.detailValue
                                                        }
                                                    >
                                                        {data?.status}
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                                <div className={styles.section}>
                                    <div className={styles.detailGroup}>
                                        <div className={styles.detailProperty}>
                                            <div className={styles.detailLabel}>
                                                Attachments
                                            </div>
                                            {loadingAttachments ? (
                                                <LoadingOverlay
                                                    size="sm"
                                                    spinColor="rgba(0,0,0,0.5)"
                                                    backgroundColor="rgba(0,0,0,0.1)"
                                                    label="Loading Attachments..."
                                                    labelPosition="right"
                                                    fontSize="12px"
                                                />
                                            ) : (
                                                <div>
                                                    <FileUpload
                                                        files={
                                                            attachments
                                                                ? attachments.map(
                                                                      (
                                                                          attachment
                                                                      ) => ({
                                                                          ...attachment,
                                                                          download: `/employee-certifications/${employeeCertificationId}/attachments/${attachment.id}/download`,
                                                                          preview: `/employee-certifications/${employeeCertificationId}/attachments/${attachment.id}/preview`
                                                                      })
                                                                  )
                                                                : []
                                                        }
                                                        emptyMessage="No files attached."
                                                        readOnly={true}
                                                        downloadSingle={true}
                                                        downloadAll={`/employee-certifications/${employeeCertificationId}/attachments/download`}
                                                        overflow={true}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className={[
                                    styles.container,
                                    styles.noSelection
                                ].join(' ')}
                            >
                                <div className={styles.message}>
                                    Please click an employee certification to
                                    see its details.
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
);

ReviewCertificationDetail.propTypes = {
    employeeCertificationId: PropTypes.number,
    handleClose: PropTypes.func,
    handleEdit: PropTypes.func,
    handleDelete: PropTypes.func
};

export default ReviewCertificationDetail;
