import PropTypes from 'prop-types';

import styles from '../../../styles/general/skeleton/TabSkeleton.module.scss';

const TabSkeleton = ({ count = 3 }) => (
    <div className={styles.container}>
        {Array.from({ length: count }, (_, index) => (
            <div
                className={styles.tab}
                style={{ width: `${Math.random() * 50 + 100}px` }}
                key={index}
            />
        ))}
    </div>
);

TabSkeleton.propTypes = {
    count: PropTypes.number
};

export default TabSkeleton;
