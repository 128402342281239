import { useCallback, useMemo, useReducer } from 'react';

import filterReducer from '../js/reducers/filterReducer';
import useLocalStorage from './useLocalStorage';

const useFilterReducer = (state, filterKey) => {
    let stateKeys = Object.keys(state);
    let [defaultFilters, setDefaultFilters] = useLocalStorage(filterKey, {});
    let defaultFilterKeys = Object.keys(defaultFilters);

    const [filter, dispatchFilter] = useReducer(
        filterReducer,
        stateKeys.reduce((acc, cur) => {
            return {
                ...acc,
                [cur]: {
                    value: defaultFilterKeys.includes(cur)
                        ? defaultFilters[cur]
                        : state[cur].value,
                    getFilter: state[cur].getFilter
                }
            };
        }, {})
    );

    const defaultState = useMemo(() => state, [state]);

    const setFilter = useCallback(
        ({ key, payload }) => {

            if (filterKey) {
                setDefaultFilters({
                    ...defaultFilters,
                    [key]: payload
                })
            }

            dispatchFilter({
                type: 'updateFilter',
                key,
                payload
            });

        },
        [dispatchFilter, defaultFilters, filterKey, setDefaultFilters]
    );

    const resetFilter = useCallback(() => {
        if (filterKey) setDefaultFilters({});
        dispatchFilter({
            type: 'resetFilter',
            payload: defaultState
        });
    }, [dispatchFilter, defaultState, filterKey, setDefaultFilters]);

    return { filter, setFilter, resetFilter, defaultState, setDefaultFilters };
};

export default useFilterReducer;
