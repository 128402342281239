import React, { useEffect, useState } from 'react';

import { SuggestionBoxReadPermissions } from '../../../js/services/permissions';
import Tabs from '../../navigation/Tabs';
import PropTypes from 'prop-types';
import { Outlet, useMatch } from 'react-router-dom';

import styles from '../../../styles/apps/suggestion_box/SuggestionBox.module.scss';

const tabs = [
    {
        key: 'submit',
        label: 'Suggestion Submission',
        link: '/suggestionbox/submit'
    },
    {
        key: 'review',
        label: 'Suggestion Review',
        permission: SuggestionBoxReadPermissions,
        condition: 'or',
        link: '/suggestionbox/review'
    }
];

const SuggestionBox = () => {
    const match = useMatch({
        path: '/suggestionbox/:page',
        end: false
    });

    const [tab, setTab] = useState(match?.params?.page ?? 'submit');

    const handleTabChange = (tab) => setTab(tab);

    useEffect(() => {
        document.title = 'CVE Apps - Suggestion Box';
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.tabs}>
                <Tabs tabs={tabs} active={tab} onChange={handleTabChange} />
            </div>
            <Outlet />
        </div>
    );
};

SuggestionBox.propTypes = {
    auth: PropTypes.bool
};

export default SuggestionBox;
