import React from 'react';

import useUser from '../../hooks/useUser';

import user from '../../assets/user.png';
import { logout } from '../../js/services/auth';
import DropdownMenu from '../general/DropdownMenu';
import {
    faChevronDown,
    faChevronUp,
    faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../../styles/navigation/Profile.module.scss';

const Profile = () => {
    const { displayName, image, mail } = useUser();

    return displayName ? (
        <DropdownMenu
            button={(toggled) => (
                <div className={styles.profileContainer}>
                    <div className={styles.profileImageContainer}>
                        <img
                            src={
                                image ? `data:image/png;base64,${image}` : user
                            }
                            alt={'User'}
                        />
                    </div>
                    <div className={styles.profileName}>{displayName}</div>
                    <div className={styles.profileDropdownIndicator}>
                        <FontAwesomeIcon
                            icon={toggled ? faChevronUp : faChevronDown}
                        />
                    </div>
                </div>
            )}
            content={() => (
                <div className={styles.profileDropdownContainer}>
                    <div className={styles.profileDropdownImage}>
                        <img
                            src={
                                image ? `data:image/png;base64,${image}` : user
                            }
                            alt={'User'}
                        />
                    </div>
                    <div className={styles.profileDropdownOptions}>
                        <div className={styles.profileOptionName}>
                            {displayName}
                        </div>
                        <div className={styles.profileOptionEmail}>{mail}</div>
                        <div className={styles.profileOptionHelp}>
                            <a href="mailto:helpdesk@cve.com">Help</a>
                        </div>
                        <div className={styles.profileOptionFeedback}>
                            <a href="mailto:helpdesk@cve.com">Feedback</a>
                        </div>
                    </div>
                    <div
                        className={styles.profileDropdownLogout}
                        onClick={logout}
                    >
                        <div className={styles.logoutIcon}>
                            <FontAwesomeIcon icon={faSignOutAlt} />
                        </div>
                        <div>Logout</div>
                    </div>
                </div>
            )}
            style={{
                container: {
                    height: '100%'
                },
                buttonContainer: {
                    height: '100%'
                },
                menuContainer: {
                    boxShadow: '1px 1px 12px 4px rgba(0,0,0,0.14)',
                    width: 'auto'
                }
            }}
            orientation="left"
            width="236px"
        />
    ) : null;
};

export default Profile;
