import { useRef, useState } from 'react';

import useFilterReducer from '../../../../hooks/useFilterReducer';
import useUser from '../../../../hooks/useUser';

import { QualityAttachmentTagPermissions } from '../../../../js/services/permissions';
import ActionCell from '../../../general/grid/cell renderers/ActionCell';
import TextCell from '../../../general/grid/cell renderers/TextCell';
import Filters from '../../../general/grid/Filters';
import Grid from '../../../general/grid/Grid';
import DateRange from '../../../general/input/DateRange';
import TextInput from '../../../general/input/TextInput';
import DeleteQualityTagModal from '../modal/DeleteQualityTagModal';
import EditQualityTagModal from '../modal/EditQualityTagModal';
import NewQualityTagModal from '../modal/NewQualityTagModal';
import {
    faFilter,
    faPencilAlt,
    faPlus,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useOutletContext } from 'react-router-dom';

import styles from '../../../../styles/apps/quality/grid/QualityGrid.module.scss';
import useLocalStorage from '../../../../hooks/useLocalStorage';

const defaultFilterState = {
    date: {
        value: {
            to: null,
            from: null
        },
        getFilter: (value) => {
            if (!value.to && !value.from) return null;
            let filterObj = {};
            if (value.to) filterObj['DateTo'] = value.to;
            if (value.from) filterObj['DateFrom'] = value.from;
            return filterObj;
        }
    },
    tagName: {
        value: '',
        getFilter: (name) => {
            return name === '' || name === null ? null : { Name: name };
        }
    }
};

const TagGrid = () => {
    const gridRef = useRef();
    const { filter, setFilter, resetFilter } = useFilterReducer(
        defaultFilterState,
        'quality_tag_grid_filters'
    );
    const { job } = useOutletContext();
    const { userHasJobPermissions, userHasPermissions } = useUser();
    const navigate = useNavigate();
    const [, setEquipmentFilter] = useLocalStorage('quality_equipment_grid_filters');
    const [, setFormFilter] = useLocalStorage('quality_form_grid_filters');
    const [, setAttachmentFilter] = useLocalStorage('quality_attachment_grid_filters');

    const [modals, setModals] = useState({
        new: false,
        edit: false,
        delete: false
    });

    const clearFilters = () => resetFilter();

    const handleFilterChange = (key, value) =>
        setFilter({
            key: key,
            payload: value
        });

    const handleDateChange = (key, date) =>
        setFilter({
            key: 'date',
            payload: {
                ...filter['date'].value,
                [key]: date
            }
        });

    const handleOpenModal = (type, value) => {
        setModals((modals) => ({
            ...modals,
            [type]: value
        }));
    };

    const handleCloseModal = (type) => {
        setModals((modals) => ({
            ...modals,
            [type]: false
        }));
    };

    const handleNewTag = (tags) => {
        gridRef?.current?.addRow(tags);
    };

    const handleEditTag = (id, tag) => {
        gridRef?.current?.modifyRow(id, tag);
    };

    const handleDeleteTag = (id) => {
        gridRef?.current?.removeRow(id);
    };

    const handleNavigateToEquipment = ({ id, name }) => {
        setEquipmentFilter({
            tags: [{
                id,
                name
            }]
        });
        navigate('../equipment');
    };
    const handleNavigateToEquipmentForms = ({ id, name }) => {
        setFormFilter({
            tags: [{
                id,
                name
            }]
        });
        navigate('../forms');
    };
    const handleNavigateToAttachments = ({ id, name }) => {
        setAttachmentFilter({
            tags: [{
                id,
                name
            }]
        });
        navigate('../attachments');
    };

    return (
        <div
            style={{
                width: '100%',
                height: '100%'
            }}
        >
            <Grid
                ref={gridRef}
                filters={filter}
                actions={[
                    userHasJobPermissions(
                        QualityAttachmentTagPermissions,
                        'or'
                    ) ||
                    userHasPermissions(QualityAttachmentTagPermissions, 'or')
                        ? {
                              type: 'primary',
                              label: 'New',
                              icon: faPlus,
                              onClick: handleOpenModal.bind(this, 'new')
                          }
                        : null
                ]}
                fixed
                multiselect
                rowSelect
                handleRowSelection={() => null}
                selected={[]}
                getRowId={(r) => r.id}
                pagination={{
                    url: `/quality/${job.id}/tags`,
                    record: job.id,
                    pageSize: 100
                }}
                columns={[
                    {
                        title: 'Created On',
                        key: 'createdOn',
                        dataKey: 'createdOn',
                        sortKey: 'createdOn',
                        sortable: true,
                        width: 120,
                        minWidth: 120,
                        cellRenderer: ({ cellData }) =>
                            cellData && (
                                <TextCell>
                                    {cellData &&
                                        new Intl.DateTimeFormat('en-US', {
                                            timeZone: 'UTC',
                                            month: '2-digit',
                                            day: '2-digit',
                                            year: 'numeric'
                                        }).format(new Date(cellData))}
                                </TextCell>
                            )
                    },
                    {
                        title: 'Tag Name',
                        key: 'tagname',
                        dataKey: 'name',
                        sortKey: 'name',
                        sortable: true,
                        width: 300,
                        minWidth: 300,
                        fixedGrow: 1,
                        cellRenderer: ({ cellData }) => (
                            <TextCell>{cellData}</TextCell>
                        )
                    },
                    {
                        title: 'Equipment',
                        key: 'equipment',
                        sortKey: 'equipment',
                        sortable: true,
                        width: 120,
                        minWidth: 120,
                        cellRenderer: ({ rowData }) => (
                            <TextCell>
                                {rowData.equipmentIds?.length ? (
                                    <p
                                        onClick={handleNavigateToEquipment.bind(
                                            this,
                                            rowData
                                        )}
                                        className={styles.attachmentLink}
                                    >{`${rowData.equipmentIds?.length} equipment`}</p>
                                ) : (
                                    ''
                                )}
                            </TextCell>
                        )
                    },
                    {
                        title: 'Forms',
                        key: 'forms',
                        sortKey: 'forms',
                        sortable: true,
                        width: 120,
                        minWidth: 120,
                        cellRenderer: ({ rowData }) => (
                            <TextCell>
                                {rowData.equipmentFormIds?.length ? (
                                    <p
                                        onClick={handleNavigateToEquipmentForms.bind(
                                            this,
                                            rowData
                                        )}
                                        className={styles.attachmentLink}
                                    >{`${rowData.equipmentFormIds?.length} forms`}</p>
                                ) : (
                                    ''
                                )}
                            </TextCell>
                        )
                    },
                    {
                        title: 'Attachments',
                        key: 'attachments',
                        sortKey: 'attachments',
                        sortable: true,
                        width: 120,
                        minWidth: 120,
                        cellRenderer: ({ rowData }) => (
                            <TextCell>
                                {rowData.attachmentIds?.length ? (
                                    <p
                                        onClick={handleNavigateToAttachments.bind(
                                            this,
                                            rowData
                                        )}
                                        className={styles.attachmentLink}
                                    >{`${rowData.attachmentIds?.length} attachments`}</p>
                                ) : (
                                    ''
                                )}
                            </TextCell>
                        )
                    },
                    ...(userHasJobPermissions(
                        QualityAttachmentTagPermissions,
                        'or'
                    ) ||
                    userHasPermissions(QualityAttachmentTagPermissions, 'or')
                        ? [
                              {
                                  title: ' ',
                                  key: 'actions',
                                  sortable: false,
                                  width: 80,
                                  minWidth: 80,
                                  cellRenderer: ({ rowData }) => (
                                      <ActionCell
                                          actions={
                                              userHasJobPermissions(
                                                  QualityAttachmentTagPermissions,
                                                  'or'
                                              ) ||
                                              userHasPermissions(
                                                  QualityAttachmentTagPermissions,
                                                  'or'
                                              )
                                                  ? [
                                                        {
                                                            icon: faPencilAlt,
                                                            type: 'grayscale',
                                                            onClick:
                                                                handleOpenModal.bind(
                                                                    this,
                                                                    'edit',
                                                                    rowData
                                                                ),
                                                            tooltip: {
                                                                tooltip: 'Edit',
                                                                hoverDelay: 650,
                                                                hoverTrigger:
                                                                    'always'
                                                            }
                                                        },
                                                        {
                                                            icon: faTrashAlt,
                                                            type: 'grayscale',
                                                            onClick:
                                                                handleOpenModal.bind(
                                                                    this,
                                                                    'delete',
                                                                    rowData
                                                                ),
                                                            tooltip: {
                                                                tooltip:
                                                                    'Delete',
                                                                hoverDelay: 650,
                                                                hoverTrigger:
                                                                    'always'
                                                            }
                                                        }
                                                    ]
                                                  : []
                                          }
                                      />
                                  )
                              }
                          ]
                        : [])
                ]}
                sidepanel={{
                    filters: {
                        label: 'Filters',
                        icon: faFilter,
                        component: Filters,
                        props: {
                            clearFilters: clearFilters,
                            filters: [
                                {
                                    label: 'Tag Name',
                                    component: TextInput,
                                    props: {
                                        placeholder: 'Tag Name',
                                        onChange: (e) =>
                                            handleFilterChange(
                                                'tagName',
                                                e.target.value
                                            ),
                                        value: filter.tagName?.value
                                    }
                                },
                                {
                                    label: 'Created Date',
                                    component: DateRange,
                                    props: {
                                        to: filter.date?.value.to
                                            ? new Date(filter.date.value.to)
                                            : null,
                                        from: filter.date?.value.from
                                            ? new Date(filter.date.value.from)
                                            : null,
                                        handleChange: handleDateChange
                                    }
                                }
                            ]
                        }
                    }
                }}
            />
            {modals.new && (
                <NewQualityTagModal
                    handleClose={handleCloseModal.bind(this, 'new')}
                    handleNew={handleNewTag}
                    job={job?.id}
                />
            )}
            {modals.edit && (
                <EditQualityTagModal
                    handleClose={handleCloseModal.bind(this, 'edit')}
                    handleEdit={handleEditTag}
                    tag={modals.edit}
                />
            )}
            {modals.delete && (
                <DeleteQualityTagModal
                    handleClose={handleCloseModal.bind(this, 'delete')}
                    handleDelete={handleDeleteTag}
                    tag={modals.delete}
                />
            )}
        </div>
    );
};

export default TagGrid;
