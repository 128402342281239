import React from 'react';

import Checkbox from '../../general/input/Checkbox';
import { v4 as uuid } from 'uuid';

import styles from '../../../styles/apps/permissions/PermissionGroup.module.scss';

const PermissionGroup = ({
    group = {},
    level = 0,
    checkedPermissions = [],
    permissionChecked = () => null,
    disabled = false
}) => {
    return (
        group &&
        Object.keys(group)
            .sort((a, b) => {
                if (typeof group[a] === typeof group[b]) {
                    return a > b ? 1 : a < b ? -1 : 0;
                } else if (typeof group[a] === 'number') {
                    return -1;
                } else {
                    return 1;
                }
            })
            .map((permission) => (
                <div
                    className={
                        level === 0
                            ? styles.permissionSection
                            : styles.permissionContainer
                    }
                    key={uuid()}
                >
                    <label
                        className={[
                            level === 0
                                ? styles.header
                                : level === 1
                                ? styles.subheader
                                : typeof group[permission] === 'object'
                                ? styles.categoryHeader
                                : styles.category
                        ].join(' ')}
                    >
                        {typeof group[permission] === 'number' ? (
                            <Checkbox
                                checked={checkedPermissions?.includes?.(
                                    group[permission]
                                )}
                                handleChange={permissionChecked.bind(
                                    this,
                                    group[permission],
                                    !checkedPermissions?.includes?.(
                                        group[permission]
                                    )
                                )}
                                disabled={disabled}
                                style={{ fontSize: '16px' }}
                            />
                        ) : null}
                        <p>{permission}</p>
                    </label>
                    {typeof group[permission] === 'object' ? (
                        <PermissionGroup
                            group={group[permission]}
                            checkedPermissions={checkedPermissions}
                            level={level + 1}
                            permissionChecked={permissionChecked}
                            disabled={disabled}
                        />
                    ) : null}
                </div>
            ))
    );
};

export default PermissionGroup;
