import { useEffect, useRef, useState } from 'react';

import useApi from '../../../../hooks/useApi';
import useUser from '../../../../hooks/useUser';

import { STATES } from '../../../../js/services/constants';
import { ContactsCustodianUpdatePermissions } from '../../../../js/services/permissions';
import ErrorMessage from '../../../general/ErrorMessage';
import Button from '../../../general/input/Button';
import CheckboxGroup from '../../../general/input/CheckboxGroup';
import Chip from '../../../general/input/Chip';
import Dropdown from '../../../general/input/Dropdown';
import InputLabel from '../../../general/input/InputLabel';
import Select from '../../../general/input/Select';
import TextInput from '../../../general/input/TextInput';
import LoadingOverlay from '../../../general/LoadingOverlay';
import LoadingModal from '../../../general/modal/LoadingModal';
import Modal from '../../../general/modal/Modal';
import { TextTooltip } from '../../../general/Tooltip';
import Tabs from '../../../navigation/Tabs';
import ContactTextInput from '../ContactTextInput';
import NewCompanyModal from './NewCompanyModal';
import { faPencil, faPlus, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { difference, isNumber, uniqueId } from 'lodash';
import { useSnackbar } from 'notistack';
import {
    Controller,
    FormProvider,
    useFieldArray,
    useForm
} from 'react-hook-form';

import styles from '../../../../styles/apps/contact/modal/EditContactModal.module.scss';

const EditContactModal = ({ handleClose, handleEdit, record, ...props }) => {
    const submitButtonRef = useRef();

    const { displayName, userHasPermissions } = useUser();

    const [newCompany, setNewCompany] = useState(false);
    const [tab, setTab] = useState('phones');

    const [{ loading }, updateContact] = useApi('/contact', 'PUT', {
        manual: true
    });
    const [{ data: contact, loading: contactLoading }, getContact] = useApi(
        '/contact',
        'GET',
        { manual: true }
    );

    const [{ loading: loadingFlags, data: flags }] = useApi(
        `/contact/flag?filter=${JSON.stringify({ ShowDeleted: false })}`,
        'GET',
        { manual: false }
    );

    const [{ loading: loadingCategories, data: categories }] = useApi(
        '/contact/categories',
        'GET',
        { manual: false }
    );

    const { enqueueSnackbar } = useSnackbar();
    const {
        handleSubmit,
        control,
        getValues,
        setError,
        setValue,
        formState: { errors },
        ...methods
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            streetAddress: '',
            city: '',
            state: null,
            zip: '',
            email: '',
            address: '',
            name: '',
            company: [],
            categories: [],
            categoryPrimary: null,
            flags: [],
            phonePrimary: null,
            custodians: []
        }
    });

    const {
        fields: phoneFields,
        append: appendPhone,
        remove: removePhone,
        replace: replacePhone
    } = useFieldArray({
        name: 'phoneFields',
        control
    });

    useEffect(() => {
        getContact({
            url: `/contact/${record?.id}`
        })
            .then((contact) => {
                setValue('company', [contact.company]);
                setValue(
                    'flags',
                    contact.flags.map((f) => f.id)
                );
                setValue(
                    'custodians',
                    contact.users.map((u) => ({
                        ...u,
                        id: u.id
                    }))
                );
                setValue(
                    'categories',
                    contact.categories.map((c) => ({
                        ...c,
                        key: c.categoryId,
                        value: c.name
                    }))
                );
                setValue(
                    'categoryPrimary',
                    contact.categories?.find?.((c) => c.isPrimary)?.categoryId
                );
                setValue('name', contact.name);
                setValue('email', contact.email);
                setValue('address', contact.address);
                setValue('city', contact.city);
                setValue('streetAddress', contact.streetAddress);
                setValue(
                    'state',
                    contact.state
                        ? {
                              key: contact.state,
                              value: contact.state
                          }
                        : null
                );
                setValue('zip', contact.zip);
                setValue(
                    'phonePrimary',
                    contact.phones?.find?.((p) => p.isPrimary)?.id
                );
                replacePhone(contact.phones.filter((p) => !p.isDeleted));
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Could not retrieve contact.', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });
    }, []); //eslint-disable-line

    const handleSave = (data) => {
        const oldPhoneIds = contact?.phones?.map((p) => p.id);
        const deletedPhoneIds = difference(
            oldPhoneIds,
            data.phoneFields?.map((pf) => pf.id)
        );
        const phoneFields = data.phoneFields?.map((pf) => ({
            ...pf,
            isPrimary: data.phonePrimary === pf.id
        }));
        const contactCategories = data.categories?.map?.((c) => ({
            ...c,
            categoryId: c.key ?? c.categoryId,
            isPrimary: data.categoryPrimary === c.key ?? c.categoryId
        }));
        const userIds = data.custodians?.map?.((m) => m.id ?? m.key);
        const companyId = data.company?.[0]?.id;

        const newContactPhones = phoneFields
            ?.filter((pf) => !isNumber(pf.id))
            .map((pf) => ({
                ...pf,
                id: null
            }));

        const updatedContactPhones = phoneFields?.filter((pf) => {
            if (!isNumber(pf.id)) return false;
            const oldPhone = contact?.phones?.find?.((p) => p.id === pf.id);
            return (
                oldPhone.phone !== pf.phone ||
                oldPhone.isPrimary !== pf.isPrimary
            );
        });

        updateContact({
            url: `/contact/${record?.id}`,
            data: {
                companyId,
                deletedPhoneIds,
                newContactPhones,
                updatedContactPhones,
                flagIds: data.flags,
                contactCategories,
                userIds,
                name: data.name,
                email: data.email,
                city: data.city,
                state: data.state?.key,
                streetAddress: data.streetAddress,
                zip: data.zip
            }
        })
            .then((contact) => {
                handleEdit(record?.id, contact);
                enqueueSnackbar('Contact updated successfully.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                handleClose();
            })
            .catch((err) => {
                console.error(err);
                err.response?.data?.detail &&
                    setError('root.serverError', {
                        type: 'custom',
                        message: err.response?.data?.detail
                    });
                enqueueSnackbar(
                    err.response?.data?.title ??
                        'Error encountered while updating contact.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000
                    }
                );
            });
    };

    const handleNewCompany = () => setNewCompany(true);
    const handleCancelNewCompany = () => setNewCompany(false);
    const handleNewCompanyCreation = (company) =>
        setValue('company', [company]);

    const handleAddPhone = () => {
        let id = uniqueId('phone');
        if (!phoneFields || phoneFields.length === 0)
            setValue('phonePrimary', id);
        appendPhone({
            id: id,
            phone: ''
        });
    };

    const handleSubmitClicked = () => submitButtonRef?.current?.click?.();

    return contactLoading ? (
        <LoadingModal message="Loading Contact..." handleClose={handleClose} />
    ) : !newCompany ? (
        <Modal open handleClose={handleClose} blocking {...props}>
            <Modal.Title icon={faPencil}>Edit Contact</Modal.Title>
            <Modal.Body>
                <FormProvider
                    handleSubmit={handleSubmit}
                    control={control}
                    {...methods}
                >
                    <form
                        autoComplete="true"
                        onSubmit={handleSubmit(handleSave)}
                        noValidate={true}
                        className={styles.form}
                    >
                        <div className={styles.row}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: 'You must provide a contact name.'
                                }}
                                render={({
                                    field: { value, onChange, ref },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Contact"
                                        required
                                        error={error?.message}
                                    >
                                        <TextInput
                                            autoFocus
                                            placeholder="Contact Name"
                                            value={value}
                                            onChange={onChange}
                                            inputRef={ref}
                                            name="Contact"
                                            autoComplete="off"
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div className={styles.row}>
                            <Controller
                                name="company"
                                control={control}
                                rules={{
                                    required: 'You must select a company.'
                                }}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <>
                                        <InputLabel
                                            label="Company"
                                            required
                                            error={error?.message}
                                        >
                                            <Select
                                                placeholder="Select Company"
                                                selected={value}
                                                pagination={{
                                                    url: `/contact/company`
                                                }}
                                                getRowValue={(row) => row?.name}
                                                handleRowSelection={onChange}
                                                sort={['name']}
                                            />
                                        </InputLabel>
                                        <div className={styles.actionContainer}>
                                            <span
                                                tabIndex={0}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleNewCompany();
                                                    }
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                                onClick={handleNewCompany}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                />
                                                <p>New Company</p>
                                            </span>
                                        </div>
                                    </>
                                )}
                            />
                        </div>
                        <div className={styles.row}>
                            <Controller
                                name="custodians"
                                control={control}
                                rules={{
                                    validate: (value) =>
                                        !userHasPermissions(
                                            ContactsCustodianUpdatePermissions
                                        ) ||
                                        (value.length && value.length !== 0) ||
                                        'You must specify a custodian.'
                                }}
                                render={({
                                    field: { value: users, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Custodians"
                                        required
                                        error={error?.message}
                                    >
                                        {userHasPermissions(
                                            ContactsCustodianUpdatePermissions
                                        ) && (
                                            <Select
                                                pagination={{
                                                    url: '/user/app'
                                                }}
                                                placeholder="Select Users"
                                                selected={users}
                                                getRowValue={(row) =>
                                                    row.displayName
                                                }
                                                multiselect
                                                handleRowSelection={onChange}
                                                sort={['name']}
                                            />
                                        )}
                                        {(users && users.length > 0) ||
                                        userHasPermissions(
                                            ContactsCustodianUpdatePermissions
                                        ) ? (
                                            <div
                                                className={styles.chipContainer}
                                            >
                                                {users.map?.((u) => (
                                                    <Chip
                                                        label={u.displayName}
                                                        id={u.id}
                                                        removeable={userHasPermissions(
                                                            ContactsCustodianUpdatePermissions
                                                        )}
                                                        handleRemove={(id) =>
                                                            onChange(
                                                                users.filter(
                                                                    (u) =>
                                                                        u.id !==
                                                                        id
                                                                )
                                                            )
                                                        }
                                                    />
                                                ))}
                                            </div>
                                        ) : (
                                            <Chip label={displayName} />
                                        )}
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div className={styles.row}>
                            <Controller
                                name="categories"
                                control={control}
                                rules={{
                                    required: 'You must select a division.'
                                }}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <>
                                        <InputLabel
                                            label="Division(s)"
                                            required
                                            error={error?.message}
                                        >
                                            <Dropdown
                                                loading={loadingCategories}
                                                placeholder="Select Divisions"
                                                options={categories?.map(
                                                    (c) => ({
                                                        ...c,
                                                        key: c.id,
                                                        categoryId: c.id,
                                                        value: c.name
                                                    })
                                                )}
                                                selected={value}
                                                multiselect
                                                handleSelect={(selection) => {
                                                    if (
                                                        !selection.some?.(
                                                            (s) =>
                                                                s.categoryId ===
                                                                getValues(
                                                                    'categoryPrimary'
                                                                )
                                                        )
                                                    ) {
                                                        setValue(
                                                            'categoryPrimary',
                                                            selection?.[0]
                                                                ?.categoryId
                                                        );
                                                    }
                                                    onChange(selection);
                                                }}
                                            />
                                        </InputLabel>
                                        <Controller
                                            name="categoryPrimary"
                                            control={control}
                                            rules={{
                                                required:
                                                    'You must select a primary division.'
                                            }}
                                            render={({
                                                field: {
                                                    value: primaryCategory,
                                                    onChange:
                                                        primaryCategoryChange
                                                }
                                            }) => (
                                                <div
                                                    className={
                                                        styles.chipContainer
                                                    }
                                                >
                                                    {value
                                                        ?.sort?.((a, b) =>
                                                            a.value?.localeCompare(
                                                                b?.value
                                                            )
                                                        )
                                                        ?.map((v) => (
                                                            <Chip
                                                                left={(
                                                                    props
                                                                ) => (
                                                                    <div
                                                                        {...props}
                                                                        className={[
                                                                            props.className,
                                                                            styles.primaryCategoryIcon,
                                                                            primaryCategory ===
                                                                            v.categoryId
                                                                                ? styles.primaryCategory
                                                                                : null
                                                                        ].join(
                                                                            ' '
                                                                        )}
                                                                    >
                                                                        <TextTooltip
                                                                            hoverTrigger="always"
                                                                            hoverDelay={
                                                                                1000
                                                                            }
                                                                            tooltip={
                                                                                primaryCategory ===
                                                                                v.id
                                                                                    ? 'Primary Division'
                                                                                    : 'Set As Primary'
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                icon={
                                                                                    faStar
                                                                                }
                                                                                onClick={primaryCategoryChange.bind(
                                                                                    this,
                                                                                    v.categoryId
                                                                                )}
                                                                            />
                                                                        </TextTooltip>
                                                                    </div>
                                                                )}
                                                                label={v.value}
                                                                key={v.key}
                                                                id={v.key}
                                                                removeable
                                                                handleRemove={(
                                                                    id
                                                                ) => {
                                                                    let newCategories =
                                                                        value.filter(
                                                                            (
                                                                                v
                                                                            ) =>
                                                                                v.categoryId !==
                                                                                id
                                                                        );
                                                                    if (
                                                                        primaryCategory ===
                                                                        id
                                                                    ) {
                                                                        primaryCategoryChange(
                                                                            newCategories?.[0]
                                                                                ?.categoryId
                                                                        );
                                                                    }
                                                                    onChange(
                                                                        newCategories
                                                                    );
                                                                }}
                                                            />
                                                        ))}
                                                </div>
                                            )}
                                        />
                                    </>
                                )}
                            />
                        </div>
                        <div className={styles.row}>
                            <Controller
                                name="email"
                                control={control}
                                render={({
                                    field: { value, onChange, ref },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Email"
                                        error={error?.message}
                                    >
                                        <TextInput
                                            autoFocus
                                            placeholder="Email Address"
                                            value={value}
                                            onChange={onChange}
                                            inputRef={ref}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div
                            className={styles.row}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px'
                            }}
                        >
                            <div style={{ flex: 1 }}>
                                <Controller
                                    name="streetAddress"
                                    control={control}
                                    render={({
                                        field: { value, onChange, ref },
                                        fieldState: { error }
                                    }) => (
                                        <InputLabel
                                            label="Street Address"
                                            error={error?.message}
                                        >
                                            <TextInput
                                                placeholder="Street Address"
                                                value={value}
                                                onChange={onChange}
                                                inputRef={ref}
                                                autoComplete="street-address"
                                            />
                                        </InputLabel>
                                    )}
                                />
                            </div>
                            <div style={{ flex: 1 }}>
                                <Controller
                                    name="city"
                                    control={control}
                                    render={({
                                        field: { value, onChange, ref },
                                        fieldState: { error }
                                    }) => (
                                        <InputLabel
                                            label="City"
                                            error={error?.message}
                                        >
                                            <TextInput
                                                placeholder="City"
                                                value={value}
                                                onChange={onChange}
                                                inputRef={ref}
                                            />
                                        </InputLabel>
                                    )}
                                />
                            </div>
                            <div style={{ width: '80px' }}>
                                <Controller
                                    name="state"
                                    control={control}
                                    render={({
                                        field: { value, onChange },
                                        fieldState: { error }
                                    }) => (
                                        <InputLabel
                                            label="State"
                                            error={error?.message}
                                            style={{
                                                container: {
                                                    width: '80px'
                                                }
                                            }}
                                        >
                                            <Dropdown
                                                selected={value}
                                                placeholder="State"
                                                options={STATES}
                                                handleSelect={onChange}
                                            />
                                        </InputLabel>
                                    )}
                                />
                            </div>
                        </div>
                        <div
                            className={styles.row}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px'
                            }}
                        >
                            <Controller
                                name="zip"
                                control={control}
                                render={({
                                    field: { value, onChange, ref },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Zip code"
                                        error={error?.message}
                                    >
                                        <TextInput
                                            placeholder="Zip code"
                                            value={value}
                                            onChange={onChange}
                                            inputRef={ref}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div
                            style={{
                                borderBottom: '1px solid rgba(0,0,0,0.1)'
                            }}
                        >
                            <Tabs
                                tabs={[
                                    {
                                        key: 'phones',
                                        label: 'Phone Numbers'
                                    },
                                    { key: 'flags', label: 'Flags' }
                                ]}
                                active={tab}
                                onChange={(tab) => setTab(tab)}
                            />
                        </div>
                        <div
                            className={styles.row}
                            style={{
                                visibility:
                                    tab === 'phones' ? 'visible' : 'hidden',
                                display: tab === 'phones' ? 'block' : 'none'
                            }}
                        >
                            <Controller
                                name="phoneFields"
                                control={control}
                                render={({ field: { value: phoneFields } }) => (
                                    <div className={styles.textInputContainer}>
                                        {phoneFields &&
                                        phoneFields.length !== 0 ? (
                                            phoneFields.map((field, index) => (
                                                <ContactTextInput
                                                    {...field}
                                                    index={index}
                                                    key={field?.id}
                                                    placeholder="Enter a phone number"
                                                    category="phoneFields"
                                                    property="phone"
                                                    primaryField="phonePrimary"
                                                    removeable
                                                    handleRemove={() => {
                                                        if (
                                                            getValues(
                                                                'phonePrimary'
                                                            ) ===
                                                                phoneFields[
                                                                    index
                                                                ].id &&
                                                            phoneFields.length >
                                                                1
                                                        )
                                                            setValue(
                                                                'phonePrimary',
                                                                phoneFields.find(
                                                                    (pf) =>
                                                                        pf.id !==
                                                                        phoneFields[
                                                                            index
                                                                        ].id
                                                                ).id
                                                            );
                                                        removePhone(index);
                                                    }}
                                                />
                                            ))
                                        ) : (
                                            <div
                                                className={
                                                    styles.emptyMessageContainer
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles.emptyMessage
                                                    }
                                                >
                                                    No phone numbers currently
                                                    associated with this
                                                    contact.
                                                </p>
                                            </div>
                                        )}
                                        <div className={styles.actionContainer}>
                                            <span
                                                tabIndex={0}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleAddPhone();
                                                    }
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                                onClick={handleAddPhone}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                />
                                                <p>Add Phone Number</p>
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div
                            className={styles.row}
                            style={{
                                visibility:
                                    tab === 'flags' ? 'visible' : 'hidden',
                                display: tab === 'flags' ? 'block' : 'none'
                            }}
                        >
                            <Controller
                                name="flags"
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                    return loadingFlags ? (
                                        <LoadingOverlay
                                            size="sm"
                                            labelPosition="right"
                                            label="Loading Flags..."
                                        />
                                    ) : (
                                        <CheckboxGroup
                                            orientation="vertical"
                                            options={flags?.data?.map?.(
                                                (f) => ({
                                                    ...f,
                                                    value: f.name,
                                                    key: f.id
                                                })
                                            )}
                                            handleChange={onChange}
                                            selected={value}
                                            multiselect
                                        />
                                    );
                                }}
                            />
                        </div>
                        {errors.root?.serverError && (
                            <div>
                                <ErrorMessage
                                    error={errors.root.serverError.message}
                                />
                            </div>
                        )}
                        <div
                            style={{
                                visibility: 'hidden',
                                opacity: 0
                            }}
                        >
                            <button formAction="submit" ref={submitButtonRef} />
                        </div>
                    </form>
                </FormProvider>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Save"
                    onClick={handleSubmitClicked}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    ) : (
        <NewCompanyModal
            handleNew={handleNewCompanyCreation}
            handleClose={handleCancelNewCompany}
        />
    );
};

export default EditContactModal;
