import useFetch from '../../../../hooks/useFetch';

import Button from '../../../general/input/Button';
import Modal from '../../../general/modal/Modal';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';

import styles from '../../../../styles/apps/contact/modal/ExportCompanyModal.module.scss';

const ExportCompanyModal = ({ handleClose, filters }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [{ loading }, exportCompanies] = useFetch(
        '/contact/report',
        'GET',
        {
            manual: true
        }
    );

    const handleExport = () => {
        const buildUrl = () => {
            let url = `/contact/company/report`;

            if (Object.keys(filters).length === 0) return url;

            let filterObj = filters
                ? Object.keys(filters)
                      ?.filter(
                          (filter) =>
                              !!filters?.[filter]?.getFilter?.(
                                  filters?.[filter]?.value
                              )
                      )
                      .reduce(
                          (acc, cur) => ({
                              ...acc,
                              ...filters[cur].getFilter?.(filters?.[cur]?.value)
                          }),
                          {}
                      )
                : {};

            url += `?filter=${JSON.stringify(filterObj)}`;

            return url;
        };

        exportCompanies({
            url: buildUrl()
        })
            .then((res) => res.blob())
            .then((data) => {
                let today = new Date();
                let month = today.getMonth() + 1;
                let day = today.getDate();
                let year = today.getFullYear();
                var objectURL = URL.createObjectURL(data);
                let element = document.createElement('a');
                element.href = objectURL;
                element.download = `${month}-${day}-${year} Company Report.csv`;
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                URL.revokeObjectURL(objectURL);
                handleClose();
            })
            .catch((err) => {
                console.log('err', err);
                enqueueSnackbar('Failed to export data.', {
                    variant: 'error',
                    autoHideDuration: 3000,
                    preventDuplicate: true
                });
            });
    };

    return (
        <Modal open blocking handleClose={handleClose}>
            <Modal.Title icon={faFileExport}>Export Companies</Modal.Title>
            <Modal.Body>
                <p className={styles.confirmationMessage}>
                    Are you sure you want to export these Companies?
                </p>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Confirm"
                    onClick={handleExport}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default ExportCompanyModal;
