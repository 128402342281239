import DatePicker from './DatePicker';
import InputLabel from './InputLabel';
import PropTypes from 'prop-types';

import styles from '../../../styles/general/input/DateRange.module.scss';

const DateRange = ({ handleChange, from, to, showLabel }) => (
    <div className={styles.dateRangeContainer}>
        <span className={styles.pickerContainer}>
            <InputLabel
                label={showLabel ? 'From' : null}
                style={{
                    container: {
                        flex: 1
                    }
                }}
            >
                <DatePicker
                    onChange={handleChange?.bind(this, 'from')}
                    value={from}
                    options={{
                        maxDate: to
                    }}
                />
            </InputLabel>
        </span>
        <span className={styles.pickerContainer}>
            <InputLabel
                label={showLabel ? 'To' : null}
                style={{
                    container: {
                        flex: 1
                    }
                }}
            >
                <DatePicker
                    onChange={handleChange?.bind(this, 'to')}
                    value={to}
                    options={{
                        minDate: from
                    }}
                />
            </InputLabel>
        </span>
    </div>
);

DateRange.propTypes = {
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date),
    handleChange: PropTypes.func.isRequired
};

export default DateRange;
