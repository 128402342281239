import { useState } from 'react';

import PropTypes from 'prop-types';

const useToggle = (initialState) => {
    let [toggled, setToggled] = useState(initialState ?? false);

    let handleToggle = (state, cb) => {
        setToggled(state !== undefined ? state : !toggled);
        if (toggled && !state && cb) cb();
    };

    return [toggled, handleToggle];
};

useToggle.propTypes = {
    initialState: PropTypes.bool
};

export default useToggle;
