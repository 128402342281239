import FormField from './FormField';

const FormBlock = ({
    id,
    style,
    children,
    field,
    control,
    readOnly,
    debug = false
}) => {
    let styles = JSON.parse(style ?? '{}');
    if (readOnly)
        styles = {
            ...styles,
            ...(styles?.readOnly ?? {})
        };
    
    return (
        <div
            style={{
                position: 'relative',
                ...styles
            }}
        >
            {children &&
                children.length > 0 &&
                children
                    .sort?.((a, b) => a.sequenceNumber - b.sequenceNumber)
                    ?.map((c) => (
                        <FormBlock
                            key={c.id}
                            {...c}
                            control={control}
                            readOnly={readOnly}
                            debug={debug}
                        />
                    ))}
            {field && (
                <FormField {...field} control={control} debug={debug} readOnly={readOnly} />
            )}
        </div>
    );
};

export default FormBlock;
