import { useEffect, useState } from 'react';

import {
    ContactsFlagCreatePermissions,
    ContactsFlagDeletePermissions,
    ContactsFlagUpdatePermissions
} from '../../../js/services/permissions';
import Tabs from '../../navigation/Tabs';
import { Outlet } from 'react-router-dom';
import { useMatch } from 'react-router-dom';

import styles from '../../../styles/apps/contact/Contact.module.scss';

const tabs = [
    {
        key: 'contacts',
        label: 'Contacts',
        link: '/contacts'
    },
    {
        key: 'companies',
        label: 'Companies',
        link: '/contacts/companies'
    },
    {
        key: 'flags',
        label: 'Flags',
        permission: [
            ContactsFlagCreatePermissions,
            ContactsFlagDeletePermissions,
            ContactsFlagUpdatePermissions
        ],
        condition: 'or',
        link: '/contacts/flags'
    }
];

const Contact = () => {
    const match = useMatch({
        path: '/contacts/:page',
        end: false
    });

    const [tab, setTab] = useState(match?.params?.page ?? 'contacts');

    const handleTabChange = (tab) => {
        setTab(tab);
    };

    useEffect(() => {
        document.title = 'CVE Apps - Contacts';
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.tabs}>
                <Tabs tabs={tabs} active={tab} onChange={handleTabChange} />
            </div>
            <div className={styles.content}>
                <Outlet />
            </div>
        </div>
    );
};

export default Contact;
