import TextInput from './TextInput';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isFunction } from 'lodash';

import styles from '../../../styles/general/input/Chip.module.scss';

const Chip = ({ left, label, id, removeable, handleRemove, classes }) => (
    <div className={[styles.container, classes?.container].join(' ')}>
        {left && (isFunction(left) ? (
            left({ className: styles.left })
        ) : (
            <div className={styles.left}>{left}</div>
        ))}
        <div className={[styles.label, classes?.container].join(' ')}>
            {isFunction(label) ? label() : label}
        </div>
        {removeable && (
            <p
                className={[styles.actions, classes?.actions].join(' ')}
                onClick={handleRemove?.bind(this, id)}
            >
                <FontAwesomeIcon icon={faTimes} />
            </p>
        )}
    </div>
);

const TextInputChip = ({
    value,
    placeholder,
    handleChange,
    classes,
    ...props
}) => (
    <Chip
        label={() => (
            <TextInput
                value={value}
                placeholder={placeholder}
                handleChange={handleChange}
                classes={{
                    input: styles.chipTextInput,
                    ...classes?.textInput
                }}
            />
        )}
        classes={classes?.chip}
        {...props}
    />
);

export { TextInputChip };
export default Chip;
