import React, { useState } from 'react';

import useFetch from '../../../../hooks/useFetch';

import Dropdown from '../../../general/input/Dropdown';
import InputLabel from '../../../general/input/InputLabel';
import Modal from '../../../general/modal/Modal';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const SuggestionReportModal = ({ handleClose = () => null, filters }) => {
    const [type, setType] = useState({
        key: 'areaOfSuggestion',
        value: 'Area Of Suggestion'
    });
    const [format, setFormat] = useState({ key: 'pdf', value: 'PDF' });

    const [{ loading: reportLoading }, exportEmployeeCertifications] = useFetch(
        'suggestions/report',
        'GET',
        { manual: true }
    );

    const { enqueueSnackbar } = useSnackbar();

    const handleType = (option) => {
        setType(option);
    };

    const handleFormat = (option) => {
        setFormat(option);
    };

    const generateReport = () => {
        const buildUrl = () => {
            let url = `/suggestions/report?type=${type.key}&format=${format.key}`;

            let filterObj = filters
                ? Object.keys(filters)
                      ?.filter(
                          (filter) =>
                              !!filters?.[filter]?.getFilter?.(
                                  filters?.[filter]?.value
                              )
                      )
                      .reduce(
                          (acc, cur) => ({
                              ...acc,
                              ...filters[cur].getFilter?.(filters?.[cur]?.value)
                          }),
                          {}
                      )
                : {};

            url += `&filter=${JSON.stringify(filterObj)}`;

            return url;
        };

        exportEmployeeCertifications({
            url: buildUrl()
        })
            .then((res) => {
                if (res.status !== 200) throw new Error();
                else return res.blob();
            })
            .then((data) => {
                var objectURL = URL.createObjectURL(data);
                let element = document.createElement('a');
                element.href = objectURL;
                element.download = `Suggestions Report.${format.key}`;
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                URL.revokeObjectURL(objectURL);
                handleClose();
            })
            .catch((err) => {
                console.log('err', err);
                enqueueSnackbar('Failed to export data.', {
                    variant: 'error',
                    autoHideDuration: 3000,
                    preventDuplicate: true
                });
            });
    };

    return (
        <Modal
            open={true}
            blocking={false}
            handleClose={handleClose}
            style={{ content: { height: '320px' } }}
        >
            <Modal.Title>Export Suggestions</Modal.Title>
            <Modal.Body>
                <div
                    style={{
                        display: 'flex',
                        padding: '12px 0',
                        margin: '24px 0 0',
                        alignItems: 'center'
                    }}
                >
                    <InputLabel label="Format" required={true}>
                        <Dropdown
                            placeholder="Export Format"
                            selected={format}
                            required={true}
                            options={[
                                { key: 'pdf', value: 'PDF' },
                                { key: 'csv', value: 'CSV' }
                            ]}
                            handleSelect={handleFormat}
                        />
                    </InputLabel>
                </div>
                <div
                    style={{
                        display: 'flex',
                        padding: '12px 0 0',
                        alignItems: 'center'
                    }}
                >
                    <InputLabel
                        label="Report Type"
                        required={true}
                        style={{ width: '300px' }}
                    >
                        <Dropdown
                            placeholder="Report Type"
                            selected={type}
                            required={true}
                            options={[
                                {
                                    key: 'areaOfSuggestion',
                                    value: 'Area Of Suggestion'
                                },
                                {
                                    key: 'resolvedStatus',
                                    value: 'Resolved Status'
                                }
                            ]}
                            handleSelect={handleType}
                        />
                    </InputLabel>
                </div>
            </Modal.Body>
            <Modal.Actions
                buttons={[
                    {
                        label: 'Cancel',
                        onClick: handleClose,
                        type: 'secondary',
                        variant: 'border'
                    },
                    {
                        label: 'Export',
                        loading: reportLoading,
                        onClick: generateReport,
                        type: 'primary'
                    }
                ]}
            />
        </Modal>
    );
};

SuggestionReportModal.propTypes = {
    handleClose: PropTypes.func,
    filters: PropTypes.shape({
        CategoryName: PropTypes.shape({
            filter: PropTypes.arrayOf(PropTypes.number)
        }),
        Feedback: PropTypes.shape({
            filter: PropTypes.string
        }),
        CreatedOn: PropTypes.shape({
            filter: PropTypes.arrayOf(PropTypes.instanceOf(Date))
        }),
        Resolved: PropTypes.shape({
            filter: PropTypes.bool
        })
    })
};

export default SuggestionReportModal;
