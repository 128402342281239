import LogoLoader from '../../../general/LogoLoader';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';

import styles from '../../../../styles/apps/quality/card/Card.module.scss';

const Card = ({ classes = {}, header, theme = 'dark', content, loading, actions, style }) => {
    return (
        <div className={[styles.card, styles[theme], classes.container].join(' ')} style={style?.container}>
            <div className={[styles.header, classes.header].join(' ')}>
                {isFunction(header) ? header() : header}
                <span
                    className={[
                        styles.actionButton,
                        actions && actions?.length > 0 ? null : styles.inactive
                    ].join(' ')}
                >
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                </span>
            </div>
            {!loading ? (
                <div className={[styles.content, classes.content].join(' ')}>
                    {isFunction(content) ? content() : content}
                </div>
            ) : (
                <div
                    className={[
                        styles.loadingContainer,
                        classes.loadingContainer
                    ].join(' ')}
                >
                    <LogoLoader size={70} />
                </div>
            )}
        </div>
    );
};

Card.propTypes = {
    classes: PropTypes.object,
    header: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    content: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    loading: PropTypes.bool,
    actions: PropTypes.array,
    theme: PropTypes.oneOf(['light', 'dark'])
};

export default Card;
