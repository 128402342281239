import React, { useEffect, useState } from 'react';

import {
    PretaskGridPermissions,
    PretaskSupervisorGridPermissions,
    PretaskTemplateCreatePermissions
} from '../../../js/services/permissions';
import Tabs from '../../navigation/Tabs';
import { Outlet, useMatch } from 'react-router-dom';

import styles from '../../../styles/apps/pretask/Pretask.module.scss';

const tabs = [
    {
        key: 'my-pretasks',
        label: 'My Pretasks',
        link: '/pretask/my-pretasks',
        permission: PretaskGridPermissions
    },
    {
        key: 'supervisor',
        label: 'Pretask Review',
        link: '/pretask/supervisor',
        condition: 'or',
        permission: PretaskSupervisorGridPermissions
    },
    {
        key: 'customize-forms',
        label: 'Customize Forms',
        link: '/pretask/customize-forms',
        permission: PretaskTemplateCreatePermissions
    }
];

const Pretask = () => {
    const match = useMatch({
        path: '/pretask/:page',
        end: false
    });

    const [tab, setTab] = useState(match?.params?.page ?? 'my-pretasks');

    const handleTabChange = (tab) => setTab(tab);

    useEffect(() => {
        document.title = 'CVE Apps - Pretask';
    });

    return (
        <div className={styles.container} style={{ position: 'relative' }}>
            <div className={styles.tabs}>
                <Tabs tabs={tabs} active={tab} onChange={handleTabChange} />
            </div>
            <Outlet />
        </div>
    );
};

export default Pretask;
