import { useRef, useState } from 'react';

import useFilterReducer from '../../../../hooks/useFilterReducer';
import useS3PresignedUrl from '../../../../hooks/useS3PresignedUrl';
import useUser from '../../../../hooks/useUser';

import { QualityAttachmentTagPermissions } from '../../../../js/services/permissions';
import ActionCell from '../../../general/grid/cell renderers/ActionCell';
import TextCell from '../../../general/grid/cell renderers/TextCell';
import Filters from '../../../general/grid/Filters';
import Grid from '../../../general/grid/Grid';
import DateRange from '../../../general/input/DateRange';
import Select from '../../../general/input/Select';
import TextInput from '../../../general/input/TextInput';
import S3DownloadModal from '../../../general/modal/S3DownloadModal';
import DeleteEquipmentAttachmentModal from '../modal/DeleteEquipmentAttachmentModal';
import EditEquipmentAttachmentModal from '../modal/EditEquipmentAttachmentModal';
import NewEquipmentAttachmentModal from '../modal/NewEquipmentAttachmentModal';
import {
    faEye,
    faFileDownload,
    faFilter,
    faPencilAlt,
    faPlus,
    faSpinner,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { useOutletContext } from 'react-router-dom';

import styles from '../../../../styles/apps/quality/grid/QualityGrid.module.scss';

const defaultFilterState = {
    equipment: {
        value: [],
        getFilter: (equipment) =>
            equipment.length === 0
                ? null
                : { EquipmentIds: equipment.map((e) => e.id) }
    },
    date: {
        value: {
            to: null,
            from: null
        },
        getFilter: (value) => {
            if (!value.to && !value.from) return null;
            let filterObj = {};
            if (value.to) filterObj['DateTo'] = value.to;
            if (value.from) filterObj['DateFrom'] = value.from;
            return filterObj;
        }
    },
    fileName: {
        value: '',
        getFilter: (name) =>
            name === '' || name === null ? null : { FileName: name }
    },
    fileDescription: {
        value: '',
        getFilter: (desc) =>
            desc === '' || desc === null ? null : { FileDescription: desc }
    },
    tags: {
        value: [],
        getFilter: (tags) =>
            tags.length === 0 ? null : { TagIds: tags.map((tag) => tag?.id) }
    }
};

const AttachmentGrid = () => {
    const gridRef = useRef();

    const { job } = useOutletContext();
    const { id, loading: urlLoading, getUrl } = useS3PresignedUrl();
    const { userHasJobPermissions, userHasPermissions } = useUser();

    const { filter, setFilter, resetFilter } = useFilterReducer(
        defaultFilterState,
        'quality_attachment_grid_filters'
    );

    const [modals, setModals] = useState({
        new: false,
        edit: false,
        download: false,
        delete: false
    });

    const clearFilters = () => {
        resetFilter();
    };

    const handleFilterChange = (key, value) => {
        setFilter({
            key: key,
            payload: value
        });
    };

    const handleDateChange = (key, date) =>
        setFilter({
            key: 'date',
            payload: {
                ...filter['date'].value,
                [key]: date
            }
        });

    const handleOpenModal = (type, value) => {
        setModals((modals) => ({
            ...modals,
            [type]: value
        }));
    };

    const handleCloseModal = (type) => {
        setModals((modals) => ({
            ...modals,
            [type]: false
        }));
    };

    const handleNewAttachment = (attachments) => {
        gridRef.current?.addRow?.(attachments);
    };

    const handleEditAttachment = (id, attachment) => {
        gridRef?.current?.modifyRow?.(id, attachment);
    };

    const handleDeleteAttachment = (id) => {
        gridRef?.current?.removeRow?.(id);
    };

    return (
        <div
            style={{
                width: '100%',
                height: '100%'
            }}
        >
            <Grid
                ref={gridRef}
                filters={filter}
                actions={[
                    userHasJobPermissions(
                        QualityAttachmentTagPermissions,
                        'or'
                    ) ||
                    userHasPermissions(QualityAttachmentTagPermissions, 'or')
                        ? {
                              type: 'primary',
                              label: 'New',
                              icon: faPlus,
                              onClick: handleOpenModal.bind(this, 'new')
                          }
                        : null
                ]}
                fixed
                multiselect
                rowSelect
                handleRowSelection={() => null}
                selected={[]}
                getRowId={(r) => r.id}
                pagination={{
                    url: `/quality/${job.id}/attachments`,
                    record: job.id,
                    pageSize: 100
                }}
                columns={[
                    {
                        title: 'Upload Date',
                        key: 'createdOn',
                        dataKey: 'createdOn',
                        sortKey: 'createdOn',
                        sortable: true,
                        width: 180,
                        minWidth: 180,
                        cellRenderer: ({ cellData }) =>
                            cellData && (
                                <TextCell>
                                    {cellData &&
                                        new Intl.DateTimeFormat('en-US', {
                                            month: '2-digit',
                                            day: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        }).format(new Date(cellData))}
                                </TextCell>
                            )
                    },
                    {
                        title: 'Equipment',
                        key: 'equipment',
                        sortKey: 'equipment',
                        dataKey: 'equipmentName',
                        sortable: true,
                        width: 200,
                        minWidth: 200,
                        cellRenderer: ({ rowData }) => (
                            <TextCell>{rowData?.equipment?.name}</TextCell>
                        )
                    },
                    {
                        title: 'File Name',
                        key: 'filename',
                        dataKey: 'filename',
                        sortKey: 'filename',
                        sortable: true,
                        width: 300,
                        minWidth: 300,
                        cellRenderer: ({ rowData }) => (
                            <TextCell>{rowData?.attachment?.filename}</TextCell>
                        )
                    },
                    {
                        title: 'Description',
                        key: 'description',
                        dataKey: 'description',
                        sortKey: 'description',
                        sortable: true,
                        width: 300,
                        minWidth: 300,
                        fixedGrow: 1,
                        cellRenderer: ({ rowData }) => (
                            <TextCell>
                                {rowData?.attachment?.description}
                            </TextCell>
                        )
                    },
                    {
                        title: 'Tags',
                        key: 'tags',
                        sortKey: 'tag',
                        sortable: true,
                        width: 120,
                        minWidth: 120,
                        cellRenderer: ({ rowData }) => (
                            <TextCell
                                hoverTrigger="always"
                                clickTrigger="always"
                                hoverDelay={400}
                                tooltip={rowData?.qualityTags?.map?.(
                                    (qt, i) => (
                                        <div key={i}>{qt.name}</div>
                                    )
                                )}
                            >
                                {rowData.qualityTags?.length ? (
                                    <p
                                        className={styles.attachmentLink}
                                    >{`${rowData.qualityTags?.length} tags`}</p>
                                ) : (
                                    ''
                                )}
                            </TextCell>
                        )
                    },
                    {
                        title: ' ',
                        key: 'actions',
                        sortable: false,
                        frozen: 'right',
                        width:
                            userHasJobPermissions(
                                QualityAttachmentTagPermissions,
                                'or'
                            ) ||
                            userHasPermissions(
                                QualityAttachmentTagPermissions,
                                'or'
                            )
                                ? 140
                                : 80,
                        minWidth:
                            userHasJobPermissions(
                                QualityAttachmentTagPermissions,
                                'or'
                            ) ||
                            userHasPermissions(
                                QualityAttachmentTagPermissions,
                                'or'
                            )
                                ? 140
                                : 80,
                        cellRenderer: ({ rowData }) => (
                            <ActionCell
                                actions={[
                                    userHasPermissions(
                                        QualityAttachmentTagPermissions,
                                        'or'
                                    ) ||
                                    userHasJobPermissions(
                                        QualityAttachmentTagPermissions,
                                        'or'
                                    )
                                        ? {
                                              icon:
                                                  id === rowData.id &&
                                                  urlLoading
                                                      ? faSpinner
                                                      : faEye,
                                              iconProps: {
                                                  spin:
                                                      id === rowData.id &&
                                                      urlLoading
                                              },
                                              type: 'grayscale',
                                              onClick: () =>
                                                  getUrl(
                                                      rowData?.id,
                                                      `/quality/attachment/${rowData?.attachment?.id}/preview`
                                                  ),
                                              tooltip: {
                                                  tooltip: 'View',
                                                  hoverDelay: 650,
                                                  hoverTrigger: 'always'
                                              }
                                          }
                                        : null,
                                    userHasPermissions(
                                        QualityAttachmentTagPermissions,
                                        'or'
                                    ) ||
                                    userHasJobPermissions(
                                        QualityAttachmentTagPermissions,
                                        'or'
                                    )
                                        ? {
                                              icon: faFileDownload,
                                              type: 'grayscale',
                                              onClick: handleOpenModal.bind(
                                                  this,
                                                  'download',
                                                  rowData
                                              ),
                                              tooltip: {
                                                  tooltip: 'Download',
                                                  hoverDelay: 650,
                                                  hoverTrigger: 'always'
                                              }
                                          }
                                        : null,
                                    userHasJobPermissions(
                                        QualityAttachmentTagPermissions,
                                        'or'
                                    ) ||
                                    userHasPermissions(
                                        QualityAttachmentTagPermissions,
                                        'or'
                                    )
                                        ? {
                                              icon: faPencilAlt,
                                              type: 'grayscale',
                                              onClick: handleOpenModal.bind(
                                                  this,
                                                  'edit',
                                                  rowData
                                              ),
                                              tooltip: {
                                                  tooltip: 'Edit',
                                                  hoverDelay: 650,
                                                  hoverTrigger: 'always'
                                              }
                                          }
                                        : null,
                                    userHasJobPermissions(
                                        QualityAttachmentTagPermissions,
                                        'or'
                                    ) ||
                                    userHasPermissions(
                                        QualityAttachmentTagPermissions,
                                        'or'
                                    )
                                        ? {
                                              icon: faTrashAlt,
                                              type: 'grayscale',
                                              onClick: handleOpenModal.bind(
                                                  this,
                                                  'delete',
                                                  rowData
                                              ),
                                              tooltip: {
                                                  tooltip: 'Delete',
                                                  hoverDelay: 650,
                                                  hoverTrigger: 'always'
                                              }
                                          }
                                        : null
                                ]}
                            />
                        )
                    }
                ]}
                sidepanel={{
                    filters: {
                        label: 'Filters',
                        icon: faFilter,
                        component: Filters,
                        props: {
                            clearFilters: clearFilters,
                            filters: [
                                {
                                    label: 'Equipment',
                                    component: Select,
                                    props: {
                                        placeholder: 'Select Equipment',
                                        multiselect: true,
                                        handleRowSelection:
                                            handleFilterChange.bind(
                                                this,
                                                'equipment'
                                            ),
                                        selected: filter['equipment'].value,
                                        getRowValue: (row) => row?.name,
                                        getRowId: (row) => row.id,
                                        pagination: {
                                            url: `/quality/${job.id}/equipment`,
                                            record: job.id
                                        },
                                        sort: ['equipment']
                                    }
                                },
                                {
                                    label: 'File Name',
                                    component: TextInput,
                                    props: {
                                        placeholder: 'File Name',
                                        onChange: (e) =>
                                            handleFilterChange(
                                                'fileName',
                                                e.target.value
                                            ),
                                        value: filter['fileName']?.value
                                    }
                                },
                                {
                                    label: 'File Description',
                                    component: TextInput,
                                    props: {
                                        placeholder: 'File Description',
                                        onChange: (e) =>
                                            handleFilterChange(
                                                'fileDescription',
                                                e.target.value
                                            ),
                                        value: filter['fileDescription']?.value
                                    }
                                },
                                {
                                    label: 'Tags',
                                    component: Select,
                                    props: {
                                        placeholder: 'Select Tag(s)',
                                        multiselect: true,
                                        handleRowSelection:
                                            handleFilterChange.bind(
                                                this,
                                                'tags'
                                            ),
                                        selected: filter['tags'].value,
                                        getRowValue: (row) => row?.name,
                                        getRowNodeId: (row) => row.id,
                                        pagination: {
                                            url: `/quality/${job.id}/tags`,
                                            record: job.id
                                        },
                                        sort: ['name']
                                    }
                                },
                                {
                                    label: 'Upload Date',
                                    component: DateRange,
                                    props: {
                                        to: filter.date?.value.to
                                            ? new Date(filter.date.value.to)
                                            : null,
                                        from: filter.date?.value.from
                                            ? new Date(filter.date.value.from)
                                            : null,
                                        handleChange: handleDateChange
                                    }
                                }
                            ]
                        }
                    }
                }}
            />
            {modals.new && (
                <NewEquipmentAttachmentModal
                    handleClose={handleCloseModal.bind(this, 'new')}
                    handleNew={handleNewAttachment}
                    jobId={job.id}
                />
            )}
            {modals.edit && (
                <EditEquipmentAttachmentModal
                    handleClose={handleCloseModal.bind(this, 'edit')}
                    handleEdit={handleEditAttachment}
                    attachment={modals.edit}
                    jobId={job.id}
                />
            )}
            {modals.delete && (
                <DeleteEquipmentAttachmentModal
                    handleClose={handleCloseModal.bind(this, 'delete')}
                    handleDelete={handleDeleteAttachment}
                    attachment={modals.delete}
                />
            )}
            {modals.download && (
                <S3DownloadModal
                    handleClose={handleCloseModal.bind(this, 'download')}
                    url={`/quality/attachment/${modals.download?.attachment?.id}/download`}
                    fileName={
                        modals.download?.attachment?.filename ?? 'download'
                    }
                    message="Downloading Attachment..."
                />
            )}
        </div>
    );
};

export default AttachmentGrid;
