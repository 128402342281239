import { useCallback, useEffect, useRef, useState } from 'react';

import api from '../js/services/api';
import PropTypes from 'prop-types';

const useApi = (url, method, options = {}) => {
    //Keep track of component mounted state to prevent state change on a DOM element
    const isMounted = useRef(true);
    const controllerRef = useRef();

    const [loading, setLoading] = useState(!options.manual);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        controllerRef.current = new AbortController();
        isMounted.current = true;

        return () => {
            controllerRef?.current?.abort?.('unmount');
            isMounted.current = false;
        };
    }, []);

    /**
     * Acquire an access token, insert it into the Authorization header, and call the API
     * @returns null
     */
    const call = useCallback(
        async (config = {}) => {
            if (!isMounted.current) return;
            setLoading(true);
            setError(null);

            let callUrl = config?.url ?? url;

            if (callUrl && Array.isArray(callUrl)) {
                const callPromises = callUrl.map((cu) =>
                    api({
                        method: method,
                        signal: controllerRef?.current?.signal,
                        data: config.data,
                        ...options,
                        ...config,
                        url: cu
                    })
                );
                const response = await Promise.allSettled(callPromises);
                if (isMounted.current) {
                    if (!options.promiseOnly) {
                        setData(
                            response.filter(
                                (datum) => datum.status === 'fulfilled'
                            )
                        );
                        setError(
                            response.filter(
                                (datum) => datum.status === 'rejected'
                            )
                        );
                    }
                    return {
                        data: response.filter(
                            (datum) => datum.status === 'fulfilled'
                        ),
                        error: response.filter(
                            (datum) => datum.status === 'rejected'
                        )
                    };
                }
                setLoading(false);
            } else {
                try {
                    const response = await api({
                        method: method,
                        signal: controllerRef?.current?.signal,
                        data: config.data,
                        ...options,
                        ...config,
                        url: callUrl
                    });

                    if (!options.promiseOnly) {
                        setData(response?.data);
                        setError(null);
                    }

                    setLoading(false);
                    return response?.data;
                } catch (err) {
                    if (!options.promiseOnly) {
                        setData(undefined);
                        setError(err);
                    }

                    setLoading(false);
                    throw err;
                }
            }
        },
        [method, options, url]
    );

    useEffect(() => {
        if (options.manual) return;
        call();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return [{ data, loading, error }, call];
};

useApi.propTypes = {
    url: PropTypes.string.isRequired,
    method: PropTypes.oneOf(['GET', 'PUT', 'POST', 'DELETE', 'PATCH']),
    options: PropTypes.shape({
        promiseOnly: PropTypes.bool,
        manual: PropTypes.bool
    })
};

export default useApi;
