import React, { useRef, useState } from 'react';

import useApi from '../../../../hooks/useApi';
import useUser from '../../../../hooks/useUser';

import {
    CertificationCreatePermissions,
    CertificationDeletePermissions,
    CertificationReportPermissions,
    CertificationReviewPermissions,
    CertificationUpdatePermissions
} from '../../../../js/services/permissions';
import ActionCell from '../../../general/grid/cell renderers/ActionCell';
import TextCell from '../../../general/grid/cell renderers/TextCell';
import Filters from '../../../general/grid/Filters';
import Grid from '../../../general/grid/Grid';
import CheckboxGroup from '../../../general/input/CheckboxGroup';
import DateRange from '../../../general/input/DateRange';
import Dropdown from '../../../general/input/Dropdown';
import Select from '../../../general/input/Select';
import DeleteEmployeeCertificationModal from '../modal/DeleteEmployeeCertificationModal';
import EditEmployeeCertificationModal from '../modal/EditEmployeeCertificationModal';
import EmployeeCertificationReportModal from '../modal/EmployeeCertificationReportModal';
import NewEmployeeCertificationModal from '../modal/NewEmployeeCertificationModal';
import ReviewCertificationModal from '../modal/ReviewCertificationModal';
import ReviewCertificationDetail from '../ReviewCertificationDetail';
import {
    faCheck,
    faFileExport,
    faFilter,
    faMagnifyingGlass,
    faPencilAlt,
    faPlus,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { DateTime } from 'luxon';

import styles from '../../../../styles/apps/certifications/Grid.module.scss';

const defaultFilterState = {
    receivedOn: {
        value: {
            to: null,
            from: null
        },
        getFilter: (value) => {
            if (!value.from && !value.to) return null;
            let filter = {};
            if (value.from)
                filter['ReceivedFrom'] = DateTime.fromJSDate(
                    new Date(value.from)
                ).toFormat('yyyy-MM-dd');
            if (value.to)
                filter['ReceivedTo'] = DateTime.fromJSDate(
                    new Date(value.to)
                ).toFormat('yyyy-MM-dd');
            return filter;
        }
    },
    expiresOn: {
        value: {
            to: null,
            from: null
        },
        getFilter: (value) => {
            if (!value.from && !value.to) return null;
            let filter = {};
            if (value.from)
                filter['ExpiresFrom'] = DateTime.fromJSDate(
                    new Date(value.from)
                ).toFormat('yyyy-MM-dd');
            if (value.to)
                filter['ExpiresTo'] = DateTime.fromJSDate(
                    new Date(value.to)
                ).toFormat('yyyy-MM-dd');
            return filter;
        }
    },
    certifications: {
        value: [],
        getFilter: (certifications) =>
            certifications.length === 0
                ? null
                : { CertificationTypeIds: certifications.map((c) => c.id) }
    },
    employees: {
        value: [],
        getFilter: (employees) =>
            employees.length === 0
                ? null
                : { EmployeeIds: employees.map((e) => e.id) }
    },
    divisionIds: {
        value: [],
        getFilter: (divisions) =>
            divisions.length === 0
                ? null
                : { DivisionIds: divisions.map?.((d) => d.key) }
    },
    hideConditions: {
        value: {
            hideInactive: true,
            hideReviewed: false
        },
        getFilter: (value) => {
            let filter = {};
            filter['IsEmployeeActive'] =
                value.hideInactive === false ? null : value.hideInactive;
            filter['IsCertification'] =
                value.hideReviewed === false ? null : !value.hideReviewed;
            return filter;
        }
    }
};

const CertificationReviewGrid = () => {
    const [{ data: divisionsList, loading: divisionsLoading }] = useApi(
        '/divisions',
        'GET'
    );

    const { userHasPermissions } = useUser();

    //Grid refs
    const gridRef = useRef(null);

    const [selected, setSelected] = useState([]);

    //Filters
    const [filter, setFilter] = useState(defaultFilterState);

    //Modal state
    const [newModal, setNewModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [exportModal, setExportModal] = useState(false);
    const [reviewModal, setReviewModal] = useState(false);

    const [detail, setDetail] = useState(null);

    /**
     * Filter state handlers
     */

    /**
     * Handle the received date picker change event
     * @param {*} type - 'from' or 'to' depending on the filter changed
     * @param {*} date - date selected in the date picker
     */
    const handleReceiveChanged = (type, date) => {
        setFilter((filter) => ({
            ...filter,
            receivedOn: {
                ...filter['receivedOn'],
                value: {
                    ...filter.receivedOn.value,
                    [type]: date
                }
            }
        }));
    };

    /**
     * Handle the expires date picker change event
     * @param {*} type - 'from' or 'to' depending on the filter changed
     * @param {*} date - date selected in the date picker
     */
    const handleExpiresChanged = (type, date) => {
        setFilter((filter) => ({
            ...filter,
            expiresOn: {
                ...filter['expiresOn'],
                value: {
                    ...filter.expiresOn.value,
                    [type]: date
                }
            }
        }));
    };

    /**
     * Handle the employees select change event
     * @param {*} employees - list of employees selected
     */
    const handleEmployeeChange = (employees) => {
        setFilter((filter) => ({
            ...filter,
            employees: {
                ...filter['employees'],
                value: employees
            }
        }));
    };

    /**
     * Handle the divisions select change event
     * @param {*} divisions - list of divisions selected
     */
    const handleDivisionChange = (division) => {
        setFilter((filter) => ({
            ...filter,
            divisionIds: {
                ...filter['divisionIds'],
                value: division
            }
        }));
    };

    /**
     * Handle the certifications select change event
     * @param {*} certifications - list of certifications selected
     */
    const handleCertificationChange = (certifications) => {
        setFilter((filter) => ({
            ...filter,
            certifications: {
                ...filter['certifications'],
                value: certifications
            }
        }));
    };

    /**
     * Reset the filter state values to their defaults
     */
    const clearFilters = () => {
        setFilter(defaultFilterState);
    };

    /**
     * Handle new modal open & close
     */
    const handleNew = () => setNewModal(true);
    const handleNewClose = () => setNewModal(false);

    /**
     * Handle export modal open & close
     */
    const handleExport = () => setExportModal(true);
    const handleExportClose = () => setExportModal(false);

    /**
     * Handle review modal open & close
     */
    const handleReview = (id) => setReviewModal(id);
    const handleReviewClose = () => setReviewModal(null);

    /**
     * Handle edit modal open & close
     */
    const handleEdit = (id) => {
        setEditModal(id);
    };
    const handleEditClose = () => setEditModal(null);

    /**
     * Handle delete modal open & close
     */
    const handleDelete = (id) => {
        setDeleteModal(id);
    };
    const handleDeleteClose = () => setDeleteModal(false);

    /**
     * Handle a new employee certifications being created
     */
    const handleCreated = (row) => {
        gridRef.current?.addRow(row);
    };

    /**
     * Handle an employee certification being edited
     * @param {*} row
     */
    const handleSaved = (row) => {
        //If the detail panel is showing the edited employee certification, refetch the panel data
        if (detail === row.employeeCertificationId) {
            gridRef?.current?.sidepanel?.refetch?.();
        }
        gridRef?.current?.modifyRow(
            row.employeeCertificationId,
            row,
            'employeeCertificationId'
        );
    };

    /**
     * Handle an employee certification being deleted
     * @param {*} id
     */
    const handleDeleted = (id) => {
        if (detail === id) {
            setDetail(null);
            gridRef.current.sidepanel.show(null);
        }
        gridRef?.current?.removeRow(id, 'employeeCertificationId');
    };

    /**
     * Open the detail sidepanel and fetch the record when row clicked
     * @param {*} row - row id that was selected
     */
    const handleRowSelected = (row) => {
        gridRef.current.sidepanel.show('detail');
        setDetail(row);
        setSelected([{ id: row }]);
    };

    /**
     * Close the detail sidepanel and clear detail
     */
    const handleRowDeselected = () => {
        gridRef.current.sidepanel.show(null);
        setDetail(null);
        setSelected([]);
    };

    const handlePanelClose = () => {
        setSelected([]);
    };

    return (
        <div className={styles.container}>
            <Grid
                ref={gridRef}
                pagination={{
                    url: '/employee-certifications',
                    pageSize: 100
                }}
                fixed
                selected={selected}
                filters={filter}
                rowKey="employeeCertificationId"
                getRowId={(r) => r.employeeCertificationId}
                getRowClass={(row) => {
                    let curDate = new Date();
                    let expiringDate = new Date();
                    expiringDate.setDate(expiringDate.getDate() + 60);
                    let value = new Date(row?.expiresOn);
                    return expiringDate <= value || !row?.expiresOn
                        ? styles.currentCertification
                        : curDate > value
                        ? styles.expiredCertification
                        : styles.expiringCertification;
                }}
                actions={[
                    {
                        label: 'New',
                        icon: faPlus,
                        onClick: handleNew,
                        permission: CertificationCreatePermissions
                    },
                    {
                        label: 'Export',
                        icon: faFileExport,
                        type: 'secondary',
                        variant: 'border',
                        onClick: handleExport,
                        permission: CertificationReportPermissions
                    }
                ].filter(
                    (action) =>
                        !action.permission ||
                        userHasPermissions(action.permission)
                )}
                rowSelect
                handleRowDeselected={handleRowDeselected}
                handleRowSelected={handleRowSelected}
                handlePanelClose={handlePanelClose}
                sidepanel={{
                    detail: {
                        label: 'Detail',
                        icon: faMagnifyingGlass,
                        component: ReviewCertificationDetail,
                        props: {
                            employeeCertificationId: detail,
                            handleEdit: (id) => setEditModal([id]),
                            handleDelete: (id) => setDeleteModal([id]),
                            handleReview: (id) => setReviewModal(id)
                        }
                    },
                    filters: {
                        label: 'Filters',
                        icon: faFilter,
                        component: Filters,
                        props: {
                            clearFilters: clearFilters,
                            filters: [
                                {
                                    label: 'Employee',
                                    component: Select,
                                    props: {
                                        handleRowSelection:
                                            handleEmployeeChange,
                                        selected: filter['employees'].value,
                                        getRowValue: (row) =>
                                            row?.firstName || row?.lastName
                                                ? `${row?.lastName}, ${
                                                      row?.firstName
                                                  } ${row?.middleName ?? ''}`
                                                : undefined,
                                        getRowId: (row) => row.id,
                                        placeholder: 'Select Employee(s)',
                                        filter: {
                                            IsActive: {
                                                value:
                                                    filter?.hideConditions
                                                        ?.value
                                                        ?.hideInactive === false
                                                        ? null
                                                        : true,
                                                getFilter: (hide) => ({
                                                    IsActive: hide
                                                })
                                            }
                                        },
                                        multiselect: true,
                                        pagination: {
                                            url: '/employees'
                                        },
                                        sort: [
                                            'LastName',
                                            'FirstName',
                                            'MiddleName',
                                            'Suffix',
                                            'EmployeeNumber'
                                        ]
                                    }
                                },
                                {
                                    label: 'Division',
                                    component: Dropdown,
                                    width: '100%',
                                    props: {
                                        placeholder: 'Select Division(s)',
                                        loading: divisionsLoading,
                                        options: divisionsList?.map((d) => ({
                                            key: d.id,
                                            value: d.name
                                        })),
                                        handleSelect: handleDivisionChange,
                                        multiselect: true,
                                        selected: filter['divisionIds'].value
                                    }
                                },
                                {
                                    component: Select,
                                    label: 'Certification',
                                    props: {
                                        placeholder: 'Select Certification(s)',
                                        handleRowSelection:
                                            handleCertificationChange,
                                        selected:
                                            filter['certifications'].value,
                                        getRowValue: (row) =>
                                            row?.code || row?.description
                                                ? `${row?.code} - ${row?.description}`
                                                : undefined,
                                        getRowId: (row) => row.id,
                                        multiselect: true,
                                        filter: {
                                            ShowInactive: {
                                                value: true,
                                                getFilter: (show) => ({
                                                    ShowInactive: show
                                                })
                                            }
                                        },
                                        pagination: {
                                            url: '/certification-types'
                                        },
                                        sort: ['Description']
                                    }
                                },
                                {
                                    label: 'Received',
                                    component: DateRange,
                                    props: {
                                        to: filter.receivedOn.value.to,
                                        from: filter.receivedOn.value.from,
                                        handleChange: handleReceiveChanged
                                    }
                                },
                                {
                                    label: 'Expires',
                                    component: DateRange,
                                    props: {
                                        to: filter.expiresOn.value.to,
                                        from: filter.expiresOn.value.from,
                                        handleChange: handleExpiresChanged
                                    }
                                },
                                {
                                    component: CheckboxGroup,
                                    props: {
                                        multiselect: true,
                                        options: [
                                            {
                                                key: 'hideInactive',
                                                value: 'Hide Inactive Employees'
                                            },
                                            {
                                                key: 'hideReviewed',
                                                value: 'Hide Reviewed Certifications'
                                            }
                                        ],
                                        orientation: 'column',
                                        selected: Object.keys(
                                            filter?.hideConditions?.value
                                        ).filter(
                                            (hc) =>
                                                filter.hideConditions?.value?.[
                                                    hc
                                                ]
                                        ),
                                        handleChange: (value) => {
                                            setFilter((filter) => ({
                                                ...filter,
                                                hideConditions: {
                                                    ...filter['hideConditions'],
                                                    value: {
                                                        hideInactive:
                                                            value.includes(
                                                                'hideInactive'
                                                            ),
                                                        hideReviewed:
                                                            value.includes(
                                                                'hideReviewed'
                                                            )
                                                    }
                                                }
                                            }));
                                        }
                                    }
                                }
                            ]
                        }
                    }
                }}
                columns={[
                    {
                        title: 'Created On',
                        key: 'CreatedOn',
                        dataKey: 'createdOn',
                        sortKey: 'CreatedOn',
                        width: 120,
                        maxWidth: 120,
                        cellRenderer: ({ cellData }) =>
                            cellData && (
                                <TextCell>
                                    {DateTime.fromJSDate(
                                        new Date(cellData)
                                    ).toFormat('MM/dd/yyyy')}
                                </TextCell>
                            )
                    },
                    {
                        title: 'Submitted By',
                        key: 'SubmitterName',
                        dataKey: 'submitterName',
                        sortKey: 'SubmitterName',
                        width: 170,
                        minWidth: 170,
                        cellRenderer: ({ cellData }) =>
                            cellData && <TextCell>{cellData}</TextCell>
                    },
                    {
                        title: 'Certified Employee',
                        key: 'Employees',
                        dataKey: 'employeeName',
                        sortColumns: [
                            'LastName',
                            'FirstName',
                            'MiddleName',
                            'Suffix',
                            'EmployeeNumber'
                        ],
                        width: 170,
                        minWidth: 170,
                        cellRenderer: ({ rowData }) => (
                            <TextCell>
                                {`${rowData?.lastName}, ${rowData?.firstName} ${
                                    rowData?.middleName ?? ''
                                }`}
                            </TextCell>
                        )
                    },
                    {
                        title: 'Division',
                        key: 'Division',
                        dataKey: 'division',
                        sortKey: 'Division',
                        width: 160,
                        minWidth: 160,
                        cellRenderer: ({ cellData }) =>
                            cellData && <TextCell>{cellData}</TextCell>
                    },
                    {
                        title: 'Certification',
                        key: 'Code',
                        dataKey: 'Code',
                        sortKey: 'Code',
                        fixedGrow: 1,
                        width: 216,
                        minWidth: 216,
                        cellRenderer: ({ rowData }) => (
                            <TextCell>
                                {rowData.code && rowData.description
                                    ? `${rowData.code} - ${rowData.description}`
                                    : null}
                            </TextCell>
                        )
                    },
                    {
                        title: 'Received',
                        key: 'CertifiedOn',
                        dataKey: 'certifiedOn',
                        sortKey: 'CertifiedOn',
                        width: 110,
                        maxWidth: 110,
                        cellRenderer: ({ cellData }) =>
                            cellData && (
                                <TextCell>
                                    {cellData &&
                                        new Intl.DateTimeFormat('en-US', {
                                            timeZone: 'UTC',
                                            month: '2-digit',
                                            day: '2-digit',
                                            year: 'numeric'
                                        }).format(new Date(cellData))}
                                </TextCell>
                            )
                    },
                    {
                        title: 'Expires',
                        key: 'ExpiresOn',
                        dataKey: 'expiresOn',
                        sortKey: 'ExpiresOn',
                        width: 110,
                        maxWidth: 110,
                        cellRenderer: ({ cellData }) =>
                            cellData && (
                                <TextCell>
                                    {cellData &&
                                        new Intl.DateTimeFormat('en-US', {
                                            timeZone: 'UTC',
                                            month: '2-digit',
                                            day: '2-digit',
                                            year: 'numeric'
                                        }).format(new Date(cellData))}
                                </TextCell>
                            )
                    },
                    {
                        title: 'Reviewer Name',
                        key: 'ReviewerName',
                        dataKey: 'reviewerName',
                        sortKey: 'ReviewerName',
                        width: 155,
                        maxWidth: 155,
                        cellRenderer: ({ cellData }) =>
                            cellData && <TextCell>{cellData}</TextCell>
                    },
                    {
                        title: 'Verifier Name',
                        key: 'VerifierName',
                        dataKey: 'verifierName',
                        sortKey: 'VerifierName',
                        width: 155,
                        maxWidth: 155,
                        cellRenderer: ({ cellData }) =>
                            cellData && <TextCell>{cellData}</TextCell>
                    },
                    {
                        title: '',
                        key: 'Actions',
                        sortKey: 'CanReview',
                        width: 110,
                        minWidth: 110,
                        frozen: 'right',
                        maxWidth: 100,
                        cellRenderer: ({ rowData }) => (
                            <ActionCell
                                actions={[
                                    ...(!rowData?.isResolved &&
                                    rowData?.canReview &&
                                    userHasPermissions(
                                        CertificationReviewPermissions,
                                        'or'
                                    )
                                        ? [
                                              {
                                                  icon: faCheck,
                                                  type: 'grayscale',
                                                  onClick: handleReview.bind(
                                                      this,
                                                      rowData?.employeeCertificationId
                                                  ),
                                                  tooltip: {
                                                      tooltip: 'Review',
                                                      hoverDelay: 650,
                                                      hoverTrigger: 'always'
                                                  }
                                              }
                                          ]
                                        : []),
                                    ...(userHasPermissions(
                                        CertificationUpdatePermissions
                                    )
                                        ? [
                                              {
                                                  icon: faPencilAlt,
                                                  type: 'grayscale',
                                                  onClick: handleEdit.bind(
                                                      this,
                                                      rowData?.employeeCertificationId
                                                  ),
                                                  tooltip: {
                                                      tooltip: 'Edit',
                                                      hoverDelay: 650,
                                                      hoverTrigger: 'always'
                                                  }
                                              }
                                          ]
                                        : []),
                                    ...(userHasPermissions(
                                        CertificationDeletePermissions
                                    )
                                        ? [
                                              {
                                                  icon: faTrashAlt,
                                                  type: 'grayscale',
                                                  onClick: handleDelete.bind(
                                                      this,
                                                      rowData?.employeeCertificationId
                                                  ),
                                                  tooltip: {
                                                      tooltip: 'Delete',
                                                      hoverDelay: 650,
                                                      hoverTrigger: 'always'
                                                  }
                                              }
                                          ]
                                        : [])
                                ]}
                            />
                        )
                    }
                ]}
            />
            {newModal && (
                <NewEmployeeCertificationModal
                    open={newModal}
                    handleClose={handleNewClose}
                    onCreated={handleCreated}
                />
            )}
            {!!editModal && (
                <EditEmployeeCertificationModal
                    open={true}
                    handleClose={handleEditClose}
                    onSaved={handleSaved}
                    certificationId={editModal}
                />
            )}
            <DeleteEmployeeCertificationModal
                open={!!deleteModal}
                handleClose={handleDeleteClose}
                onDeleted={handleDeleted}
                certificationId={deleteModal}
            />
            {!!reviewModal && (
                <ReviewCertificationModal
                    certificationId={reviewModal}
                    handleClose={handleReviewClose}
                    onSaved={handleSaved}
                />
            )}
            {exportModal && (
                <EmployeeCertificationReportModal
                    handleClose={handleExportClose}
                    filters={filter}
                />
            )}
        </div>
    );
};

export default CertificationReviewGrid;
