import React from 'react';

import Copy from '../../general/Copy';
import Button from '../../general/input/Button';
import ErrorCode from './ErrorCode';

import styles from '../../../styles/config/ErrorBoundaries/GridDetailErrorBoundary.module.scss';

class GridDetailErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            errorInfo: null,
            errorObj: null
        };

        this.reset = this.reset.bind(this);
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidUpdate(prevProps) {
        if (
            !this.props.updateProps ||
            !prevProps.updateProps ||
            !(prevProps.updateProps instanceof Object)
        )
            return;
        if (
            Object.keys(prevProps.updateProps).some(
                (key) =>
                    !(prevProps.updateProps?.[key] instanceof Function) &&
                    prevProps.updateProps?.[key] !==
                        this.props.updateProps?.[key]
            )
        ) {
            this.setState({
                hasError: false,
                errorInfo: null,
                errorObj: null
            });
        }
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            errorInfo: errorInfo,
            errorObj: error
        });
    }

    reset() {
        this.setState({
            hasError: false,
            errorInfo: null,
            errorObj: null,
            showStack: false
        });
    }

    render() {
        return this.state.hasError ? (
            <div className={styles.container}>
                <div className={styles.innerContainer} style={this.props.style}>
                    <div className={styles.iconContainer}>:(</div>
                    <div className={styles.body}>
                        <div className={styles.content}>
                            <div className={styles.header}>
                                We encountered an error and couldn't load the
                                detail.
                            </div>
                            <div className={styles.subheader}>
                                We're sorry for the inconvenience.
                            </div>
                            {this.state.errorObj instanceof ErrorCode && (
                                <div className={styles.errorCode}>
                                    <Copy
                                        value={`Error: ${
                                            this.state.errorObj.code
                                        } ${
                                            this.state.errorObj.message
                                                ? `- ${this.state.errorObj.message}`
                                                : null
                                        } ${
                                            this.state.errorObj.description
                                                ? `- ${this.state.errorObj.description}`
                                                : null
                                        }`}
                                    />
                                    <p>
                                        Error: {this.state.errorObj.code}{' '}
                                        {this.state.errorObj.message
                                            ? `- ${this.state.errorObj.message}`
                                            : null}{' '}
                                        {this.state.errorObj.description
                                            ? `- ${this.state.errorObj.description}`
                                            : null}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className={styles.information}>
                            <div className={styles.retry}>
                                <Button
                                    label="Retry"
                                    onClick={() => this.reset()}
                                />
                            </div>
                            <div className={styles.contact}>
                                <p>
                                    If you continue to have this issue, please
                                    create a ticket for the developers by
                                    sending an email to{' '}
                                    <a href="mailto:helpdesk@cve.com">
                                        helpdesk@cve.com
                                    </a>{' '}
                                    or visiting{' '}
                                    <a
                                        href="https://helpdesk.cve.com"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        helpdesk.cve.com
                                    </a>
                                    .{' '}
                                    {this.state.errorObj instanceof ErrorCode
                                        ? 'For a faster experience, please include the error code found above in your ticket.'
                                        : null}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            this.props.children
        );
    }
}

export default GridDetailErrorBoundary;
