import React from 'react';

import useApi from '../../../../hooks/useApi';

import Modal from '../../../general/modal/Modal';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const DeleteEmployeeCertificationModal = ({
    open,
    blocking,
    handleClose = () => null,
    onDeleted = () => null,
    certificationId
}) => {
    const [, deleteEmployeeCertification] = useApi(``, 'DELETE', {
        manual: true
    });

    const { enqueueSnackbar } = useSnackbar();

    const handleDelete = async () => {
        deleteEmployeeCertification({
            url: `/employee-certifications/${certificationId}`
        })
            .then(() => {
                handleClose();
                enqueueSnackbar('Employee certification deleted successfuly.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                onDeleted(certificationId);
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar(
                    'Error encountered while deleting the certification.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000
                    }
                );
            });
    };

    return (
        <Modal open={open} blocking={blocking} handleClose={handleClose}>
            <Modal.Title>Delete Employee Certification</Modal.Title>
            <Modal.Body>
                <div
                    style={{
                        display: 'flex',
                        padding: '12px 0',
                        marginTop: '24px'
                    }}
                >
                    Are you sure you want to delete this certification?
                </div>
            </Modal.Body>
            <Modal.Actions
                buttons={[
                    {
                        label: 'Cancel',
                        onClick: handleClose,
                        type: 'secondary',
                        variant: 'border'
                    },
                    {
                        label: 'Confirm',
                        onClick: handleDelete,
                        type: 'primary'
                    }
                ]}
            />
        </Modal>
    );
};

DeleteEmployeeCertificationModal.propTypes = {
    open: PropTypes.bool,
    blocking: PropTypes.bool,
    handleClose: PropTypes.func,
    onDeleted: PropTypes.func,
    certificationId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool
    ])
};

export default DeleteEmployeeCertificationModal;
