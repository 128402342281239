import React, { useEffect, useState } from 'react';

import useApi from '../../../../hooks/useApi';
import useLocalStorage from '../../../../hooks/useLocalStorage';

import Button from '../../../general/input/Button';
import Dropdown from '../../../general/input/Dropdown';
import InputLabel from '../../../general/input/InputLabel';
import Select from '../../../general/input/Select';
import Modal from '../../../general/modal/Modal';
import { useNavigate } from 'react-router-dom';

import styles from '../../../../styles/apps/pretask/modal/NewPretaskModal.module.scss';

const NewPretaskModal = ({ handleClose = () => null }) => {
    const navigate = useNavigate();

    const [{ data: divisions, loading: loadingDivisions }] = useApi(
        '/divisions?includeDepartments=true',
        'GET'
    );
    const [defaults, setDefaults] = useLocalStorage('new-pretask-defaults');

    const [division, setDivision] = useState(defaults?.divisionId ?? null);
    const [divisionError, setDivisionError] = useState(null);
    const [department, setDepartment] = useState(
        defaults?.departmentId ?? null
    );
    const [departments, setDepartments] = useState(null);
    const [jobNumber, setJobNumber] = useState(
        defaults?.job &&
            typeof defaults.job === 'object' &&
            Object.keys(defaults.job).length > 0
            ? [defaults.job]
            : []
    );

    useEffect(() => {
        setDepartments(
            divisions
                ?.find((d) => d.id === division?.key)
                ?.departments?.filter?.(
                    (department) => department.name !== division?.value
                )
                ?.map?.((department) => ({
                    key: department.id,
                    value: department.name
                }))
        );
    }, [division, divisions]);

    const handleDivision = (selected) => {
        setDivision((division) => {
            if (division?.key !== selected?.key) setDepartment(null);
            return selected;
        });
    };

    const handleDepartment = (selected) => {
        setDepartment(selected);
    };

    const handleJobNumber = (selected) => {
        setJobNumber(selected);
    };

    const handleCreate = () => {
        if (!division?.key)
            return setDivisionError('You must choose a division.');

        let query = [];
        if (division?.key) query = [...query, `divisionId=${division?.key}`];
        if (department?.key)
            query = [...query, `departmentId=${department?.key}`];
        if (jobNumber && jobNumber.length > 0)
            query = [...query, `jobId=${jobNumber[0]?.id}`];
        setDefaults({
            divisionId: division,
            departmentId: department,
            job: {
                id: jobNumber[0]?.id,
                vistaJobNumber: jobNumber[0]?.vistaJobNumber,
                vistaJobDescription: jobNumber[0]?.vistaJobDescription
            }
        });
        navigate({
            pathname: '/pretask/new',
            search: query.join('&')
        });
    };

    return (
        <Modal
            open={true}
            handleClose={handleClose}
            style={{ content: { minHeight: '360px' } }}
        >
            <Modal.Body>
                <div className={styles.container}>
                    <div className={styles.customizePromptContainer}>
                        <div className={styles.header}>New Pretask</div>
                        <div className={styles.inputs}>
                            <InputLabel
                                label="Division"
                                required={true}
                                error={divisionError}
                            >
                                <Dropdown
                                    placeholder="Division"
                                    options={divisions?.map((division) => ({
                                        key: division.id,
                                        value: division.name
                                    }))}
                                    handleSelect={handleDivision}
                                    selected={division}
                                    loading={loadingDivisions}
                                />
                            </InputLabel>
                            {departments && departments?.length > 0 && (
                                <InputLabel label="Department (optional)">
                                    <Dropdown
                                        placeholder="Department"
                                        options={departments}
                                        selected={department}
                                        handleSelect={handleDepartment}
                                    />
                                </InputLabel>
                            )}
                            <InputLabel label="Job (optional)">
                                <Select
                                    placeholder="Job Number"
                                    filters={{
                                        isActive: {
                                            value: true,
                                            getFilter: (isActive) => ({
                                                IsActive: isActive
                                            })
                                        }
                                    }}
                                    handleRowSelection={handleJobNumber}
                                    selected={jobNumber}
                                    getRowValue={(row) =>
                                        row?.vistaJobNumber ||
                                        row?.vistaJobDescription
                                            ? `${row?.vistaJobNumber ?? ''} - ${
                                                  row?.vistaJobDescription ?? ''
                                              }`
                                            : undefined
                                    }
                                    getRowId={(row) => row.id}
                                    pagination={{ url: '/jobs' }}
                                />
                            </InputLabel>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    type="secondary"
                    variant="border"
                    onClick={handleClose}
                />
                <Button label="Create" onClick={handleCreate} />
            </Modal.Actions>
        </Modal>
    );
};

export default NewPretaskModal;
