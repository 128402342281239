import React, { useRef, useState } from 'react';

import InputLabel from '../input/InputLabel';
import SignaturePad from '../input/SignaturePad';
import TextInput from '../input/TextInput';
import Modal from './Modal';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../../../styles/general/modal/SignatureModal.module.scss';

const SignatureModal = ({
    header,
    required = true,
    editableHeader = {},
    handleSave: _handleSave = () => null,
    handleClose = () => null
}) => {
    const signatureAPI = useRef();

    const [editedHeader, setEditedHeader] = useState(
        editableHeader?.value ?? ''
    );
    const [headerError, setHeaderError] = useState(null);
    const [signatureError, setSignatureError] = useState(null);

    const handleSave = () => {
        setHeaderError(null);
        setSignatureError(null);
        if (
            editableHeader?.required &&
            (editedHeader ?? '')?.replace?.(/\s/g, '') === ''
        )
            setHeaderError(
                editableHeader?.requiredMessage ?? 'This field is required.'
            );
        else if (required && signatureAPI.current.isEmpty())
            setSignatureError('The signature is required.');
        else
            _handleSave(
                signatureAPI?.current?.toDataURL(),
                editableHeader ? editedHeader : header
            );
    };

    const handleEditHeader = (e) => {
        setEditedHeader(e?.target?.value);
    };

    return (
        <Modal
            open={true}
            classes={{ dismiss: styles.dismiss }}
            handleClose={handleClose}
        >
            <Modal.Body style={{ content: { padding: 0 } }}>
                <div className={styles.container}>
                    <div className={styles.header}>
                        {editableHeader && Object.keys(editableHeader).length !== 0 ? (
                            <InputLabel
                                classes={{
                                    container: styles.headerLabel,
                                    content: styles.headerLabel
                                }}
                                label={editableHeader.label}
                                required={editableHeader.required}
                                error={headerError ?? editableHeader.error}
                            >
                                <TextInput
                                    value={editedHeader}
                                    onChange={handleEditHeader}
                                    readonly={editableHeader.readonly}
                                />
                            </InputLabel>
                        ) : (
                            header
                        )}
                    </div>
                    <SignaturePad
                        ref={signatureAPI}
                        required={required}
                        error={signatureError}
                    />
                    <div className={styles.actions}>
                        <div onClick={handleClose} className={styles.cancel}>
                            Cancel
                        </div>
                        <div onClick={handleSave} className={styles.save}>
                            <FontAwesomeIcon icon={faSave} />
                            <p>Save</p>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

SignatureModal.propTypes = {};

export default SignatureModal;
