import { useCallback, useRef, useState } from 'react';

import useFilterReducer from '../../../../hooks/useFilterReducer';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import useUser from '../../../../hooks/useUser';

import {
    QualityAttachmentTagPermissions,
    QualityFormSetupPermissions
} from '../../../../js/services/permissions';
import { isNullOrEmpty } from '../../../../js/services/validation';
import ActionCell from '../../../general/grid/cell renderers/ActionCell';
import TextCell from '../../../general/grid/cell renderers/TextCell';
import Filters from '../../../general/grid/Filters';
import Grid from '../../../general/grid/Grid';
import DateRange from '../../../general/input/DateRange';
import Select from '../../../general/input/Select';
import DeleteEquipmentModal from '../modal/DeleteEquipmentModal';
import EditEquipmentModal from '../modal/EditEquipmentModal';
import NewEquipmentAttachmentModal from '../modal/NewEquipmentAttachmentModal';
import NewEquipmentFormModal from '../modal/NewEquipmentFormModal';
import NewEquipmentModal from '../modal/NewEquipmentModal';
import {
    faCheck,
    faFileCirclePlus,
    faFileUpload,
    faFilter,
    faMinus,
    faPencilAlt,
    faPlus,
    faTimes,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useOutletContext } from 'react-router-dom';

import styles from '../../../../styles/apps/quality/grid/QualityGrid.module.scss';

const defaultFilterState = {
    equipment: {
        defaultValue: [],
        value: [],
        getFilter: (equipment) =>
            equipment.length === 0
                ? null
                : { EquipmentIds: equipment.map((e) => e.id) }
    },
    date: {
        value: {
            to: null,
            from: null
        },
        getFilter: (value) => {
            if (!value.to && !value.from) return null;
            let filterObj = {};
            if (value.to) filterObj['DateTo'] = value.to;
            if (value.from) filterObj['DateFrom'] = value.from;
            return filterObj;
        }
    },
    forms: {
        value: [],
        getFilter: (forms) =>
            forms.length === 0
                ? null
                : { FormTypeIds: forms.map((form) => form?.id) }
    },
    tags: {
        value: [],
        getFilter: (tags) =>
            tags.length === 0 ? null : { TagIds: tags.map((tag) => tag?.id) }
    }
};

const EquipmentGrid = () => {
    const gridRef = useRef();
    const navigate = useNavigate();
    const { job } = useOutletContext();
    const { userHasPermissions, userHasJobPermissions } = useUser();
    const [, setFormFilter] = useLocalStorage('quality_form_grid_filters');
    const [, setAttachmentFilter] = useLocalStorage(
        'quality_attachment_grid_filters'
    );

    const { filter, setFilter, resetFilter } = useFilterReducer(
        defaultFilterState,
        'quality_equipment_grid_filters'
    );

    const [modals, setModals] = useState({
        new: false,
        form: false,
        attachment: false,
        edit: false,
        delete: false
    });

    const handleOpenModal = (type, value) =>
        setModals((modals) => ({
            ...modals,
            [type]: value ?? true
        }));

    const handleCloseModal = (type) =>
        setModals({
            ...modals,
            [type]: false
        });

    const clearFilters = useCallback(() => {
        resetFilter();
    }, [resetFilter]);

    const handleFilterChange = (key, value) =>
        setFilter({
            key: key,
            payload: value
        });

    const handleDateChange = (key, date) =>
        setFilter({
            key: 'date',
            payload: {
                ...filter['date'].value,
                [key]: date
            }
        });

    const handleNavigateToForms = ({ id, name }) => {
        setFormFilter({
            equipment:
                !id || !name
                    ? []
                    : [
                          {
                              id,
                              name
                          }
                      ]
        });
        navigate('../forms');
    };

    const handleNavigateToAttachments = ({ id, name }) => {
        setAttachmentFilter({
            equipment:
                !id || !name
                    ? []
                    : [
                          {
                              id,
                              name
                          }
                      ]
        });
        navigate('../attachments');
    };

    const handleNewEquipment = (equipment) => {
        gridRef?.current?.addRow(equipment);
    };

    const handleEditEquipment = (equipment) => {
        gridRef?.current?.modifyRow(equipment.id, equipment);
    };

    const handleDeleteEquipment = (id) => {
        gridRef?.current?.removeRow(id);
    };

    const handleNewForms = (forms, id) => {
        let equipment = gridRef?.current?.getRow(id);
        gridRef?.current?.modifyRow(id, {
            ...equipment,
            equipmentForms: [...equipment?.equipmentForms, ...forms]
        });
    };

    const handleNewAttachments = (equipmentAttachments, id) => {
        let equipment = gridRef?.current?.getRow(id);
        gridRef?.current?.modifyRow(id, {
            ...equipment,
            attachments: [
                ...(equipment?.attachments ?? []),
                ...equipmentAttachments?.map((ea) => ea.attachment)
            ]
        });
    };

    return (
        <div
            style={{
                height: '100%',
                width: '100%'
            }}
        >
            <Grid
                ref={gridRef}
                filters={filter}
                actions={[
                    userHasPermissions(QualityFormSetupPermissions) ||
                    userHasJobPermissions(QualityFormSetupPermissions)
                        ? {
                              type: 'primary',
                              label: 'New',
                              icon: faPlus,
                              onClick: handleOpenModal.bind(this, 'new')
                          }
                        : null
                ]}
                fixed
                multiselect
                rowSelect
                handleRowSelection={() => null}
                selected={[]}
                getRowId={(r) => r.id}
                pagination={{
                    url: `/quality/${job.id}/equipment`,
                    pageSize: 100
                }}
                columns={[
                    {
                        title: 'Date',
                        key: 'createdOn',
                        dataKey: 'createdOn',
                        sortKey: 'createdon',
                        sortable: true,
                        width: 120,
                        minWidth: 120,
                        cellRenderer: ({ cellData }) =>
                            cellData && (
                                <TextCell>
                                    {cellData &&
                                        new Intl.DateTimeFormat('en-US', {
                                            timeZone: 'UTC',
                                            month: '2-digit',
                                            day: '2-digit',
                                            year: 'numeric'
                                        }).format(new Date(cellData))}
                                </TextCell>
                            )
                    },
                    {
                        title: 'Equipment',
                        key: 'name',
                        dataKey: 'name',
                        sortKey: 'equipment',
                        sortable: true,
                        width: 300,
                        minWidth: 300,
                        fixedGrow: 1,
                        cellRenderer: ({ cellData }) => (
                            <TextCell>{cellData}</TextCell>
                        )
                    },
                    /* {
                        title: 'Job',
                        key: 'job',
                        sortKey: 'job',
                        sortable: true,
                        width: 200,
                        minWidth: 200,
                        cellRenderer: ({ rowData }) => (
                            <TextCell>
                                {rowData.job?.vistaJobNumber} -{' '}
                                {rowData.job?.vistaJobDescription}
                            </TextCell>
                        )
                    }, */
                    {
                        title: 'Forms',
                        key: 'forms',
                        sortKey: 'form',
                        sortable: true,
                        width: 120,
                        minWidth: 120,
                        cellRenderer: ({ rowData }) => (
                            <TextCell>
                                {rowData.equipmentForms?.length ? (
                                    <p
                                        onClick={handleNavigateToForms.bind(
                                            this,
                                            rowData
                                        )}
                                        className={styles.attachmentLink}
                                    >{`${rowData.equipmentForms?.length} forms`}</p>
                                ) : (
                                    ''
                                )}
                            </TextCell>
                        )
                    },
                    {
                        title: 'Tags',
                        key: 'tags',
                        sortKey: 'tag',
                        sortable: true,
                        width: 120,
                        minWidth: 120,
                        cellRenderer: ({ rowData }) => (
                            <TextCell
                                hoverTrigger="always"
                                clickTrigger="always"
                                hoverDelay={0}
                                tooltip={rowData?.qualityTags?.map?.(
                                    (qt, i) => (
                                        <div key={i}>{qt.name}</div>
                                    )
                                )}
                            >
                                {rowData.qualityTags?.length ? (
                                    <p
                                        className={styles.attachmentLink}
                                    >{`${rowData.qualityTags?.length} tags`}</p>
                                ) : (
                                    ''
                                )}
                            </TextCell>
                        )
                    },
                    {
                        title: 'Attachments',
                        key: 'attachments',
                        sortKey: 'attachment',
                        sortable: true,
                        width: 120,
                        minWidth: 120,
                        cellRenderer: ({ rowData }) => (
                            <TextCell>
                                {rowData.attachments?.length ? (
                                    <p
                                        onClick={handleNavigateToAttachments.bind(
                                            this,
                                            rowData
                                        )}
                                        className={styles.attachmentLink}
                                    >{`${rowData.attachments?.length} attachments`}</p>
                                ) : (
                                    ''
                                )}
                            </TextCell>
                        )
                    },
                    {
                        title: 'Completion Status',
                        key: 'status',
                        sortKey: 'status',
                        dataKey: 'status',
                        sortable: true,
                        width: 130,
                        minWidth: 90,
                        cellRenderer: ({ cellData, rowData }) =>
                            isNullOrEmpty(rowData?.equipmentForms) ? (
                                <p className={styles.noForms}>
                                    <FontAwesomeIcon icon={faMinus} />
                                </p>
                            ) : (
                                <TextCell
                                    hoverTrigger="always"
                                    hoverDelay={0}
                                    tooltip={
                                        isNullOrEmpty(
                                            rowData?.equipmentForms
                                        ) ? (
                                            <p>-</p>
                                        ) : (
                                            rowData?.equipmentForms?.map?.(
                                                (ef, i) => (
                                                    <div
                                                        key={i}
                                                        className={
                                                            ef.isLocked
                                                                ? styles.completed
                                                                : styles.incomplete
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={
                                                                ef.isLocked
                                                                    ? faCheck
                                                                    : faTimes
                                                            }
                                                        />
                                                        {ef.formTemplate?.name}
                                                    </div>
                                                )
                                            )
                                        )
                                    }
                                >
                                    <p className={styles.iconAttachmentLink}>
                                        <FontAwesomeIcon
                                            icon={
                                                cellData === 'Completed'
                                                    ? faCheck
                                                    : faTimes
                                            }
                                        />
                                    </p>
                                </TextCell>
                            )
                    },
                    ...(userHasPermissions(QualityFormSetupPermissions) ||
                    userHasJobPermissions(QualityFormSetupPermissions) ||
                    userHasJobPermissions(
                        QualityAttachmentTagPermissions,
                        'or'
                    ) ||
                    userHasPermissions(QualityAttachmentTagPermissions, 'or')
                        ? [
                              {
                                  title: ' ',
                                  key: 'actions',
                                  sortable: false,
                                  frozen: 'right',
                                  width:
                                      userHasPermissions(
                                          QualityFormSetupPermissions
                                      ) ||
                                      userHasJobPermissions(
                                          QualityFormSetupPermissions
                                      )
                                          ? 140
                                          : userHasJobPermissions(
                                                QualityAttachmentTagPermissions,
                                                'or'
                                            ) ||
                                            userHasPermissions(
                                                QualityAttachmentTagPermissions,
                                                'or'
                                            )
                                          ? 60
                                          : 0,
                                  minWidth:
                                      userHasPermissions(
                                          QualityFormSetupPermissions
                                      ) ||
                                      userHasJobPermissions(
                                          QualityFormSetupPermissions
                                      )
                                          ? 140
                                          : userHasJobPermissions(
                                                QualityAttachmentTagPermissions,
                                                'or'
                                            ) ||
                                            userHasPermissions(
                                                QualityAttachmentTagPermissions,
                                                'or'
                                            )
                                          ? 60
                                          : 0,
                                  cellRenderer: ({ rowData }) => (
                                      <ActionCell
                                          actions={
                                              userHasPermissions(
                                                  QualityFormSetupPermissions
                                              ) ||
                                              userHasJobPermissions(
                                                  QualityFormSetupPermissions
                                              )
                                                  ? [
                                                        {
                                                            icon: faFileCirclePlus,
                                                            type: 'grayscale',
                                                            onClick:
                                                                handleOpenModal.bind(
                                                                    this,
                                                                    'form',
                                                                    rowData
                                                                ),
                                                            tooltip: {
                                                                tooltip:
                                                                    'New Forms',
                                                                hoverDelay: 650,
                                                                hoverTrigger:
                                                                    'always'
                                                            }
                                                        },
                                                        {
                                                            icon: faFileUpload,
                                                            type: 'grayscale',
                                                            onClick:
                                                                handleOpenModal.bind(
                                                                    this,
                                                                    'attachment',
                                                                    rowData
                                                                ),
                                                            tooltip: {
                                                                tooltip:
                                                                    'Add Attachments',
                                                                hoverDelay: 650,
                                                                hoverTrigger:
                                                                    'always'
                                                            }
                                                        },
                                                        {
                                                            icon: faPencilAlt,
                                                            type: 'grayscale',
                                                            onClick:
                                                                handleOpenModal.bind(
                                                                    this,
                                                                    'edit',
                                                                    rowData
                                                                ),
                                                            tooltip: {
                                                                tooltip: 'Edit',
                                                                hoverDelay: 650,
                                                                hoverTrigger:
                                                                    'always'
                                                            }
                                                        },
                                                        {
                                                            icon: faTrashAlt,
                                                            type: 'grayscale',
                                                            onClick:
                                                                handleOpenModal.bind(
                                                                    this,
                                                                    'delete',
                                                                    rowData
                                                                ),
                                                            tooltip: {
                                                                tooltip:
                                                                    'Delete',
                                                                hoverDelay: 650,
                                                                hoverTrigger:
                                                                    'always'
                                                            }
                                                        }
                                                    ]
                                                  : [
                                                        userHasJobPermissions(
                                                            QualityAttachmentTagPermissions,
                                                            'or'
                                                        ) ||
                                                        userHasPermissions(
                                                            QualityAttachmentTagPermissions,
                                                            'or'
                                                        )
                                                            ? {
                                                                  icon: faFileUpload,
                                                                  type: 'grayscale',
                                                                  onClick:
                                                                      handleOpenModal.bind(
                                                                          this,
                                                                          'attachment',
                                                                          rowData
                                                                      ),
                                                                  tooltip: {
                                                                      tooltip:
                                                                          'Add Attachments',
                                                                      hoverDelay: 650,
                                                                      hoverTrigger:
                                                                          'always'
                                                                  }
                                                              }
                                                            : null
                                                    ]
                                          }
                                      />
                                  )
                              }
                          ]
                        : [])
                ]}
                sidepanel={{
                    filters: {
                        label: 'Filters',
                        icon: faFilter,
                        component: Filters,
                        props: {
                            clearFilters: clearFilters,
                            filters: [
                                {
                                    label: 'Equipment',
                                    component: Select,
                                    props: {
                                        placeholder: 'Select Equipment',
                                        multiselect: true,
                                        handleRowSelection:
                                            handleFilterChange.bind(
                                                this,
                                                'equipment'
                                            ),
                                        selected: filter['equipment'].value,
                                        getRowValue: (row) => row?.name,
                                        getRowId: (row) => row.id,
                                        pagination: {
                                            url: `/quality/${job.id}/equipment`,
                                            record: job.id
                                        },
                                        sort: ['equipment']
                                    }
                                },
                                {
                                    label: 'Form Type',
                                    component: Select,
                                    props: {
                                        placeholder: 'Select Form Type(s)',
                                        multiselect: true,
                                        filter: {
                                            InUse: {
                                                value: true,
                                                getFilter: () => ({
                                                    InUse: true
                                                })
                                            }
                                        },
                                        handleRowSelection:
                                            handleFilterChange.bind(
                                                this,
                                                'forms'
                                            ),
                                        selected: filter['forms'].value,
                                        getRowValue: (row) => row?.name,
                                        getRowNodeId: (row) => row.id,
                                        pagination: {
                                            url: `/quality/${job.id}/form/templates`,
                                            record: job.id,
                                            params: {
                                                jobSpecific: true
                                            }
                                        },
                                        sort: ['name']
                                    }
                                },
                                {
                                    label: 'Tags',
                                    component: Select,
                                    props: {
                                        placeholder: 'Select Tag(s)',
                                        multiselect: true,
                                        handleRowSelection:
                                            handleFilterChange.bind(
                                                this,
                                                'tags'
                                            ),
                                        selected: filter['tags'].value,
                                        getRowValue: (row) => row?.name,
                                        getRowNodeId: (row) => row.id,
                                        pagination: {
                                            url: `/quality/${job.id}/tags`,
                                            record: job.id
                                        },
                                        sort: ['name']
                                    }
                                },
                                {
                                    label: 'Date',
                                    component: DateRange,
                                    props: {
                                        to: filter.date?.value.to
                                            ? new Date(filter.date.value.to)
                                            : null,
                                        from: filter.date?.value.from
                                            ? new Date(filter.date.value.from)
                                            : null,
                                        handleChange: handleDateChange
                                    }
                                }
                            ]
                        }
                    }
                }}
            />
            {modals.new && (
                <NewEquipmentModal
                    handleClose={handleCloseModal.bind(this, 'new')}
                    handleNew={handleNewEquipment}
                    jobId={job.id}
                />
            )}
            {modals.form && (
                <NewEquipmentFormModal
                    equipment={modals.form}
                    handleClose={handleCloseModal.bind(this, 'form')}
                    handleNew={handleNewForms}
                    jobId={job.id}
                />
            )}
            {modals.attachment && (
                <NewEquipmentAttachmentModal
                    equipment={modals.attachment}
                    handleClose={handleCloseModal.bind(this, 'attachment')}
                    handleNew={handleNewAttachments}
                    jobId={job.id}
                />
            )}
            {modals.edit && (
                <EditEquipmentModal
                    equipment={modals.edit}
                    handleEdit={handleEditEquipment}
                    handleClose={handleCloseModal.bind(this, 'edit')}
                    jobId={job.id}
                />
            )}
            {modals.delete && (
                <DeleteEquipmentModal
                    equipment={modals.delete}
                    handleClose={handleCloseModal.bind(this, 'delete')}
                    handleDelete={handleDeleteEquipment}
                />
            )}
        </div>
    );
};

export default EquipmentGrid;
