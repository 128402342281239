import { TextTooltip } from '../../Tooltip';
import Cell from './Cell';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';

import styles from '../../../../styles/general/grid/cell renderers/TextCell.module.scss';

const TextCell = ({ children, tooltip, ...props }) => (
    <TextTooltip tooltip={tooltip ?? children} hoverDelay={1200} hoverTrigger='overflow' clickTrigger='overflow' {...props}>
        {isFunction(children) ? children() : children}
    </TextTooltip>
);

const TextContextCell = ({ children, ...props }) => (
    <Cell customClasses={styles.contextCell}>
        <TextCell children={children} {...(props?.text ?? {})} />
        {props.context && (
            <TextCell
                children={props.contextLabel}
                tooltip={props.contextTooltip}
                classes={{
                    textTooltipContent: styles.contextLabel,
                    tooltipContainer: styles.tooltipContainer
                }}
                {...(props?.context ?? {})}
            />
        )}
    </Cell>
);

TextContextCell.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.func
    ]),
    text: PropTypes.object,
    contextLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    contextTooltip: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.func,
        PropTypes.array
    ])
};

export { TextContextCell };
export default TextCell;
