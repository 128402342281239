import React, { useEffect, useRef } from 'react';

import CheckboxGroup from '../../general/input/CheckboxGroup';
import TextInput from '../../general/input/TextInput';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import styles from '../../../styles/apps/pretask/PretaskChecklist.module.scss';

const ChecklistItem = ({
    label,
    option,
    valueCondition = '',
    value,
    handleChange,
    handleSelect,
    readOnly
}) => {
    const inputRef = useRef();
    const initialRef = useRef(false);

    useEffect(() => {
        if (!initialRef.current) {
            initialRef.current = true;
        } else {
            if (valueCondition?.split?.('.')?.includes?.(option))
                inputRef?.current?.focus?.();
        }
    }, [option, valueCondition]);

    return (
        <div className={styles.item}>
            <div className={styles.header}>
                <div className={styles.label}>{label}</div>
                <div className={styles.options}>
                    <CheckboxGroup
                        options={[
                            { key: 'YES', value: 'Yes' },
                            { key: 'NO', value: 'No' },
                            { key: 'NA', value: 'N/A' }
                        ]}
                        selected={option}
                        style={{
                            input: {
                                container: {
                                    margin: 0,
                                    padding: 0,
                                    borderRight: '1px solid rgba(0,0,0,0.1)',
                                    width: '70px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    pageBreakInside: 'avoid'
                                },
                                label: {
                                    paddingTop: '0px'
                                },
                                content: {
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: readOnly ? 'unset' : 'pointer',
                                    pageBreakInside: 'avoid'
                                }
                            }
                        }}
                        handleChange={handleSelect}
                        readOnly={readOnly}
                    />
                </div>
            </div>
            {valueCondition?.split?.('.')?.includes?.(option) && (
                <div className={styles.body}>
                    <div>
                        {readOnly ? (
                            <div className={styles.value}>
                                {value ?? 'No answer provided.'}
                            </div>
                        ) : (
                            <TextInput
                                value={value}
                                onChange={handleChange}
                                placeholder={`Explain your checklist selection`}
                                inputRef={inputRef}
                                icon={faClipboardList}
                                style={{
                                    icon: {
                                        color: 'var(--primary-theme-color-light)'
                                    }
                                }}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

const PretaskChecklist = ({
    checklists = [],
    handleChange: _handleChange = () => null,
    readOnly = false,
    error = []
}) => {
    const handleSelect = (id, selection) => {
        _handleChange(
            checklists.map((checklist) =>
                checklist.id === id
                    ? { ...checklist, value: selection }
                    : checklist
            )
        );
    };

    const handleChange = (id, e) => {
        _handleChange(
            checklists.map((checklist) =>
                checklist.id === id
                    ? { ...checklist, responseValue: e?.target?.value }
                    : checklist
            )
        );
    };

    return (
        <div className={styles.container}>
            {checklists
                .sort((a, b) =>
                    a.sequence > b.sequence
                        ? 1
                        : a.sequence === b.sequence
                        ? 0
                        : -1
                )
                ?.map((checklist) => (
                    <div
                        className={[
                            styles.checklistItem,
                            styles.readOnly,
                            error?.includes?.(checklist.id)
                                ? styles.error
                                : null
                        ].join(' ')}
                        key={checklist.id}
                    >
                        <ChecklistItem
                            label={checklist.name}
                            option={checklist.value}
                            valueCondition={checklist.responseFlag}
                            value={checklist.responseValue}
                            readOnly={readOnly}
                            handleChange={handleChange.bind(this, checklist.id)}
                            handleSelect={handleSelect.bind(this, checklist.id)}
                        />
                    </div>
                ))}
        </div>
    );
};

PretaskChecklist.propTypes = {
    checklists: PropTypes.arrayOf(
        PropTypes.shape({
            checklistName: PropTypes.string,
            checklistResponseFlag: PropTypes.string,
            checklistResponseValue: PropTypes.string,
            checklistValue: PropTypes.string
        })
    ),
    handleChange: PropTypes.func,
    readOnly: PropTypes.bool
};

export default PretaskChecklist;
