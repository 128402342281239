import { useRef, useState } from 'react';

import useFilterReducer from '../../../../hooks/useFilterReducer';
import useUser from '../../../../hooks/useUser';

import {
    ContactsFlagCreatePermissions,
    ContactsFlagDeletePermissions,
    ContactsFlagUpdatePermissions
} from '../../../../js/services/permissions';
import ActionCell from '../../../general/grid/cell renderers/ActionCell';
import TextCell from '../../../general/grid/cell renderers/TextCell';
import Filters from '../../../general/grid/Filters';
import Grid from '../../../general/grid/Grid';
import TextInput from '../../../general/input/TextInput';
import DeleteFlagModal from '../modal/DeleteFlagModal';
import EditFlagModal from '../modal/EditFlagModal';
import NewFlagModal from '../modal/NewFlagModal';
import {
    faCheck,
    faFilter,
    faPencilAlt,
    faPlus,
    faTimes,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../../../../styles/apps/contact/grid/ContactGrid.module.scss';

const defaultFilterState = {
    flag: {
        value: '',
        getFilter: (value) =>
            !value || value.replace(/\s/g, '') === '' ? null : { Name: value }
    }
};

const FlagGrid = () => {
    const gridRef = useRef();

    const { userHasPermissions } = useUser();

    const { filter, setFilter, resetFilter } =
        useFilterReducer(defaultFilterState);

    const [selected, setSelected] = useState([]);
    const [modalState, setModalState] = useState({
        new: false,
        edit: false,
        delete: false,
        export: false
    });

    const handleRowSelection = (rows) => {
        setSelected(rows);
    };

    /* Filter Handlers */
    const clearFilters = () => resetFilter();

    const handleFilterChange = (key, value) => {
        setFilter({
            key: key,
            payload: value
        });
    };

    const handleOpenModal = (type, value) =>
        setModalState((modalState) => ({
            ...modalState,
            [type]: value ?? true
        }));

    const handleCloseModal = (type) =>
        setModalState((modalState) => ({
            ...modalState,
            [type]: null
        }));

    const handleNew = (flag) => gridRef?.current?.addRow(flag);
    const handleEdit = (id, flag) => gridRef?.current?.modifyRow(id, flag);
    const handleDelete = (id) => gridRef?.current?.removeRow(id);

    return (
        <div className={styles.container}>
            <Grid
                ref={gridRef}
                pagination={{
                    url: '/contact/flag',
                    pageSize: 100
                }}
                fixed
                selected={selected}
                filters={filter}
                rowKey="id"
                getRowId={(r) => r.id}
                handleRowSelection={handleRowSelection}
                actions={[
                    {
                        type: 'primary',
                        label: 'New',
                        icon: faPlus,
                        onClick: handleOpenModal.bind(this, 'new'),
                        permission: ContactsFlagCreatePermissions
                    }
                ].filter(
                    (action) =>
                        !action.permission ||
                        userHasPermissions(action.permission)
                )}
                sidepanel={{
                    filters: {
                        label: 'Filters',
                        icon: faFilter,
                        component: Filters,
                        props: {
                            clearFilters: clearFilters,
                            filters: [
                                {
                                    component: TextInput,
                                    label: 'Flag',
                                    width: '100%',
                                    props: {
                                        placeholder: 'Flag Name',
                                        value: filter['flag']?.value,
                                        onChange: (e) =>
                                            handleFilterChange(
                                                'flag',
                                                e.target.value
                                            )
                                    }
                                }
                            ]
                        }
                    }
                }}
                columns={[
                    {
                        title: 'Flag',
                        key: 'Flag',
                        dataKey: 'name',
                        sortKey: 'name',
                        width: 300,
                        maxWidth: 300,
                        fixedGrow: 1,
                        cellRenderer: ({ cellData }) =>
                            cellData && <TextCell>{cellData}</TextCell>
                    },
                    {
                        title: 'Contacts',
                        key: 'Contacts',
                        dataKey: 'contacts',
                        sortKey: 'contacts',
                        width: 300,
                        maxWidth: 300,
                        cellRenderer: ({ cellData }) =>
                            cellData && (
                                <TextCell>
                                    {cellData?.length} Contact(s)
                                </TextCell>
                            )
                    },
                    {
                        title: 'Selected By Default',
                        key: 'Default Selected',
                        dataKey: 'defaultSelected',
                        sortKey: 'selected',
                        width: 150,
                        maxWidth: 150,
                        cellRenderer: ({ cellData }) => (
                            <TextCell>
                                {
                                    <FontAwesomeIcon
                                        icon={cellData ? faCheck : faTimes}
                                    />
                                }
                            </TextCell>
                        )
                    },
                    {
                        title: ' ',
                        key: 'actions',
                        sortable: false,
                        width: 85,
                        minWidth: 85,
                        cellRenderer: ({ rowData }) => (
                            <ActionCell
                                actions={[
                                    ...(userHasPermissions(
                                        ContactsFlagUpdatePermissions
                                    )
                                        ? [
                                              {
                                                  icon: faPencilAlt,
                                                  type: 'grayscale',
                                                  onClick: handleOpenModal.bind(
                                                      this,
                                                      'edit',
                                                      rowData
                                                  ),
                                                  tooltip: {
                                                      tooltip: 'Edit',
                                                      hoverDelay: 650,
                                                      hoverTrigger: 'always'
                                                  }
                                              }
                                          ]
                                        : []),
                                    ...(userHasPermissions(
                                        ContactsFlagDeletePermissions
                                    )
                                        ? [
                                              {
                                                  icon: faTrashAlt,
                                                  type: 'grayscale',
                                                  onClick: handleOpenModal.bind(
                                                      this,
                                                      'delete',
                                                      rowData
                                                  ),
                                                  tooltip: {
                                                      tooltip: 'Delete',
                                                      hoverDelay: 650,
                                                      hoverTrigger: 'always'
                                                  }
                                              }
                                          ]
                                        : [])
                                ]}
                            />
                        )
                    }
                ]}
            />
            {modalState.delete && (
                <DeleteFlagModal
                    handleClose={handleCloseModal.bind(this, 'delete')}
                    handleDelete={handleDelete}
                    record={modalState.delete}
                />
            )}
            {modalState.edit && (
                <EditFlagModal
                    handleClose={handleCloseModal.bind(this, 'edit')}
                    handleEdit={handleEdit}
                    record={modalState.edit}
                />
            )}
            {modalState.new && (
                <NewFlagModal
                    handleClose={handleCloseModal.bind(this, 'new')}
                    handleNew={handleNew}
                />
            )}
        </div>
    );
};

export default FlagGrid;
