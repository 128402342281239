import React, { useEffect } from 'react';

import useToggle from '../../../hooks/useToggle';

import PropTypes from 'prop-types';

import styles from '../../../styles/general/input/Toggle.module.scss';

const Toggle = ({ handleToggled, defaultToggled = true }) => {
    const [toggled, toggle] = useToggle(defaultToggled);

    useEffect(() => {
        if (handleToggled) handleToggled(toggled);
    }, [toggled, handleToggled]);

    return (
        <div
            className={[styles.toggle, toggled ? styles.on : styles.off].join(
                ' '
            )}
            onClick={() => toggle()}
        />
    );
};

Toggle.propTypes = {
    handleToggled: PropTypes.func,
    defaultToggled: PropTypes.bool
};

export default Toggle;
