import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';

import useApi from '../../../hooks/useApi';
import useUser from '../../../hooks/useUser';

import { SuggestionBoxReviewActionPermissions } from '../../../js/services/permissions';
import ErrorCode from '../../config/ErrorBoundaries/ErrorCode';
import FileUpload from '../../general/input/FileUpload';
import LoadingOverlay from '../../general/LoadingOverlay';
import { TextTooltip } from '../../general/Tooltip';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import styles from '../../../styles/apps/suggestion_box/SuggestionDetail.module.scss';

const SuggestionDetail = forwardRef(
    ({ suggestion, handleClose, handleReview = () => null }, ref) => {
        const [{ data, loading }, getSuggestion] = useApi(
            `/suggestions/${suggestion?.id}`,
            'GET',
            { manual: true }
        );
        const [
            {
                data: attachments,
                error: attachmentError,
                loading: loadingAttachments
            },
            getAttachments
        ] = useApi(`/suggestions/${suggestion?.id}/attachments`, 'GET', {
            manual: true
        });

        const { userHasPermissions } = useUser();

        const [, setError] = useState(null);

        const stableGetSuggestion = useRef(getSuggestion);
        const stableGetAttachments = useRef(getAttachments);

        useImperativeHandle(ref, () => ({
            refetch: () => {
                if (!suggestion?.id) return;

                stableGetSuggestion
                    .current({
                        url: `/suggestions/${suggestion?.id}`
                    })
                    .catch((err) => {
                        setError(() => {
                            throw new ErrorCode(
                                `Get Suggestion By ID (${suggestion?.id})`,
                                'E_SuggestionBox_Detail_0003',
                                err?.message
                            );
                        });
                    });
                stableGetAttachments
                    .current({
                        url: `/suggestions/${suggestion?.id}/attachments`
                    })
                    .catch((err) => {
                        setError(() => {
                            throw new ErrorCode(
                                `Get Suggestion Attachments By ID (${suggestion?.id})`,
                                'E_SuggestionBox_Detail_0004',
                                err?.message
                            );
                        });
                    });
            }
        }));

        useEffect(() => {
            if (suggestion?.id == null) return;

            stableGetSuggestion
                .current({
                    url: `/suggestions/${suggestion?.id}`
                })
                .catch((err) => {
                    setError(() => {
                        throw new ErrorCode(
                            `Get Suggestion By ID (${suggestion?.id})`,
                            'E_SuggestionBox_Detail_0001',
                            err?.message
                        );
                    });
                });
            stableGetAttachments
                .current({
                    url: `/suggestions/${suggestion?.id}/attachments`
                })
                .catch((err) => {
                    setError(() => {
                        throw new ErrorCode(
                            `Get Suggestion Attachments By ID (${suggestion?.id})`,
                            'E_SuggestionBox_Detail_0002',
                            err?.message
                        );
                    });
                });
        }, [suggestion?.id]);

        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.close} onClick={handleClose}>
                        <FontAwesomeIcon icon={faTimes} />
                        <div className={styles.label}>Close</div>
                    </div>
                    {console.log(
                        SuggestionBoxReviewActionPermissions,
                        data?.categoryName
                    )}
                    {suggestion &&
                        data &&
                        data.isResolved === false &&
                        userHasPermissions(
                            SuggestionBoxReviewActionPermissions?.[
                                data?.categoryName
                            ]
                        ) && (
                            <div className={styles.actions}>
                                <div
                                    className={styles.action}
                                    onClick={() => handleReview(suggestion)}
                                >
                                    <TextTooltip
                                        hoverTrigger="always"
                                        hoverDelay={500}
                                        tooltip="Review"
                                    >
                                        <FontAwesomeIcon icon={faCheck} />
                                    </TextTooltip>
                                </div>
                            </div>
                        )}
                </div>
                <div className={styles.body}>
                    <div className={styles.content}>
                        {suggestion ? (
                            <div className={styles.container}>
                                <div className={styles.section}>
                                    {loading ? (
                                        <div className={styles.loading}>
                                            <LoadingOverlay
                                                size="lg"
                                                spinColor="rgba(0,0,0,0.7)"
                                                backgroundColor="rgba(0,0,0,0.1)"
                                                label="Loading Suggestion..."
                                            />
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            <div className={styles.detailGroup}>
                                                <div
                                                    className={
                                                        styles.detailProperty
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.detailLabel
                                                        }
                                                    >
                                                        Date
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.detailValue
                                                        }
                                                    >
                                                        {data?.createdOn &&
                                                            DateTime.fromISO(
                                                                data?.createdOn
                                                            ).toFormat(
                                                                'MM/dd/yyyy'
                                                            )}
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        styles.detailProperty
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.detailLabel
                                                        }
                                                    >
                                                        Status
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.detailValue
                                                        }
                                                    >
                                                        {data?.isResolved
                                                            ? 'Resolved'
                                                            : 'Unresolved'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.detailGroup}>
                                                <div
                                                    className={
                                                        styles.detailProperty
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.detailLabel
                                                        }
                                                    >
                                                        Name
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.detailValue
                                                        }
                                                    >
                                                        {data?.suggesterName}
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        styles.detailProperty
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.detailLabel
                                                        }
                                                    >
                                                        Job Name
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.detailValue
                                                        }
                                                    >
                                                        {data?.jobName}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.detailGroup}>
                                                <div
                                                    className={
                                                        styles.detailProperty
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.detailLabel
                                                        }
                                                    >
                                                        Area of Suggestion
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.detailValue
                                                        }
                                                    >
                                                        {data?.categoryName}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.detailGroup}>
                                                <div
                                                    className={
                                                        styles.detailProperty
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.detailLabel
                                                        }
                                                    >
                                                        Suggestion
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.detailValue
                                                        }
                                                    >
                                                        {data?.feedback}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.detailGroup}>
                                                <div
                                                    className={
                                                        styles.detailProperty
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.detailLabel
                                                        }
                                                    >
                                                        Follow-up Requested
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.detailValue
                                                        }
                                                    >
                                                        {data?.isFollowUpRequested
                                                            ? 'Yes'
                                                            : 'No'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.detailGroup}>
                                                <div
                                                    className={
                                                        styles.detailProperty
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.detailLabel
                                                        }
                                                    >
                                                        Attachments
                                                    </div>
                                                    {loadingAttachments ? (
                                                        <LoadingOverlay
                                                            size="sm"
                                                            spinColor="rgba(0,0,0,0.5)"
                                                            backgroundColor="rgba(0,0,0,0.1)"
                                                            label="Loading..."
                                                            labelPosition="right"
                                                            fontSize="12px"
                                                        />
                                                    ) : (
                                                        <div>
                                                            <FileUpload
                                                                files={
                                                                    attachments
                                                                        ? attachments.map(
                                                                              (
                                                                                  attachment
                                                                              ) => ({
                                                                                  ...attachment,
                                                                                  download: `/suggestions/${suggestion?.id}/attachments/${attachment.id}/download`,
                                                                                  preview: `/suggestions/${suggestion?.id}/attachments/${attachment.id}/preview`
                                                                              })
                                                                          )
                                                                        : []
                                                                }
                                                                emptyMessage={
                                                                    attachmentError &&
                                                                    attachmentError
                                                                        ?.response
                                                                        ?.status ===
                                                                        403
                                                                        ? "You don't have permissions to view the attachments."
                                                                        : attachmentError
                                                                        ? 'Error retrieving attachments'
                                                                        : 'No attachments added.'
                                                                }
                                                                readOnly={true}
                                                                downloadSingle={
                                                                    true
                                                                }
                                                                downloadAll={`/suggestions/${suggestion?.id}/attachments/download`}
                                                                overflow={true}
                                                                width='100%'
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                                {data &&
                                    (data?.reviewerRemarks ||
                                        data?.reviewerName) && (
                                        <div className={styles.section}>
                                            <div className={styles.detailGroup}>
                                                <div
                                                    className={
                                                        styles.detailProperty
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.detailLabel
                                                        }
                                                    >
                                                        Comments
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.detailValue
                                                        }
                                                    >
                                                        {data?.reviewerRemarks ? (
                                                            data.reviewerRemarks
                                                        ) : (
                                                            <p>
                                                                No comment
                                                                provided.
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.reviewedBy
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className={
                                                                styles.icon
                                                            }
                                                        />
                                                        <p
                                                            className={
                                                                styles.value
                                                            }
                                                        >
                                                            {data?.reviewerName}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        ) : (
                            <div
                                className={[
                                    styles.container,
                                    styles.noSelection
                                ].join(' ')}
                            >
                                <div className={styles.message}>
                                    Please click a suggestion to see its
                                    details.
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
);

SuggestionDetail.propTypes = {
    suggestionId: PropTypes.number,
    handleClose: PropTypes.func,
    handleReview: PropTypes.func
};

export default SuggestionDetail;
