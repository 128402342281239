import React from 'react';

import Modal from '../../../general/modal/Modal';
import PropTypes from 'prop-types';

import styles from '../../../../styles/apps/suggestion_box/modal/SubmitSuggestionModal.module.scss';

const SubmitSuggestionModal = ({ handleClose, open }) => {
    return (
        <Modal open={open} blocking={false} handleClose={handleClose}>
            <Modal.Body>
                <div className={styles.body}>
                    <section>
                        <h1 className={styles.header}>Submitted</h1>
                    </section>
                    <section>
                        <p className={styles.message}>
                            Thank you for your submission. The appropriate
                            personnel will review your comments and contact you
                            if you requested a follow-up.
                        </p>
                    </section>
                </div>
            </Modal.Body>
            <Modal.Actions
                buttons={[
                    {
                        type: 'primary',
                        label: 'Close',
                        onClick: handleClose
                    }
                ]}
            />
        </Modal>
    );
};

SubmitSuggestionModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func
};

export default SubmitSuggestionModal;
