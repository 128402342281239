import { tryParseJSON } from "../../../js/services/manipulation";
import Dropdown from "../input/Dropdown";

const FormDropdown = ({id, error, isEditable, onChange, props, style, value}) =>
    isEditable ? (
        <div
            style={{
                background: error ? 'rgba(255, 144, 144, 0.5)' : 'rgba(249, 184, 150, 0.5)',
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                ...tryParseJSON(style ?? '{}')
            }}
        >
            <Dropdown
                {...tryParseJSON(props ?? '{}')}
                selected={tryParseJSON(value) ?? {}}
                handleSelect={(selected) => onChange(JSON.stringify(selected))}
                style={{
                    buttonContainer: {
                        background: 'transparent',
                        borderRaduis: 0,
                        border: 'none'
                    }
                }}
            />
        </div>
    ) : (
        <div style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            fontSize: '.8rem',
            marginLeft: '6px',
            marginTop: '1px',
            minHeight: '14px',
            color: 'var(--light-text-color)',
            wordBreak: 'break-word',
            ...tryParseJSON(style ?? '{}')
        }} {...tryParseJSON(props ?? '{}')}>
            {value?.map?.((v) => (
                <p>{v.value}</p>
            )) ?? (value?.value)}
        </div>
    );

export default FormDropdown;
