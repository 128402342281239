import React, { useCallback, useEffect, useState } from 'react';

import useFetch from '../../../../hooks/useFetch';

import Button from '../../../general/input/Button';
import LoadingOverlay from '../../../general/LoadingOverlay';
import Modal from '../../../general/modal/Modal';
import { useSnackbar } from 'notistack';

import styles from '../../../../styles/apps/pretask/modal/ExportPretaskModal.module.scss';

const ExportEquipmentFormModal = ({ handleClose, filters, type, selected, jobId}) => {
    const [{ loading }, exportPretask] = useFetch('', 'GET', { manual: true });
    const { enqueueSnackbar } = useSnackbar();

    const [error, setError] = useState(false);

    const handleExport = useCallback(() => {
        const buildUrl = () => {
            let url = `/quality/form/export`;

            if (type === 'Filtered Rows') {
                //Build filter parameter
                let filterObj = filters
                    ? Object.keys(filters)
                          ?.filter(
                              (filter) =>
                                  !!filters?.[filter]?.getFilter?.(
                                      filters?.[filter]?.value
                                  )
                          )
                          .reduce(
                              (acc, cur) => ({
                                  ...acc,
                                  ...filters[cur].getFilter?.(
                                      filters?.[cur]?.value
                                  )
                              }),
                              {}
                          )
                    : {};
                filterObj['JobId'] = jobId;

                url += `?filter=${JSON.stringify(filterObj)}`;
            } else if (type === 'Selected Rows') {
                let filterObj = {
                    EquipmentFormIds: selected.map((s) => s.id)
                };

                url = url + `?filter=${JSON.stringify(filterObj)}`;
            }

            return url;
        };

        exportPretask({
            url: buildUrl()
        })
            .then(
                (res) =>
                    new Promise((resolve, reject) => {
                        if (res.ok) resolve(res.blob());
                        else {
                            res.json()
                                .then((data) => {
                                    setError({
                                        title: data.title,
                                        detail: data.detail
                                    });
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        }
                    })
            )
            .then((data) => {
                var objectURL = URL.createObjectURL(data);
                let element = document.createElement('a');
                element.href = objectURL;
                if (type === 'Selected Rows' && selected.length === 1)
                    element.download = `${(selected?.[0]?.name ?? 'Quality Form Export')}.pdf`;
                else element.download = `Quality Form Export.zip`;
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                URL.revokeObjectURL(objectURL);
                handleClose();
            })
            .catch((err) => {
                console.err('err', err);
                enqueueSnackbar('Failed to export forms.', {
                    variant: 'error',
                    autoHideDuration: 3000,
                    preventDuplicate: true
                });
                handleClose();
            })
            .catch((err) => {
                console.err('err', err);
                enqueueSnackbar('Failed to export forms.', {
                    variant: 'error',
                    autoHideDuration: 3000,
                    preventDuplicate: true
                });
                handleClose();
            });
    }, [enqueueSnackbar]); // eslint-disable-line

    useEffect(() => {
        handleExport();
    }, [handleExport]);

    return (
        <Modal
            blocking
            open={true}
            handleClose={handleClose}
            style={{ content: { minHeight: '250px', width: '400px' } }}
        >
            <Modal.Title>Export Quality Forms</Modal.Title>
            <Modal.Body
                style={{ container: { display: 'flex', alignItems: 'center' } }}
            >
                {!loading ? (
                    error ? (
                        <div className={styles.container}>
                            <div
                                style={{
                                    display: 'flex',
                                    padding: '6px 0',
                                    margin: '24px 20px 0',
                                    alignItems: 'center',
                                    color: 'var(--medium-text-color)',
                                    fontSize: '14px'
                                }}
                            >
                                {error?.title === 'Batch Export Limit' ? 'There is a limit of 100 forms per export. Please narrow your filters/selections to fall under that limit.' : 'Error encountered while exporting form, please close and try again.'}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    padding: '6px 0',
                                    margin: '12px 20px 0',
                                    alignItems: 'center',
                                    color: 'var(--medium-text-color)',
                                    fontSize: '12px'
                                }}
                            >
                                {error?.detail}
                            </div>
                        </div>
                    ) : (
                        <div className={styles.container}>
                            <div
                                style={{
                                    display: 'flex',
                                    padding: '6px 0',
                                    margin: '24px 0 0',
                                    alignItems: 'center',
                                    color: 'var(--light-text-color)',
                                    textAlign: 'center'
                                }}
                            >
                                Your export will start momentarily.
                            </div>
                        </div>
                    )
                ) : (
                    <div
                        style={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <LoadingOverlay
                            size="lg"
                            label="Exporting Forms..."
                            spinColor="rgba(0,0,0,0.5)"
                            backgroundColor="rgba(0,0,0,0.05)"
                        />
                        <div
                            style={{
                                marginTop: '10px',
                                color: 'var(--medium-text-color)',
                                fontSize: '14px'
                            }}
                        >
                            This may take a few minutes, please be patient.
                        </div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    type="secondary"
                    variant="border"
                    onClick={handleClose}
                />
                {!loading && type !== 'Selected Rows' && !error && (
                    <Button label="Export" onClick={handleExport} />
                )}
            </Modal.Actions>
        </Modal>
    );
};

export default ExportEquipmentFormModal;
