import React from 'react';

import PropTypes from 'prop-types';

const Cell = ({ children, customClasses, className, ...props }) => (
    <div {...props} className={[className, Array.isArray(customClasses) ? customClasses.join(' ') : customClasses].join(' ')}>
        {React.Children.map(
            children,
            (child) => React.isValidElement(child) && React.cloneElement(child)
        )}
    </div>
);

Cell.propTypes = {
    children: PropTypes.any
};

export default Cell;
