import React, { useEffect } from 'react';

import { apiScopes } from '../../js/services/api';
import LogoLoader from '../general/LogoLoader';
import { useMsal } from '@azure/msal-react';

const Login = () => {
    const { instance } = useMsal();

    useEffect(() => {
        instance.loginRedirect({ scopes: apiScopes });
    }, [instance]);

    return <LogoLoader />;
};

export default Login;
