import React from 'react';

import logo from '../../assets/cve.png';
import { login as loginUser, logout } from '../../js/services/auth';
import Profile from './Profile';
import { faBars, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

const NavBar = ({
    styles,
    menuButtonRef,
    authenticated,
    basic = false,
    login = true
}) => {
    const dispatch = useDispatch();

    return (
        <nav className={styles.navBar}>
            <div className={styles.leftNavGroup}>
                {authenticated && !basic && (
                    <div
                        className={styles.menuButtonContainer}
                        onClick={() => dispatch({ type: 'TOGGLE_EXPANDED' })}
                        ref={menuButtonRef}
                    >
                        <FontAwesomeIcon
                            icon={faBars}
                            className={styles.menuButton}
                        />
                    </div>
                )}
                <div className={styles.logoContainer}>
                    {authenticated && !basic ? (
                        <Link to="/">
                            <img
                                src={logo}
                                alt={'CVE Apps Home'}
                                className={styles.logo}
                            />
                        </Link>
                    ) : (
                        <img
                            src={logo}
                            alt={'CVE Apps Home'}
                            className={styles.logo}
                            style={{ marginLeft: '10px' }}
                        />
                    )}
                </div>
            </div>
            <div className={styles.rightNavGroup}>
                {authenticated && !basic ? (
                    <Profile />
                ) : login ? (
                    <div
                        className={styles.login}
                        onClick={!authenticated ? loginUser : logout}
                    >
                        <FontAwesomeIcon
                            icon={faSignInAlt}
                            className={styles.icon}
                        />
                        <p>{authenticated ? 'Logout' : 'Login'}</p>
                    </div>
                ) : null}
            </div>
        </nav>
    );
};

NavBar.propTypes = {
    styles: PropTypes.object,
    visible: PropTypes.bool,
    menuButtonRef: PropTypes.object,
    authenticated: PropTypes.bool
};

export default NavBar;
