import React from 'react';

import Copy from '../../general/Copy';
import Navigation from '../../navigation/Navigation';
import ErrorCode from './ErrorCode';

import styles from '../../../styles/config/ErrorBoundaries/RootErrorBoundary.module.scss';
import instance from '../../../js/services/api';

class RootErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            errorInfo: null,
            errorObj: null,
            showStack: false
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        instance.post('/error', {
                message: `Root Error Boundary ${error.message} ${error.stack}`
        });

        this.setState({
            errorInfo: errorInfo,
            errorObj: error
        });
    }

    render() {
        return this.state.hasError ? (
            <Navigation basic={true}>
                <div className={styles.container}>
                    <div className={styles.innerContainer}>
                        <div className={styles.iconContainer}>:(</div>
                        <div className={styles.body}>
                            <div className={styles.content}>
                                <div className={styles.header}>
                                    We encountered an error and couldn't load
                                    the page.
                                </div>
                                <div className={styles.subheader}>
                                    We're sorry for the inconvenience.
                                </div>
                                {this.state.errorObj instanceof ErrorCode && (
                                    <div className={styles.errorCode}>
                                        <Copy
                                            value={`Error: ${
                                                this.state.errorObj.code
                                            } ${
                                                this.state.errorObj.message
                                                    ? `- ${this.state.errorObj.message}`
                                                    : null
                                            } ${
                                                this.state.errorObj.description
                                                    ? `- ${this.state.errorObj.description}`
                                                    : null
                                            }`}
                                        />
                                        <p>
                                            Error: {this.state.errorObj.code}{' '}
                                            {this.state.errorObj.message
                                                ? `- ${this.state.errorObj.message}`
                                                : null}{' '}
                                            {this.state.errorObj.description
                                                ? `- ${this.state.errorObj.description}`
                                                : null}
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className={styles.information}>
                                <p className={styles.header}>
                                    Troubleshooting Steps
                                </p>
                                <ul className={styles.steps}>
                                    <li>
                                        <a href={window.location.href}>
                                            Refreshing
                                        </a>{' '}
                                        the page
                                    </li>
                                    <li>Logging out and back in</li>
                                </ul>
                                <div className={styles.contact}>
                                    <p>
                                        If none of the above steps work for you,
                                        please create a ticket for the
                                        developers by sending an email to{' '}
                                        <a href="mailto:helpdesk@cve.com">
                                            helpdesk@cve.com
                                        </a>{' '}
                                        or visiting{' '}
                                        <a
                                            href="https://helpdesk.cve.com"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            helpdesk.cve.com
                                        </a>
                                        .{' '}
                                        {this.state.errorObj instanceof
                                        ErrorCode
                                            ? 'For a faster experience, please include the error code found above in your ticket.'
                                            : null}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Navigation>
        ) : (
            this.props.children
        );
    }
}

export default RootErrorBoundary;
