import Loader from '../../Loader';

import styles from '../../../../styles/general/grid/row renderers/LoadingRow.module.scss';

const LoadingRow = () => (
    <div className={[styles.loading, 'BaseTable__row-cell'].join(' ')}>
        <span className={styles.loadingContainer}>
            <Loader background="#d8d8d8" spinColor="#7e7e7e" size=".8rem" />
        </span>
        <span style={{ marginLeft: '6px' }}>Loading...</span>
    </div>
);
export default LoadingRow;
