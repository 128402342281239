import { useRef } from 'react';

import useApi from '../../../../hooks/useApi';

import Button from '../../../general/input/Button';
import FileUpload from '../../../general/input/FileUpload';
import InputLabel from '../../../general/input/InputLabel';
import Select from '../../../general/input/Select';
import Modal from '../../../general/modal/Modal';
import QualityTagInput from '../QualityTagInput';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { isNumber } from 'lodash';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import styles from '../../../../styles/apps/quality/modal/NewEquipmentAttachmentModal.module.scss';

const NewEquipmentAttachmentModal = ({
    handleClose,
    handleNew,
    equipment,
    jobId
}) => {
    const submitButtonRef = useRef();

    const { enqueueSnackbar } = useSnackbar();
    const { handleSubmit, getValues, setValue, control } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            equipment: equipment ? [equipment] : [],
            attachments: []
        }
    });

    const [{ loading }, createAttachments] = useApi(
        '/quality/attachment',
        'POST',
        {
            manual: true
        }
    );

    const handleSubmitClicked = () => submitButtonRef?.current?.click?.();

    const handleCreate = (data) => {
        let formData = new FormData();

        formData.append(
            'jsonData',
            JSON.stringify({
                equipmentId: data.equipment?.[0]?.id,
                jobId: jobId,
                attachmentMetadata: data.attachments.map((attachment) => ({
                    attachmentId: `file_${attachment.file.name}/${attachment.file.size}/${attachment.file.type}`,
                    description: attachment.description,
                    qualityTags: attachment.qualityTags?.map?.((qt) => ({
                        ...qt,
                        id: isNumber(qt.id) ? qt.id : 0
                    })) ?? []
                }))
            })
        );

        data.attachments.forEach((attachment) => {
            formData.append(
                `file_${attachment.file.name}/${attachment.file.size}/${attachment.file.type}`,
                attachment.file
            );
        });

        createAttachments({
            data: formData
        })
            .then((attachments) => {
                handleNew?.(attachments, data.equipment?.[0]?.id);
                enqueueSnackbar('Attachment(s) created successfully.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                handleClose();
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Failed to create Attachment(s).', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });
    };

    const handleAddQualityTag = (id, qualityTags) => {
        let files = getValues('attachments');
        setValue(
            'attachments',
            files.map((file) =>
                file.id === id
                    ? {
                          ...file,
                          qualityTags: qualityTags
                      }
                    : file
            )
        );
    };

    return (
        <Modal open handleClose={handleClose} blocking>
            <Modal.Title icon={faPlus}>New Attachment</Modal.Title>
            <Modal.Body>
                <form
                    autoComplete="true"
                    onSubmit={handleSubmit(handleCreate)}
                    noValidate={true}
                    className={styles.form}
                >
                    <div className={styles.row}>
                        <Controller
                            name="equipment"
                            control={control}
                            render={({
                                field: { value, onChange, ref },
                                fieldState: { error }
                            }) => (
                                <InputLabel
                                    label="Equipment"
                                    error={error?.message}
                                >
                                    <Select
                                        placeholder="Select Equipment"
                                        selected={value}
                                        handleRowSelection={onChange}
                                        pagination={{
                                            url: `/quality/${jobId}/equipment`
                                        }}
                                        getRowValue={(r) => r.name}
                                        sort={['equipment']}
                                    />
                                </InputLabel>
                            )}
                        />
                    </div>
                    <div className={styles.row}>
                        <Controller
                            name="attachments"
                            control={control}
                            rules={{
                                required:
                                    'You must supply at least one attachment.'
                            }}
                            render={({
                                field: { value, onChange },
                                fieldState: { error }
                            }) => (
                                <InputLabel
                                    error={error?.message}
                                    label="Attachments"
                                    required
                                    labelClick={false}
                                >
                                    <FileUpload
                                        overflow
                                        files={value}
                                        handleChange={onChange}
                                        showDescription
                                        fileRenderer={(file) => (
                                            <div
                                                className={
                                                    styles.qualityTagInput
                                                }
                                            >
                                                <QualityTagInput
                                                    value={
                                                        file.qualityTags ?? []
                                                    }
                                                    jobId={jobId}
                                                    onChange={handleAddQualityTag.bind(
                                                        this,
                                                        file.id
                                                    )}
                                                />
                                            </div>
                                        )}
                                    />
                                </InputLabel>
                            )}
                        />
                    </div>
                    <div
                        style={{
                            visibility: 'hidden',
                            opacity: 0
                        }}
                    >
                        <button formAction="submit" ref={submitButtonRef} />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Create"
                    onClick={handleSubmitClicked}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default NewEquipmentAttachmentModal;
