import { apiScopes } from './api';
import { appBaseUrl } from './url';
import { LogLevel } from '@azure/msal-browser';
import { EventType, PublicClientApplication } from '@azure/msal-browser';

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId:
            process.env.REACT_APP_ENV === 'prod'
                ? '2c822a1d-57ce-4008-96ce-f27fdfea431d'
                : 'c714bb29-689d-48c6-bc87-0a92bfc83b6a',
        authority:
            'https://login.microsoftonline.com/c5f6a277-ed92-4648-bda8-79f4910e388c',
        redirectUri: appBaseUrl,
        postLogoutRedirectUri: appBaseUrl
    },
    cache: {
        storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii || process.env.REACT_APP_ENV === 'prod') {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

//MSAL configuration
const msalInstance = new PublicClientApplication(msalConfig);

if (msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});

const logout = () => {
    msalInstance.logoutRedirect({
        account: msalInstance?.getActiveAccount(),
        postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri
    });
};

const login = () => {
    msalInstance.loginRedirect({ scopes: apiScopes });
};

export { msalInstance, logout, login };
export default msalInstance;
