import { useEffect, useState } from 'react';

import useApi from '../../../hooks/useApi';
import useUser from '../../../hooks/useUser';

import { QualityPermissionsUpdatePermissions } from '../../../js/services/permissions';
import Button from '../../general/input/Button';
import LoadingModal from '../../general/modal/LoadingModal';
import GridSkeleton from '../../general/skeletons/GridSkeleton';
import TabSkeleton from '../../general/skeletons/TabSkeleton';
import { TextTooltip } from '../../general/Tooltip';
import Tabs from '../../navigation/Tabs';
import ChangeJobModal from './modal/ChangeJobModal';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { useMatch } from 'react-router-dom';

import styles from '../../../styles/apps/quality/Quality.module.scss';

const tabs = [
    {
        key: 'overview',
        label: 'Overview',
        link: `/quality/overview`
    },
    {
        key: 'equipment',
        label: 'Equipment',
        link: `/quality/equipment`
    },
    {
        key: 'forms',
        label: 'Forms',
        link: `/quality/forms`
    },
    {
        key: 'attachments',
        label: 'Attachments',
        link: `/quality/attachments`
    },
    {
        key: 'tags',
        label: 'Tags',
        link: `/quality/tags`
    },
    {
        key: 'permissions',
        label: 'Permissions',
        link: `/quality/permissions`,
        permission: QualityPermissionsUpdatePermissions
    }
];

const Quality = () => {
    const match = useMatch({
        path: '/quality/:page',
        end: false
    });

    const {
        defaultJob: job,
        setUserJob
    } = useUser();
    const navigate = useNavigate();

    const [, setSearchParams] = useSearchParams();

    const [{ data, loading }, getJobStatus] = useApi(
        `/quality/${job?.id}/user`,
        'GET',
        { manual: true }
    );

    const [tab, setTab] = useState(match?.params?.page ?? 'equipment');
    const [jobModal, setJobModal] = useState(false);

    const handleTabChange = (tab) => setTab(tab);
    const handleChangeJob = (job) => {
        setSearchParams({});
        setUserJob(job);
        setJobModal(false);
    };
    const handleOpenJobModal = () => setJobModal(true);
    const handleCloseJobModal = () => setJobModal(false);

    const handleNavigateHome = () => navigate('/');

    useEffect(() => {
        document.title = 'CVE Apps - Quality';
    });

    useEffect(() => {
        setTab(match?.params?.page);
    }, [match]);

    useEffect(() => {
        getJobStatus();
    }, [job]); //eslint-disable-line

    return job ? (
        loading || data === null ? (
            <div className={styles.container}>
                <TabSkeleton />
                <GridSkeleton />
                <LoadingModal
                    message={`Checking permissions for job #${job.vistaJobNumber}...`}
                />
            </div>
        ) : data === false ? (
            <div className={styles.container}>
                <div className={styles.noPermissions}>
                    <h2>No Permissions</h2>
                    <p>
                        You do not have permissions to view or edit quality
                        documents for this job. Please select a different job.
                    </p>
                    <Button
                        label="Change Job"
                        type="secondary"
                        icon={faPencilAlt}
                        className={styles.changeJob}
                        onClick={handleOpenJobModal}
                    />
                </div>
                {jobModal && (
                    <ChangeJobModal
                        handleClose={handleCloseJobModal}
                        handleConfirm={handleChangeJob}
                    />
                )}
            </div>
        ) : (
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.tabs}>
                        <Tabs
                            tabs={tabs}
                            active={tab}
                            onChange={handleTabChange}
                        />
                    </div>
                    <div className={styles.rightOptions}>
                        <div className={styles.currentJob}>
                            <TextTooltip
                                tooltip="Change Job"
                                hoverTrigger="always"
                                position={{
                                    x: 'center',
                                    y: 'bottom'
                                }}
                            >
                                <p
                                    className={styles.editJob}
                                    onClick={handleOpenJobModal}
                                >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </p>
                            </TextTooltip>
                            <div className={styles.jobDescriptionContainer}>
                                <p className={styles.jobDescription}>
                                    {job.vistaJobNumber} -{' '}
                                    {job.vistaJobDescription}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Outlet
                    context={{ job: job, handleChangeJob: handleOpenJobModal }}
                />
                {jobModal && (
                    <ChangeJobModal
                        handleClose={handleCloseJobModal}
                        handleConfirm={handleChangeJob}
                    />
                )}
            </div>
        )
    ) : (
        <div className={styles.container}>
            <div className={styles.tabs}>
                <TabSkeleton />
            </div>
            <GridSkeleton />
            <ChangeJobModal
                title="Select A Job"
                message="Please select a job from the dropdown below to access the Quality app.
                If you do not see your job, ask a QA/QC supervisor to have you added to the job.
                "
                handleClose={handleNavigateHome}
                handleConfirm={handleChangeJob}
            />
        </div>
    );
};

export default Quality;
