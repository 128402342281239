import React from 'react';

import Button from '../../../general/input/Button';
import Modal from '../../../general/modal/Modal';
import PropTypes from 'prop-types';

import styles from '../../../../styles/apps/pretask/modal/IncompleteActivityModal.module.scss';

const IncompleteActivityModal = ({ handleClose = () => null }) => (
    <Modal open={true} handleClose={handleClose} blocking={false}>
        <Modal.Body>
            <div className={styles.body}>
                <section>
                    <h1 className={styles.header}>Incomplete Activity</h1>
                </section>
                <section>
                    <p className={styles.message}>
                        One or more of the activities this employee is a part of
                        is incomplete. Please complete filling out the activity
                        before having the employee sign.
                    </p>
                </section>
            </div>
        </Modal.Body>
        <Modal.Actions>
            <Button label="Continue" onClick={handleClose} />
        </Modal.Actions>
    </Modal>
);

IncompleteActivityModal.propTypes = {
    handleClose: PropTypes.func
};

export default IncompleteActivityModal;
