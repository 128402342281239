import { useRef, useState } from 'react';

import useApi from '../../../../hooks/useApi';
import useFilterReducer from '../../../../hooks/useFilterReducer';
import useUser from '../../../../hooks/useUser';

import {
    ContactsCreatePermissions,
    ContactsCustodianUpdatePermissions,
    ContactsDeletePermissions,
    ContactsUpdatePermissions
} from '../../../../js/services/permissions';
import ActionCell from '../../../general/grid/cell renderers/ActionCell';
import TextCell, {
    TextContextCell
} from '../../../general/grid/cell renderers/TextCell';
import Filters from '../../../general/grid/Filters';
import Grid from '../../../general/grid/Grid';
import Checkbox from '../../../general/input/Checkbox';
import Dropdown from '../../../general/input/Dropdown';
import Select from '../../../general/input/Select';
import TextInput from '../../../general/input/TextInput';
import BulkReassignModal from '../modal/BulkReassignModal';
import DeleteContactModal from '../modal/DeleteContactModal';
import EditContactModal from '../modal/EditContactModal';
import ExportContactModal from '../modal/ExportContactModal';
import NewContactModal from '../modal/NewContactModal';
import ViewContactModal from '../modal/ViewContactModal';
import {
    faExchange,
    faEye,
    faFileExport,
    faFilter,
    faPencilAlt,
    faPlus,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';

import styles from '../../../../styles/apps/contact/grid/ContactGrid.module.scss';

const defaultFilterState = {
    companies: {
        value: [],
        getFilter: (companies) =>
            companies.length === 0
                ? null
                : { CompanyIds: companies.map((company) => company?.id) }
    },
    address: {
        value: '',
        getFilter: (value) =>
            !value || value.replace(/\s/g, '') === ''
                ? null
                : { Address: value }
    },
    contact: {
        value: '',
        getFilter: (value) =>
            !value || value.replace(/\s/g, '') === '' ? null : { Name: value }
    },
    email: {
        value: '',
        getFilter: (value) =>
            !value || value.replace(/\s/g, '') === '' ? null : { Email: value }
    },
    flags: {
        value: [],
        getFilter: (flags) =>
            flags.length === 0
                ? null
                : { FlagIds: flags.map((flag) => flag?.id) }
    },
    phone: {
        value: '',
        getFilter: (value) =>
            !value || value.replace(/\s/g, '') === '' ? null : { Phone: value }
    },
    categories: {
        value: [],
        getFilter: (categories) =>
            categories.length === 0
                ? null
                : { CategoryIds: categories.map((c) => c.key ?? c.id) }
    },
    primaryCategories: {
        value: false,
        getFilter: (primaryCategories) => ({
            PrimaryCategories: primaryCategories
        })
    },
    completeAddresses: {
        value: false,
        getFilter: (completeAddresses) => ({
            CompleteAddress: completeAddresses
        })
    },
    custodians: {
        value: [],
        getFilter: (custodians) =>
            custodians.length === 0
                ? null
                : { CustodianIds: custodians.map((c) => c.id) }
    }
};

const ContactGrid = () => {
    const gridRef = useRef();

    const { userHasPermissions } = useUser();

    const [{ loading: loadingCategories, data: categories }] = useApi(
        '/contact/categories',
        'GET',
        { manual: false }
    );
    const { filter, setFilter, resetFilter } =
        useFilterReducer(defaultFilterState);

    const [selected, setSelected] = useState([]);
    const [modalState, setModalState] = useState({
        new: false,
        edit: false,
        delete: false,
        export: false,
        view: false,
        bulkReassign: false
    });

    const handleRowSelection = (rows) => {
        setSelected(rows);
    };

    /* Filter Handlers */
    const clearFilters = () => resetFilter();
    const handleFilterChange = (key, value) => {
        console.log('filter', key, value);
        setFilter({
            key: key,
            payload: value
        });
    };

    /* Modal Handlers */
    const handleOpenModal = (type, value) =>
        setModalState((modalState) => ({
            ...modalState,
            [type]: value ?? true
        }));

    const handleCloseModal = (type) =>
        setModalState((modals) => ({
            ...modals,
            [type]: false
        }));

    const handleRowDoubleClick = (data) =>
        handleOpenModal('view', data.rowData);

    const handleNewContact = (contact) => gridRef?.current?.addRow(contact);
    const handleEditContact = (id, contact) =>
        gridRef?.current?.modifyRow(id, contact);
    const handleDeleteContact = (id) => gridRef?.current?.removeRow(id);

    return (
        <div className={styles.container}>
            <Grid
                ref={gridRef}
                pagination={{
                    url: '/contact',
                    pageSize: 100
                }}
                fixed
                selected={selected}
                filters={filter}
                rowKey="id"
                getRowId={(r) => r.id}
                handleRowSelection={handleRowSelection}
                rowEventHandlers={{
                    onDoubleClick: handleRowDoubleClick
                }}
                actions={[
                    {
                        type: 'primary',
                        label: 'New',
                        icon: faPlus,
                        onClick: handleOpenModal.bind(this, 'new'),
                        permission: ContactsCreatePermissions
                    },
                    {
                        type: 'secondary',
                        label: 'Bulk Reassign',
                        icon: faExchange,
                        onClick: handleOpenModal.bind(
                            this,
                            'bulkReassign',
                            filter.custodians?.value?.length === 1
                                ? filter.custodians?.value?.[0]
                                : null
                        ),
                        permission: ContactsCustodianUpdatePermissions
                    },
                    {
                        type: 'secondary',
                        variant: 'border',
                        label: 'Export',
                        icon: faFileExport,
                        onClick: handleOpenModal.bind(this, 'export')
                    }
                ].filter(
                    (action) =>
                        !action.permission ||
                        userHasPermissions(action.permission)
                )}
                sidepanel={{
                    filters: {
                        label: 'Filters',
                        icon: faFilter,
                        component: Filters,
                        props: {
                            clearFilters: clearFilters,
                            filters: [
                                ...[
                                    {
                                        label: 'Company',
                                        component: Select,
                                        props: {
                                            handleRowSelection:
                                                handleFilterChange.bind(
                                                    this,
                                                    'companies'
                                                ),
                                            selected: filter.companies?.value,
                                            getRowValue: (row) => row?.name,
                                            getRowId: (row) => row.id,
                                            placeholder: 'Select Company(s)',
                                            multiselect: true,
                                            pagination: {
                                                url: '/contact/company'
                                            },
                                            sort: ['name']
                                        }
                                    }
                                ],
                                {
                                    label: 'Flag',
                                    component: Select,
                                    props: {
                                        handleRowSelection:
                                            handleFilterChange.bind(
                                                this,
                                                'flags'
                                            ),
                                        selected: filter.flags?.value,
                                        getRowValue: (row) => row?.name,
                                        getRowId: (row) => row.id,
                                        placeholder: 'Select Flag(s)',
                                        multiselect: true,
                                        pagination: {
                                            url: '/contact/flag'
                                        },
                                        sort: ['name']
                                    }
                                },
                                {
                                    component: Select,
                                    label: 'Custodian',
                                    props: {
                                        pagination: {
                                            url: '/contact/custodians'
                                        },
                                        placeholder: 'Select Users',
                                        selected: filter.custodians?.value,
                                        getRowValue: (row) => row.displayName,
                                        multiselect: true,
                                        handleRowSelection:
                                            handleFilterChange.bind(
                                                this,
                                                'custodians'
                                            ),
                                        sort: ['name']
                                    }
                                },
                                {
                                    label: 'Division',
                                    component: Dropdown,
                                    props: {
                                        placeholder: 'Select Division(s)',
                                        selected: filter.categories?.value,
                                        loading: loadingCategories,
                                        multiselect: true,
                                        options: categories?.map((c) => ({
                                            ...c,
                                            key: c.id,
                                            value: c.name
                                        })),
                                        handleSelect: handleFilterChange.bind(
                                            this,
                                            'categories'
                                        )
                                    }
                                },
                                {
                                    component: Checkbox,
                                    props: {
                                        label: 'Primary Divisions Only',
                                        checked:
                                            filter.primaryCategories?.value,
                                        handleChange: handleFilterChange.bind(
                                            this,
                                            'primaryCategories',
                                            !filter.primaryCategories?.value
                                        )
                                    }
                                },
                                {
                                    component: Checkbox,
                                    props: {
                                        label: 'Complete Addresses Only',
                                        checked:
                                            filter.completeAddresses?.value,
                                        handleChange: handleFilterChange.bind(
                                            this,
                                            'completeAddresses',
                                            !filter.completeAddresses?.value
                                        )
                                    }
                                },
                                {
                                    component: TextInput,
                                    label: 'Contact Name',
                                    width: '100%',
                                    props: {
                                        placeholder: 'Name',
                                        value: filter['contact']?.value,
                                        onChange: (e) =>
                                            handleFilterChange(
                                                'contact',
                                                e.target.value
                                            )
                                    }
                                },
                                {
                                    component: TextInput,
                                    label: 'Address',
                                    width: '100%',
                                    props: {
                                        placeholder: 'Address',
                                        value: filter['address']?.value,
                                        onChange: (e) =>
                                            handleFilterChange(
                                                'address',
                                                e.target.value
                                            )
                                    }
                                },
                                {
                                    component: TextInput,
                                    label: 'Email',
                                    width: '100%',
                                    props: {
                                        placeholder: 'Email Address',
                                        value: filter['email']?.value,
                                        onChange: (e) =>
                                            handleFilterChange(
                                                'email',
                                                e.target.value
                                            )
                                    }
                                },
                                {
                                    component: TextInput,
                                    label: 'Phone Number',
                                    width: '100%',
                                    props: {
                                        placeholder: 'Phone Number',
                                        value: filter['phone']?.value,
                                        onChange: (e) =>
                                            handleFilterChange(
                                                'phone',
                                                e.target.value
                                            )
                                    }
                                }
                            ]
                        }
                    }
                }}
                columns={[
                    {
                        title: 'Contact',
                        key: 'Contact',
                        dataKey: 'Contact',
                        sortKey: 'name',
                        width: 190,
                        minWidth: 190,
                        fixedGrow: 1,
                        cellRenderer: ({ rowData }) => (
                            <TextCell>{rowData?.name}</TextCell>
                        )
                    },
                    {
                        title: 'Company',
                        key: 'Company',
                        dataKey: 'company',
                        sortKey: 'company',
                        width: 180,
                        maxWidth: 180,
                        cellRenderer: ({ cellData }) =>
                            cellData && <TextCell>{cellData?.name}</TextCell>
                    },
                    {
                        title: 'Address',
                        key: 'Address',
                        dataKey: 'address',
                        sortKey: 'address',
                        width: 260,
                        maxWidth: 260,
                        cellRenderer: ({ cellData }) =>
                            cellData &&
                            cellData && <TextCell>{cellData}</TextCell>
                    },
                    {
                        title: 'Email',
                        key: 'Email',
                        dataKey: 'email',
                        sortKey: 'email',
                        width: 260,
                        maxWidth: 260,
                        cellRenderer: ({ cellData }) =>
                            cellData &&
                            cellData && <TextCell>{cellData}</TextCell>
                    },
                    {
                        title: 'Phone Number',
                        key: 'Phone',
                        dataKey: 'phones',
                        sortKey: 'phone',
                        width: 160,
                        maxWidth: 160,
                        cellRenderer: ({ cellData }) =>
                            cellData && (
                                <TextContextCell
                                    context={{
                                        hoverTrigger: 'always',
                                        clickTrigger: 'always'
                                    }}
                                    contextTooltip={cellData
                                        ?.filter?.(
                                            (p) => !p.isPrimary && !p.isDeleted
                                        )
                                        ?.map?.((p, i) => (
                                            <div key={i}>{p.phone}</div>
                                        ))}
                                    contextLabel={
                                        cellData.filter?.((p) => !p.isDeleted)
                                            .length > 1
                                            ? ` ${
                                                  cellData.filter?.(
                                                      (p) =>
                                                          !p.isPrimary &&
                                                          !p.isDeleted
                                                  ).length
                                              } other(s)`
                                            : null
                                    }
                                >
                                    {cellData.find((p) => p.isPrimary)?.phone}
                                </TextContextCell>
                            )
                    },
                    {
                        title: 'Divisions',
                        key: 'Division',
                        dataKey: 'categories',
                        sortKey: 'category',
                        width: 200,
                        maxWidth: 200,
                        cellRenderer: ({ cellData }) =>
                            cellData && (
                                <TextContextCell
                                    context={{
                                        hoverTrigger: 'always',
                                        clickTrigger: 'always'
                                    }}
                                    contextTooltip={cellData
                                        ?.filter?.(
                                            (c) => !c.isPrimary && !c.isDeleted
                                        )
                                        ?.map?.((c, i) => (
                                            <div key={i}>{c?.name}</div>
                                        ))}
                                    contextLabel={
                                        cellData.filter?.((c) => !c.isDeleted)
                                            .length > 1
                                            ? ` ${
                                                  cellData.filter?.(
                                                      (c) => !c.isPrimary
                                                  ).length
                                              } other(s)`
                                            : null
                                    }
                                >
                                    {cellData.find((c) => c.isPrimary)?.name}
                                </TextContextCell>
                            )
                    },
                    {
                        title: 'Flags',
                        key: 'Flag',
                        dataKey: 'flags',
                        sortKey: 'flag',
                        width: 120,
                        maxWidth: 120,
                        cellRenderer: ({ cellData }) =>
                            cellData &&
                            cellData.filter?.((f) => !f.isDeleted)?.length >
                                0 ? (
                                <TextContextCell
                                    context={{
                                        hoverTrigger: 'always',
                                        clickTrigger: 'always'
                                    }}
                                    contextTooltip={cellData
                                        ?.filter((f) => !f.isDeleted)
                                        ?.map?.((f, i) => (
                                            <div key={i}>{f.name}</div>
                                        ))}
                                    contextLabel={`${
                                        cellData.filter((f) => !f.isDeleted)
                                            ?.length ?? 0
                                    } Flag(s)`}
                                ></TextContextCell>
                            ) : (
                                <TextCell>0 Flags</TextCell>
                            )
                    },
                    {
                        title: ' ',
                        key: 'actions',
                        sortable: false,
                        width: 115,
                        minWidth: 115,
                        cellRenderer: ({ rowData }) => (
                            <ActionCell
                                actions={[
                                    {
                                        icon: faEye,
                                        type: 'grayscale',
                                        onClick: handleOpenModal.bind(
                                            this,
                                            'view',
                                            rowData
                                        ),
                                        tooltip: {
                                            tooltip: 'View',
                                            hoverDelay: 650,
                                            hoverTrigger: 'always'
                                        }
                                    },
                                    ...(userHasPermissions(
                                        ContactsUpdatePermissions
                                    )
                                        ? [
                                              {
                                                  icon: faPencilAlt,
                                                  type: 'grayscale',
                                                  onClick: handleOpenModal.bind(
                                                      this,
                                                      'edit',
                                                      rowData
                                                  ),
                                                  tooltip: {
                                                      tooltip: 'Edit',
                                                      hoverDelay: 650,
                                                      hoverTrigger: 'always'
                                                  }
                                              }
                                          ]
                                        : []),
                                    ...(userHasPermissions(
                                        ContactsDeletePermissions
                                    )
                                        ? [
                                              {
                                                  icon: faTrashAlt,
                                                  type: 'grayscale',
                                                  onClick: handleOpenModal.bind(
                                                      this,
                                                      'delete',
                                                      rowData
                                                  ),
                                                  tooltip: {
                                                      tooltip: 'Delete',
                                                      hoverDelay: 650,
                                                      hoverTrigger: 'always'
                                                  }
                                              }
                                          ]
                                        : [])
                                ]}
                            />
                        )
                    }
                ]}
            />
            {modalState.delete && (
                <DeleteContactModal
                    handleClose={handleCloseModal.bind(this, 'delete')}
                    record={modalState.delete}
                    handleDelete={handleDeleteContact}
                />
            )}
            {modalState.edit && (
                <EditContactModal
                    handleClose={handleCloseModal.bind(this, 'edit')}
                    record={modalState.edit}
                    handleEdit={handleEditContact}
                />
            )}
            {modalState.new && (
                <NewContactModal
                    handleClose={handleCloseModal.bind(this, 'new')}
                    handleNew={handleNewContact}
                />
            )}
            {modalState.export && (
                <ExportContactModal
                    handleClose={handleCloseModal.bind(this, 'export')}
                    filters={filter}
                />
            )}
            {modalState.view && (
                <ViewContactModal
                    handleClose={handleCloseModal.bind(this, 'view')}
                    handleEdit={(record) => {
                        handleCloseModal('view');
                        handleOpenModal('edit', record);
                    }}
                    handleDelete={(record) => {
                        handleCloseModal('view');
                        handleOpenModal('delete', record);
                    }}
                    record={modalState.view}
                />
            )}
            {modalState.bulkReassign && (
                <BulkReassignModal
                    handleClose={handleCloseModal.bind(this, 'bulkReassign')}
                    handleReassign={(user) => {
                        if (filter.custodians?.value?.length > 0) {
                            handleFilterChange('custodians', [
                                ...filter.custodians.value?.filter?.(
                                    (c) => c.id !== modalState.bulkReassign?.id
                                ),
                                user
                            ]);
                        }
                    }}
                    record={modalState.bulkReassign}
                />
            )}
        </div>
    );
};

export default ContactGrid;
