import { useState } from 'react';

import SignatureModal from '../modal/SignatureModal';

const FormSignature = ({ error, isEditable, onChange, props, style, value }) => {
    //const { header } = JSON.parse(props ?? {});
    const header = '';

    const [modal, setModal] = useState(false);

    const handleModalOpen = () => setModal(true);
    const handleModalClose = () => {
        setModal(false);
    };

    const handleSave = (signature) => {
        onChange({
            signature: signature,
            label: header
        });
        setModal(false);
    };

    return isEditable ? (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <div
                style={{
                    background: error ? 'rgba(255, 144, 144, 0.5)' : 'rgba(211, 211, 211, 0.5)',
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer',
                    ...JSON.parse(style ?? '{}')
                }}
                onClick={handleModalOpen}
            >
                {value?.signature && (
                    <img
                        srcSet={value?.signature}
                        alt=""
                        style={{
                            width: '100%',
                            height: '64px',
                            objectFit: 'scale-down'
                        }}
                    />
                )}
            </div>
            {modal && (
                <SignatureModal
                    handleClose={handleModalClose}
                    handleSave={handleSave}
                    header={header}
                />
            )}
        </div>
    ) : (
        <div
            style={{
                width: '100%'
            }}
        >
            {value?.signature && (
                <img
                    srcSet={value?.signature}
                    alt=""
                    style={{
                        width: '100%',
                        height: '64px',
                        objectFit: 'scale-down'
                    }}
                />
            )}
        </div>
    );
};

export default FormSignature;
