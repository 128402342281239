import { useRef } from 'react';

import useApi from '../../../../hooks/useApi';

import ErrorMessage from '../../../general/ErrorMessage';
import Button from '../../../general/input/Button';
import LoadingModal from '../../../general/modal/LoadingModal';
import Modal from '../../../general/modal/Modal';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import styles from '../../../../styles/apps/quality/modal/NewPermissionModal.module.scss';
import InputLabel from '../../../general/input/InputLabel';
import Select from '../../../general/input/Select';
import CheckboxGroup from '../../../general/input/CheckboxGroup';
import { useSnackbar } from 'notistack';

const NewPermissionModal = ({ handleClose, jobId, handleNew }) => {
    const submitButtonRef = useRef();
    const { enqueueSnackbar } = useSnackbar();

    const [{ data, loading }] = useApi(
        `/quality/permissions`,
        'GET'
    );

    const [{loading: updating}, updatePermissions] = useApi(`/quality/${jobId}/permissions`, 'POST', {manual: true});

    const {
        handleSubmit,
        control,
        setError,
        formState: { errors },
        ...methods
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            users: [],
            permissionIds: []
        }
    });

    const handleCreate = (data) => {
        updatePermissions({
            data: {
                jobPermissionIds: data.permissionIds,
                userIds: data.users?.map((u) => u.id)
            }
        }).then((result) => {
            handleNew(result);
            handleClose();
            enqueueSnackbar('Successfully added permissions.', {
                variant: 'success',
                autoHideDuration: 3000
            });
        }).catch((err) => {
            console.error(err);
            enqueueSnackbar('Failed to update permissions.', {
                variant: 'error',
                autoHideDuration: 3000
            });
        })
    };

    const handleSubmitClicked = () => submitButtonRef.current?.click?.();

    return loading ? (
        <LoadingModal message="Loading permissions..." />
    ) : (
        <Modal open handleClose={handleClose} blocking>
            <Modal.Title icon={faPlus}>Add Permissions</Modal.Title>
            <Modal.Body>
                <FormProvider
                    handleSubmit={handleSubmit}
                    control={control}
                    {...methods}
                >
                    <form
                        autoComplete="true"
                        onSubmit={handleSubmit(handleCreate)}
                        noValidate={true}
                        className={styles.form}
                    >
                        <div className={styles.row}>
                            <Controller
                                name={`users`}
                                control={control}
                                rules={{
                                    required: 'You must select an employee.'
                                }}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel error={error?.message} label='Employees'>
                                        <Select
                                            placeholder="Select Employee(s)"
                                            selected={value}
                                            pagination={{
                                                url: `/user/app`
                                            }}
                                            getRowValue={(row) => row?.displayName}
                                            handleRowSelection={onChange}
                                            multiselect
                                            sort={['name']}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div className={styles.row}>
                            <Controller
                                name={`permissionIds`}
                                control={control}
                                rules={{
                                    required: 'You must select 1 or more permissions.'
                                }}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel error={error?.message} label='Form Permissions'>
                                        <CheckboxGroup
                                            orientation='vertical'
                                            options={data?.map?.(p => ({
                                                key: p.id,
                                                value: p.name
                                            }))}
                                            selected={value}
                                            handleChange={onChange}
                                            multiselect
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        {errors.root?.serverError && (
                            <div>
                                <ErrorMessage
                                    error={errors.root.serverError.message}
                                />
                            </div>
                        )}
                        <div
                            style={{
                                visibility: 'hidden',
                                opacity: 0
                            }}
                        >
                            <button formAction="submit" ref={submitButtonRef} />
                        </div>
                    </form>
                </FormProvider>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Add"
                    onClick={handleSubmitClicked}
                    loading={updating}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default NewPermissionModal;
