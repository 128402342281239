import React, { useRef } from 'react';

import useOutsideClick from '../../../hooks/useOutsideClick';
import useToggle from '../../../hooks/useToggle';

import Loader from '../Loader';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import styles from '../../../styles/general/Button.module.scss';

const Button = ({
    type = 'primary',
    variant = 'filled',
    onClick,
    label,
    loading,
    icon,
    iconProps = {},
    disabled = false,
    className,
    formAction
}) => (
    <button
        className={[
            styles.button,
            styles[type],
            styles[variant],
            disabled ? styles.disabled : null,
            className
        ].join(' ')}
        onClick={disabled ? null : onClick}
        type={formAction ?? 'button'}
    >
        {icon && (
            <div style={{ opacity: loading ? 0 : 1 }} className={styles.icon}>
                <FontAwesomeIcon icon={icon} {...iconProps} />
            </div>
        )}
        {label && (
            <div style={{ opacity: loading ? 0 : 1 }} className={styles.label}>
                {label}
            </div>
        )}
        <div className={styles.loader}>
            {loading && <Loader className={styles[`${type}-loader`]} />}
        </div>
    </button>
);

Button.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    label: PropTypes.string,
    loading: PropTypes.bool,
    icon: PropTypes.object,
    iconProps: PropTypes.object,
    type: PropTypes.oneOf([
        'primary',
        'Primary',
        'secondary',
        'Secondary',
        'grayscale',
        'Grayscale'
    ]),
    className: PropTypes.string
};

const DropdownButton = ({
    type = 'primary',
    variant = 'filled',
    options = [],
    handleClick = () => null,
    label,
    loading,
    icon,
    iconProps = {},
    disabled = false,
    className
}) => {
    const buttonRef = useRef();

    const [toggled, handleToggle] = useToggle();
    useOutsideClick(buttonRef, () => handleToggle(false));

    const _handleClick = (option) => {
        handleToggle(false);
        if (option?.handleClick) option.handleClick(option?.key);
        else handleClick(option?.key ? option.key : option);
    };

    return (
        <button
            className={[styles.dropdownButton, className].join(' ')}
            ref={buttonRef}
        >
            <div
                className={[
                    styles.button,
                    styles[type],
                    styles[variant],
                    disabled ? styles.disabled : null,
                    toggled ? styles.toggled : null
                ].join(' ')}
                onClick={disabled ? null : () => handleToggle()}
            >
                {icon && (
                    <div
                        style={{ opacity: loading ? 0 : 1 }}
                        className={styles.icon}
                    >
                        <FontAwesomeIcon icon={icon} />
                    </div>
                )}
                {label && (
                    <div
                        style={{ opacity: loading ? 0 : 1 }}
                        className={styles.label}
                    >
                        {label}
                    </div>
                )}
                <div className={styles.dropdownIcon}>
                    <FontAwesomeIcon icon={toggled ? faCaretUp : faCaretDown} />
                </div>
                <div className={styles.loader}>
                    {loading && <Loader className={styles[`${type}-loader`]} />}
                </div>
            </div>
            {toggled && (
                <div className={styles.menu}>
                    {options.map((option) => (
                        <DropdownButtonOption
                            option={option}
                            handleClick={_handleClick}
                            key={option}
                        />
                    ))}
                </div>
            )}
        </button>
    );
};

const DropdownButtonOption = ({ option, handleClick }) => (
    <div className={styles.option} onClick={handleClick.bind(this, option)}>
        {option?.icon && (
            <div>
                <FontAwesomeIcon
                    icon={option.icon}
                    {...(option.iconProps ?? {})}
                />
            </div>
        )}
        <p>{option?.label ?? option}</p>
    </div>
);

export default Button;
export { DropdownButton };
