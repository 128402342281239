import { useCallback, useEffect } from 'react';

import PropTypes from 'prop-types';

const useOutsideClick = (
    ref,
    callback,
    active = true,
    event = 'mousedown',
    rightClick = false
) => {
    const handleClick = useCallback(
        (e) => {
            if (!rightClick && e.button !== 0) return;

            const refs = Array.isArray(ref) ? ref : [ref];

            if (!refs.some((ref) => ref?.current?.contains(e.target)) && active)
                callback();
        },
        [ref, callback, active, rightClick]
    );

    useEffect(() => {
        document.addEventListener(event, handleClick);

        return () => {
            document.removeEventListener(event, handleClick);
        };
    }, [active, event, handleClick]);
};

useOutsideClick.propTypes = {
    ref: PropTypes.oneOfType([
        PropTypes.shape({
            current: PropTypes.object
        }),
        PropTypes.arrayOf(
            PropTypes.shape({
                current: PropTypes.object
            })
        )
    ]),
    callback: PropTypes.func.isRequired,
    active: PropTypes.bool,
    event: PropTypes.string,
    rightClick: PropTypes.bool
};

export default useOutsideClick;
