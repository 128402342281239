import React from 'react';

import Loader from '../Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import styles from '../../../styles/general/card/ItemCard.module.scss';

const ItemCard = ({
    icon,
    label,
    loading,
    actions = [],
    style = {},
    classes = {}
}) => (
    <div
        className={[styles.container, classes?.container].join(' ')}
        style={style?.container}
        data-testid="item-card"
    >
        {icon && (
            <div
                className={[styles.icon, classes?.icon].join(' ')}
                style={style?.icon}
                data-testid="item-card-icon"
            >
                {typeof icon === 'object' ? (
                    <FontAwesomeIcon icon={icon} />
                ) : (
                    icon
                )}
            </div>
        )}
        {loading && (
            <div
                className={[styles.icon, classes?.icon].join(' ')}
                style={style?.icon}
            >
                <Loader
                    spinColor="rgba(0,0,0,0.3)"
                    background="rgba(0,0,0,0.1)"
                />
            </div>
        )}
        <div
            className={[styles.content, classes?.content].join(' ')}
            style={style?.content}
        >
            <p
                className={[styles.label, classes?.label].join(' ')}
                style={style?.label}
            >
                {label}
            </p>
        </div>
        <div
            className={[styles.actions, classes?.actions].join(' ')}
            style={style?.actions}
        >
            {actions.map((action, index) => (
                <div
                    className={[styles.action, classes?.action].join(' ')}
                    style={style?.action}
                    onClick={action?.handleClick}
                    key={index}
                    data-testid="item-card-action"
                >
                    {action?.icon && <FontAwesomeIcon icon={action?.icon} />}
                </div>
            ))}
        </div>
    </div>
);

ItemCard.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    label: PropTypes.string,
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            handleClick: PropTypes.func
        })
    ),
    classes: PropTypes.shape({
        container: PropTypes.object,
        content: PropTypes.object,
        label: PropTypes.object,
        actions: PropTypes.object,
        action: PropTypes.object
    }),
    style: PropTypes.object
};

export default ItemCard;
