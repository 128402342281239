import { useLayoutEffect, useState } from 'react';

const useOverflowDetector = (ref, callback) => {
    const [overflowing, setOverflowing] = useState(false);

    useLayoutEffect(() => {
        if (!ref.current) return;

        const { current } = ref;

        const trigger = () => {
            const hasOverflow = current.scrollWidth > current.clientWidth;

            setOverflowing(hasOverflow);

            if (callback) callback(hasOverflow);
        };

        if (current) {
            trigger();
        }
    }, [callback, ref]);

    const getState = () => {
        return ref?.current?.scrollWidth > ref?.current?.offsetWidth;
    };

    return [overflowing, getState];
};

export default useOverflowDetector;
