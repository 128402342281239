import { useEffect } from 'react';

import useApi from '../../../hooks/useApi';

import { tryParseJSON } from '../../../js/services/manipulation';
import LoadingOverlay from '../../general/LoadingOverlay';
import FormBlock from './FormBlock';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useMatch } from 'react-router-dom';

import styles from '../../../styles/apps/quality/ViewQualityForm.module.scss';

const ViewQualityForm = () => {
    const match = useMatch({
        path: '/quality/forms/:formId/view',
        end: false
    });

    const [{ data: template, loading, error }] = useApi(
        `/quality/form/${match?.params?.formId}?readOnly=true&allSteps=true`,
        'GET',
        { manual: false }
    );

    const { control, reset } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all'
    });

    useEffect(() => {
        reset(
            template?.fields?.reduce?.(
                (acc, field) => ({
                    ...acc,
                    [`${field.formTemplateFieldId}`]: tryParseJSON(field.value)
                }),
                {}
            )
        );
    }, [template, reset]);

    return (
        <div className={styles.container}>
            {loading ? (
                <div>
                    <LoadingOverlay label="Loading Form..." />
                </div>
            ) : error ? (
                <div className={styles.error}>
                    <h3>{error?.response?.data?.title}</h3>
                    <p>{error?.response?.data?.detail}</p>
                    <Link to="/quality/forms">Back To Forms</Link>
                </div>
            ) : (
                <FormProvider control={control}>
                    <div className={styles.innerContainer}>
                        <Link to="/quality/forms">Back To Forms</Link>
                        <form autoComplete="true" className={styles.form}>
                            {template?.formTemplate?.layout?.map?.((b) => (
                                <FormBlock
                                    key={b.id}
                                    {...b}
                                    control={control}
                                    readOnly
                                    //debug
                                />
                            ))}
                        </form>
                    </div>
                </FormProvider>
            )}
        </div>
    );
};

export default ViewQualityForm;
