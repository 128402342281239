import React, { useMemo, useRef, useState } from 'react';

import ItemCard from '../../general/cards/ItemCard';
import Dropdown from '../../general/input/Dropdown';
import InputLabel from '../../general/input/InputLabel';
import Select from '../../general/input/Select';
import TextInput from '../../general/input/TextInput';
import Callout from '../../general/modal/Callout';
import PretaskEmployee from './PretaskEmployee';
import {
    faExclamationTriangle,
    faLock,
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import styles from '../../../styles/apps/pretask/PretaskActivity.module.scss';

const PretaskActivity = ({
    activity = {},
    availableHazards = [],
    handleUpdate: _handleUpdate,
    handleDelete,
    handleAdd,
    handleRemove
}) => {
    const {
        sequence,
        description,
        locationOfWork,
        hazards,
        employees = [],
        mitigateHazard,
        explainHazard,
        modifiedOn,
        isLocked
    } = activity;

    const { enqueueSnackbar } = useSnackbar();

    const employeeGridRef = useRef();

    const [employeeCertifications, setEmployeeCertifications] = useState({});

    const missingCertifications = useMemo(() => {
        let missingCerts = {};

        let hazardList = hazards.reduce(
            (acc, cur) => [
                ...acc,
                ...availableHazards?.find?.((hazard) => hazard.id === cur.key)
                    ?.requiredCertifications
            ],
            []
        );

        Object.keys(employeeCertifications).forEach((employeeCert) => {
            hazardList.forEach((hazard) => {
                if (
                    !employeeCertifications[
                        employeeCert
                    ]?.certifications?.includes(hazard.id)
                ) {
                    if (
                        !missingCerts[
                            employeeCertifications[employeeCert].employee.id
                        ]
                    ) {
                        missingCerts[
                            employeeCertifications[employeeCert].employee.id
                        ] = {
                            employee: `${
                                employeeCertifications[employeeCert].employee
                                    .lastName
                            }, ${
                                employeeCertifications[employeeCert].employee
                                    .firstName
                            } ${
                                employeeCertifications[employeeCert].employee
                                    .middleName ?? ''
                            }`,
                            certifications: []
                        };
                    }
                    missingCerts[
                        employeeCertifications[employeeCert].employee.id
                    ].certifications = [
                        ...(missingCerts[
                            employeeCertifications[employeeCert].employee.id
                        ]?.certifications ?? []),
                        hazard
                    ];
                }
            });
        });

        return missingCerts;
    }, [hazards, employeeCertifications, availableHazards]);

    const handleEmployeeAdd = (id, selected) => {
        if (!activity?.employees?.some((e) => e.id === selected.id)) {
            selected = {
                ...selected,
                employeeFirstName: selected.firstName,
                employeeLastName: selected.lastName,
                employeeMiddleName: selected.middleName,
                employeeId: selected.id
            };

            handleAdd(selected);
            _handleUpdate(sequence, {
                ...activity,
                employees: [...activity.employees, selected]
            });
        }
    };

    const addEmployeeCertifications = (employee, certifications) => {
        setEmployeeCertifications((employeeCertifications) => ({
            ...employeeCertifications,
            [employee.id]: {
                employee,
                certifications
            }
        }));
    };

    const handleFieldChange = (field, e) => {
        _handleUpdate(sequence, {
            ...activity,
            [field]: e.target.value
        });
    };

    const handleEmployeeDeselect = (id) => {
        if (employeeGridRef?.current?.getRowNode(id)) {
            employeeGridRef?.current?.deselectRow(id);
        } else {
            handleEmployeeRemove(id);
        }
    };

    const handleEmployeeRemove = (id) => {
        let employee = activity?.employees?.find((e) => e.id === id);
        if (employee && employee.isRemovable === false) {
            employeeGridRef?.current?.selectRow(id);
            enqueueSnackbar('You cannot remove an employee that has signed.', {
                variant: 'warning',
                autoHideDuration: 2500,
                preventDuplicate: true
            });
        } else {
            employeeGridRef?.current?.removeDefaultSelected(id);
            handleRemove(id, sequence);

            setEmployeeCertifications((employeeCerts) => {
                let certs = { ...employeeCerts };
                if (employee?.id) delete certs[employee.id];
                return certs;
            });
        }
    };

    const handleEmployeeReset = () => {
        let removableEmployees = employees.filter(
            (e) => e.isRemovable !== false
        );
        removableEmployees.forEach((employee) =>
            handleEmployeeDeselect(employee.id)
        );

        return true;
    };

    const setHazards = (selected) => {
        _handleUpdate(sequence, {
            ...activity,
            hazards: selected.map((selection) => ({
                key: selection.key,
                value: selection.value,
                object: selection.object
            }))
        });
    };

    const removeHazard = (property) => {
        _handleUpdate(sequence, {
            ...activity,
            hazards: hazards.filter((h) => h.key !== property.key)
        });
    };

    return (
        <div
            className={styles.activityContainer}
            data-testid="pretask-activity"
        >
            {isLocked && (
                <div className={styles.activityLock}>
                    <FontAwesomeIcon icon={faLock} />
                </div>
            )}
            {!isLocked && (
                <div className={styles.activityRemoveContainer}>
                    <div
                        className={styles.activityRemoval}
                        onClick={handleDelete?.bind(this, sequence)}
                    >
                        <p>Delete</p>
                    </div>
                </div>
            )}
            <div className={styles.activityBody}>
                <div>
                    <InputLabel label="Description of Work Activity">
                        <TextInput
                            lines={3}
                            value={description}
                            onChange={handleFieldChange.bind(
                                this,
                                'description'
                            )}
                            placeholder="Description of work activity"
                            readonly={isLocked}
                        />
                    </InputLabel>
                </div>
                <div className={styles.locationOfWork}>
                    <InputLabel label="Location of Work Activity">
                        <TextInput
                            value={locationOfWork}
                            onChange={handleFieldChange.bind(
                                this,
                                'locationOfWork'
                            )}
                            placeholder="Location of work activities"
                            readonly={isLocked}
                        />
                    </InputLabel>
                </div>
                <div className={styles.hazardContainer}>
                    <div className={styles.hazardAddContainer}>
                        <h5 className={styles.activitySection}>Hazards</h5>
                    </div>
                    <div className={styles.hazardDropdown}>
                        <Dropdown
                            options={availableHazards
                                .sort((a, b) =>
                                    a.sequence > b.sequence
                                        ? 1
                                        : a.sequence < b.sequence
                                        ? -1
                                        : 0
                                )
                                .map((hazard) => ({
                                    key: hazard.id,
                                    value: hazard.description,
                                    object: hazard
                                }))}
                            handleSelect={setHazards}
                            selected={hazards}
                            multiselect={true}
                            placeholder="Choose Hazard(s)"
                            readonly={isLocked}
                        />
                    </div>
                    <div className={styles.hazardItems}>
                        {hazards?.length > 0 ? (
                            hazards
                                ?.sort((a, b) =>
                                    a.sequence > b.sequence
                                        ? 1
                                        : a.sequence < b.sequence
                                        ? -1
                                        : 0
                                )
                                .map((hazard) => (
                                    <div
                                        className={styles.item}
                                        key={hazard.key}
                                    >
                                        <ItemCard
                                            label={hazard.value}
                                            actions={
                                                !isLocked
                                                    ? [
                                                          {
                                                              icon: faTimes,
                                                              handleClick:
                                                                  removeHazard.bind(
                                                                      this,
                                                                      hazard
                                                                  )
                                                          }
                                                      ]
                                                    : []
                                            }
                                        />
                                    </div>
                                ))
                        ) : (
                            <div className={styles.noHazards}>
                                No hazards selected
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    <InputLabel label="Explain each potential hazard">
                        <TextInput
                            lines={3}
                            value={explainHazard}
                            onChange={handleFieldChange.bind(
                                this,
                                'explainHazard'
                            )}
                            placeholder="Explain each potential hazard"
                            readonly={isLocked}
                        />
                    </InputLabel>
                </div>
                <div>
                    <InputLabel label="How will each potential hazard be mitigated?">
                        <TextInput
                            lines={3}
                            value={mitigateHazard}
                            onChange={handleFieldChange.bind(
                                this,
                                'mitigateHazard'
                            )}
                            placeholder="How will each potential hazard be mitigated?"
                            readonly={isLocked}
                        />
                    </InputLabel>
                </div>
                <div className={styles.employeeContainer}>
                    <div className={styles.employeeAddContainer}>
                        <h5>Assigned Employees</h5>
                        {Object.keys(missingCertifications).length > 0 && (
                            <div className={styles.hazardCertificationWarning}>
                                <div className={styles.icon}>
                                    <FontAwesomeIcon
                                        icon={faExclamationTriangle}
                                    />
                                </div>
                                <div className={styles.message}>
                                    <p className={styles.context}>
                                        One or more employee's you have selected
                                        do not have a current certification for
                                        the hazards you selected.
                                    </p>
                                    <div className={styles.details}>
                                        <Callout
                                            label={() => (
                                                <div
                                                    style={{
                                                        textDecoration:
                                                            'underline'
                                                    }}
                                                >
                                                    Click for Details
                                                </div>
                                            )}
                                            content={(handleClose) => (
                                                <div
                                                    className={
                                                        styles.missingCertContainer
                                                    }
                                                >
                                                    <div
                                                        onClick={handleClose}
                                                        className={styles.close}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faTimes}
                                                        />
                                                    </div>
                                                    <h1
                                                        className={
                                                            styles.header
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faExclamationTriangle
                                                            }
                                                        />
                                                        <p>
                                                            Missing
                                                            Certifications
                                                        </p>
                                                    </h1>
                                                    <div
                                                        className={
                                                            styles.missingCerts
                                                        }
                                                    >
                                                        {Object.keys(
                                                            missingCertifications
                                                        ).map((id) => {
                                                            return (
                                                                <div
                                                                    className={
                                                                        styles.missingCert
                                                                    }
                                                                    key={id}
                                                                >
                                                                    <h5
                                                                        className={
                                                                            styles.cert
                                                                        }
                                                                    >
                                                                        {
                                                                            missingCertifications[
                                                                                id
                                                                            ]
                                                                                ?.employee
                                                                        }
                                                                    </h5>
                                                                    {missingCertifications[
                                                                        id
                                                                    ]?.certifications
                                                                        ?.filter(
                                                                            (
                                                                                c,
                                                                                index
                                                                            ) =>
                                                                                missingCertifications[
                                                                                    id
                                                                                ]?.certifications.indexOf(
                                                                                    c
                                                                                ) ===
                                                                                index
                                                                        )
                                                                        ?.map(
                                                                            (
                                                                                c
                                                                            ) => (
                                                                                <div
                                                                                    className={
                                                                                        styles.employee
                                                                                    }
                                                                                    key={
                                                                                        c?.id
                                                                                    }
                                                                                >
                                                                                    {`${c?.code} - ${c?.description}`}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                            persistOnClick={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={styles.employeeSelect}>
                            <Select
                                placeholder="Select Employee(s)"
                                selected={employees}
                                handleRowSelected={handleEmployeeAdd}
                                handleRowDeselected={handleEmployeeRemove}
                                handleReset={handleEmployeeReset}
                                pagination={{
                                    url: '/employees'
                                }}
                                style={{
                                    menuContainer: {
                                        boxShadow:
                                            '2px 0px 12px 2px rgba(0,0,0,0.08)',
                                        bottom: 0,
                                        top: 'unset'
                                    }
                                }}
                                sort={[
                                    'LastName',
                                    'FirstName',
                                    'MiddleName',
                                    'Suffix',
                                    'EmployeeNumber'
                                ]}
                                getRowValue={(row) =>
                                    row?.firstName || row?.lastName
                                        ? `${row?.lastName}, ${
                                              row?.firstName
                                          } ${row?.middleName ?? ''}`
                                        : undefined
                                }
                                getRowId={(row) =>
                                    row.id ? row.id : row.employeeId
                                }
                                gridRef={employeeGridRef}
                            />
                        </div>
                        <div className={styles.employeeList}>
                            {employees
                                .sort((a, b) =>
                                    `${a.lastName}, ${a.middleName} ${a.firstName}`.localeCompare(
                                        `${b.lastName}, ${b.middleName} ${b.firstName}`
                                    )
                                )
                                .map((e) => (
                                    <PretaskEmployee
                                        key={e.id}
                                        employee={e}
                                        status={
                                            !employeeCertifications[e.id]
                                                ? 'loading'
                                                : hazards.length === 0 ||
                                                  !missingCertifications[e.id]
                                                ? 'certified'
                                                : 'uncertified'
                                        }
                                        isRemovable={e.isRemovable}
                                        handleRemove={handleEmployeeDeselect}
                                        handleCertifications={
                                            addEmployeeCertifications
                                        }
                                    />
                                ))}
                        </div>
                    </div>
                </div>
                {modifiedOn && isLocked && (
                    <div className={styles.timestamp}>
                        {DateTime.fromJSDate(new Date(modifiedOn)).toFormat(
                            'yyyy-MM-dd hh:mm:ss a'
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

PretaskActivity.propTypes = {
    activity: PropTypes.shape({
        description: PropTypes.string,
        employees: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.number,
                label: PropTypes.string
            })
        ),
        defaultEmployees: PropTypes.arrayOf(PropTypes.number),
        hazards: PropTypes.array,
        locationOfWork: PropTypes.string,
        safeProceduresAndControls: PropTypes.string,
        sequence: PropTypes.number,
        specificInjuriesAndHazards: PropTypes.string
    }),
    removable: PropTypes.bool,
    handleDelete: PropTypes.func,
    handleUpdate: PropTypes.func
};

export default PretaskActivity;
