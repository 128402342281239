import React from 'react';

import PropTypes from 'prop-types';

import styles from '../../../../styles/general/grid/row renderers/Row.module.scss';

const Row = ({
    children,
    className,
    selected,
    disabled,
    rowClass,
    index,
    role,
    style,
    onClick,
    onDoubleClick
}) => (
    <div
        className={[
            className,
            rowClass,
            selected ? styles.selected : null,
            disabled ? styles.disabled : null,
            index % 2 === 0 ? styles.even : styles.odd
        ].join(' ')}
        role={role}
        style={style}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
    >
        {React.Children.map(children, (child) => React.cloneElement(child))}
    </div>
);

Row.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    index: PropTypes.any
};

export default Row;
