import useApi from '../../../../hooks/useApi';

import Button from '../../../general/input/Button';
import Modal from '../../../general/modal/Modal';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';

import styles from '../../../../styles/apps/contact/modal/DeleteFlagModal.module.scss';

const DeleteFlagModal = ({
    handleClose,
    handleDelete,
    record
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [{ loading }, deleteFlag] = useApi('/contact/flag', 'DELETE', {
        manual: true
    });

    const handleSubmitClicked = () =>
        deleteFlag({
            url: `/contact/flag/${record.id}`
        })
            .then(() => {
                handleDelete?.(record.id);
                enqueueSnackbar('Flag deleted successfully.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                handleClose?.();
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Failed to delete Flag.', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });

    return (
        <Modal open handleClose={handleClose}>
            <Modal.Title icon={faTrashAlt}>Delete Flag</Modal.Title>
            <Modal.Body>
                <div className={styles.confirmationMessage}>
                    Are you sure you want to delete this flag?
                </div>
                <div className={styles.resourceContainer}>
                    <div className={styles.resourceMessage}>
                        The following resources will be deleted:
                    </div>
                    <div className={styles.resourceList}>
                        <ul>
                            <li>1 Flag</li>
                            {record?.contacts?.filter?.(c => !c.isDeleted)?.length > 0 && <li>{`Flags from ${record?.contacts?.filter?.(c => !c.isDeleted)?.length} contacts`}</li>}
                        </ul>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Confirm"
                    onClick={handleSubmitClicked}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteFlagModal;