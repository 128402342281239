import logo from '../../assets/cve-colorx70.png';

import styles from '../../styles/general/LogoLoader.module.scss';

const LogoLoader = ({ size = 120, hideLogo = false }) => {
    return (
        <div className={styles.container}>
            <div
                className={styles.loader}
                style={{
                    width: size,
                    height: size
                }}
            >
                <div
                    className={styles.loaderCircle}
                    style={{
                        width: size,
                        height: size,
                        marginLeft: -size / 2,
                        marginTop: -size / 2
                    }}
                ></div>
                <div
                    className={styles.loaderLineMask}
                    style={{
                        width: size / 2,
                        height: size,
                        marginLeft: -size / 2,
                        marginTop: -size / 2,
                        transformOrigin: `${size / 2}px ${size / 2}px`
                    }}
                >
                    <div
                        className={styles.loaderLine}
                        style={{
                            width: size,
                            height: size
                        }}
                    ></div>
                </div>
                {!hideLogo && (
                    <img
                        src={logo}
                        alt="CVE"
                        className={styles.logo}
                        style={{
                            width: size / 1.7
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default LogoLoader;
