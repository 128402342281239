import React, { useLayoutEffect, useRef, useState } from 'react';

import useDimensions from '../../hooks/useDimensions';
import useOutsideClick from '../../hooks/useOutsideClick';
import useOverflowDetector from '../../hooks/useOverflowDetector';
import useScrollPosition from '../../hooks/useScrollPosition';
import useToggle from '../../hooks/useToggle';

import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import styles from '../../styles/general/Tooltip.module.scss';

const Tooltip = ({
    children,
    classes,
    tooltip,
    clickTrigger = 'overflow',
    hoverTrigger = 'overflow',
    hoverDelay,
    position = {
        x: 'center',
        y: 'top'
    },
    offset = {
        x: 0,
        y: 8
    },
    width: tooltipWidth = 300,
    style = {},
    scrollRef
}) => {
    const containerRef = useRef();
    const overflowRef = useRef();

    const hoverTimer = useRef();

    const [clicked, toggleClicked] = useToggle(false);
    const [hovered, toggleHovered] = useToggle(false);

    const [windowWidth] = useDimensions();
    const [, getOverflowState] = useOverflowDetector(overflowRef);
    useScrollPosition({
        ref: scrollRef
    });

    const handleClick = (e) => {
        if (
            !clickTrigger ||
            clickTrigger === 'never' ||
            (clickTrigger === 'overflow' && getOverflowState() === false)
        )
            return;

        toggleClicked(clicked => !clicked);
        e.preventDefault();
        e.stopPropagation();
    };

    const handleHoverAction = (state) => {
        if (!hoverTrigger || hoverTrigger === 'never') return;

        if (state && hoverTrigger === 'overflow') state = getOverflowState();

        toggleHovered(state);
    };

    const handleHover = (state) => {
        if (hoverDelay) {
            if (state) {
                hoverTimer.current = setTimeout(
                    () => handleHoverAction(state),
                    hoverDelay
                );
                return;
            } else {
                if (hoverTimer.current) clearTimeout(hoverTimer.current);
                hoverTimer.current = null;
            }
        }
        handleHoverAction(state);
    };

    useOutsideClick(
        containerRef,
        toggleClicked.bind(this, false),
        clicked && clickTrigger && clickTrigger !== 'never'
    );

    return (
        <div
            className={[styles.container, classes?.tooltipContainer].join(' ')}
            onMouseEnter={handleHover.bind(this, true)}
            onMouseLeave={handleHover.bind(this, false)}
            onClick={handleClick}
            ref={containerRef}
            style={style?.container}
        >
            {(hovered || clicked) &&
                createPortal(
                    <TooltipContent
                        style={style}
                        containerRef={containerRef}
                        windowWidth={windowWidth}
                        position={position}
                        offset={offset}
                        tooltip={tooltip}
                        tooltipWidth={tooltipWidth}
                    />,
                    document.getElementById('tooltip-root')
                )}
            {isFunction(children) ? children(overflowRef) : children}
        </div>
    );
};

Tooltip.propTypes = {
    children: PropTypes.any,
    tooltip: PropTypes.any,
    clickTrigger: PropTypes.oneOf(['always', 'never', 'overflow']),
    hoverTrigger: PropTypes.oneOf(['always', 'never', 'overflow']),
    hoverDelay: PropTypes.number,
    position: PropTypes.shape({
        x: PropTypes.oneOf(['left', 'right', 'center']),
        y: PropTypes.oneOf(['top', 'bottom', 'center'])
    }),
    offset: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
    }),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    style: PropTypes.shape({
        container: PropTypes.object,
        tooltip: PropTypes.object
    })
};

const TextTooltip = ({ tooltip, children, width, style, classes, ...rest }) => (
    <Tooltip
        tooltip={
            <div className={styles.textTooltip} style={style?.textTooltip}>
                {tooltip}
            </div>
        }
        width={width}
        style={style}
        {...rest}
        classes={classes}
    >
        {(overflowRef) => (
            <div
                className={[styles.textTooltipContent, classes?.textTooltipContent].join(' ')}
                ref={overflowRef}
                style={style?.textTooltipContent}
            >
                {children}
            </div>
        )}
    </Tooltip>
);

TextTooltip.propTypes = {
    children: PropTypes.any,
    tooltip: PropTypes.any,
    clickTrigger: PropTypes.oneOf(['always', 'never', 'overflow']),
    hoverTrigger: PropTypes.oneOf(['always', 'never', 'overflow']),
    hoverDelay: PropTypes.number,
    position: PropTypes.shape({
        x: PropTypes.oneOf(['left', 'right', 'center']),
        y: PropTypes.oneOf(['top', 'bottom', 'center'])
    }),
    offset: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
    }),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    style: PropTypes.shape({
        container: PropTypes.object,
        tooltip: PropTypes.object,
        textTooltip: PropTypes.object,
        textTooltipContent: PropTypes.object
    }),
    scrollRef: PropTypes.object
};

const TooltipContent = ({
    style,
    containerRef,
    position,
    offset,
    tooltipWidth,
    tooltip,
    windowWidth
}) => {
    const tooltipRef = useRef();

    const [transform, setTransform] = useState(null);

    useLayoutEffect(() => {
        const { left, top, width, height } =
            containerRef?.current?.getBoundingClientRect();

        const { width: tooltipWidth } =
            tooltipRef?.current?.getBoundingClientRect();

        let y;
        switch (position.y) {
            case 'top':
                y = `${top - offset.y}px`;
                break;
            case 'center':
                y = `${top + height / 2}px`;
                break;
            case 'bottom':
                y = `${top + height + offset.y}px`;
                break;
            default:
                y = `${top - height - offset.y}px`;
                break;
        }

        let x;
        switch (position.x) {
            case 'center':
                left + tooltipWidth / 2 + width / 2 + offset.x >=
                windowWidth - 10
                    ? (x = -tooltipWidth + windowWidth - 10)
                    : (x = left + width / 2 - tooltipWidth / 2 + offset.x);
                break;
            case 'right':
                left + width + offset.x + tooltipWidth >= windowWidth - 10
                    ? (x = -tooltipWidth + windowWidth - 10)
                    : (x = left + width + offset.x);
                break;
            case 'left':
                left - tooltipWidth - offset.x <= 10
                    ? (x = 10)
                    : (x = left - tooltipWidth - offset.x);
                break;
            default:
                left + tooltipWidth / 2 + width / 2 + offset.x >=
                windowWidth - 10
                    ? (x = -tooltipWidth + windowWidth - 10)
                    : (x = left + width / 2 - tooltipWidth / 2 + offset.x);
                break;
        }

        let translations = [
            `translate3d(${x}px,${y},0)`,
            position.y === 'center'
                ? 'translateY(-50%)'
                : position.y === 'top'
                ? 'translateY(-100%)'
                : null
        ];

        setTransform(translations.join(' '));
    }, [containerRef, offset.x, offset.y, position.x, position.y, windowWidth]);

    return (
        <div
            className={styles.tooltip}
            ref={tooltipRef}
            style={{
                transform: transform,
                maxWidth: tooltipWidth,
                ...style?.tooltip
            }}
        >
            {tooltip}
        </div>
    );
};

export { TextTooltip };
export default Tooltip;
