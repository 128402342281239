import { useEffect, useState } from 'react';

import { debounce as _debounce } from 'lodash';
import PropTypes from 'prop-types';

const useScrollPosition = (options = {}) => {
    const { ref, debounce, element } = options;

    const [scroll, setScroll] = useState(
        (ref?.current ?? element ?? document.getElementById('main'))?.scrollTop
    );

    const scrollAction = () => {
        let scrollElement =
            ref?.current ?? element ?? document.getElementById('main');

        setScroll(scrollElement.scrollTop);
    };

    const handleScroll = debounce ? _debounce(scrollAction, 80) : scrollAction;

    useEffect(() => {
        (
            ref?.current ??
            element ??
            document.getElementById('main')
        ).addEventListener('scroll', handleScroll);

        let persistedRef = ref?.current;

        return () => {
            (
                persistedRef ??
                element ??
                document.getElementById('main')
            )?.removeEventListener?.('scroll', handleScroll);
        };
    }, []); //eslint-disable-line

    return scroll;
};

useScrollPosition.propTypes = {
    options: PropTypes.shape({
        debounce: PropTypes.bool,
        ref: PropTypes.object,
        element: PropTypes.element
    })
};

export default useScrollPosition;
