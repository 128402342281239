import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';

import useDimensions from '../../../hooks/useDimensions';
import useOutsideClick from '../../../hooks/useOutsideClick';

import PropTypes from 'prop-types';

import styles from '../../../styles/general/Callout.module.scss';

const Callout = ({
    label,
    content,
    position = { x: 'center', y: 'top' },
    offset = { x: 0, y: 20 },
    persistOnClick = false,
    style = {},
    classes = {}
}) => {
    const calloutContainerRef = useRef(null);
    const calloutElementRef = useRef(null);
    const calloutPopupRef = useRef(null);

    const [width] = useDimensions();
    useOutsideClick(calloutContainerRef, () => setVisible(false));

    const [styling, setStyling] = useState(null);
    const [visible, setVisible] = useState(false);
    //const [hovered, setHovered] = useState(false);

    const recalculatePosition = useCallback(() => {
        let target = calloutElementRef?.current?.getBoundingClientRect?.();
        let popup = calloutPopupRef?.current?.getBoundingClientRect?.();
        let style = {};

        setStyling(() => {
            if (position.x === 'left') {
                if (target.x - popup.width <= 0) {
                    style.right = 0;
                } else {
                    style.right = `calc(100% + ${offset?.x}px)`;
                }
            } else if (position.x === 'right') {
                if (
                    target.x + target.width + popup.width >=
                    window.innerWidth
                ) {
                    if (
                        target.x + target.width + popup.width / 2 >=
                        window.innerWidth
                    ) {
                        style.right = 0;
                    } else {
                        style.left = '-50%';
                    }
                } else {
                    style.left = '100%';
                }
            } else {
                if (
                    target.x + target.width + popup.width / 2 >=
                    window.innerWidth
                ) {
                    style.right = 0;
                } else {
                    style.left = `calc(50% + ${offset?.x}px)`;
                    style.transform = 'translateX(-50%)';
                }
            }

            if (position.y === 'top') {
                style.bottom = `calc(100% + ${offset?.y}px)`;
            } else if (position.y === 'bottom') {
                style.top = `calc(100% + ${offset?.y}px)`;
            } else {
                style.bottom =
                    target.height / 2 - popup.height + (offset?.y ?? 0);
                style.transform = style.transform
                    ? style.transform + ' translateY(-50%)'
                    : style.transform;
            }

            return style;
        });
    }, [offset.x, offset.y, position.x, position.y]);

    /* useLayoutEffect(() => {
        if((!visible && hovered) || visible) recalculatePosition();
    }, [visible, hovered]); */

    useLayoutEffect(() => {
        recalculatePosition();
    }, [width, visible, recalculatePosition]);

    /* const handleHover = e => {
        setHovered(true);
        e.stopPropagation();
    }

    const handleMouseLeave = e => {
        setHovered(false);
        e.stopPropagation();
    } */

    const handleClick = () => {
        if (!persistOnClick) return;
        setVisible((visible) => !visible);
        //setHovered(false);
    };

    const handleClose = () => {
        setVisible(false);
        //setHovered(false);
    };

    return (
        <div
            className={[styles.container, classes?.container].join(' ')}
            ref={calloutContainerRef}
        >
            <div
                ref={calloutElementRef}
                onClick={handleClick}
                className={[styles.label, classes?.label].join(' ')}
            >
                {label?.()}
            </div>
            <div
                style={{
                    ...styling,
                    display: visible ? 'block' : 'none',
                    ...style
                }}
                ref={calloutPopupRef}
                className={[styles.content, classes?.content].join(' ')}
            >
                {content?.(handleClose)}
            </div>
        </div>
    );
};

Callout.propTypes = {
    label: PropTypes.func,
    content: PropTypes.func,
    position: PropTypes.shape({
        x: PropTypes.oneOf(['left', 'center', 'right']),
        y: PropTypes.oneOf(['top', 'center', 'bottom'])
    }),
    offset: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
    }),
    persistOnClick: PropTypes.bool,
    style: PropTypes.object,
    classes: PropTypes.object
};

export default Callout;
