import React, { useEffect, useMemo, useRef, useState } from 'react';

import DropdownMenu from '../DropdownMenu';
import TextCell from '../grid/cell renderers/TextCell';
import Grid, { ClientGrid } from '../grid/Grid';
import Modal from '../modal/Modal';
import { TextTooltip } from '../Tooltip';
import Button from './Button';
import TextInput from './TextInput';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import {
    faChevronDown,
    faChevronUp,
    faSortAlphaDown,
    faSortAlphaUp
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce, isFunction } from 'lodash';
import PropTypes from 'prop-types';

import styles from '../../../styles/general/input/Select.module.scss';

const defaultFilters = {
    search: {
        value: '',
        getFilter: (search) => ({
            Search: search
        })
    }
};

const Select = (props) => {
    let {
        externalFilter,
        options,
        handleFilterRows,
        handleRowSelection,
        handleReset: _handleReset,
        sort: _sort
    } = props;

    let searchRef = useRef();

    const [search, setSearch] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [checked, setChecked] = useState(false);
    const [filter, setFilter] = useState({
        ...defaultFilters,
        ...(props?.externalFilter ?? {})
    });

    const filteredOptions = useMemo(
        () =>
            options && handleFilterRows && isFunction(handleFilterRows)
                ? handleFilterRows(search, options)
                : options,
        [search, options] //eslint-disable-line
    );

    /**
     * Handle changes to the search box
     * @param {*} e - text input event
     * @returns
     */
    const handleSearch = (e) => setSearch(e?.target?.value);

    /**
     * Toggles the sort variable to ascending or descending
     * @returns null
     */
    const toggleSort = () =>
        setSortDirection((sortDirection) =>
            sortDirection === 'asc' ? 'desc' : 'asc'
        );

    /**
     *
     * @returns Handles resetting all of the values back to defaults
     */
    const handleReset = () => {
        setSearch('');
        setChecked(false);
        handleRowSelection?.([]);
        _handleReset?.();
    };

    const searchFunction = useMemo(
        () =>
            debounce((search) => {
                setFilter((filter) => ({
                    ...filter,
                    search: {
                        ...filter.search,
                        value: search
                    }
                }));
            }, 400),
        []
    );

    useEffect(() => {
        searchFunction(search);
    }, [search, searchFunction]);

    useEffect(() => {
        if (!externalFilter) return;

        let changedFilter = false;
        Object.keys(externalFilter).forEach((f) => {
            if (!filter[f] || filter[f].value !== externalFilter[f].value)
                changedFilter = true;
        });

        if (!changedFilter) return;

        setFilter((filter) => ({
            ...filter,
            ...(externalFilter ?? {})
        }));
    }, [externalFilter, filter]);

    const sort = useMemo(
        () =>
            _sort?.reduce?.(
                (acc, cur) => ({
                    ...acc,
                    [cur]: {
                        direction: sortDirection
                    }
                }),
                {}
            ),
        [sortDirection] //eslint-disable-line
    );

    props = {
        ...props,
        checked,
        externalFilter: props.filter,
        filter,
        filteredOptions,
        handleReset,
        handleSearch,
        handleFilterRows,
        search,
        searchRef,
        setChecked,
        sort,
        sortDirection,
        toggleSort
    };

    return props?.modal ? (
        <ModalSelect {...props} />
    ) : (
        <DropdownSelect {...props} />
    );
};

const DropdownSelect = ({
    alwaysOpen,
    checked,
    classes = {},
    closeOnSelect,
    disabled,
    disabledIds,
    filter,
    filteredOptions,
    getRowId,
    getRowValue,
    height,
    handleRowSelection,
    handleRowSelected,
    handleRowDeselected,
    handleReset,
    handleSearch,
    hideSelected,
    multiselect,
    pagination,
    placeholder,
    search,
    searchRef,
    selected,
    setChecked,
    sort,
    sortDirection,
    toggleSort,
    style,
    width
}) => {
    return (
        <DropdownMenu
            button={(active) => (
                <SelectButton
                    active={active}
                    classes={classes?.button}
                    getRowValue={getRowValue}
                    placeholder={placeholder}
                    selected={selected}
                    style={style}
                />
            )}
            content={(active, handleClose) => (
                <SelectDropdown
                    active={active}
                    checked={checked}
                    disabledIds={disabledIds}
                    filter={filter}
                    getRowId={getRowId}
                    getRowValue={getRowValue}
                    handleClose={handleClose}
                    handleReset={handleReset}
                    handleRowSelection={handleRowSelection}
                    handleRowDeselected={handleRowDeselected}
                    handleRowSelected={
                        closeOnSelect
                            ? (...value) => {
                                  handleClose();
                                  handleRowSelected?.(value);
                              }
                            : handleRowSelected
                    }
                    handleSearch={handleSearch}
                    height={height}
                    hideSelected={hideSelected}
                    multiselect={multiselect}
                    pagination={pagination}
                    options={filteredOptions}
                    placeholder={placeholder}
                    search={search}
                    searchRef={searchRef}
                    selected={selected}
                    setChecked={setChecked}
                    sort={sort}
                    sortDirection={sortDirection}
                    toggleSort={toggleSort}
                    width={width}
                />
            )}
            disabled={disabled}
            overlay={true}
            style={{
                ...style,
                menuContainer: {
                    boxShadow: '2px 5px 12px 2px rgba(0,0,0,0.18)',
                    height: height ?? '400px',
                    borderBottomLeftRadius: '4px',
                    borderBottomRightRadius: '4px',
                    ...style?.menuContainer
                }
            }}
            classes={classes?.menu}
            open={alwaysOpen}
            hidden={true}
        />
    );
};

const ModalSelect = ({
    checked,
    classes = {},
    disabledIds,
    filter,
    filteredOptions,
    getRowId,
    getRowValue,
    handleRowSelection,
    handleRowSelected,
    handleRowDeselected,
    handleReset,
    handleSearch,
    hideSelected,
    multiselect,
    pagination,
    placeholder,
    search,
    searchRef,
    selected,
    setChecked,
    sort,
    sortDirection,
    toggleSort,
    style,
    width
}) => {
    const [open, setOpen] = useState(false);

    const handleOpenModal = () => setOpen(true);
    const handleCloseModal = () => setOpen(false);

    return (
        <React.Fragment>
            <SelectButton
                //active={active}
                handleClick={handleOpenModal}
                classes={classes?.button}
                getRowValue={getRowValue}
                placeholder={placeholder}
                selected={selected}
                style={style}
            />
            <Modal
                open={open}
                handleClose={handleCloseModal}
                blocking
                dismissable={false}
                style={{
                    content: {
                        overflow: 'hidden',
                        borderRadius: 4
                    }
                }}
                classes={{
                    content: styles.modalContainer
                }}
            >
                <Modal.Body
                    style={{
                        content: {
                            padding: '6px 0 0 0',
                            display: 'flex',
                            flexDirection: 'column'
                        }
                    }}
                >
                    <SelectHeader
                        checked={checked}
                        getRowValue={getRowValue}
                        hideSelected={hideSelected}
                        placeholder={placeholder}
                        selected={selected}
                        setChecked={setChecked}
                        sortDirection={sortDirection}
                        toggleSort={toggleSort}
                    />
                    <SelectSearch
                        handleSearch={handleSearch}
                        search={search}
                        searchRef={searchRef}
                    />
                    <SelectOptions
                        checked={checked}
                        disabledIds={disabledIds}
                        filter={filter}
                        getRowId={getRowId}
                        getRowValue={getRowValue}
                        handleRowSelection={handleRowSelection}
                        handleRowSelected={handleRowSelected}
                        handleRowDeselected={handleRowDeselected}
                        multiselect={multiselect}
                        selected={selected}
                        sortDirection={sortDirection}
                        sort={sort}
                        options={filteredOptions}
                        pagination={pagination}
                    />
                    <SelectActions
                        handleReset={handleReset}
                        handleClose={handleCloseModal}
                    />
                </Modal.Body>
            </Modal>
            {/* <SelectDropdown
                active={active}
                checked={checked}
                disabledIds={disabledIds}
                filter={filter}
                getRowId={getRowId}
                getRowValue={getRowValue}
                handleClose={handleClose}
                handleReset={handleReset}
                handleRowSelection={handleRowSelection}
                handleRowDeselected={handleRowDeselected}
                handleRowSelected={handleRowSelected}
                handleSearch={handleSearch}
                height={height}
                hideSelected={hideSelected}
                multiselect={multiselect}
                pagination={pagination}
                options={filteredOptions}
                placeholder={placeholder}
                search={search}
                searchRef={searchRef}
                selected={selected}
                setChecked={setChecked}
                sort={sort}
                sortDirection={sortDirection}
                toggleSort={toggleSort}
                width={width}
            /> */}
        </React.Fragment>
    );
};

const SelectButton = ({
    active,
    classes = {},
    getRowValue,
    handleClick,
    placeholder,
    selected,
    style
}) => (
    <div
        className={[
            styles.buttonContainer,
            active ? styles['toggled'] : null,
            classes.container
        ].join(' ')}
        style={style?.buttonContainer}
        onClick={handleClick}
    >
        <div className={[styles.valueLabel, classes.label].join(' ')}>
            {!Array.isArray(selected) || selected?.length === 0 ? (
                <p
                    className={[styles.placeholder, classes.placeholder].join(
                        ' '
                    )}
                >
                    {placeholder}
                </p>
            ) : selected?.length > 1 ? (
                `${selected?.length} selected`
            ) : (
                getRowValue?.(selected[0])
            )}
        </div>
        <div
            className={[
                styles.activeIcon,
                active ? styles['toggled'] : null,
                classes.toggle
            ].join(' ')}
        >
            <FontAwesomeIcon icon={active ? faChevronUp : faChevronDown} />
        </div>
    </div>
);

const SelectDropdown = ({
    active,
    checked,
    disabledIds,
    filter,
    getRowId,
    getRowValue,
    handleClose,
    handleReset,
    handleRowSelected,
    handleRowSelection,
    handleRowDeselected,
    handleSearch,
    height,
    hideSelected,
    multiselect,
    options,
    pagination,
    placeholder,
    search,
    searchRef,
    selected,
    setChecked,
    sort,
    sortDirection,
    toggleSort,
    width
}) => {
    useEffect(() => {
        if (active) searchRef?.current?.focus?.();
    }, [active, searchRef]);

    return (
        <div
            className={styles.contentContainer}
            style={{ height: height, width: width }}
        >
            <SelectHeader
                checked={checked}
                getRowValue={getRowValue}
                hideSelected={hideSelected}
                placeholder={placeholder}
                selected={selected}
                setChecked={setChecked}
                sortDirection={sortDirection}
                toggleSort={toggleSort}
            />
            <SelectSearch
                handleSearch={handleSearch}
                search={search}
                searchRef={searchRef}
            />
            <SelectOptions
                checked={checked}
                disabledIds={disabledIds}
                filter={filter}
                getRowId={getRowId}
                getRowValue={getRowValue}
                handleRowSelection={handleRowSelection}
                handleRowSelected={handleRowSelected}
                handleRowDeselected={handleRowDeselected}
                multiselect={multiselect}
                selected={selected}
                sortDirection={sortDirection}
                sort={sort}
                options={options}
                pagination={pagination}
            />
            <SelectActions
                handleReset={handleReset}
                handleClose={handleClose}
            />
        </div>
    );
};

const SelectHeader = ({
    checked,
    getRowValue,
    hideSelected,
    placeholder,
    selected,
    setChecked,
    sortDirection,
    toggleSort
}) => (
    <div className={styles.header}>
        <div className={styles.count}>
            {!Array.isArray(selected) || selected?.length === 0 ? (
                <p className={styles.placeholder}>{placeholder}</p>
            ) : selected?.length > 1 ? (
                `${selected?.length} selected`
            ) : (
                getRowValue?.(selected[0])
            )}
        </div>
        <div className={styles.options}>
            {!checked && (
                <TextTooltip
                    tooltip={
                        sortDirection === 'asc'
                            ? 'Sort Descending'
                            : 'Sort Ascending'
                    }
                    position={{
                        x: 'left',
                        y: 'top'
                    }}
                    offset={{
                        x: 8,
                        y: 8
                    }}
                    hoverTrigger="always"
                    hoverDelay={1000}
                >
                    <div className={styles.option} onClick={toggleSort}>
                        <FontAwesomeIcon
                            icon={
                                sortDirection === 'asc'
                                    ? faSortAlphaDown
                                    : faSortAlphaUp
                            }
                        />
                    </div>
                </TextTooltip>
            )}
            {!hideSelected && (
                <TextTooltip
                    tooltip={checked ? 'Show All' : 'Show Checked'}
                    position={{
                        x: 'left',
                        y: 'top'
                    }}
                    offset={{
                        x: 8,
                        y: 8
                    }}
                    hoverTrigger="always"
                    hoverDelay={1000}
                >
                    <div
                        className={[
                            styles.option,
                            checked ? styles.active : null
                        ].join(' ')}
                        onClick={() => setChecked(!checked)}
                    >
                        <FontAwesomeIcon icon={faCheckSquare} />
                    </div>
                </TextTooltip>
            )}
        </div>
    </div>
);

const SelectSearch = ({ handleSearch, search, searchRef }) => (
    <div className={styles.searchContainer}>
        <TextInput
            placeholder="Search"
            value={search}
            onChange={handleSearch}
            inputRef={searchRef}
            autoFocus
        />
    </div>
);

const SelectOptions = ({
    checked,
    disabledIds,
    filter,
    getRowId,
    getRowValue,
    handleRowSelected,
    handleRowDeselected,
    handleRowSelection,
    multiselect,
    options,
    pagination,
    selected,
    sort
}) => (
    <div className={styles.optionContainer}>
        <div
            style={{ display: checked ? 'none' : 'flex' }}
            className={styles.gridContainer}
        >
            {options && !pagination ? (
                <ClientGrid
                    hideHeader
                    headerHeight={0}
                    disabledIds={disabledIds}
                    fullHeight
                    checkboxSelection
                    rowSelect
                    handleRowSelection={handleRowSelection}
                    handleRowDeselected={handleRowDeselected}
                    handleRowSelected={handleRowSelected}
                    multiselect={multiselect}
                    selected={selected}
                    sort={sort}
                    options={options}
                    getRowId={(row) => getRowId?.(row) ?? row.id}
                    columns={[
                        {
                            title: 'value',
                            key: 'firstName',
                            dataKey: 'firstName',
                            width: 0,
                            flexGrow: 1,
                            cellRenderer: ({ rowData }) => (
                                <TextCell>{getRowValue?.(rowData)}</TextCell>
                            )
                        }
                    ]}
                />
            ) : (
                <Grid
                    hideHeader
                    headerHeight={0}
                    disabledIds={disabledIds}
                    filters={filter}
                    fullHeight
                    checkboxSelection
                    rowSelect
                    handleRowSelection={handleRowSelection}
                    handleRowDeselected={handleRowDeselected}
                    handleRowSelected={handleRowSelected}
                    multiselect={multiselect}
                    selected={selected}
                    sort={sort}
                    pagination={pagination}
                    getRowId={(row) => getRowId?.(row) ?? row.id}
                    columns={[
                        {
                            title: 'value',
                            key: 'firstName',
                            dataKey: 'firstName',
                            width: 0,
                            flexGrow: 1,
                            cellRenderer: ({ rowData }) => (
                                <TextCell>{getRowValue?.(rowData)}</TextCell>
                            )
                        }
                    ]}
                />
            )}
        </div>
        <div
            style={{ display: !checked ? 'none' : 'flex' }}
            className={styles.gridContainer}
        >
            <ClientGrid
                getRowId={(row) => getRowId?.(row) ?? row.id}
                fullHeight
                hideHeader
                headerHeight={0}
                checkboxSelection
                rowSelect
                multiselect
                handleRowSelection={handleRowSelection}
                handleRowDeselected={handleRowDeselected}
                columns={[
                    {
                        title: 'value',
                        key: 'firstName',
                        dataKey: 'firstName',
                        flexGrow: 1,
                        width: 0,
                        cellRenderer: ({ rowData }) => (
                            <TextCell>{getRowValue?.(rowData)}</TextCell>
                        )
                    }
                ]}
                options={selected}
                selected={selected}
            />
        </div>
    </div>
);

const SelectActions = ({ handleReset, handleClose }) => (
    <div className={styles.actionsContainer}>
        <div style={{ flexGrow: 1 }}>
            <Button
                label="Reset"
                type="secondary"
                variant="border"
                onClick={handleReset}
            />
        </div>
        <div>
            <Button
                label="Close"
                type="secondary"
                variant="border"
                onClick={() => handleClose(false)}
            />
        </div>
    </div>
);

Select.propTypes = {
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    options: PropTypes.shape({
        selected: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        onSelect: PropTypes.func,
        multiselect: PropTypes.bool,
        search: PropTypes.bool,
        filter: PropTypes.bool,
        sort: PropTypes.shape({
            fields: PropTypes.array,
            default: PropTypes.string
        })
    }),
    pagination: PropTypes.shape({
        url: PropTypes.string,
        valueGetter: PropTypes.func,
        getRowNodeId: PropTypes.func,
        sort: PropTypes.arrayOf(PropTypes.string)
    })
};

export default Select;
