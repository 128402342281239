import { useRef } from 'react';

import useApi from '../../../../hooks/useApi';

import ErrorMessage from '../../../general/ErrorMessage';
import Button from '../../../general/input/Button';
import InputLabel from '../../../general/input/InputLabel';
import Select from '../../../general/input/Select';
import Modal from '../../../general/modal/Modal';
import { faExchange } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import styles from '../../../../styles/apps/contact/modal/BulkReassignModal.module.scss';

const BulkReassignModal = ({
    handleClose,
    handleReassign: _handleReassign,
    record
}) => {
    const submitButtonRef = useRef();

    const { enqueueSnackbar } = useSnackbar();
    const [{ loading }, reassignUser] = useApi('/contact/reassign', 'PUT', {
        manual: true
    });

    const {
        handleSubmit,
        control,
        getValues,
        setError,
        setValue,
        formState: { errors },
        ...methods
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            transferFrom: record?.id ? [record] : [],
            transferTo: []
        }
    });

    const handleReassign = (data) => {
        reassignUser({
            data: {
                transferFromUserId: data.transferFrom?.[0]?.id,
                transferToUserId: data.transferTo?.[0]?.id
            }
        })
            .then(() => {
                enqueueSnackbar(`Transferred contact records.`, {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                _handleReassign(data.transferTo[0]);
                handleClose();
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Failed to transfer records.', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });
    };

    const handleSubmitClicked = () => submitButtonRef.current?.click?.();

    return (
        <Modal open handleClose={handleClose}>
            <Modal.Title icon={faExchange}>Bulk Reassign</Modal.Title>
            <Modal.Body>
                <FormProvider
                    handleSubmit={handleSubmit}
                    control={control}
                    {...methods}
                >
                    <form
                        autoComplete="true"
                        onSubmit={handleSubmit(handleReassign)}
                        noValidate={true}
                        className={styles.form}
                    >
                        <div className={styles.row}>
                            <Controller
                                name="transferFrom"
                                control={control}
                                rules={{
                                    required:
                                        'You must specify a user to transfer from.'
                                }}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Transfer From"
                                        required
                                        error={error?.message}
                                    >
                                        <Select
                                            pagination={{
                                                url: '/user/app'
                                            }}
                                            placeholder="Select User"
                                            selected={value}
                                            getRowValue={(row) =>
                                                row.displayName
                                            }
                                            handleRowSelection={onChange}
                                            sort={['name']}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div className={styles.row}>
                            <Controller
                                name="transferTo"
                                control={control}
                                rules={{
                                    required:
                                        'You must specify a user to transfer to.'
                                }}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Transfer To"
                                        required
                                        error={error?.message}
                                    >
                                        <Select
                                            pagination={{
                                                url: '/user/app'
                                            }}
                                            placeholder="Select User"
                                            selected={value}
                                            getRowValue={(row) =>
                                                row.displayName
                                            }
                                            handleRowSelection={onChange}
                                            sort={['name']}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        {errors.root?.serverError && (
                            <div>
                                <ErrorMessage
                                    error={errors.root.serverError.message}
                                />
                            </div>
                        )}
                        <div
                            style={{
                                visibility: 'hidden',
                                opacity: 0
                            }}
                        >
                            <button formAction="submit" ref={submitButtonRef} />
                        </div>
                    </form>
                </FormProvider>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Save"
                    onClick={handleSubmitClicked}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default BulkReassignModal;
