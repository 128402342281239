import Select from '../input/Select';

const FormEmployeePicker = ({ isEditable, onChange, value }) => {

    const handleSelection = (selection) => {
        onChange({
            type: 'updateField',
            payload: {
                ...value,
                complexValue: JSON.stringify(selection?.map?.((s) => ({
                    id: s.id,
                    firstName: s.firstName,
                    middleName: s.middleName,
                    lastName: s.lastName
                }))) ?? JSON.stringify([{
                    id: selection.id,
                    firstName: selection.firstName,
                    middleName: selection.middleName,
                    lastName: selection.lastName
                }])
            }
        });
    };

    return isEditable ? (
        <Select
            handleRowSelection={handleSelection}
            selected={value}
            getRowValue={(row) =>
                row?.firstName || row?.lastName
                    ? `${row?.lastName}, ${row?.firstName} ${
                          row?.middleName ?? ''
                      }`
                    : undefined
            }
            getRowId={(row) => row.id}
            pagination={{
                url: '/employees'
            }}
            placeholder="Employee Name"
            sort={[
                'LastName',
                'FirstName',
                'MiddleName',
                'Suffix',
                'EmployeeNumber'
            ]}
            style={{
                container: {
                    height: '100%'
                },
                buttonContainer: {
                    height: '100%',
                    borderRadius: 0,
                    background: 'coral',
                    border: 'none'
                }
            }}
        />
    ) : (
        <div>
            {JSON.parse(value?.complexValue ?? '[]')?.map?.(e => <p>
                {`${e.lastName}, ${e.firstName}${e.middleName ? ` ${e.middleName}` : ''}`}
            </p>)}
        </div>
    );
};

export default FormEmployeePicker;
