const filterReducer = (state, action) => {
    switch(action.type){
        case 'updateFilter': 
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    value: action.payload
                }
            };
        case 'resetFilter': 
            return action.payload;
        default:
            return state;
    }
};

export default filterReducer;