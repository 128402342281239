import { useEffect } from 'react';

import useApi from '../../../hooks/useApi';
import useLocalStorage from '../../../hooks/useLocalStorage';

import { isNullOrEmpty } from '../../../js/services/validation';
import Button from '../../general/input/Button';
import Dropdown from '../../general/input/Dropdown';
import InputLabel from '../../general/input/InputLabel';
import Select from '../../general/input/Select';
import TextInput from '../../general/input/TextInput';
import WorkOrderTime from './WorkOrderTime';
import { faAt, faSave, faUser } from '@fortawesome/free-solid-svg-icons';
import { uniqueId } from 'lodash';
import { useSnackbar } from 'notistack';
import {
    Controller,
    FormProvider,
    useFieldArray,
    useForm,
    useWatch
} from 'react-hook-form';

import styles from '../../../styles/apps/workorder/NewWorkOrder.module.scss';
import { addHours } from 'date-fns';

const NewWorkOrder = () => {
    const [defaults, setDefaults] = useLocalStorage('work-order-engineer');

    const {
        clearErrors,
        handleSubmit,
        control,
        setError,
        setValue,
        reset,
        //formState: { errors },
        ...methods
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            summary: '',
            engineer: defaults ?? [],
            customer: [],
            cveo: null,
            contact: {
                existing: null,
                firstName: '',
                lastName: '',
                email: ''
            },
            timeEntries: []
        }
    });

    const customer = useWatch({
        control,
        name: 'customer'
    });

    const { append, remove, update } = useFieldArray({
        name: 'timeEntries',
        control
    });

    const [{ loading: submissionLoading }, postWorkOrder] = useApi(
        '/connectwise/ticket',
        'POST',
        { manual: true }
    );

    const [{ data: contacts, loading: loadingContacts }, getContacts] = useApi(
        '',
        'GET',
        { manual: true }
    );

    const [
        { data: opportunitites, loading: loadingOpportunities },
        getOpportunities
    ] = useApi('', 'GET', { manual: true });

    const { enqueueSnackbar } = useSnackbar();

    const handleCreate = (data) => {
        let engineer = data['engineer'];
        postWorkOrder({
            data: {
                board: {
                    id: 26
                },
                company: {
                    id: data['customer']?.[0]?.id
                },
                contact: {
                    id: data['contact']?.existing?.id,
                    firstName: data['contact']?.firstName,
                    lastName: data['contact']?.lastName,
                    email: data['contact']?.email
                },
                opportunity: {
                    id: data['cveo']?.id
                },
                status: {
                    id: 530
                },
                summary: data['summary'],
                timeEntries: data['timeEntries']?.map?.((entry) => ({
                    dateEntered: entry?.startDate,
                    timeStart: entry?.startDate,
                    timeEnd: addHours(entry?.startDate, entry?.hoursWorked),
                    notes: entry?.notes,
                    workType: {
                        id: entry?.workType?.id
                    },
                    member: {
                        id: data['engineer']?.[0]?.id
                    }
                }))
            }
        })
            .then(() => {
                setDefaults(engineer);
                enqueueSnackbar('Work Order created successfully.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                reset();
            })
            .catch((error) => {
                console.error(error);
                enqueueSnackbar(`${error?.response?.data?.detail}`, {
                    variant: 'error',
                    autoHideDuration: 8000
                });
            });
    };

    const handleAddTime = (data, e) => {
        clearErrors('timeEntries');
        append({
            id: uniqueId('timeEntry'),
            ...data
        });
    };

    const handleEditTime = (id, data) => {
        clearErrors('timeEntries');
        update(id, data);
    };

    useEffect(() => {
        setValue('contact', {
            existing: null,
            firstName: '',
            lastName: '',
            email: ''
        });
        setValue('cveo', []);
        if (isNullOrEmpty(customer)) return;
        getContacts({
            url: `/connectwise/company/${customer?.[0]?.id}/contact`
        });
        getOpportunities({
            url: `/connectwise/company/${customer?.[0]?.id}/opportunity`
        });
    }, [customer]); //eslint-disable-line

    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <h2>New Work Order</h2>
                <FormProvider
                    handleSubmit={handleSubmit}
                    control={control}
                    {...methods}
                >
                    <form
                        autoComplete="true"
                        onSubmit={handleSubmit(handleCreate)}
                        noValidate={true}
                        className={styles.formContainer}
                    >
                        <Controller
                            name="engineer"
                            control={control}
                            rules={{
                                required: 'You must provide an engineer.'
                            }}
                            render={({
                                field: { value, onChange, ref },
                                fieldState: { error }
                            }) => (
                                <InputLabel
                                    label="Engineer"
                                    required
                                    error={error?.message}
                                >
                                    <Select
                                        placeholder="Select Engineer"
                                        selected={value}
                                        handleRowSelection={onChange}
                                        pagination={{
                                            url: `/connectwise/member`
                                        }}
                                        getRowValue={(r) =>
                                            `${r.firstName} ${r.lastName ?? ''}`
                                        }
                                        closeOnSelect
                                    />
                                </InputLabel>
                            )}
                        />
                        <Controller
                            name="summary"
                            control={control}
                            rules={{
                                required: 'You must provide a summary.'
                            }}
                            render={({
                                field: { value, onChange, ref },
                                fieldState: { error }
                            }) => (
                                <InputLabel
                                    label="Summary"
                                    required
                                    error={error?.message}
                                >
                                    <TextInput
                                        placeholder="Summary"
                                        value={value}
                                        onChange={onChange}
                                        inputRef={ref}
                                    />
                                </InputLabel>
                            )}
                        />
                        <Controller
                            name="customer"
                            control={control}
                            rules={{
                                required: 'You must specify the customer.'
                            }}
                            render={({
                                field: { value, onChange, ref },
                                fieldState: { error }
                            }) => (
                                <InputLabel
                                    label="Customer"
                                    required
                                    error={error?.message}
                                >
                                    <Select
                                        placeholder="Select Customer"
                                        selected={value}
                                        handleRowSelection={onChange}
                                        pagination={{
                                            url: `/connectwise/company`
                                        }}
                                        getRowValue={(r) => r.name}
                                        closeOnSelect
                                    />
                                </InputLabel>
                            )}
                        />
                        {!isNullOrEmpty(customer) && (
                            <Controller
                                name="contact"
                                control={control}
                                rules={{
                                    validate: (value) => {
                                        if (
                                            !isNullOrEmpty(value.existing) ||
                                            (!isNullOrEmpty(value.firstName) &&
                                                !isNullOrEmpty(
                                                    value.lastName
                                                ) &&
                                                !isNullOrEmpty(value.email))
                                        )
                                            return null;
                                        else if (
                                            isNullOrEmpty(value.firstName) +
                                                isNullOrEmpty(value.lastName) +
                                                isNullOrEmpty(value.email) >
                                                0 &&
                                            isNullOrEmpty(value.firstName) +
                                                isNullOrEmpty(value.lastName) +
                                                isNullOrEmpty(value.email) <
                                                3
                                        )
                                            return 'You must provide a first name, last name, and email address.';
                                        else
                                            return 'You must specify an existing contact or create a new one.';
                                    }
                                }}
                                render={({
                                    field: { value, onChange, ref },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Contact"
                                        required
                                        error={error?.message}
                                        variant="group"
                                        labelClick={false}
                                    >
                                        <Dropdown
                                            options={
                                                contacts?.data?.map((c) => ({
                                                    ...c,
                                                    key: c.id,
                                                    value: `${
                                                        c.firstName ?? ''
                                                    } ${c.lastName ?? ''}`
                                                })) ?? []
                                            }
                                            loading={loadingContacts}
                                            placeholder="Existing Contact"
                                            selected={value?.existing}
                                            handleSelect={(selected) =>
                                                onChange({
                                                    ...value,
                                                    existing: selected
                                                })
                                            }
                                        />
                                        <div className={styles.orContainer}>
                                            <p className={styles.orSeparator}>
                                                OR
                                            </p>
                                        </div>
                                        <div className={styles.input}>
                                            <TextInput
                                                placeholder="First Name"
                                                icon={faUser}
                                                value={value.firstName}
                                                onChange={(e) =>
                                                    onChange({
                                                        ...value,
                                                        firstName:
                                                            e?.target?.value
                                                    })
                                                }
                                            />
                                            <TextInput
                                                placeholder="Last Name"
                                                value={value.lastName}
                                                onChange={(e) =>
                                                    onChange({
                                                        ...value,
                                                        lastName:
                                                            e?.target?.value
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className={styles.input}>
                                            <TextInput
                                                placeholder="Email Address"
                                                icon={faAt}
                                                value={value.email}
                                                onChange={(e) =>
                                                    onChange({
                                                        ...value,
                                                        email: e?.target?.value
                                                    })
                                                }
                                            />
                                        </div>
                                    </InputLabel>
                                )}
                            />
                        )}
                        {!isNullOrEmpty(customer) && (
                            <Controller
                                name="cveo"
                                control={control}
                                render={({
                                    field: { value, onChange, ref },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="CVEO#"
                                        error={error?.message}
                                    >
                                        <Dropdown
                                            options={
                                                opportunitites?.data?.map(
                                                    (o) => ({
                                                        ...o,
                                                        key: o.id,
                                                        value: o.name
                                                    })
                                                ) ?? []
                                            }
                                            loading={loadingOpportunities}
                                            placeholder="Select CVEO#"
                                            selected={value}
                                            handleSelect={onChange}
                                        />
                                    </InputLabel>
                                )}
                            />
                        )}
                        <Controller
                            name="timeEntries"
                            control={control}
                            rules={{
                                validate: (value) => {
                                    if (isNullOrEmpty(value))
                                        return 'You must provide time for this work order.';
                                }
                            }}
                            render={({
                                field: { value },
                                fieldState: { error }
                            }) => (
                                <InputLabel
                                    error={error?.root?.message}
                                    labelClick={false}
                                >
                                    <WorkOrderTime
                                        control={control}
                                        fields={value}
                                        handleAdd={handleAddTime}
                                        handleRemove={remove}
                                        handleEdit={handleEditTime}
                                    />
                                </InputLabel>
                            )}
                        />
                        <Button
                            label="Submit Work Order"
                            icon={faSave}
                            className={styles.submitButton}
                            formAction="submit"
                            loading={submissionLoading}
                        />
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default NewWorkOrder;
