const logger = (store) => (next) => (action) => {
    if (process.env.REACT_APP_ENV !== 'prod') {
        console.group(action.type);
        console.info('dispatching', action);
    }
    let result = next(action);
    if (process.env.REACT_APP_ENV !== 'prod') {
        console.log('next state', store.getState());
        console.groupEnd();
    }
    return result;
};

export default logger;
