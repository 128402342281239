import { useState } from 'react';

import useApi from '../../../../hooks/useApi';
import useUser from '../../../../hooks/useUser';

import {
    ContactsDeletePermissions,
    ContactsUpdatePermissions
} from '../../../../js/services/permissions';
import Chip from '../../../general/input/Chip';
import InputLabel from '../../../general/input/InputLabel';
import LoadingModal from '../../../general/modal/LoadingModal';
import Modal from '../../../general/modal/Modal';
import { TextTooltip } from '../../../general/Tooltip';
import Tabs from '../../../navigation/Tabs';
import { faContactCard } from '@fortawesome/free-regular-svg-icons';
import {
    faCheckCircle,
    faPencilAlt,
    faStar,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../../../../styles/apps/contact/modal/ViewContactModal.module.scss';

const ViewContactModal = ({
    handleClose,
    handleEdit,
    handleDelete,
    record
}) => {
    const [tab, setTab] = useState('phones');

    const { userHasPermissions } = useUser();

    const [{ data: contact, loading: loadingContact }] = useApi(
        `/contact/${record?.id}`,
        'GET'
    );

    const handleTabChange = (tab) => setTab(tab);

    return loadingContact ? (
        <LoadingModal message="Loading Contact..." handleClose={handleClose}/>
    ) : (
        <Modal open handleClose={handleClose}>
            <Modal.Title icon={faContactCard}>
                <div className={styles.titleContainer}>
                    <p>View Contact</p>
                    <div className={styles.actionContainer}>
                        {userHasPermissions(ContactsUpdatePermissions) && (
                            <p
                                className={styles.action}
                                onClick={handleEdit.bind(this, record)}
                            >
                                <TextTooltip
                                    tooltip="Edit"
                                    hoverTrigger="always"
                                    hoverDelay={1000}
                                >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </TextTooltip>
                            </p>
                        )}
                        {userHasPermissions(ContactsDeletePermissions) && (
                            <p
                                className={styles.action}
                                onClick={handleDelete.bind(this, record)}
                            >
                                <TextTooltip
                                    tooltip="Delete"
                                    hoverTrigger="always"
                                    hoverDelay={1000}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </TextTooltip>
                            </p>
                        )}
                    </div>
                </div>
            </Modal.Title>
            <Modal.Body>
                <div className={styles.container}>
                    <div className={[styles.row, styles.twoColumn].join(' ')}>
                        <InputLabel label="Contact Name">
                            <p className={styles.text}>{contact?.name}</p>
                        </InputLabel>
                        <InputLabel label="Company">
                            <p className={styles.text}>
                                {contact?.company?.name}
                            </p>
                        </InputLabel>
                    </div>
                    <div className={[styles.row, styles.twoColumn].join(' ')}>
                        <InputLabel label="Custodians">
                            <div className={styles.chipContainer}>
                                {contact?.users?.map((u) => (
                                    <Chip label={u?.displayName} />
                                ))}
                            </div>
                        </InputLabel>
                        <InputLabel label="Divisions">
                            <div className={styles.chipContainer}>
                                {contact?.categories?.sort?.((a, b) => a.name?.localeCompare?.(b.name))?.map((c) => (
                                    <Chip
                                        label={c?.name}
                                        left={(props) =>
                                            c.isPrimary && (
                                                <FontAwesomeIcon
                                                    icon={faStar}
                                                    {...props}
                                                />
                                            )
                                        }
                                    />
                                ))}
                            </div>
                        </InputLabel>
                    </div>
                    <div className={[styles.row, styles.twoColumn].join(' ')}>
                        <InputLabel
                            label="Email Address"
                            style={{
                                container: {
                                    paddingRight: '8px',
                                    maxWidth: '50%'
                                }
                            }}
                        >
                            <p className={styles.text}>{contact?.email}</p>
                        </InputLabel>
                        <InputLabel
                            label="Address"
                            style={{
                                container: {
                                    paddingRight: '8px',
                                    maxWidth: '50%'
                                }
                            }}
                        >
                            <p className={styles.text}>{contact?.address}</p>
                        </InputLabel>
                    </div>
                    <div
                        style={{
                            borderBottom: '1px solid rgba(0,0,0,0.1)'
                        }}
                    >
                        <Tabs
                            tabs={[
                                {
                                    key: 'phones',
                                    label: 'Phones'
                                },
                                {
                                    key: 'flags',
                                    label: 'Flags'
                                }
                            ]}
                            onChange={handleTabChange}
                            active={tab}
                        />
                    </div>
                    <div
                        className={styles.row}
                        style={{
                            visibility: tab === 'phones' ? 'visible' : 'hidden',
                            display: tab === 'phones' ? 'block' : 'none'
                        }}
                    >
                        {contact?.phones && contact?.phones?.length > 0 ? (
                            <div>
                                {contact?.phones?.map?.((p) => (
                                    <p className={styles.primaryText}>
                                        <div
                                            className={styles.primaryContainer}
                                        >
                                            {p.isPrimary && (
                                                <TextTooltip
                                                    tooltip="Primary"
                                                    hoverTrigger="always"
                                                    hoverDelay={1000}
                                                    clickTrigger="always"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCheckCircle}
                                                        className={
                                                            styles.primary
                                                        }
                                                    />
                                                </TextTooltip>
                                            )}
                                        </div>
                                        {p.phone}
                                    </p>
                                ))}
                            </div>
                        ) : (
                            <div className={styles.emptyMessageContainer}>
                                <p className={styles.emptyMessage}>
                                    No phone numbers currently associated with
                                    this contact.
                                </p>
                            </div>
                        )}
                    </div>
                    <div
                        className={styles.row}
                        style={{
                            visibility: tab === 'flags' ? 'visible' : 'hidden',
                            display: tab === 'flags' ? 'block' : 'none'
                        }}
                    >
                        {contact?.flags && contact?.flags?.length > 0 ? (
                            <div className={styles.chipContainer}>
                                {contact?.flags?.map?.((f) => (
                                    <Chip label={f.name} />
                                ))}
                            </div>
                        ) : (
                            <div className={styles.emptyMessageContainer}>
                                <p className={styles.emptyMessage}>
                                    No flags currently associated with this
                                    contact.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ViewContactModal;
