import useApi from '../../../../hooks/useApi';

import Button from '../../../general/input/Button';
import Modal from '../../../general/modal/Modal';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';

import styles from '../../../../styles/apps/quality/modal/DeletePermissionModal.module.scss';

const DeletePermissionModal = ({ handleClose, record, job, handleDelete }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [{ loading: deleting }, deletePermissions] = useApi(``, 'DELETE', {
        manual: true
    });

    const handleSubmit = (data) => {
        deletePermissions({
            url: `/quality/${job}/${record.id}/permissions`
        })
            .then(() => {
                handleDelete(record.id);
                handleClose();
                enqueueSnackbar('Successfully removed permissions.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Failed to remove permissions.', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });
    };

    return (
        <Modal open handleClose={handleClose}>
            <Modal.Title icon={faTrashAlt}>
                Remove Employee Permissions
            </Modal.Title>
            <Modal.Body>
                <div className={styles.confirmationMessage}>
                    Are you sure you want to remove this employee's permissions?
                </div>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Confirm"
                    onClick={handleSubmit}
                    loading={deleting}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default DeletePermissionModal;
