import React from 'react';

import useUser from '../../hooks/useUser';

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import styles from '../../styles/navigation/Tabs.module.scss';

const Tabs = ({ tabs = [], active, onChange = () => null }) => {
    const navigate = useNavigate();
    const { userHasPermissions } = useUser();

    const handleChange = (tab) => {
        if (tab.link) navigate(tab.link);
        /* if(tab.param) setSearchParams({
            [tab?.param?.key]: tab?.param?.value
        }); */
        onChange(tab.key);
    };

    return (
        <div className={styles.tabs}>
            <div className={styles.tabContainer}>
                {tabs
                    ?.filter((tab) => {
                        if (!tab.permission) return true;
                        return userHasPermissions(
                            tab.permission,
                            tab.condition
                        );
                    })
                    ?.map((tab) => (
                        <div
                            key={tab.key}
                            className={[
                                styles.container,
                                active === tab.key ? styles.active : null
                            ].join(' ')}
                            onClick={handleChange.bind(this, tab)}
                        >
                            {tab?.label}
                        </div>
                    ))}
            </div>
        </div>
    );
};

Tabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            label: PropTypes.string,
            link: PropTypes.string
        })
    ),
    active: PropTypes.string,
    onChange: PropTypes.func
};

export default Tabs;
