const FormImage = ({ props, style }) => (
    <img
        style={{
            width: '100%',
            ...JSON.parse(style ?? '{}')
        }}
        alt=""
        {...JSON.parse(props ?? '{}')}
    />
);

export default FormImage;
