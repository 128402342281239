import React, { useState } from 'react';

import useApi from '../../../../hooks/useApi';
import useUser from '../../../../hooks/useUser';

import {
    PretaskCreatePermissions,
    PretaskReportPermissions,
    PretaskUpdatePermissions
} from '../../../../js/services/permissions';
import ActionCell from '../../../general/grid/cell renderers/ActionCell';
import TextCell from '../../../general/grid/cell renderers/TextCell';
import Filters from '../../../general/grid/Filters';
import Grid from '../../../general/grid/Grid';
import DateRange from '../../../general/input/DateRange';
import Dropdown from '../../../general/input/Dropdown';
import Select from '../../../general/input/Select';
import ExportPretaskModal from '../modal/ExportPretaskModal';
import NewPretaskModal from '../modal/NewPretaskModal';
import {
    faFileExport,
    faFilter,
    faPencilAlt,
    faPlus
} from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import styles from '../../../../styles/apps/pretask/Grid.module.scss';

const defaultFilterState = {
    jobs: {
        value: [],
        getFilter: (jobs) =>
            jobs.length === 0 ? null : { JobIds: jobs.map((job) => job?.id) }
    },
    division: {
        value: [],
        getFilter: (divisions) =>
            divisions.length === 0
                ? null
                : { DivisionIds: divisions.map((d) => d.key) }
    },
    hazards: {
        value: [],
        getFilter: (hazards) =>
            hazards.length === 0
                ? null
                : { HazardIds: hazards.map((h) => h?.id) }
    },
    date: {
        value: {
            to: null,
            from: null
        },
        getFilter: (value) => {
            if (!value.to && !value.from) return null;
            let filterObj = {};
            if (value.to) filterObj['DateTo'] = value.to;
            if (value.from) filterObj['DateFrom'] = value.from;
            return filterObj;
        }
    },
    employees: {
        value: [],
        getFilter: (employees) =>
            employees.length === 0
                ? null
                : { EmployeeIds: employees.map((emp) => emp?.id) }
    }
};

const PretaskGrid = () => {
    const navigate = useNavigate();
    const { userHasPermissions } = useUser();
    const { enqueueSnackbar } = useSnackbar();
    const [{ data: divisionsList, loading: divisionsLoading }] = useApi(
        '/divisions',
        'GET'
    );

    const [selected, setSelected] = useState([]);

    /**
     * Filter State
     */
    const [filter, setFilter] = useState(defaultFilterState);

    /**
     * Modal State
     */
    const [showNewModal, setShowNewModal] = useState(null);
    const [showExportModal, setShowExportModal] = useState(null);

    const handleNew = () => setShowNewModal(true);
    const handleCloseNew = () => setShowNewModal(false);

    const handleEdit = (id) => {
        if (id) navigate(`/pretask/edit/${id}`);
    };

    const handleExport = (option) => {
        if (option === 'Selected Rows' && selected.length <= 0)
            return enqueueSnackbar('No grid rows are selected.', {
                variant: 'error',
                autoHideDuration: 3000,
                preventDuplicate: false
            });
        setShowExportModal(option);
    };
    const handleCloseExport = () => setShowExportModal(null);

    const handleEmployeesChange = (employees) => {
        setFilter((filter) => ({
            ...filter,
            employees: {
                ...filter['employees'],
                value: employees
            }
        }));
    };

    const handleJobsChange = (jobs) => {
        setFilter((filter) => ({
            ...filter,
            jobs: {
                ...filter['jobs'],
                value: jobs
            }
        }));
    };
    const handleDivisionsChange = (division) => {
        setFilter((filter) => ({
            ...filter,
            division: {
                ...filter['division'],
                value: division
            }
        }));
    };

    const handleHazardsChange = (hazard) => {
        setFilter((filter) => ({
            ...filter,
            hazards: {
                ...filter['hazards'],
                value: hazard
            }
        }));
    };

    const handleDateChange = (field, date) => {
        setFilter((filter) => ({
            ...filter,
            date: {
                ...filter['date'],
                value: {
                    ...filter['date'].value,
                    [field]: date
                }
            }
        }));
    };

    const clearFilters = () => {
        setFilter(defaultFilterState);
    };

    /**
     * Open the detail sidepanel and fetch the record when row clicked
     * @param {*} row - row id that was selected
     */
    const handleRowSelection = (row) => {
        setSelected(row);
    };

    const handleRowDoubleClick = ({ rowData }) => {
        if (!rowData.id) return;
        navigate(`/pretask/view/${rowData.id}`);
    };

    return (
        <div className={styles.container}>
            <Grid
                actions={[
                    {
                        type: 'primary',
                        label: 'New',
                        icon: faPlus,
                        onClick: handleNew,
                        permission: PretaskCreatePermissions
                    },
                    {
                        type: 'secondary',
                        variant: 'border',
                        label: 'Export',
                        icon: faFileExport,
                        permission: PretaskReportPermissions,
                        handleClick: handleExport,
                        options: ['Selected Rows', 'Filtered Rows']
                    }
                ].filter(
                    (action) =>
                        !action.permission ||
                        userHasPermissions(action.permission)
                )}
                classes={{ header: styles.gridHeader }}
                checkboxSelection
                filters={filter}
                fixed
                multiselect
                rowSelect
                handleRowSelection={handleRowSelection}
                selected={selected}
                rowEventHandlers={{
                    onDoubleClick: handleRowDoubleClick
                }}
                getRowId={(r) => r.id}
                pagination={{
                    url: '/pretasks',
                    pageSize: 100,
                    params: {
                        audience: 'field-supervisor'
                    }
                }}
                sidepanel={{
                    filters: {
                        label: 'Filters',
                        icon: faFilter,
                        component: Filters,
                        props: {
                            clearFilters: clearFilters,
                            filters: [
                                {
                                    label: 'Division',
                                    component: Dropdown,
                                    width: '100%',
                                    props: {
                                        placeholder: 'Select Division(s)',
                                        loading: divisionsLoading,
                                        options: divisionsList?.map((d) => ({
                                            key: d.id,
                                            value: d.name
                                        })),
                                        handleSelect: handleDivisionsChange,
                                        multiselect: true,
                                        selected: filter.division?.value
                                    }
                                },
                                {
                                    label: 'Jobs',
                                    component: Select,
                                    props: {
                                        placeholder: 'Select Job(s)',
                                        multiselect: true,
                                        handleRowSelection: handleJobsChange,
                                        selected: filter['jobs'].value,
                                        getRowValue: (row) =>
                                            row.id
                                                ? `${row?.vistaJobNumber} - ${row?.vistaJobDescription}`
                                                : undefined,
                                        getRowId: (row) => row.id,
                                        pagination: {
                                            url: '/jobs'
                                        },
                                        sort: [
                                            'VistaJobNumber',
                                            'VistaJobDescription'
                                        ]
                                    }
                                },
                                {
                                    label: 'Employees',
                                    component: Select,
                                    props: {
                                        placeholder: 'Select Employee(s)',
                                        multiselect: true,
                                        filter: {
                                            IsActive: {
                                                value: true,
                                                getFilter: () => ({
                                                    IsActive: true
                                                })
                                            }
                                        },
                                        handleRowSelection:
                                            handleEmployeesChange,
                                        selected: filter['employees'].value,
                                        getRowValue: (row) =>
                                            row?.firstName || row?.lastName
                                                ? `${row?.lastName}, ${
                                                      row?.firstName
                                                  } ${row?.middleName ?? ''}`
                                                : undefined,
                                        getRowNodeId: (row) => row.id,
                                        pagination: {
                                            url: '/employees'
                                        },
                                        sort: [
                                            'LastName',
                                            'FirstName',
                                            'MiddleName',
                                            'Suffix',
                                            'EmployeeNumber'
                                        ]
                                    }
                                },
                                {
                                    label: 'Hazard',
                                    component: Select,
                                    props: {
                                        placeholder: 'Select Hazards(s)',
                                        multiselect: true,
                                        handleRowSelection: handleHazardsChange,
                                        selected: filter['hazards'].value,
                                        getRowValue: (row) => row?.description,
                                        getRowNodeId: (row) => row.id,
                                        pagination: {
                                            url: '/hazards'
                                        },
                                        sort: ['Description']
                                    }
                                },
                                {
                                    label: 'Date',
                                    component: DateRange,
                                    props: {
                                        to: filter.date?.value.to,
                                        from: filter.date?.value.from,
                                        handleChange: handleDateChange
                                    }
                                }
                            ]
                        }
                    }
                }}
                columns={[
                    {
                        title: 'Date',
                        key: 'DateSpecified',
                        dataKey: 'dateSpecified',
                        sortKey: 'DateSpecified',
                        width: 120,
                        minWidth: 120,
                        cellRenderer: ({ cellData }) =>
                            cellData && (
                                <TextCell>
                                    {cellData &&
                                        new Intl.DateTimeFormat('en-US', {
                                            timeZone: 'UTC',
                                            month: '2-digit',
                                            day: '2-digit',
                                            year: 'numeric'
                                        }).format(new Date(cellData))}
                                </TextCell>
                            )
                    },
                    {
                        title: 'Division',
                        key: 'DivisionName',
                        dataKey: 'divisionName',
                        sortKey: 'DivisionName',
                        width: 140,
                        minWidth: 140,
                        cellRenderer: ({ cellData }) =>
                            cellData && <TextCell>{cellData}</TextCell>
                    },
                    {
                        title: 'Department',
                        key: 'DepartmentName',
                        dataKey: 'departmentName',
                        sortKey: 'DepartmentName',
                        width: 180,
                        minWidth: 180,
                        cellRenderer: ({ cellData }) =>
                            cellData && <TextCell>{cellData}</TextCell>
                    },
                    {
                        title: 'Job Number',
                        key: 'JobNumber',
                        dataKey: 'jobNumber',
                        sortKey: 'JobNumber',
                        width: 120,
                        maxWidth: 120,
                        cellRenderer: ({ cellData }) =>
                            cellData && <TextCell>{cellData}</TextCell>
                    },
                    {
                        title: 'Job Description',
                        key: 'JobDescription',
                        dataKey: 'jobDescription',
                        sortKey: 'JobDescription',
                        width: 210,
                        minWidth: 210,
                        cellRenderer: ({ cellData }) =>
                            cellData && <TextCell>{cellData}</TextCell>
                    },
                    {
                        title: 'Comments',
                        key: 'Comments',
                        dataKey: 'comments',
                        sortKey: 'Comments',
                        fixedGrow: 1,
                        minWidth: 210,
                        cellRenderer: ({ cellData }) =>
                            cellData && <TextCell>{cellData}</TextCell>
                    },
                    {
                        title: 'Edit Status',
                        key: 'EditStatus',
                        dataKey: 'editStatus',
                        frozen: 'right',
                        sortable: false,
                        minWidth: 120,
                        width: 120,
                        cellRenderer: ({ cellData }) =>
                            cellData && <TextCell>{cellData}</TextCell>
                    },
                    ...(userHasPermissions(PretaskUpdatePermissions)
                        ? [
                              {
                                  title: '',
                                  key: 'Action',
                                  frozen: 'right',
                                  width: 60,
                                  minWidth: 60,
                                  sortable: false,
                                  cellRenderer: ({ rowData }) => (
                                      <ActionCell
                                          actions={[
                                              ...(userHasPermissions(
                                                  PretaskUpdatePermissions
                                              ) &&
                                              rowData.editStatus !== 'Closed'
                                                  ? [
                                                        {
                                                            icon: faPencilAlt,
                                                            type: 'grayscale',
                                                            onClick:
                                                                handleEdit.bind(
                                                                    this,
                                                                    rowData?.id
                                                                ),
                                                            tooltip: {
                                                                tooltip: 'Edit',
                                                                hoverDelay: 650,
                                                                hoverTrigger:
                                                                    'always'
                                                            }
                                                        }
                                                    ]
                                                  : [])
                                          ]}
                                      />
                                  )
                              }
                          ]
                        : [])
                ]}
            />
            {showNewModal && <NewPretaskModal handleClose={handleCloseNew} />}
            {showExportModal && (
                <ExportPretaskModal
                    handleClose={handleCloseExport}
                    filters={filter}
                    type={showExportModal}
                    selected={selected}
                    audience="field-supervisor"
                />
            )}
        </div>
    );
};

export default PretaskGrid;
