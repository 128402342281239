import { useRef } from 'react';

import useApi from '../../../../hooks/useApi';

import Button from '../../../general/input/Button';
import InputLabel from '../../../general/input/InputLabel';
import TextInput from '../../../general/input/TextInput';
import Modal from '../../../general/modal/Modal';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import styles from '../../../../styles/apps/quality/modal/EditQualityTagModal.module.scss';
import ErrorMessage from '../../../general/ErrorMessage';

const EditQualityTagModal = ({ handleClose, handleEdit, tag }) => {
    const submitButtonRef = useRef();

    const [{ loading }, updateTag] = useApi('/quality/tag', 'PUT', {
        manual: true
    });
    const { enqueueSnackbar } = useSnackbar();
    const {
        handleSubmit,
        control,
        setError,
        formState: { errors }
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            name: tag.name
        }
    });

    const handleCreate = (data) => {
        updateTag({
            url: `/quality/tag/${tag.id}`,
            data: {
                name: data?.name
            }
        })
            .then((tag) => {
                handleEdit(tag.id, tag);
                enqueueSnackbar('Tag updated successfully.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                handleClose();
            })
            .catch((err) => {
                console.error(err);
                setError('root.serverError', {
                    type: 'custom',
                    message: err.response?.data?.detail
                });
                enqueueSnackbar(
                    err.response?.data?.title ??
                        'Error encountered while updating tag.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000
                    }
                );
            });
    };

    const handleSubmitClicked = () => submitButtonRef?.current?.click?.();

    return (
        <Modal open handleClose={handleClose} blocking>
            <Modal.Title icon={faPencilAlt}>Edit Tag</Modal.Title>
            <Modal.Body>
                <form
                    autoComplete="true"
                    onSubmit={handleSubmit(handleCreate)}
                    noValidate={true}
                    className={styles.form}
                >
                    <div className={styles.row}>
                        <Controller
                            name="name"
                            control={control}
                            rules={{
                                required: 'You must provide a tag name.'
                            }}
                            render={({
                                field: { value, onChange, ref },
                                fieldState: { error }
                            }) => (
                                <InputLabel
                                    label="Tag Name"
                                    required
                                    error={error?.message}
                                >
                                    <TextInput
                                        placeholder="Tag Name"
                                        value={value}
                                        onChange={onChange}
                                        inputRef={ref}
                                    />
                                </InputLabel>
                            )}
                        />
                    </div>
                    {errors.root?.serverError && (
                        <div>
                            <ErrorMessage
                                error={errors.root.serverError.message}
                            />
                        </div>
                    )}
                    <div
                        style={{
                            visibility: 'hidden',
                            opacity: 0
                        }}
                    >
                        <button formAction="submit" ref={submitButtonRef} />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Update"
                    onClick={handleSubmitClicked}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default EditQualityTagModal;
