import Button from '../../../general/input/Button';
import Modal from '../../../general/modal/Modal';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';

import styles from '../../../../styles/apps/workorder/modal/DeleteWorkOrderTimeModal.module.scss';

const DeleteWorkOrderTimeModal = ({
    handleClose,
    handleDelete,
    timeRecord
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmitClicked = () => {
        handleDelete(timeRecord?.index);
        handleClose();
        enqueueSnackbar('Time Entry Removed', {
            variant: 'success',
            autoHideDuration: 2000,
            preventDuplicate: true
        });
    };

    return (
        <Modal open handleClose={handleClose}>
            <Modal.Title icon={faTrashAlt}>Delete Time Entry</Modal.Title>
            <Modal.Body>
                <div className={styles.confirmationMessage}>
                    Are you sure you want to delete this time entry?
                </div>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button label="Confirm" onClick={handleSubmitClicked} />
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteWorkOrderTimeModal;
