import React from 'react';

import { faCalendarAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-date-picker';
import ReactDateTimePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
//import DatePicker from 'react-date-picker/dist/entry.nostyle';
import '../../../styles/general/input/DatePicker.scss';

const DatePicker = ({ onChange, value, options, props }) => (
    <ReactDatePicker
        onChange={onChange}
        value={value}
        calendarIcon={
            options?.hideCalendarIcon ? null : (
                <FontAwesomeIcon icon={faCalendarAlt} />
            )
        }
        clearIcon={options?.clearIcon || <FontAwesomeIcon icon={faTimes} />}
        {...options}
        {...props}
    />
);

DatePicker.propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.shape({
        calendarIcon: PropTypes.elementType,
        clearIcon: PropTypes.elementType,
        openCalendarOnFocus: PropTypes.bool,
        autoFocus: PropTypes.bool
    }),
    value: PropTypes.instanceOf(Date),
    props: PropTypes.object
};

const DateTimePicker = ({ onChange, value, options, ...props }) => {
    return (
        <ReactDateTimePicker
            onChange={onChange}
            selected={value}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            {...options}
            {...props}
            wrapperClassName={['react-datepicker-wrapper', props.wrapperClassName ?? ''].join(' ')}
        />
    );
};

export default DatePicker;
export { DateTimePicker };
