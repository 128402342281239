import useApi from '../../../../hooks/useApi';

import Button from '../../../general/input/Button';
import Modal from '../../../general/modal/Modal';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';

import styles from '../../../../styles/apps/quality/modal/DeleteEquipmentAttachmentModal.module.scss';

const DeleteEquipmentAttachmentModal = ({
    handleClose,
    handleDelete,
    attachment
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const [{ loading }, deleteAttachment] = useApi(
        '/quality/attachment',
        'DELETE',
        {
            manual: true
        }
    );

    const handleSubmitClicked = () =>
        deleteAttachment({
            url: `/quality/attachment/${attachment?.attachment?.id}`
        })
            .then(() => {
                handleDelete?.(attachment?.id);
                enqueueSnackbar('Attachment deleted successfully.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                handleClose?.();
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Failed to delete Attachment.', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });

    return (
        <Modal open handleClose={handleClose}>
            <Modal.Title icon={faTrashAlt}>Delete Attachment</Modal.Title>
            <Modal.Body>
                <div className={styles.confirmationMessage}>
                    Are you sure you want to delete this attachment?
                </div>
                <div className={styles.resourceContainer}>
                    <div className={styles.resourceMessage}>
                        The following resources will be deleted:
                    </div>
                    <div className={styles.resourceList}>
                        <ul>
                            <li>1 Attachment</li>
                        </ul>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Confirm"
                    onClick={handleSubmitClicked}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteEquipmentAttachmentModal;
