const apiBaseUrl =
    process.env.REACT_APP_ENV === 'prod'
        ? 'https://api.apps.cve.com'
        : process.env.REACT_APP_ENV === 'dev'
        ? 'https://api.apps-dev.cve.com'
        : 'http://localhost:5080';

const appBaseUrl =
    process.env.REACT_APP_ENV === 'prod'
        ? 'https://apps.cve.com/'
        : process.env.REACT_APP_ENV === 'dev'
        ? 'https://apps-dev.cve.com/'
        : 'http://localhost:3000/';

export { apiBaseUrl, appBaseUrl };
