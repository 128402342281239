import CheckboxGroup from "../input/CheckboxGroup";

const FormCheckbox = ({id, isEditable, onChange, props, value}) =>
    isEditable ? (
        <div>
            <CheckboxGroup {...JSON.parse(props ?? '{}')} handleChange={onChange} selected={value}/>
        </div>
    ) : (
        <div>
            <CheckboxGroup {...JSON.parse(props ?? '{}')} selected={value} readOnly/>
        </div>
    );

export default FormCheckbox;
