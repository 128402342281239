import React from 'react';

import PropTypes from 'prop-types';

import styles from '../../styles/general/Loader.module.scss';

const Loader = ({ height, background, spinColor, className }) => (
    <div
        style={{
            height,
            width: height,
            borderColor: background,
            borderRightColor: spinColor
        }}
        className={[styles.loader, styles.simpleCircle, className].join(' ')}
    />
);

Loader.propTypes = {
    height: PropTypes.string,
    background: PropTypes.string,
    spinColor: PropTypes.string,
    className: PropTypes.string
};

export default Loader;
