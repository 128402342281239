import React, { useEffect, useState } from 'react';

import useApi from '../../../../hooks/useApi';

import InputLabel from '../../../general/input/InputLabel';
import TextInput from '../../../general/input/TextInput';
import Toggle from '../../../general/input/Toggle';
import LoadingOverlay from '../../../general/LoadingOverlay';
import Modal from '../../../general/modal/Modal';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const EditCertificationTypeModal = ({
    open,
    blocking,
    certificationId,
    handleClose = () => null,
    onEdited = () => null
}) => {
    const [code, setCode] = useState('');
    const [description, setDescription] = useState('');
    const [isActive, setIsActive] = useState(null);
    const [{ loading }, editCertificationType] = useApi(
        '/certification-types',
        'PUT',
        { manual: true }
    );

    //Retrieve the certification type
    const [{ data: certificationType }] = useApi(
        `/certification-types/${certificationId}`,
        'GET'
    );

    const { enqueueSnackbar } = useSnackbar();

    const handleCodeChange = (e) => {
        setCode(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleIsActiveChange = (state) => {
        setIsActive(state);
    };

    const handleEdit = () => {
        editCertificationType({
            url: `/certification-types/${certificationId}`,
            data: {
                Code: code ? code : certificationType.code,
                Description: description
                    ? description
                    : certificationType.description,
                IsActive:
                    isActive != null ? !!isActive : certificationType.isActive
            }
        })
            .then((editedCert) => {
                handleClose();
                enqueueSnackbar(
                    `Certification "${
                        code ? code : certificationType.code
                    }" has been updated.`,
                    {
                        variant: 'success',
                        autoHideDuration: 3000
                    }
                );
                onEdited(editedCert);
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar(
                    'Error encountered while editing the certification type.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000
                    }
                );
            });
    };

    useEffect(() => {
        setCode('');
        setDescription('');
        setIsActive(null);
    }, [certificationId]);

    return (
        <Modal open={open} blocking={blocking} handleClose={handleClose}>
            <Modal.Title>Edit Certification Type</Modal.Title>
            <Modal.Body>
                {certificationType ? (
                    <React.Fragment>
                        <div
                            style={{
                                display: 'flex',
                                padding: '12px 0',
                                marginTop: '24px',
                                alignItems: 'center'
                            }}
                        >
                            <div style={{ marginRight: '24px' }}>
                                <InputLabel
                                    label="Certification Code"
                                    required={true}
                                >
                                    <TextInput
                                        placeholder="Code"
                                        value={
                                            code ? code : certificationType.code
                                        }
                                        onChange={handleCodeChange}
                                    />
                                </InputLabel>
                            </div>
                            <div>
                                <InputLabel label="Active">
                                    <Toggle
                                        handleToggled={handleIsActiveChange}
                                        defaultToggled={
                                            isActive
                                                ? isActive
                                                : certificationType.isActive
                                        }
                                    />
                                </InputLabel>
                            </div>
                        </div>
                        <div style={{ display: 'flex', padding: '12px 0' }}>
                            <div style={{ width: '80%' }}>
                                <InputLabel label="Description" required={true}>
                                    <TextInput
                                        placeholder="Description"
                                        value={
                                            description
                                                ? description
                                                : certificationType.description
                                        }
                                        onChange={handleDescriptionChange}
                                        lines={4}
                                    />
                                </InputLabel>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <div
                        style={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <LoadingOverlay
                            spinColor="rgba(0,0,0,0.7)"
                            backgroundColor="rgba(0,0,0,0.1)"
                            size="lg"
                        />
                    </div>
                )}
            </Modal.Body>
            <Modal.Actions
                buttons={[
                    {
                        label: 'Cancel',
                        onClick: handleClose,
                        type: 'secondary',
                        variant: 'border'
                    },
                    {
                        label: 'Save',
                        loading: loading,
                        onClick: handleEdit,
                        type: 'primary'
                    }
                ]}
            />
        </Modal>
    );
};

EditCertificationTypeModal.propTypes = {
    open: PropTypes.bool,
    blocking: PropTypes.bool,
    certificationId: PropTypes.number,
    handleClose: PropTypes.func,
    onEdited: PropTypes.func
};

export default EditCertificationTypeModal;
