import { useRef, useState } from 'react';

import useFilterReducer from '../../../../hooks/useFilterReducer';
import useUser from '../../../../hooks/useUser';

import {
    ContactsCreatePermissions,
    ContactsDeletePermissions,
    ContactsUpdatePermissions
} from '../../../../js/services/permissions';
import ActionCell from '../../../general/grid/cell renderers/ActionCell';
import TextCell, {
    TextContextCell
} from '../../../general/grid/cell renderers/TextCell';
import Filters from '../../../general/grid/Filters';
import Grid from '../../../general/grid/Grid';
import TextInput from '../../../general/input/TextInput';
import DeleteCompanyModal from '../modal/DeleteCompanyModal';
import EditCompanyModal from '../modal/EditCompanyModal';
import ExportCompanyModal from '../modal/ExportCompanyModal';
import NewCompanyModal from '../modal/NewCompanyModal';
import {
    faFileExport,
    faFilter,
    faPencilAlt,
    faPlus,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';

import styles from '../../../../styles/apps/contact/grid/ContactGrid.module.scss';

const defaultFilterState = {
    address: {
        value: '',
        getFilter: (value) =>
            !value || value.replace(/\s/g, '') === ''
                ? null
                : { Address: value }
    },
    company: {
        value: '',
        getFilter: (value) =>
            !value || value.replace(/\s/g, '') === '' ? null : { Name: value }
    }
};

const CompanyGrid = () => {
    const gridRef = useRef();

    const { userHasPermissions } = useUser();

    const { filter, setFilter, resetFilter } =
        useFilterReducer(defaultFilterState);

    const [selected, setSelected] = useState([]);
    const [modalState, setModalState] = useState({
        new: false,
        edit: false,
        delete: false,
        export: false
    });

    const handleRowSelection = (rows) => {
        setSelected(rows);
    };

    /* Filter Handlers */
    const clearFilters = () => resetFilter();

    const handleFilterChange = (key, value) => {
        setFilter({
            key: key,
            payload: value
        });
    };

    /* Modal Handlers */
    const handleOpenModal = (type, value) =>
        setModalState((modalState) => ({
            ...modalState,
            [type]: value ?? true
        }));

    const handleCloseModal = (type) =>
        setModalState((modalState) => ({
            ...modalState,
            [type]: null
        }));

    const handleNew = (company) => gridRef?.current?.addRow(company);
    const handleEdit = (id, company) =>
        gridRef?.current?.modifyRow(id, company);
    const handleDelete = (id) => gridRef?.current?.removeRow(id);

    return (
        <div className={styles.container}>
            <Grid
                ref={gridRef}
                pagination={{
                    url: '/contact/company',
                    pageSize: 100
                }}
                fixed
                selected={selected}
                filters={filter}
                rowKey="id"
                getRowId={(r) => r.id}
                handleRowSelection={handleRowSelection}
                actions={[
                    {
                        type: 'primary',
                        label: 'New',
                        icon: faPlus,
                        onClick: handleOpenModal.bind(this, 'new'),
                        permission: ContactsCreatePermissions
                    },
                    {
                        type: 'secondary',
                        variant: 'border',
                        label: 'Export',
                        icon: faFileExport,
                        onClick: handleOpenModal.bind(this, 'export')
                    }
                ].filter(
                    (action) =>
                        !action.permission ||
                        userHasPermissions(action.permission)
                )}
                sidepanel={{
                    filters: {
                        label: 'Filters',
                        icon: faFilter,
                        component: Filters,
                        props: {
                            clearFilters: clearFilters,
                            filters: [
                                {
                                    component: TextInput,
                                    label: 'Company Name',
                                    width: '100%',
                                    props: {
                                        placeholder: 'Name',
                                        value: filter['company']?.value,
                                        onChange: (e) =>
                                            handleFilterChange(
                                                'company',
                                                e.target.value
                                            )
                                    }
                                },
                                {
                                    component: TextInput,
                                    label: 'Address',
                                    width: '100%',
                                    props: {
                                        placeholder: 'Address',
                                        value: filter['address']?.value,
                                        onChange: (e) =>
                                            handleFilterChange(
                                                'address',
                                                e.target.value
                                            )
                                    }
                                }
                            ]
                        }
                    }
                }}
                columns={[
                    {
                        title: 'Company',
                        key: 'Company',
                        dataKey: 'name',
                        sortKey: 'name',
                        width: 180,
                        maxWidth: 180,
                        cellRenderer: ({ cellData }) =>
                            cellData && <TextCell>{cellData}</TextCell>
                    },
                    {
                        title: 'Address',
                        key: 'Address',
                        dataKey: 'addresses',
                        sortKey: 'address',
                        width: 300,
                        maxWidth: 300,
                        fixedGrow: 1,
                        cellRenderer: ({ cellData }) =>
                            cellData && (
                                <TextContextCell
                                    context={{
                                        hoverTrigger: 'always',
                                        clickTrigger: 'always'
                                    }}
                                    contextTooltip={cellData
                                        ?.filter?.(
                                            (a) => !a.isPrimary && !a.isDeleted
                                        )
                                        ?.map?.((a, i) => (
                                            <div key={i}>{a.address}</div>
                                        ))}
                                    contextLabel={
                                        cellData?.filter?.((a) => !a.isDeleted)
                                            .length > 1
                                            ? ` ${
                                                  cellData?.filter?.(
                                                      (a) =>
                                                          !a.isPrimary &&
                                                          !a.isDeleted
                                                  ).length
                                              } other(s)`
                                            : null
                                    }
                                >
                                    {cellData.find((a) => a.isPrimary)?.address}
                                </TextContextCell>
                            )
                    },
                    {
                        title: 'Contacts',
                        key: 'Contacts',
                        dataKey: 'contacts',
                        sortKey: 'contacts',
                        width: 150,
                        maxWidth: 150,
                        cellRenderer: ({ cellData }) =>
                            cellData && (
                                <TextCell>
                                    {cellData?.filter?.((c) => !c.isDeleted)
                                        .length ?? 0}{' '}
                                    Contacts
                                </TextCell>
                            )
                    },
                    {
                        title: ' ',
                        key: 'actions',
                        sortable: false,
                        width: 85,
                        minWidth: 85,
                        cellRenderer: ({ rowData }) => (
                            <ActionCell
                                actions={[
                                    ...(userHasPermissions(
                                        ContactsUpdatePermissions
                                    )
                                        ? [
                                              {
                                                  icon: faPencilAlt,
                                                  type: 'grayscale',
                                                  onClick: handleOpenModal.bind(
                                                      this,
                                                      'edit',
                                                      rowData
                                                  ),
                                                  tooltip: {
                                                      tooltip: 'Edit',
                                                      hoverDelay: 650,
                                                      hoverTrigger: 'always'
                                                  }
                                              }
                                          ]
                                        : []),
                                    ...(userHasPermissions(
                                        ContactsDeletePermissions
                                    )
                                        ? [
                                              {
                                                  icon: faTrashAlt,
                                                  type: 'grayscale',
                                                  onClick: handleOpenModal.bind(
                                                      this,
                                                      'delete',
                                                      rowData
                                                  ),
                                                  tooltip: {
                                                      tooltip: 'Delete',
                                                      hoverDelay: 650,
                                                      hoverTrigger: 'always'
                                                  }
                                              }
                                          ]
                                        : [])
                                ]}
                            />
                        )
                    }
                ]}
            />
            {modalState.delete && (
                <DeleteCompanyModal
                    handleClose={handleCloseModal.bind(this, 'delete')}
                    handleDelete={handleDelete}
                    record={modalState.delete}
                />
            )}
            {modalState.edit && (
                <EditCompanyModal
                    handleClose={handleCloseModal.bind(this, 'edit')}
                    handleEdit={handleEdit}
                    record={modalState.edit}
                />
            )}
            {modalState.export && (
                <ExportCompanyModal
                    handleClose={handleCloseModal.bind(this, 'export')}
                    filters={filter}
                />
            )}
            {modalState.new && (
                <NewCompanyModal
                    handleClose={handleCloseModal.bind(this, 'new')}
                    handleNew={handleNew}
                />
            )}
        </div>
    );
};

export default CompanyGrid;
