import { useRef } from 'react';

import useApi from '../../../../hooks/useApi';

import ErrorMessage from '../../../general/ErrorMessage';
import Button from '../../../general/input/Button';
import Checkbox from '../../../general/input/Checkbox';
import Dropdown from '../../../general/input/Dropdown';
import InputLabel from '../../../general/input/InputLabel';
import Select from '../../../general/input/Select';
import Modal from '../../../general/modal/Modal';
import Tabs from '../../../navigation/Tabs';
import NewEquipmentFormCard from '../NewEquipmentFormCard';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { isNumber, uniqueId } from 'lodash';
import { useSnackbar } from 'notistack';
import {
    Controller,
    FormProvider,
    useFieldArray,
    useForm
} from 'react-hook-form';

import styles from '../../../../styles/apps/quality/modal/NewEquipmentFormModal.module.scss';

const NewEquipmentFormModal = ({
    handleClose,
    handleNew,
    equipment,
    jobId
}) => {
    const submitButtonRef = useRef();

    const { enqueueSnackbar } = useSnackbar();
    const {
        handleSubmit,
        control,
        setError,
        clearErrors,
        formState: { errors },
        ...methods
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            equipment: equipment ? [equipment] : [],
            equipmentForms: [],
            inheritTags: true
        }
    });

    const {
        fields: formTypeFields,
        append: appendFormType,
        remove: removeFormType
    } = useFieldArray({
        name: 'equipmentForms',
        control
    });

    const [{ loading: loadingFormTypes, data: formTypes }] = useApi(
        `/quality/${jobId}/form/templates?jobSpecific=true`
    );

    const [{ loading }, createForms] = useApi('/quality/form', 'POST', {
        manual: true
    });

    const handleSubmitClicked = () => submitButtonRef?.current?.click?.();

    const handleAddForm = (selected) => {
        clearErrors('equipmentForms');
        appendFormType({
            id: uniqueId('equipmentForms'),
            name: '',
            formTemplate: selected,
            qualityTags: []
        });
    };

    const handleCreate = (data) => {
        if (!data.equipmentForms || data.equipmentForms?.length <= 0)
            return setError('equipmentForms', {
                message: 'You must add at least 1 form.'
            });

        createForms({
            data: {
                inheritTags: data.inheritTags,
                equipmentId: data.equipment?.[0]?.id,
                equipmentForms: data.equipmentForms.map((ef) => ({
                    ...ef,
                    formTemplate: ef.formTemplate,
                    qualityTags: ef.qualityTags.map((qt) => ({
                        ...qt,
                        id: isNumber(qt.id) ? qt.id : 0
                    }))
                }))
            }
        })
            .then((forms) => {
                handleNew(forms, data.equipment?.[0]?.id);
                enqueueSnackbar('Forms created successfully.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                handleClose();
            })
            .catch((err) => {
                console.error(err);
                setError('root.serverError', {
                    type: 'custom',
                    message: err.response?.data?.detail
                });
                enqueueSnackbar(
                    err.response?.data?.title ??
                        'Error encountered while creating forms.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000
                    }
                );
            });
    };

    return (
        <Modal open handleClose={handleClose} blocking>
            <Modal.Title icon={faPlus}>New Form</Modal.Title>
            <Modal.Body>
                <FormProvider
                    handleSubmit={handleSubmit}
                    control={control}
                    {...methods}
                >
                    <form
                        autoComplete="true"
                        onSubmit={handleSubmit(handleCreate)}
                        noValidate={true}
                        className={styles.form}
                    >
                        <div className={styles.row}>
                            <Controller
                                name="equipment"
                                control={control}
                                rules={{
                                    required:
                                        'You must specify the equipment these forms belongs to.'
                                }}
                                render={({
                                    field: { value, onChange, ref },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Equipment"
                                        required
                                        error={error?.message}
                                    >
                                        <Select
                                            placeholder="Select Equipment"
                                            selected={value}
                                            handleRowSelection={onChange}
                                            pagination={{
                                                url: `/quality/${jobId}/equipment`
                                            }}
                                            getRowValue={(r) => r.name}
                                            sort={['equipment']}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div>
                            <Tabs
                                tabs={[{ key: 'forms', label: 'Forms' }]}
                                active={'forms'}
                            />
                        </div>
                        <div
                            className={styles.row}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '4px'
                            }}
                        >
                            <Controller
                                name="inheritTags"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <Checkbox
                                        label="Inherit Equipment Tags"
                                        checked={value}
                                        handleChange={onChange.bind(
                                            this,
                                            !value
                                        )}
                                    />
                                )}
                            />
                            <Dropdown
                                placeholder="Select Form Type(s)"
                                selected={[]}
                                required={true}
                                options={formTypes?.data?.map((ft) => ({
                                    key: ft.name,
                                    value: ft.name,
                                    ...ft
                                }))}
                                persistent
                                loading={loadingFormTypes}
                                handleSelect={handleAddForm}
                            />
                            {formTypeFields.map((field, index) => (
                                <NewEquipmentFormCard
                                    {...field}
                                    key={field.id}
                                    index={index}
                                    removeFormType={removeFormType}
                                    jobId={jobId}
                                />
                            ))}
                        </div>
                        {errors.root?.serverError && (
                            <div>
                                <ErrorMessage
                                    error={errors.root.serverError.message}
                                />
                            </div>
                        )}
                        <div
                            style={{
                                visibility: 'hidden',
                                opacity: 0
                            }}
                        >
                            <button formAction="submit" ref={submitButtonRef} />
                        </div>
                    </form>
                </FormProvider>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Create"
                    onClick={handleSubmitClicked}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default NewEquipmentFormModal;
