import React from 'react';

import useDimensions from '../../../hooks/useDimensions';

import GridDetailErrorBoundary from '../../config/ErrorBoundaries/GridDetailErrorBoundary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';

import styles from '../../../styles/general/grid/SidePanel.module.scss';

const SidePanel = ({
    sidepanel = {},
    panel,
    hidePanel = () => null,
    togglePanel = () => null,
    panelRef
}) => {
    const [width] = useDimensions();

    return (
        <div className={styles.sidePanel}>
            {sidepanel &&
                typeof sidepanel === 'object' &&
                Object.keys(sidepanel).length > 0 && (
                    <div
                        className={[
                            styles.content,
                            panel && width < 700
                                ? styles.overlay
                                : panel && width < 1200
                                ? styles.modal
                                : null
                        ].join(' ')}
                    >
                        <GridDetailErrorBoundary
                            style={{
                                flexDirection: 'column',
                                fontSize: '1.6rem',
                                width: 400
                            }}
                            updateProps={sidepanel.props}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    height: '100%'
                                }}
                            >
                                {Object.keys(sidepanel)
                                    .filter(
                                        (p) =>
                                            panel ===
                                            (sidepanel[p]?.key
                                                ? sidepanel[p].key
                                                : p)
                                    )
                                    .map((p) => {
                                        let SidepanelComponent =
                                            sidepanel[p]?.component;
                                        return (
                                            <SidepanelComponent
                                                {...(sidepanel[p]?.props ?? {})}
                                                handleClose={hidePanel}
                                                close={width < 700}
                                                ref={
                                                    isFunction(
                                                        SidepanelComponent
                                                    )
                                                        ? null
                                                        : panelRef
                                                }
                                                key={p}
                                            />
                                        );
                                    })}
                            </div>
                        </GridDetailErrorBoundary>
                    </div>
                )}
            {sidepanel &&
                typeof sidepanel === 'object' &&
                Object.keys(sidepanel).length > 0 && (
                    <div className={styles.tabs}>
                        {Object.keys(sidepanel).map((p) => (
                            <div
                                className={[
                                    styles.tab,
                                    panel ===
                                    (sidepanel[p]?.key ? sidepanel[p].key : p)
                                        ? styles.selected
                                        : null
                                ].join(' ')}
                                onClick={togglePanel.bind(
                                    this,
                                    sidepanel[p]?.key ? sidepanel[p].key : p
                                )}
                                key={p}
                            >
                                <FontAwesomeIcon icon={sidepanel[p]?.icon} />
                                <div className={styles.label}>
                                    {sidepanel[p]?.label
                                        ? sidepanel[p].label
                                        : p}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
        </div>
    );
};

SidePanel.propTypes = {
    sidepanel: PropTypes.object,
    panel: PropTypes.string,
    hidePanel: PropTypes.func,
    togglePanel: PropTypes.func
};

export default SidePanel;
