const tryParseJSON = (obj) => {
    try {
        return JSON.parse(obj);
    }
    catch (e) {
        return obj;
    }
}

const removeNulls = arr => arr?.filter?.(a => a !== null && a !== undefined);

export {
    removeNulls,
    tryParseJSON
};