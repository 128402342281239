import { useState } from 'react';

import determineComponent from '../../../js/services/form-inputs';
import { tryParseJSON } from '../../../js/services/manipulation';
import Button from '../input/Button';
import Modal from '../modal/Modal';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useFieldArray } from 'react-hook-form';

import styles from '../../../styles/general/forminputs/FormDynamic.module.scss';

const FormDynamic = ({
    control,
    error,
    id,
    isEditable,
    props,
    styles: style,
    value
}) => {
    const [deleteModal, setDeleteModal] = useState(null);

    const dynamicField = props ? JSON.parse(props) : {};

    const { fields, append, remove } = useFieldArray({
        name: `${id}`,
        control
    });

    const handleNewItem = () => {
        append(
            dynamicField?.fields?.reduce?.(
                (acc, cur) => ({
                    ...acc,
                    [cur.id]: cur.defaultValue ?? null
                }),
                {}
            ) ?? {}
        );
    };

    const handleDeleteItem = (index) => {
        setDeleteModal(index);
    };

    const handleCloseDeleteModal = () => setDeleteModal(null);

    const handleConfirmDelete = (index) => {
        remove(index);
        setDeleteModal(null);
    };

    return (
        <div style={{ width: '100%' }}>
            {dynamicField?.showHeader && (
                <div
                    style={dynamicField?.style}
                    className={styles.lineContainer}
                >
                    {dynamicField.fields?.map?.((f, i) => (
                        <div
                            className={styles.fieldContainer}
                            style={{
                                ...(tryParseJSON(f.style) ?? {})
                            }}
                            key={f.id}
                        >
                            <p>{f.header}</p>
                        </div>
                    ))}
                </div>
            )}
            {fields && Array.isArray(fields) && (
                <div style={dynamicField?.containerStyle}>
                    {fields.map((v, i) => (
                        <div
                            style={dynamicField?.style}
                            className={styles.lineContainer}
                            key={v.id}
                        >
                            {dynamicField.fields?.map?.((f, fieldIndex) => (
                                <div
                                    style={tryParseJSON(f.style) ?? {}}
                                    key={fieldIndex}
                                >
                                    <FormDynamicLineItem
                                        control={control}
                                        dynamicFieldId={`${id}`}
                                        isEditable={isEditable}
                                        item={f}
                                        index={i}
                                    />
                                </div>
                            ))}
                            {isEditable && (
                                <div
                                    className={styles.removeLine}
                                    style={dynamicField?.deleteIconStyle}
                                    onClick={handleDeleteItem.bind(this, i)}
                                >
                                    <FontAwesomeIcon
                                        icon={faTrashAlt}
                                        className={styles.removeIcon}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                    {isEditable && (
                        <div
                            className={styles.newItem}
                            onClick={handleNewItem}
                            style={dynamicField?.buttonStyle}
                        >
                            <FontAwesomeIcon
                                icon={faPlus}
                                fontSize={'.7rem'}
                                className={styles.icon}
                            />
                            <p>{dynamicField?.newPrompt ?? 'New Line Item'}</p>
                        </div>
                    )}
                </div>
            )}
            {deleteModal !== null && (
                <Modal open handleClose={handleCloseDeleteModal}>
                    <Modal.Title>
                        {dynamicField?.deletePrompt ?? 'Delete Line Item'}
                    </Modal.Title>
                    <Modal.Body>
                        <div className={styles.confirmationMessage}>
                            {dynamicField?.deleteMessage ??
                                'Are you sure you want to delete this line item?'}
                        </div>
                    </Modal.Body>
                    <Modal.Actions>
                        <Button
                            onClick={handleCloseDeleteModal}
                            label={'Cancel'}
                            type="secondary"
                        />
                        <Button
                            onClick={handleConfirmDelete.bind(
                                this,
                                deleteModal
                            )}
                            label={'Confirm'}
                        />
                    </Modal.Actions>
                </Modal>
            )}
        </div>
    );
};

const FormDynamicLineItem = ({
    control,
    dynamicFieldId,
    isEditable,
    item,
    index
}) => (
    <Controller
        control={control}
        name={`${dynamicFieldId}.${index}.${item?.id}`}
        render={({ field: { value, onChange }, fieldState: { error } }) =>
            determineComponent({
                type: item.type,
                props: {
                    ...(item.props ?? {}),
                    label: item.props?.label?.includes?.('{{index}}')
                        ? item.props.label.replace('{{index}}', index + 1)
                        : null,
                    options: item.props?.options && tryParseJSON(item.props.options)
                },
                isEditable,
                value: tryParseJSON(value),
                onChange: onChange,
                error: error
            })
        }
    />
);

export default FormDynamic;
