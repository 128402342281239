import jobReducer from './jobReducer';
import navigationReducer from './navigationReducer';
import userReducer from './userReducer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    user: userReducer,
    job: jobReducer,
    navigation: navigationReducer
});

export default rootReducer;
