import { useEffect } from 'react';

import useFetch from '../../../hooks/useFetch';

import Button from '../input/Button';
import LogoLoader from '../LogoLoader';
import Modal from './Modal';
import {
    faFileCircleExclamation,
    faRedo,
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';

import styles from '../../../styles/general/modal/S3DownloadModal.module.scss';

const S3DownloadModal = ({
    handleClose,
    url,
    fileName,
    message = 'Downloading file...'
}) => {
    const [{ error }, downloadFile] = useFetch(url, 'GET', {
        manual: true
    });

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        retrieveS3File();
    }, []); //eslint-disable-line

    const retrieveS3File = () => {
        downloadFile()
            .then((res) => res?.blob?.())
            .then((file) => {
                var objectURL = URL.createObjectURL(file);
                let element = document.createElement('a');
                element.href = objectURL;
                element.target = '_blank';
                element.download = fileName;
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                URL.revokeObjectURL(objectURL);
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Download Failed', {
                    variant: 'error',
                    autoHideDuration: 3000,
                    preventDuplicate: true
                });
            })
            .finally(() => handleClose());
    };

    return (
        <Modal open handleClose={handleClose}>
            <Modal.Body>
                {!error ? (
                    <div className={styles.container}>
                        <div className={styles.downloadMessage}>{message}</div>
                        <div className={styles.downloadLoader}>
                            <LogoLoader size={40} hideLogo />
                        </div>
                    </div>
                ) : (
                    <div className={styles.container}>
                        <div className={styles.errorMessage}>
                            We encountered an error and could not download this
                            file.
                        </div>
                        <div className={styles.errorIcon}>
                            <FontAwesomeIcon icon={faFileCircleExclamation} />
                        </div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Actions>
                {!error ? (
                    <Button
                        label="Cancel"
                        type="grayscale"
                        icon={faTimes}
                        onClick={handleClose}
                    />
                ) : (
                    <Button
                        label="Retry"
                        icon={faRedo}
                        type="grayscale"
                        onClick={retrieveS3File}
                    />
                )}
            </Modal.Actions>
        </Modal>
    );
};

export default S3DownloadModal;
