import { useEffect } from 'react';

import useApi from '../../../../hooks/useApi';
import useLocalStorage from '../../../../hooks/useLocalStorage';

import Card from './Card';
import { useNavigate } from 'react-router-dom';

import styles from '../../../../styles/apps/quality/card/RecentEquipmentCard.module.scss';

const RecentEquipmentCard = ({ jobId }) => {
    const navigate = useNavigate();
    const [, setEquipmentFilter] = useLocalStorage('quality_equipment_grid_filters');

    const [{ data: equipment, loading }, getRecentEquipment] = useApi(
        `/quality/${jobId}/equipment/recent`,
        'GET',
        { manual: true }
    );

    const handleEquipmentNavigate = (id, name) => {
        setEquipmentFilter({
            equipment:
                !id || !name
                    ? []
                    : [
                          {
                              id,
                              name
                          }
                      ]
        });
        navigate('../equipment');
    };

    const handleAllEquipmentNavigate = () => handleEquipmentNavigate();

    useEffect(() => {
        getRecentEquipment();
    }, [jobId]); //eslint-disable-line

    return (
        <Card
            classes={{
                container: styles.card,
                content: styles.content
            }}
            style={{
                container: {
                    minWidth: '450px'
                }
            }}
            header={<p>Recently Created/Modified Equipment</p>}
            content={
                <div>
                    <div className={[styles.row, styles.header].join(' ')}>
                        <div className={styles.name}>
                            <p>Name</p>
                        </div>
                        <div className={styles.date}>
                            <p>Modified</p>
                        </div>
                        <div className={styles.date}>
                            <p>Created</p>
                        </div>
                    </div>
                    {equipment?.length > 0 ? (
                        equipment?.map((e) => (
                            <div className={styles.row}>
                                <div className={styles.name}>
                                    <p
                                        onClick={handleEquipmentNavigate.bind(
                                            this,
                                            e.id,
                                            e.name
                                        )}
                                    >
                                        {e.name}
                                    </p>
                                </div>
                                <div className={styles.date}>
                                    <p>
                                        {new Intl.DateTimeFormat('en-US', {
                                            month: '2-digit',
                                            day: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        }).format(new Date(e.modifiedOn))}
                                    </p>
                                </div>
                                <div className={styles.date}>
                                    <p>
                                        {new Intl.DateTimeFormat('en-US', {
                                            month: '2-digit',
                                            day: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        }).format(new Date(e.createdOn))}
                                    </p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className={styles.noEquipment}>
                            <p>There is no equipment for this job.</p>
                        </div>
                    )}
                    <div className={styles.footer}>
                        <p onClick={handleAllEquipmentNavigate}>
                            View All Equipment
                        </p>
                    </div>
                </div>
            }
            loading={!equipment || loading}
        />
    );
};

export default RecentEquipmentCard;
