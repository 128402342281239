import React from 'react';

import NavBar from './NavBar';
import NavMenu from './NavMenu';
import { useIsAuthenticated } from '@azure/msal-react';
import PropTypes from 'prop-types';

import styles from '../../styles/navigation/Navigation.module.scss';

/**
 * Container for the Navigation components
 * @param visible - Whether or not to show navigation on the page
 * @param render - Provides content to render in the area not taken up by Navigation
 */
const Navigation = ({ visible = true, children, basic = false, login }) => {
    let menuButtonRef = React.useRef();

    const isAuthenticated = useIsAuthenticated();

    return (
        <div className={styles.navigationWrapper}>
            {visible && (
                <NavBar
                    styles={styles}
                    menuButtonRef={menuButtonRef}
                    authenticated={isAuthenticated}
                    basic={basic}
                    login={login}
                />
            )}
            <div className={styles.navigationBody}>
                {visible && isAuthenticated && !basic && (
                    <div className={styles.navMenuContainer}>
                        <NavMenu
                            styles={styles}
                            menuButtonRef={menuButtonRef}
                        />
                    </div>
                )}
                <div className={styles.bodyContainer}>
                    <div className={styles.bodyContent}>{children}</div>
                </div>
            </div>
        </div>
    );
};

Navigation.propTypes = {
    visible: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ])
};

export default Navigation;
