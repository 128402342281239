import React, { useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import styles from '../../../styles/general/input/TextInput.module.scss';

const TextInput = ({
    autoComplete,
    expand,
    placeholder,
    value,
    onChange = () => null,
    onEnter,
    fontSize = '13px',
    lines = 1,
    icon,
    readonly = false,
    inputRef,
    classes,
    style = {},
    autoFocus = false,
    name
}) => {
    const minHeight = useRef(0);
    const innerInputRef = useRef(null);

    useEffect(() => {
        if (!expand) return;
        minHeight.current = innerInputRef?.current?.clientHeight;
        setTimeout(() => {
            minHeight.current = innerInputRef?.current?.clientHeight;
            innerInputRef.current.style.height = 0;
            innerInputRef.current.style.height = `${
                minHeight.current >= innerInputRef.current.scrollHeight
                    ? minHeight.current
                    : innerInputRef.current.scrollHeight
            }px`;
        }, 100);
    }, [expand]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (onEnter) onEnter?.(e);
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const handleChange = (e) => {
        if (expand) e.target.style.height = 0;

        onChange?.(e);

        if (expand)
            e.target.style.height = `${
                minHeight.current >= e.target.scrollHeight
                    ? minHeight.current
                    : e.target.scrollHeight
            }px`;
    };

    return (
        <div
            className={[
                styles.container,
                icon ? styles.iconInput : null,
                classes?.container
            ].join(' ')}
            style={{ fontSize: fontSize, ...style.container }}
        >
            {lines > 1 || expand ? (
                <textarea
                    value={value}
                    rows={lines}
                    onChange={readonly ? null : handleChange}
                    placeholder={placeholder}
                    ref={inputRef ?? innerInputRef}
                    className={[
                        styles.input,
                        readonly ? styles?.readonly : null,
                        classes?.input
                    ].join(' ')}
                    readOnly={readonly}
                    autoFocus={autoFocus}
                    style={{
                        ...style.input
                    }}
                    autoComplete={autoComplete}
                    name={name}
                ></textarea>
            ) : (
                <React.Fragment>
                    {icon && (
                        <span className={styles.icon} style={{ ...style.icon }}>
                            <FontAwesomeIcon icon={icon} />
                        </span>
                    )}
                    <input
                        type="text"
                        value={value}
                        onChange={readonly ? null : handleChange}
                        onKeyDown={handleKeyDown}
                        placeholder={placeholder}
                        autoFocus={autoFocus}
                        className={[
                            styles.input,
                            readonly ? styles?.readonly : null,
                            classes?.input
                        ].join(' ')}
                        ref={inputRef}
                        readOnly={readonly}
                        style={{ ...style.input }}
                        autoComplete={autoComplete}
                        name={name}
                    />
                </React.Fragment>
            )}
        </div>
    );
};

TextInput.propTypes = {
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    fontSize: PropTypes.string,
    lines: PropTypes.number,
    readonly: PropTypes.bool,
    inputRef: PropTypes.oneOf([
        PropTypes.shape({
            current: PropTypes.any
        }),
        PropTypes.func
    ]),
    icon: PropTypes.object,
    classes: PropTypes.shape({
        input: PropTypes.object,
        container: PropTypes.object
    }),
    style: PropTypes.object
};

export default TextInput;
