import monitorReducersEnhancer from '../enhancers/monitorReducer';
import loggerMiddleware from '../middleware/logger';
import rootReducer from '../reducers/rootReducer';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

let createStore = (preloadedState) => {
    const store = configureStore({
        reducer: rootReducer,
        middleware: [loggerMiddleware, ...getDefaultMiddleware()],
        preloadedState,
        enhancers: [monitorReducersEnhancer]
    });

    if (process.env.REACT_APP_ENV === 'local' && module.hot) {
        module.hot.accept('../reducers/rootReducer', () =>
            store.replaceReducer(rootReducer)
        );
    }

    return store;
};

export default createStore;
