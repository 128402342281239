import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../../../../styles/general/grid/row renderers/ErrorRow.module.scss';

const ErrorRow = () => (
    <div className={[styles.error, 'BaseTable__row-cell'].join(' ')}>
        <span className={styles.icon}>
            <FontAwesomeIcon icon={faWarning} />
        </span>
        <span className={styles.message}>
            We ran into an issue when retrieving the data.
        </span>
    </div>
);
export default ErrorRow;
