import CurrentJobCard from './card/CurrentJobCard';
import RecentCreatedFormCard from './card/RecentCreatedFormCard';
import RecentEquipmentCard from './card/RecentEquipmentCard';
import RecentFormCard from './card/RecentFormCard';
import { useOutletContext } from 'react-router-dom';

import styles from '../../../styles/apps/quality/JobOverview.module.scss';

const JobOverview = () => {
    const { job, handleChangeJob } = useOutletContext();

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <CurrentJobCard
                    jobId={job.id}
                    handleChangeJob={handleChangeJob}
                />
                <RecentEquipmentCard jobId={job.id} />
            </div>
            <div className={styles.row}>
                <RecentFormCard jobId={job.id} />
                <RecentCreatedFormCard jobId={job.id} />
            </div>
        </div>
    );
};

export default JobOverview;
