import { createReducer } from '@reduxjs/toolkit';

const setJob = (_, action) => action.job;

const incrementJob = (jobState) => +jobState + 1;

const jobReducer = createReducer('12345', {
    SET_JOB: setJob,
    INCREMENT_JOB: incrementJob
});

export default jobReducer;
