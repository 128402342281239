import { msalInstance } from './auth';
import { apiBaseUrl } from './url';
import axios from 'axios';

const devApiScopes = [
    'api://c714bb29-689d-48c6-bc87-0a92bfc83b6a/access_as_user'
];
const prodApiScopes = [
    'api://2c822a1d-57ce-4008-96ce-f27fdfea431d/access_as_user'
];

const apiScopes =
    process.env.REACT_APP_ENV === 'prod' ? prodApiScopes : devApiScopes;

const recaptchaSiteKey =
    process.env.REACT_APP_ENV === 'prod'
        ? '6LeDBG8mAAAAAK1kyBUOhXXrVyQn-xEcK-OxuxCC'
        : process.env.REACT_APP_ENV === 'dev'
        ? '6LdUAG8mAAAAABaa3SIutS5PgEl2X5JbnqMDvV4U'
        : '6LdUAG8mAAAAABaa3SIutS5PgEl2X5JbnqMDvV4U';

const instance = axios.create({
    baseURL: apiBaseUrl
});

//Intercept axios requests to add authorization header with token
instance.interceptors.request.use(
    async (response) => {
        const account = msalInstance.getAllAccounts()?.[0];
        if (account) {
            const msalResponse = await msalInstance.acquireTokenSilent({
                scopes: apiScopes,
                account: account
            });
            response.headers[
                'Authorization'
            ] = `Bearer ${msalResponse.accessToken}`;
        }
        return response;
    },
    (error) => Promise.reject(error)
);

export { apiScopes, recaptchaSiteKey };
export default instance;
