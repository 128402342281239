import { useEffect, useState } from 'react';

import { debounce as _debounce } from 'lodash';
import PropTypes from 'prop-types';

const useDimensions = (options = {}) => {
    const { ref, debounce } = options;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const resizeAction = (entries) => {
        setWidth(entries[0]?.contentRect?.width);
        setHeight(entries[0]?.contentRect?.height);
    };

    const handleResize = debounce ? _debounce(resizeAction, 80) : resizeAction;

    useEffect(() => {
        let element = ref?.current ?? document.getElementsByTagName('body')[0];

        const observer = new ResizeObserver(handleResize);

        observer.observe(element);

        return () => {
            observer.disconnect();
        };
    }, []); //eslint-disable-line

    return [width, height];
};

useDimensions.propTypes = {
    options: PropTypes.shape({
        debounce: PropTypes.bool,
        ref: PropTypes.object
    })
};

export default useDimensions;
