import { useEffect } from 'react';

import useApi from '../../../../hooks/useApi';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import useUser from '../../../../hooks/useUser';

import { TextTooltip } from '../../../general/Tooltip';
import Card from './Card';
import { faEye, faPencilRuler } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

import styles from '../../../../styles/apps/quality/card/RecentFormCard.module.scss';

const RecentFormCard = ({ jobId }) => {
    const navigate = useNavigate();

    const [{ data: forms, loading }, getRecentForms] = useApi(
        `/quality/${jobId}/forms/recent`,
        'GET',
        { manual: true }
    );
    const [, setFormFilter] = useLocalStorage('quality_form_grid_filters');

    const { userHasPermissions, userHasJobPermissions } = useUser();

    const handleFormNavigate = (name, equipment_id, equipment_name) => {
        setFormFilter({
            equipment: equipment_id && equipment_name ? [
                {
                    id: equipment_id,
                    name: equipment_name
                }
            ] : [],
            formName: name
        });
        navigate('../forms');
    };

    const handleEquipmentNavigate = (equipment_id, equipment_name) => {
        setFormFilter({
            equipment: [
                {
                    id: equipment_id,
                    name: equipment_name
                }
            ]
        });
        navigate('../forms');
    };

    const handleAllFormsNavigate = () => handleFormNavigate();

    useEffect(() => {
        getRecentForms();
    }, [jobId]); //eslint-disable-line

    const handleEdit = ({id, currentStep}) => {
        navigate(`/quality/forms/${id}/${currentStep?.action?.toLowerCase?.()}`);
    };

    const handleView = (id) => {
        navigate(`/quality/forms/${id}/view`);
    };

    return (
        <Card
            classes={{
                container: styles.card,
                content: styles.content
            }}
            header={<p>Recently Modified Forms</p>}
            content={
                <div>
                    <div className={[styles.row, styles.header].join(' ')}>
                        <div className={styles.name}>
                            <p>Name</p>
                        </div>
                        <div className={styles.equipment}>
                            <p>Equipment</p>
                        </div>
                        {/* <div className={styles.date}>
                            <p>Modified</p>
                        </div> */}
                        <div className={styles.step}>
                            <p>Current Step</p>
                        </div>
                        <div className={styles.actions}></div>
                    </div>
                    {forms?.length > 0 ? (
                        forms?.map((f) => (
                            <div className={styles.row}>
                                <div className={styles.name}>
                                    <p
                                        onClick={handleFormNavigate.bind(
                                            this,
                                            f.name,
                                            f.equipmentId,
                                            f.equipmentName
                                        )}
                                    >
                                        {f.name}
                                    </p>
                                </div>
                                <div className={styles.equipment}>
                                    <p
                                        onClick={handleEquipmentNavigate.bind(
                                            this,
                                            f.equipmentId,
                                            f.equipmentName
                                        )}
                                    >
                                        {f?.equipmentName}
                                    </p>
                                </div>
                                {/* <div className={styles.date}>
                                    <p>
                                        {f.modifiedOn &&
                                            new Intl.DateTimeFormat('en-US', {
                                                timeZone: 'UTC',
                                                month: '2-digit',
                                                day: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            }).format(new Date(f?.modifiedOn))}
                                    </p>
                                </div> */}
                                <div className={styles.step}>
                                    <p>
                                        {f.currentStep?.action
                                            ? f.currentStep.action
                                            : f.currentStep?.name}
                                    </p>
                                </div>
                                <div className={styles.actions}>
                                    <div
                                        className={styles.action}
                                        onClick={handleView.bind(this, f.id)}
                                    >
                                        <TextTooltip
                                            tooltip="View"
                                            hoverTrigger="always"
                                        >
                                            <FontAwesomeIcon
                                                icon={faEye}
                                                style={{
                                                    verticalAlign: 'middle'
                                                }}
                                            />
                                        </TextTooltip>
                                    </div>
                                    {userHasPermissions(
                                        f.currentStep?.permission
                                    ) ||
                                    userHasJobPermissions(
                                        f.currentStep?.permission
                                    ) ? (
                                        <div
                                            className={styles.action}
                                            onClick={handleEdit.bind(
                                                this,
                                                f
                                            )}
                                        >
                                            <TextTooltip
                                                tooltip={f.currentStep?.action}
                                                hoverTrigger="always"
                                            >
                                                <FontAwesomeIcon
                                                    icon={f?.currentStep?.icon ?? faPencilRuler}
                                                    style={{
                                                        verticalAlign: 'middle'
                                                    }}
                                                />
                                            </TextTooltip>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className={styles.noForms}>
                            <p>There are no recently modified forms for this job.</p>
                        </div>
                    )}
                    <div className={styles.footer}>
                        <p onClick={handleAllFormsNavigate}>View All Forms</p>
                    </div>
                </div>
            }
            loading={!forms || loading}
        />
    );
};

export default RecentFormCard;
