import { useRef } from 'react';

import useApi from '../../../../hooks/useApi';

import Button from '../../../general/input/Button';
import InputLabel from '../../../general/input/InputLabel';
import Modal from '../../../general/modal/Modal';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import styles from '../../../../styles/apps/quality/modal/NewQualityTagModal.module.scss';
import QualityTagInput from '../QualityTagInput';

const NewQualityTagModal = ({ handleClose, handleNew, job }) => {
    const submitButtonRef = useRef();

    const [{ loading }, createTag] = useApi('/quality/tag', 'POST', {
        manual: true
    });

    const { enqueueSnackbar } = useSnackbar();
    const { handleSubmit, control } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            qualityTags: []
        }
    });

    const handleCreate = (data) => {
        createTag({
            data: {
                jobId: job,
                qualityTags: data.qualityTags.map((qt) => ({ name: qt.name }))
            }
        })
            .then((tag) => {
                handleNew(tag);
                enqueueSnackbar('Tag created successfully.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                handleClose();
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Error encountered while creating tag.', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });
    };

    const handleSubmitClicked = () => submitButtonRef?.current?.click?.();

    return (
        <Modal open handleClose={handleClose} blocking>
            <Modal.Title icon={faPlus}>New Tag</Modal.Title>
            <Modal.Body>
                <form
                    autoComplete="true"
                    onSubmit={handleSubmit(handleCreate)}
                    noValidate={true}
                    className={styles.form}
                >
                    <div className={styles.row}>
                        <Controller
                            name="qualityTags"
                            control={control}
                            rules={{
                                required: 'You must provide a tag name.'
                            }}
                            render={({
                                field: { value, onChange, ref },
                                fieldState: { error }
                            }) => (
                                <InputLabel
                                    label="Tags"
                                    required
                                    error={error?.message}
                                >
                                    <QualityTagInput
                                        onChange={onChange}
                                        value={value}
                                        hideSelect
                                    />
                                </InputLabel>
                            )}
                        />
                    </div>
                    <div
                        style={{
                            visibility: 'hidden',
                            opacity: 0
                        }}
                    >
                        <button formAction="submit" ref={submitButtonRef} />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Create"
                    onClick={handleSubmitClicked}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default NewQualityTagModal;
